/*!* fullPage 3.1.1* https://github.com/alvarotrigo/fullPage.js** @license GPLv3 for open source use only * or Fullpage Commercial License for commercial use * http://alvarotrigo.com/fullPage/pricing/** Copyright (C) 2018 http://alvarotrigo.com/fullPage - A project by Alvaro Trigo*/
html.fp-enabled,
.fp-enabled body {
    margin: 0;
    padding: 0;
    overflow: hidden;

    /*Avoid flicker on slides transitions for mobile phones #336 */
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.fp-section {
    position: relative;
    -webkit-box-sizing: border-box;
    /* Safari<=5 Android<=3 */
    -moz-box-sizing: border-box;
    /* <=28 */
    box-sizing: border-box;
    padding: 100px 0 0;
}

.fp-slide {
    float: left;
}

.fp-slide,
.fp-slidesContainer {
    height: 100%;
    display: block;
}

.fp-slides {
    z-index: 1;
    height: 100%;
    overflow: hidden;
    position: relative;
    -webkit-transition: all 0.3s ease-out;
    /* Safari<=6 Android<=4.3 */
    transition: all 0.3s ease-out;
}

.fp-section.fp-table,
.fp-slide.fp-table {
    display: table;
    table-layout: fixed;
    width: 100%;
}

.fp-tableCell {
    display: table-cell;
    vertical-align: middle;
    width: 100%;
    height: 100%;
}

.fp-slidesContainer {
    float: left;
    position: relative;
}

.fp-controlArrow {
    -webkit-user-select: none;
    /* webkit (safari, chrome) browsers */
    -moz-user-select: none;
    /* mozilla browsers */
    -khtml-user-select: none;
    /* webkit (konqueror) browsers */
    -ms-user-select: none;
    /* IE10+ */
    position: absolute;
    z-index: 4;
    top: 50%;
    cursor: pointer;
    width: 0;
    height: 0;
    border-style: solid;
    margin-top: -38px;
    -webkit-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

.fp-controlArrow.fp-prev {
    left: 15px;
    width: 0;
    border-width: 38.5px 34px 38.5px 0;
    border-color: transparent #fff transparent transparent;
}

.fp-controlArrow.fp-next {
    right: 15px;
    border-width: 38.5px 0 38.5px 34px;
    border-color: transparent transparent transparent #fff;
}

.fp-scrollable {
    overflow: hidden;
    position: relative;
}

.fp-scroller {
    overflow: hidden;
}

.iScrollIndicator {
    border: 0 !important;
}

.fp-notransition {
    -webkit-transition: none !important;
    transition: none !important;
}

.fp-auto-height.fp-section,
.fp-auto-height .fp-slide,
.fp-auto-height .fp-tableCell {
    height: auto !important;
}

.fp-responsive .fp-auto-height-responsive.fp-section,
.fp-responsive .fp-auto-height-responsive .fp-slide,
.fp-responsive .fp-auto-height-responsive .fp-tableCell {
    height: auto !important;
}