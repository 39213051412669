@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800&family=Poppins:wght@100;200;300;400;500;600;700;800&display=swap');

body {
   padding: 0;
   margin: 0;
   background-color: #101214 !important;
   font-family: 'Poppins', sans-serif;
}

ul,
li,
ol,
p,
span,
strong,
img,
a,
h1,
h2,
h3,
h4,
h5,
h6 {
   padding: 0;
   margin: 0;
}

a:focus,
a:hover,
button:focus,
button:hover {
   outline: none;
}

.mobile_html .success {
   margin: 10px 0 0;
   padding: 15px 10px;
   text-align: center;
   border-radius: 10px;
}

.mobile_html .form-error {
   margin: 10px 0 0;
   padding: 15px 10px;
   text-align: center;
   border-radius: 10px;
}

.mobile_html .container {
   max-width: 768px;
   padding: 0 8px;
}

.mobile_html .text-center {
   text-align: center;
}

.mobile_html .text-left {
   text-align: left;
}

.mobile_html .text-right {
   text-align: right;
}

.mobile_html .bg-dark {
   background-color: #101214 !important;
}

.mobile_html .header_min {
   padding: 89px 0 22px
      /*32px 0*/
   ;
   position: fixed;
   z-index: 9;
   left: 0;
   top: 0;
   width: 100%;
   background: #000;
}

.mobile_html .header_min a+span {
   position: absolute;
   color: #414141;
   left: 65px;
   top: 4px;
   font-weight: 500;
   font-size: 15px;
}

.mobile_html .header_min .container {
   position: relative;
}




.mobile_html .btn.btn-primary {
   text-transform: uppercase;
   overflow: hidden;
   background: #e4a400 !important;
   border: 0;
   border-radius: 50px;
   letter-spacing: 1.6px;
   padding: 11px 22px;
   font-size: 13px;
}

.mobile_html .hero_slide .container {
   position: relative;
}

.mobile_html .mob-footer a.btn.btn-primary {
   text-transform: uppercase;
   font-weight: 600;
   color: #fff;
}

.mobile_html .crc_head .btn.btn-primary {
   text-transform: uppercase;
   font-weight: 600;
}

.mobile_html .top_heading h2 {
   color: #fff;
   font-size: 30px;
   line-height: 35px;
}

.mobile_html .top_heading p {
   color: #e4a400;
   font-size: 13px;
   line-height: 40px;
}

.mobile_html .intl-tel-input.separate-dial-code .selected-dial-code {
   color: #dedede;
}

.mobile_html .intl-tel-input .selected-flag .iti-arrow {
   right: 0px;
}

.mobile_html .tst_img {
   width: 50px;
   height: 50px;
   overflow: hidden;
   border-radius: 50%;
   border: 2px solid #FDCB04;
   margin-right: 10px;
}

.mobile_html .tst_img img {
   width: 100%;
   height: 100%;
   object-fit: cover;
}

.mobile_html .tst_cnt {
   flex: 0 0 calc(100% - 60px)
}

.mobile_html .tst_cnt h4 {
   font-size: 11px;
   font-weight: 400;
   margin-bottom: 5px;
   margin-top: 8px;
}

.mobile_html .tst_cnt h5 {
   font-size: 11px;
   font-weight: 400;
   opacity: .7;
}

.mobile_html .tst_rating {
   font-size: 10px;
   margin-top: 15px;
}

.mobile_html .tst_msg {
   font-size: 12px;
   margin-top: 16px;
}

.mobile_html .mob_container .carousel-inner {
   padding: 10px 25px;
   margin-top: -10px;
}

.mobile_html .mobile_testimonial_bx {
   box-shadow: 5px 5px 16px 0 rgba(0, 0, 0, 0.2);
   padding: 15px 15px 35px;
   border: solid 1px #626060;
   border-radius: 10px;
   background: #101214;
   color: #fff;
}

.mobile_html .mob_container .top_heading {
   padding: 0px 30px;
}

.mobile_html .tst_rating .fa {
   color: #e1574a;
}

.mobile_html .da-cluch-bx {
   padding: 0 30px;
}

.mobile_html .da-cluch-bx ul {
   border: 1px solid rgba(255, 255, 255, 0.2);
   border-radius: 10px;
   margin-top: 110px;
   padding: 10px 15px;
}

.mobile_html .da-cluch-bx ul li {
   display: flex;
   margin-bottom: 10px;
   margin-top: 10px;
   height: 70px;
}

.mobile_html .da-cluch-bx ul li p {
   color: #fff;
   margin-left: 15px;
   font-size: 13px;
   text-transform: uppercase;
   opacity: .7;
}

.mobile_html .mobile_testimonial .carousel-indicators button {
   text-indent: 0;
   height: 36px;
   border: 0;
   text-align: left;
   background: transparent;
   color: #fff;
   border-bottom: 2px solid #fff;
   margin: 0;
   width: 42px;
   padding: 0 6px;
   box-sizing: border-box;
}

.mobile_html .mobile_testimonial .carousel-indicators {
   bottom: -80px;
}

.mobile_html .mobile_serv {
   padding: 50px 0;
}

.mobile_html .mobile_serv#jobs_min_mob {
   padding: 100px 0 50px;
}

.mobile_html .mobile_serv #accordionExample {
   overflow: hidden;
}



.mobile_html .srv_bx {
   padding: 40px 0;
}

.mobile_html .mobile_serv .top_heading p {
   color: #e4a400;
   font-size: 13px;
   line-height: 22px;
   margin-top: 10px;
}

.mobile_html .mobile_serv .accordion-item {
   background: #121212;
   color: #fdfdfd;
   border: 1px solid #222 !important;
   font-size: 12px;
   line-height: 20px;
   border-left: 0;
   border-right: 0;
}

.mobile_html .mobile_serv .accordion-item .accordion-collapse p {
   opacity: .7;
   font-size: 12px;
   line-height: 26px;
}

.mobile_html .mobile_serv .accordion-item .accordion-button {
   background: #121212;
   color: #f9f9f9;
   padding: 25px 20px;
}

.mobile_html .accordion-button::after {
   flex-shrink: 0;
   width: 12px;
   height: 8px;
   margin-left: auto;
   content: "";
   background-image: url("https://d2e3cmtjkfw2a3.cloudfront.net/static/media/mobile_img/caret-down.png");
   background-repeat: no-repeat;
   background-size: 1.25rem;
   transition: transform .2s ease-in-out;
   background-size: 100%;
}

.mobile_html .accordion-button:not(.collapsed)::after {
   background-image: url("https://d2e3cmtjkfw2a3.cloudfront.net/static/media/mobile_img/caret-down.png");
   transform: rotate(180deg);
}

.mobile_html .mobile_serv .accordion-item:first-child {
   border-top: 0;
}

.mobile_html .mobile_serv .accordion-item:last-child {
   border-bottom: 0;
}

.mobile_html .mobile_client {
   padding: 60px 0 40px;
}

.mobile_html .mobile_client h4 {
   color: #e4a400;
   font-size: 15px;
   margin-bottom: 40px;
   line-height: 28px;
}

.mobile_html .mobile_client li {
   list-style: none;
   text-align: center;
   margin: 20px 0;
}

.mobile_html .mobile_testimonial .top_heading {
   margin-bottom: 40px;
   padding: 0 30px;
}



.mobile_html .mobile_testimonial {
   padding: 50px 0;
}

.mobile_html .mob-footer {
   background: #000;
   padding: 50px 0;
}

.mobile_html .mob-footer img {
   margin-bottom: 40px;
   display: block;
}

.mobile_html .mob-footer h2 {
   margin-top: 30px;
   color: #dbdbdb;
   font-size: 20px;
   font-weight: 500;
   margin-bottom: 15px;
}

.mobile_html .mob-footer a {
   color: #dbdbdb;
   font-size: 12px;
   text-decoration: none;
}

.mobile_html .mob-footer li {
   list-style: none;
   margin-bottom: 5px;
}

.mobile_html .mob-footer .social li {
   margin: 0;
   display: inline-block;
   padding-right: 10px;
}

.mobile_html .mob-footer .social li a {
   font-size: 20px;
}

.mobile_html .mob-footer .foot-inline li {
   display: inline-block;
   margin-top: 30px;
   padding-right: 3px;
}

.mobile_html .mob-footer .da_copy {
   color: #dfdfdf;
   font-size: 10px;
   margin-top: 5px;
}

.mobile_html .lets_talk {
   background: url(https://d2e3cmtjkfw2a3.cloudfront.net/static/media/about/BG.jpg);
   background-position: top left;
   background-size: cover;
   padding: 50px 0;
   position: relative;
   background-attachment: fixed;
}

.mobile_html .lets_talk input.form-control {
   background: transparent;
   border: solid 1px #818181;
   border-radius: 50px;
   height: 48px;
   padding: 10px 40px;
}

.mobile_html .lets_talk textarea.form-control {
   background: transparent;
   border: solid 1px #818181;
   border-radius: 15px;
   height: 80px;
   padding: 10px 40px;
}

.mobile_html .lets_talk h3 {
   color: #fdfdfd;
   font-size: 30px;
   font-weight: 600;
   margin-bottom: 20px;
}

.mobile_html .lets_talk p {
   color: #afafaf;
   max-width: 400px;
   line-height: 28px;
   margin-bottom: 30px;
   font-size: 12px;
}

.mobile_html .lets_talk p a {
   display: block;
   color: #e4a400;
   text-decoration: none;
   font-size: 12px;
}

.mobile_html .lets_talk .form-group {
   margin-bottom: 30px;
}

.mobile_html .lets_talk .form-group label {
   color: #fdfdfd;
   font-size: 12px;
   margin-bottom: 10px;
}

.mobile_html .btn.btn-dark.btn-line {
   font-size: 13px;
   background: transparent;
   color: #fdfdfd;
   border: 2px solid #fdfdfd;
   border-radius: 50px;
   padding: 10px 50px;
   overflow: hidden;
}

.mobile_html .srv_talk.lets_talk .btn.btn-primary.btn-dark.btn-line.hvr-sweep-to-right {
   background: transparent !important;
}



.mobile_html .srv_img {
   max-height: 315px;
}

.mobile_html .srv_img img {
   margin-top: -2px;
}

.mobile_html .mob-services .srv_cnt {
   padding: 20px;
   color: #f9f9f9;
}

.mobile_html .mob-services h3 {
   font-size: 16px;
   margin-bottom: 10px;
}

.mobile_html .mob-services p {
   opacity: .6;
   font-size: 12px;
   line-height: 20px;
   margin-bottom: 18px;
}

.mobile_html .crc_head {
   padding: 180px 0 60px;
}

.mobile_html .crc_head h2 {
   color: #f9f9f9;
   font-size: 30px;
   line-height: 50px;
   margin-bottom: 40px;
}

.mobile_html .team_tst {
   padding: 100px 0;
}

.mobile_html .tm_img {
   width: 180px;
   overflow: hidden;
   height: 150px;
   box-shadow: 5px 5px 16px 0 rgba(0, 0, 0, 0.3);
   border-radius: 20px;
   background: #101214;
}

.mobile_html .tm_img img {
   width: 100%;
   height: 100%;
   object-fit: contain;
}

.mobile_html .team_tst h3 {
   color: #f9f9f9;
   margin-top: 30px;
   letter-spacing: 3px;
   font-weight: 600;
   font-size: 16px;
   text-transform: uppercase;
   margin-bottom: 4px;
}

.mobile_html .team_tst h5 {
   min-height: 150px;
   color: #f9f9f9;
   font-weight: 600;
   font-size: 16px;
   line-height: 28px;
   margin-bottom: 70px;
}

.mobile_html .team_tst p {
   color: #f9f9f9;
   font-size: 12px;
   margin-bottom: 4px;
   font-weight: 600;
   margin-bottom: 20px;
}


.mobile_html .mn_slider {
   overflow-x: scroll;
   overflow-y: hidden;
}

.mobile_html .mn_slider ul {
   display: flex;
}

.mobile_html .mn_slider li {
   width: 22%;
   padding: 3px;
   flex: 0 0 22%;
   display: inline;
   background: #1d1d1d;
   margin: 1%;
}

.mobile_html .mn_slider li:nth-child(2n+1) {
   background: #0a0a0a;
}

.mobile_html .mn_slider li img {
   width: 100%;
}



.mobile_html .mob_stand h2 {
   color: #f9f9f9;
   font-size: 30px;
   line-height: 48px;
}

.mobile_html .mob_stand img {
   width: 50px;
}

.mobile_html .mob_stand p {
   color: #fdfdfd;
   font-size: 13px;
   line-height: 24px;
   margin-top: 10px;
   opacity: .7;
}

.mobile_html .mob_container.mob_stand {
   padding: 0px 30px;
}

.mobile_html .ab_mobile_serv {
   padding: 90px 0 10px;
}

.mobile_html .mob_ab_gal .owl-carousel .owl-stage {
   display: flex;
   align-items: center;
}

.mobile_html .mob_ab_gal .owl-nav {
   display: block !important;
   color: #fff;
   position: absolute;
   left: 0;
   top: 40%;
   font-size: 35px;
   width: 100%;
}

.mobile_html .mob_ab_gal .owl-nav .owl-next {
   position: absolute;
   right: 0;
}

.mobile_html .mob_ab_gal {
   padding: 100px 0 150px;
}



.mobile_html #mob_op {
   padding: 40px 0;
}

.mobile_html .mob_bx {
   border: 1px solid #333;
   ;
   padding: 27px 20px;
   margin: 30px 30px;
   border-radius: 20px;
}

.mobile_html .mob_bx h2 {
   font-size: 15px;
}

.mobile_html .mob_bx p {
   font-size: 12px;
   color: #fdfdfd;
   opacity: .7;
   line-height: 20px;
   min-height: 90px;
}

.mobile_html .mobile_project li.mbl_th_bx {
   position: relative;
   flex: 0 0 160px;
   width: 160px;
}

.mobile_html .mobile_project .mbl_th_bx .pro_img {
   padding: 0;
}

.mobile_html .mobile_project .mbl_th_bx .pro_cnt {
   position: absolute;
   bottom: 0;
   background: rgba(0, 0, 0, 0.7);
   left: 0;
   width: 100%;
}

.mobile_html .mobile_project .mbl_th_bx .pro_cnt h5 {
   color: #c2c2c2;
   font-size: 10px;
   text-align: center;
}

.mobile_html .mobile_project .mbl_th_bx .pro_cnt h3 {
   font-size: 12px;
   text-align: center;
   margin-bottom: 4px;
   color: #f4f4f4;
}

.mobile_html .mobile_project .mbl_th_bx .pro_cnt p {
   margin: 0;
   text-align: center;
   font-size: 10px;
   line-height: 16px;
}

.mobile_html .mob_team h2 {
   color: #f9f9f9;
   font-size: 30px;
   margin-bottom: 20px;
}

.mobile_html .mob_team .mbl_th {
   font-size: 13px;
   color: #fdfdfd;
   line-height: 24px;
   opacity: .7;
   padding-bottom: 30px;
}

.mobile_html .mob_noteworthy .carousel-indicators button {
   text-indent: 0;
   height: 36px;
   border: 0;
   text-align: left;
   background: transparent;
   color: #fff;
   border-bottom: 2px solid #fff;
   margin: 0;
   width: 72px;
   padding: 0 6px;
   box-sizing: border-box;
}

.mobile_html .mob_noteworthy .carousel-indicators {
   position: relative;
}

.mobile_html .mob_noteworthy h2 {
   color: #f9f9f9;
   font-size: 30px;
   line-height: 48px;
   margin-bottom: 40px;
}

.mobile_html .not_top h3 {
   color: #f9f9f9;
   font-size: 30px;
   margin-bottom: 10px;
}

.mobile_html .not_top h6 {
   font-size: 13px;
   color: #fff;
}

.mobile_html .not_top h6 .fa {
   color: #E1574A;
}

.mobile_html .not_top img {
   padding: 7px 10px;
}

.mobile_html .not_top p {
   margin-top: 25px;
}

.mobile_html .mob_port {
   padding: 30px 0;
}

.mobile_html .mob_cn_p {
   padding: 50px 30px;
}

.mobile_html .mob_cn_p h1 {
   font-size: 50px;
   color: #fff;
   font-weight: bold;
   margin-bottom: 25px;
}

.mobile_html .mob_cn_p p {
   color: #fdfdfd;
   font-size: 13px;
   line-height: 24px;
   opacity: .7;
}



.mobile_html .mob_serv_bx {
   padding-bottom: 50px;
   position: relative;
   color: #efefef;
   font-size: 16px;
   line-height: 28px;
}


.mobile_html .gallery {
   padding: 50px 0;
   background: #101214;
}

.mobile_html .gallery .owl-carousel .owl-stage {
   display: flex;
   align-items: center;
}

.mobile_html .gallery .owl-nav {
   display: block !important;
   color: #fff;
   position: absolute;
   left: 0;
   top: 20%;
   font-size: 35px;
   width: 100%;
}

.mobile_html .gallery .owl-nav .owl-next {
   position: absolute;
   right: 0;
}

.mobile_html .mobile_project li {
   background: #121212;
   width: 230px;
   border: 1px solid #2f2f2f;
   margin-left: 20px;
   margin-right: 10px;
   flex: 0 0 230px;
   border-radius: 10px;
   overflow: hidden;
}

.mobile_html .mobile_project .pro_cnt {
   color: #f9f9f9;
   padding: 8px;
}

.mobile_html .mobile_project .mbl_th_bx .pro_img img {
   width: 100%;
}

.mobile_html .mobile_project {
   background: #121212;
   padding: 50px 0px;
   overflow: auto;
}

.mobile_html .bg_tap {
   background: url(https://d2e3cmtjkfw2a3.cloudfront.net/static/media/others/technologies_bg.jpg);
   background-position: top left;
   background-size: cover;
   padding: 50px 0;
   position: relative;
   background-attachment: fixed;
   overflow: auto;
}

.mobile_html .mob_heading h2 {
   color: #fdfdfd;
   font-size: 30px;
   font-weight: 500;
}

.mobile_html .mob_heading p {
   color: #e4a400;
   font-size: 13px;
   line-height: 40px;
   margin-bottom: 8px;
}

.mobile_html .join_tribe {
   padding: 20px 0;
}

.mobile_html .mob_team {
   padding: 50px 0 0;
}

.mobile_html .ab_hd {
   background: url(https://d2e3cmtjkfw2a3.cloudfront.net/static/media/about/about-head.png);
   background-size: contain;
   margin-top: 94px;
   padding: 150px 0 0;
   background-repeat: no-repeat;
}

.mobile_html .ab_hd .cnt_ab_hd {
   color: #fff;
   background: linear-gradient(180deg, rgba(18, 18, 18, 0.7) 0%, #121212 100%);
   ;
   padding: 40px;
}

.mobile_html .ab_hd .cnt_ab_hd h3 {
   color: #e4a400;
   font-size: 16px;
   margin-bottom: 10px;
}

.mobile_html .ab_hd .cnt_ab_hd h2 {
   line-height: 32px;
   font-size: 16px;
   color: #f9f9f9;
   font-weight: normal;
}

.mobile_html .mobile_serv .mob_stand #accordionExample {
   border-radius: 0;
   overflow: auto;
}

.mobile_html .mobile_serv .mob_stand .accordion-item .accordion-button {
   background: transparent;
   color: #f9f9f9;
   padding: 10px 20px;
}

.mobile_html .mobile_serv .mob_stand .accordion-item {
   background: transparent;
   border: 1px solid #333333 !important;
}

.mobile_html .mobile_serv .mob_stand .accordion-collapse {
   border-top: 1px solid #333;
}

.mobile_html .accordion-button:focus {
   z-index: 3;
   border-color: transparent;
   outline: 0;
   box-shadow: none;
}

.mobile_html .positions {
   display: flex;
   margin-top: 10px;
   margin-bottom: 20px;
}

.mobile_html .positions_cn h2 {
   color: #fdfdfd;
   font-size: 16px;
   margin-bottom: 5px;
}

.mobile_html .positions_cn p {
   color: #fdfdfd;
   font-size: 12px;
   margin-bottom: 5px !important;
   opacity: .7;
}

.mobile_html .positions_cn p.pl_cr {
   color: #e4a400;
   opacity: 1 !important;
}

.mobile_html .positions_cn {
   width: calc(100% - 30px);
}

.mobile_html .positions_more div {
   text-align: center;
   border: solid 1px #282828;
   color: #fdcb04;
   width: 25px;
   display: block;
   margin: 0 auto;
   height: 25px;
   border-radius: 50%;
   line-height: 24px;
   font-size: 10px;
}

.mobile_html .new_cn_frm {
   padding: 160px 0 30px;
}

.mobile_html .new_cn_frm h2 {
   color: #f9f9f9;
   font-size: 30px;
   line-height: 64px;
}

.mobile_html .new_cn_frm h2 img {
   max-width: 42px;
}

.mobile_html .new_cn_frm p {
   color: #b6b6b6;
   font-size: 12px;
   line-height: 24px;
}

.mobile_html .form-group {
   margin-bottom: 35px;
   position: relative;
}

.mobile_html .form-group .fa.fa-star {
   font-size: 12px;
   position: absolute;
   bottom: 13px;
   right: 13px;
   color: #e4a400;
}

.mobile_html .form-group input.form-control {
   font-size: 12px;
   background: transparent;
   border: 1px solid #373737;
   height: 45px;
}

.mobile_html .form-group textarea.form-control {
   background: transparent;
   border: 1px solid #373737;
   height: 105px;
   font-size: 12px;
}

.mobile_html .cnt_form {
   margin: 0 0 40px;
}

.mobile_html .form-group label {
   color: #f9f9f9;
   margin-bottom: 15px;
   margin-top: 30px;
   font-size: 16px !important;
}

.mobile_html #careerForm .form-group label {
   margin-top: 0;
}

.mobile_html .cr_frm .file_input_new #careerDoc-error {
   bottom: -90px;
}

.mobile_html .jobExperienField label {
   margin-top: 0;
}

.mobile_html .new_cn_frm .enq_con h3 {
   color: #f9f9f9;
   font-size: 20px;
   line-height: 40px;
}

.mobile_html .new_cn_frm .enq_con p {
   color: #b6b6b6;
   font-size: 12px;
   max-width: 140px;
   line-height: 20px;
   margin-bottom: 10px;
}

.mobile_html .new_cn_frm .enq_con a {
   color: #e4a400;
   font-size: 16px;
   line-height: 28px;
   text-decoration: none;
}

.mobile_html .enq_con {
   margin-bottom: 30px;
}

.mobile_html .connectUs {
   padding: 30px 0;
}

.mobile_html .connectUs h2 {
   font-size: 30px;
   color: #f9f9f9;
   font-weight: 600;
   margin-bottom: 10px;
}

.mobile_html .connectUs p {
   color: #b6b6b6;
   font-size: 12px;
   margin-bottom: 30px;
}

.mobile_html .connectUs a,
span.call {
   color: #e4a400;
   font-size: 13px;
   letter-spacing: 1.6px;
   margin-bottom: 20px;
   display: inline-block;
   text-decoration: navajowhite;
   font-weight: 600;
}

.mobile_html .cnt_ad h5 {
   color: #f9f9f9;
   font-size: 15px;
   margin-bottom: 5px;
}

.mobile_html .cnt_ad h6 {
   color: #e4a400;
   font-size: 13px;
   margin-bottom: 10px;
}

.mobile_html .cnt_ad p {
   font-size: 13px;
   color: #b6b6b6;
   margin-bottom: 40px;
}

.mobile_html .tmr_lst li .d-cnt {
   width: calc(100% - 110px);
   padding-left: 20px;
}

.mobile_html .tmr_lst li a {
   color: #fff;
   background: #ffb700;
   width: 30px;
   display: block;
   height: 30px;
   text-align: center;
   line-height: 30px;
   font-size: 15px;
   border-radius: 50%;
   position: absolute;
   left: 50%;
   bottom: 25%;
}

.mobile_html .tmr_lst li a.contact {
   width: unset;
   background: transparent;
   left: 45%;
   font-size: 13px;
   bottom: 42px;
   color: #ffb700;
}

.mobile_html .tmr_lst {
   padding-top: 0px;
}

.mobile_html .mn_add {
   display: flex;
   flex-direction: column;
   justify-content: center;
   height: 100%;
   border-right: 1px solid #9c9c9c;
}

.mobile_html .top_con {
   margin-bottom: 60px;
}

.mobile_html .top_con h2 {
   font-size: 30px;
   font-weight: 600;
   color: #f9f9f9;
   display: flex;
   line-height: 48px;
   max-width: 250px;
}

.mobile_html .top_con h2 img {
   padding-left: 10px;
}

.mobile_html .tmr_lst li {
   list-style: none;
   width: 350px;
   padding: 20px;
   box-shadow: 9px 9px 20px 0 rgba(11, 11, 11, 0.8), -9px -9px 20px 0 rgba(27, 27, 27, 0.8);
   border-radius: 20px;
   background: #101214;
   position: relative;
   margin: 0 auto 40px;
}

.mobile_html .tmr_lst li .d-img {
   width: 100px;
   height: 100px;
   overflow: hidden;
   margin-right: 20px;
   position: relative;
}

.mobile_html .tmr_lst li .d-img img {
   width: 70px;
   height: 70px;
   object-fit: cover;
}

.mobile_html .tmr_lst li h5 {
   color: #f9f9f9;
   font-size: 13px;
   margin-bottom: 5px;
}

.mobile_html .tmr_lst li p {
   color: #9c9c9c;
   font-size: 10px;
   margin-bottom: 5px;
}

.mobile_html .tmr_lst li p span {
   color: #e4a400;
}



.mobile_html .cn_form .form-group label {
   margin-top: 0;
}


.mobile_html .srv_head_mob {
   padding: 170px 0;
}

.mobile_html .srv_head_mob h2 {
   font-size: 30px;
   line-height: 50px;
   color: #f9f9f9;
}

.mobile_html .srv_head_mob .title {
   font-size: 30px;
   line-height: 50px;
   color: #f9f9f9;
}

.mobile_html .srv_head_mob p {
   font-size: 20px;
   color: #e4a400;
   margin: 30px 0 70px;
   min-height: 35px;
}

.mobile_html .serv_bx.card {
   padding: 30px 0px;
   border-radius: 27px;
   border: none;
   padding-left: 20px;
}

.mobile_html .serv_bx.card-1 {
   background: linear-gradient(97.53deg, #363185 0.74%, #0E0F0F 97.55%);
}

.mobile_html .serv_bx.card-2 {
   background: linear-gradient(97.53deg, #E976AF 0.74%, #0E0F0F 97.55%);
}

.mobile_html .serv_bx.card-3 {
   background: linear-gradient(97.53deg, #CA9F4B 0.74%, #0E0F0F 97.55%);
}

.mobile_html .serv_bx.card-4 {
   background: linear-gradient(97.53deg, #C76DB3 0.74%, #0E0F0F 97.55%);
}

.mobile_html .serv_bx.card-5 {
   background: linear-gradient(97.53deg, #54B7FF 0.74%, #0E0F0F 97.55%);
}

.mobile_html .serv_bx.card-6 {
   background: linear-gradient(97.53deg, #3EAC78 7.89%, #0E0F0F 97.55%);
}

.mobile_html .serv_bx.card-7 {
   background: linear-gradient(97.53deg, #775DCA 0.74%, #0E0F0F 97.55%);
}

.mobile_html .serv_bx .serv_img {
   max-width: 120px;
   margin-bottom: 40px;
}

.mobile_html .serv_bx .srv_num_mob {
   flex: 0 0 calc(100% - 150px);
   margin-right: 20px;
   color: #f9f9f9;
   margin-bottom: 20px;
}

.mobile_html .serv_bx .sub_srv_page a {
   color: white;
}

.mobile_html .srv_num_mob .numPos {
   background: #101214;
   width: 40px;
   height: 40px;
   text-align: center;
   line-height: 40px;
   color: #fff;
   border-radius: 50%;
   font-size: 14px;
   margin-bottom: 20px;
   border: 1px solid #333333;
}

.mobile_html .srv_num_mob h2 {
   color: #f9f9f9;
   line-height: 36px;
   font-size: 24px;
}

.mobile_html .serv_bx p {
   color: #efefef;
   font-size: 12px;
   line-height: 24px;
   margin-bottom: 10px;
   min-height: 130px;
}

.mobile_html .serv_bx .srv_cnt p {
   min-height: auto;
   color: #efefef;
   font-size: 12px;
   line-height: 24px;
   margin-bottom: 30px;
   min-height: 120px;
}

.mobile_html .serv_bx ul {
   margin-bottom: 40px;
}

.mobile_html .serv_bx li {
   color: #efefef;
   font-size: 15px;
   line-height: 24px;
   margin-bottom: 10px;
   list-style: none;
   position: relative;
   padding-left: 15px;
}

.mobile_html .serv_bx {
   margin-bottom: 80px;
}

.mobile_html .serv_bx li::before {
   content: "";
   position: absolute;
   left: 0;
   top: 9px;
   width: 5px;
   height: 5px;
   background: #e4a400;
   border-radius: 50%;
}

.mobile_html .srv_mob_tm {
   position: relative;
   max-width: 360px;
   margin: 0 auto;
}

.mobile_html .srv_mob_tm .container {
   position: absolute;
   top: 290px;
}

.mobile_html .srv_mob_tm h2 {
   color: #efefef;
   font-size: 20px;
   line-height: 36px;
}

.mobile_html .srv_mob_tm h2 span {
   color: #e4a400;
}

.mobile_html p {
   color: #f9f9f9;
   line-height: 32px;
   font-size: 16px;
}

.mobile_html .srv_talk.lets_talk {
   background: transparent;
}

.mobile_html .srv_talk.lets_talk::after {
   display: none;
}

.mobile_html .srv_talk.lets_talk .form-control {
   border-radius: 5px;
   padding: 15px 25px 15px 15px;
}

.mobile_html .srv_talk.lets_talk input.form-control {
   height: 48px;
}

.mobile_html .mobile-menu {
   position: fixed;
   left: 0;
   top: 0;
   z-index: 999;
   width: 100%;
   background: #101214;
   height: 100%;
   text-align: right;
   overflow: auto;
   padding: 60px 0;
   display: none;
}

.mobile_html .mobile-menu a {
   color: #f9f9f9;
   text-decoration: none;
   font-size: 22px;
   font-weight: 500;
}

.mobile_html .mobile-menu li {
   padding: 15px 0;
   list-style: none;
   display: block;
}

.mobile_html .mobile-menu.active {
   display: block;
}

.mobile_html .modal-content {
   background-color: #040405;
   color: #f9f9f9;
}

.mobile_html .modal-content .btn-close {
   border: 1px solid #333;
   float: right;
   padding: 10px 10px;
   line-height: 0;
   border-radius: 40px;
   opacity: 1;
   color: #e4a400;
   position: absolute;
   right: 20px;
   top: 30px;
}

.mobile_html .cr_dt_bx {
   max-width: 700px;
   border: 1px solid #222222;
   padding: 20px;
   margin: 30px 0 0;
   border-radius: 5px;
}

.mobile_html .cr_dt_bx h2 {
   color: #f9f9f9;
   letter-spacing: 1px;
   font-size: 20px;
   margin-bottom: 10px;
}

.mobile_html .cr_dt_bx h4 {
   color: #e4a400;
   font-size: 16px;
   letter-spacing: 1px;
   margin-bottom: 20px;
}

.mobile_html .cr_dt_bx p {
   color: #b6b6b7;
   font-size: 14px;
}

.mobile_html .cr_dt h5 {
   color: #f9f9f9;
   font-size: 16px;
   margin-top: 100px;
   margin-bottom: 22px;
}

.mobile_html .cr_dt ul li {
   color: #b6b6b7;
   line-height: 28px;
   font-size: 14px;
   margin-bottom: 10px;
   margin-left: 15px;
}

.mobile_html .cr_frm h6 {
   color: #b6b6b6;
   padding: 15px 0;
}

.mobile_html .cr_frm h2 {
   color: #f9f9f9;
   font-size: 30px;
   margin-top: 70px;
   margin-bottom: 0;
}

.mobile_html .cr_frm .file_input_new {
   display: flex;
   justify-content: space-between !important;
   border: 1px solid #373737;
   color: #b6b6b6;
   font-size: 14px;
   padding: 20px 2px;
   border-radius: 5px;
   position: relative;
   text-align: center;
}

.mobile_html .cr_frm .file_input_new input {
   position: absolute;
   left: 0;
   top: 0;
   width: 80%;
   height: 100%;
   z-index: 9;
   opacity: 0;
   cursor: pointer;
}

.mobile_html .cr_frm .d-flex {
   display: block !important;
}

.mobile_html .mobile_client img {
   max-width: 100%;
}

.mobile_html .form-control {
   color: #fff !important;
}

.mobile_html .form-group label.error {
   left: 0;
   color: red !important;
   font-size: 14px;
   position: absolute;
   bottom: -55px;
   height: 39px;
   font-weight: 500;
}

.mobile_html .form-group .form-check-inline label {
   color: #f9f9f9;
   margin-bottom: 15px;
   margin-top: 0;
}

.mobile_html #project_enquery_mob .file_input_new {
   margin-bottom: 30px;
   border: 1px solid #373737;
   color: #b6b6b6;
   font-size: 14px;
   padding: 20px 15px;
   border-radius: 5px;
   position: relative;
}

.mobile_html #project_enquery_mob .file_input_new input {
   position: absolute;
   left: 0;
   top: 0;
   width: 70%;
   height: 100%;
   z-index: 9;
   opacity: 0;
   cursor: pointer;
}

.mobile_html #project_enquery_mob .d-flex {
   display: block !important;
}

.mobile_html #project_enquery_mob .file_input_new .clearFile {
   position: absolute;
   right: 14px;
   top: 20px;
}

.mobile_html .mob_proEn {
   padding: 100px 0 0px;
}

.mobile_html .mob_proEn #project_enquery_mobile .file_input_new {
   margin-bottom: 30px;
   border: 1px solid #373737;
   color: #b6b6b6;
   font-size: 14px;
   padding: 20px 15px;
   border-radius: 5px;
   position: relative;
}

.mobile_html .mob_proEn #project_enquery_mobile .file_input_new input {
   position: absolute;
   left: 0;
   top: 0;
   width: 70%;
   height: 100%;
   z-index: 9;
   opacity: 0;
   cursor: pointer;
}

.mobile_html .mob_proEn #project_enquery_mobile .d-flex {
   display: block !important;
}

.mobile_html .mob_proEn #project_enquery_mobile .file_input_new .clearFile {
   position: absolute;
   right: 14px;
   top: 20px;
}

.mobile_html .mob_proEn #project_enquery_mobile {
   color: #fff;
}

.mobile_html .mob_proEn #project_enquery_mobile h2 {
   margin-bottom: 20px;
   color: #e4a400;
}

.mobile_html .nw_pr_rn_frm .pr-en-content .pr_en_hd {
   font-size: 32px;
   line-height: 50px;
}

.mobile_html .nw_pr_rn_frm .pr-en-content .pr_en_sub_hd {
   font-size: 20px;
   padding: 5px 0px;
   line-height: 30px;
}

.mobile_html .nw_pr_rn_frm .pr-en-content .pr_en_sub_title {
   font-size: 18px;
}



.mobile_html .thankyou {
   padding: 120px 0 150px;
}

.mobile_html .thanks_bx h2 {
   font-size: 30px;
   letter-spacing: 1px;
   color: #f9f9f9;
   margin: 20px 0 10px;
   font-family: 'Montserrat', sans-serif;
}

.mobile_html .thanks_bx p {
   font-size: 16px;
   line-height: 24px;
   max-width: 400px;
   margin: 0 auto 20px;
   color: #f9f9f9;
}

.mobile_html .thanks_bx .btn {
   margin-bottom: 15px;
}

.mobile_html .thankyou img {
   max-width: 200px;
}

.mobile_html .btn.btn-primary.hvr-sweep-to-right::before {
   background: #e4a400 !important;
   overflow: hidden;
   border-radius: 30px;
}

.mobile_html .mobile_testimonial_bx .tst_msg {
   min-height: 60px;
   line-height: inherit;
}

.mobile_html .form-processing {
   display: none;
   width: 100%;
   height: 100%;
   z-index: 1;
   position: absolute;
   align-items: center;
   justify-content: center;
}

.mobile_html .intl-tel-input {
   position: relative;
   display: block;
}

.mobile_html .lets_talk::after {
   content: "";
   position: absolute;
   left: 0;
   top: 0;
   width: 100%;
   height: 100%;
   background: rgba(0, 0, 0, 0.9);
}

.mobile_html .lets_talk.otb_tlk::after {
   background: rgba(0, 0, 0, 0.5);
}

.mobile_html .lets_talk .container {
   z-index: 2;
   position: relative;
}

/*Case Study CSS*/
.mobile_html .case_mob_hero {
   margin-top: 93px;
   position: relative;
   padding: 80px 0;
   text-align: center;
   background: url(https://d2e3cmtjkfw2a3.cloudfront.net/static/media/PagarKhata/main_sm.png);
   background-position: top left;
   background-size: 100% 100%;
   min-height: 634px;
}

.mobile_html .case_mob_hero_bx {
   position: relative;
   z-index: 5
}

.mobile_html .case_mob_hero_bx img {
   max-width: 142px;
   margin-bottom: 60px;
}

.mobile_html .case_mob_hero_bx ul {
   display: flex;
}

.mobile_html .case_mob_hero_bx li {
   list-style: none;
   display: inline-block;
   width: 33.33%;
   flex: 0 0 33.33%;
   color: #f9f9f9;
   font-size: 12px;
}

.mobile_html .case_mob_hero_bx li span {
   display: block;
   color: rgba(249, 249, 249, 0.41);
   margin-bottom: 10px;
}

.mobile_html .case_testi_mob {
   padding: 40px 0;
}

.mobile_html .case_testi_mob .tag-line {
   font-size: 28px;
   text-align: center;
   color: #e4a400;
   padding-bottom: 30px;
   line-height: 40px;
}

.mobile_html .case_testi_mob img {
   max-width: 100%;
   margin-bottom: 30px;
}

.mobile_html .case_tst_mb {
   border: 1px solid #333333;
   border-radius: 20px;
   padding: 11px 6px;
   margin-bottom: 20px;
}

.mobile_html .case_tst_mb img {
   margin-bottom: 10px;
   max-height: 25px;
}

.mobile_html .case_tst_mb p {
   color: #fff;
   font-size: 10px;
   line-height: 20px;
   min-height: 100px;
}

.mobile_html .case_about_mob {
   background: url(https://d2e3cmtjkfw2a3.cloudfront.net/static/media/PagarKhata/);
   background-size: cover;
   padding: 40px 0;
   text-align: center;
}

.mobile_html .case_about_mob h2 {
   color: #fff;
   font-size: 25px;
   margin-bottom: 14px;
}

.mobile_html .case_about_mob p {
   color: #fdfdfd;
   font-size: 12px;
   line-height: 20px;
   margin-bottom: 55px;
}

.mobile_html .case_about_mob h3 {
   color: #e4a400;
   font-size: 25px;
   margin-bottom: 20px;
}

.mobile_html .case_about_mob li {
   list-style: none;
   flex: 0 0 50%;
   max-width: 50%;
}

.mobile_html .case_about_mob .cp_mob {
   background: #101214;
   border: solid 0.5px #9c9c9c;
   margin-bottom: 15px;
   border-radius: 15px;
   padding: 15px 0;
}

.mobile_html .case_about_mob .cp_mob span {
   display: block;
}

.mobile_html .case_about_mob .cp_mob a {
   color: #d5d5d5;
   text-decoration: none;
   font-size: 15px;
   line-height: 30px;
}

.mobile_html .case_scope_mob {
   padding: 50px 0;
   text-align: center;
   position: relative;
   overflow: hidden;
}

.mobile_html .case_scope_mob h2 {
   color: #fff;
   font-size: 25px;
   margin-bottom: 15px;
}

.mobile_html .case_scope_mob h2 img {
   position: absolute;
   right: -20px;
   top: 60px;
}

.mobile_html .case_scope_mob p {
   color: #fdfdfd;
   line-height: 20px;
   font-size: 12px;
   margin-bottom: 15px;
}

.mobile_html .case_scope_mob img {
   max-width: 100%;
}

.mobile_html .mob_case_other {
   text-align: center;
   padding: 60px 0;
}

.mobile_html .mob_case_other h2 {
   color: #f9f9f9;
   font-size: 25px;
   margin-bottom: 30px;
}

.mobile_html .mob_case_other .d-flex {
   padding-bottom: 15px;
   overflow: auto;
}

.mobile_html .mob_case_ot_bx {
   position: relative;
   flex: 0 0 225px;
   max-width: 225px;
   margin-left: 15px;
   margin-right: 15px;
}

.mobile_html .mob_case_ot_bx>img {
   max-width: 100%;
}

.mobile_html .mob_case_ot_bx .mob_case_ot_cn {
   position: absolute;
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%);
}

.mobile_html .cob_img {
   margin-bottom: 15px;
   display: table;
}

.mobile_html .cob_img img {
   max-width: 120px;
}

.mobile_html .mob_case_app {
   padding: 40px 0;
   text-align: center;
}

.mobile_html .mob_case_app h2 {
   color: #fff;
   font-size: 20px;
   margin-bottom: 20px;
}

.mobile_html .mob_case_app p {
   line-height: 20px;
   color: #b2b2b2;
   max-width: 584px;
   margin: 0 auto 30px;
   font-size: 12px;
}

.mobile_html .mob_case_app li {
   list-style: none;
   display: inline-block;
   padding: 0 10px;
}

.mobile_html .mob_case_app li img {
   max-height: 38px;
}

.mobile_html .mob_case_conclusion {
   padding: 40px 0;
}

.mobile_html .mob_case_conclusion h2 {
   color: #fff;
   font-size: 20px;
   margin-bottom: 20px;
}

.mobile_html .mob_case_conclusion p {
   line-height: 20px;
   color: #b2b2b2;
   max-width: 720px;
   margin: 0 auto;
   font-size: 12px;
}

.mobile_html .case_care {
   background: url(https://d2e3cmtjkfw2a3.cloudfront.net/static/media/PagarKhata/web_care_bg.png);
   background-size: cover;
   text-align: center;
   padding: 40px 0;
}

.mobile_html .case_care h2 {
   color: #fff;
   font-size: 20px;
   margin-bottom: 20px;
}

.mobile_html .case_care p {
   line-height: 20px;
   font-size: 12px;
   color: #b2b2b2;
   max-width: 584px;
   margin: 0 auto;
}

.after_none:after {
   display: none;
}

.mobile_html .after_none .form-group .form-control {
   padding: 10px 25px;
   border: 1px solid #818181;
}

.mobile_html .mob_case_port {
   padding: 40px 0;
}

.mobile_html .mob_case_port h2 {
   color: #fff;
   font-size: 20px;
   margin-bottom: 20px;
}

.mobile_html .mob_case_port p {
   line-height: 20px;
   font-size: 12px;
   color: #b2b2b2;
   max-width: 584px;
   margin: 0 auto 20px;
}

.mobile_html .mob_case_port h4 {
   color: #e4a400;
   font-size: 14px;
   font-weight: 600;
   margin-bottom: 15px;
}

.mobile_html .mob_case_port p {
   max-width: 639px;
}

.mobile_html .mob_case_port .owl-next {
   position: absolute;
   right: -25px;
   color: #fff !important;
   font-size: 60px !important;
   top: 25%;
}

.mobile_html .mob_case_port .owl-prev {
   position: absolute;
   left: -25px;
   color: #fff !important;
   font-size: 60px !important;
   top: 25%;
}

.mobile_html .case_design {
   padding: 40px 0;
   background: #000;
}

.mobile_html .case_design .case_sec_head h2 {
   color: #fff;
   font-size: 20px;
   margin-bottom: 20px;
}

.mobile_html .case_design .case_sec_head p {
   line-height: 20px;
   font-size: 12px;
   color: #b2b2b2;
   max-width: 584px;
   margin: 0 auto 20px;
}

.mobile_html .cd_bx h3 {
   color: #e4a400;
   font-size: 15px;
   line-height: 32px;
   margin-bottom: 20px;
}

.mobile_html .fnts h2 {
   font-size: 90px;
   letter-spacing: -5px;
   color: #9c9c9c;
   margin-right: 10px;
   font-weight: normal;
   line-height: 72px;
}

.mobile_html .fnts_detail h4 {
   font-size: 12px;
   color: #9c9c9c;
   line-height: 20px;
   margin-bottom: 0px;
}

.mobile_html .fnts_detail h5 {
   font-size: 12px;
   color: #9c9c9c;
   letter-spacing: -0.3px;
   line-height: 20px;
   margin-bottom: 0px;
}

.mobile_html .fnts_detail p {
   font-size: 12px;
   color: #9c9c9c;
   line-height: 20px;
   margin-bottom: 0px;
}

.mobile_html .fnts_detail p span {
   display: inline-block;
   margin-right: 10px;
}

.mobile_html .color_bx {
   width: 50px;
   height: 50px;
   border-radius: 5px;
}

.mobile_html .palette li {
   list-style: none;
   padding: 0 5px;
   flex: 0 0 20%;
   max-width: 20%;
   text-align: center;
   margin-bottom: 15px;
}

.mobile_html .palette li p {
   color: #dadada;
   font-size: 10px;
   margin-top: 5px;
}

.mobile_html .cd_bx {
   margin-bottom: 20px;
}

.mobile_html .lst_img li img {
   max-width: 100%;
}

.mobile_html .cd_bx_img {
   max-height: 60px;
}

.mobile_html .lst_img {
   max-width: 600px;
   margin: 0 auto;
}

.mobile_html .lst_img li {
   list-style: none;
   flex: 0 0 20%;
   max-width: 20%;
   margin-bottom: 20px;
}

.mobile_html .case_wire_mob {
   padding: 40px 0;
}

.mobile_html .case_wire_mob h2 {
   color: #fff;
   font-size: 20px;
   margin-bottom: 20px;
}

.mobile_html .case_wire_mob p {
   line-height: 20px;
   font-size: 12px;
   color: #b2b2b2;
   max-width: 584px;
   margin: 0 auto 20px;
}

.mobile_html .case_wire_mob .owl-next {
   position: absolute;
   right: -25px;
   color: #fff !important;
   font-size: 60px !important;
   top: 25%;
}

.mobile_html .case_wire_mob .owl-prev {
   position: absolute;
   left: -25px;
   color: #fff !important;
   font-size: 60px !important;
   top: 25%;
}

.mobile_html .mob_case_goals_bx {
   background: #101214;
   padding: 40px;
   border-radius: 20px;
   box-shadow: 5px 5px 16px 0 rgba(0, 0, 0, 0.2);
   border: solid 1px rgba(0, 0, 0, 0.05);
}

.mobile_html .mob_case_goals_bx h4 {
   color: #f9f9f9;
   font-size: 14px;
   line-height: 20px;
   min-height: 44px;
   margin-bottom: 20px;
}

.mobile_html .mob_case_goals_bx p {
   color: #9c9c9c;
   line-height: 20px;
   font-size: 12px;
}

.mobile_html .mob_case_goals {
   background: #000;
   padding: 40px 0;
}

.mobile_html .mob_case_goals .carousel-indicators [data-bs-target] {
   width: 5px;
   height: 5px;
   border-radius: 50%;
   border: 1px solid #fff;
   background: transparent;
}

.mobile_html .mob_case_goals .carousel-indicators [data-bs-target].active {
   background: #fff;
}

.mobile_html .mob_case_goals .carousel-indicators {
   right: 33px;
   bottom: auto;
   left: auto;
   margin-right: 0;
   margin-bottom: 0;
   margin-left: 0;
   top: -26px;
}

.mobile_html .mob_case_goals .carousel-control-next,
.mobile_html .mob_case_goals .carousel-control-prev {
   top: -35px;
   bottom: auto;
   width: auto;
}

.mobile_html .mob_case_goals .carousel-control-prev {
   left: auto;
   right: 70px;
}

.mobile_html .mob_case_goals .carousel-control-next-icon,
.mobile_html .mob_case_goals .carousel-control-prev-icon {
   width: 1.5rem;
   height: 1.5rem;
}

.mobile_html .mob_case_goals h2 {
   font-size: 25px;
   color: #fff;
   margin-bottom: 15px;
}

.mobile_html .mob_case_goals h3 {
   font-size: 12px;
   color: #fdfdfd;
   margin-bottom: 55px;
}

.mobile_html .mob_case_research {
   background: url(https://d2e3cmtjkfw2a3.cloudfront.net/static/media/PagarKhata/);
   background-size: cover;
   text-align: center;
   padding: 40px 0;
}

.mobile_html .mob_case_research h2 {
   color: #fff;
   font-size: 25px;
   margin-bottom: 15px;
}

.mobile_html .mob_case_research p {
   line-height: 20px;
   color: #b2b2b2;
   max-width: 832px;
   margin: 0 auto 35px;
   font-size: 12px;
}

.mobile_html .mob_case_challenge {
   max-width: 1039px;
   margin: 0 auto;
}

.mobile_html .mob_case_challenge h3 {
   color: #e4a400;
   font-size: 11px;
   font-weight: 600;
   margin-bottom: 15px;
   max-width: 200px;
   line-height: 20px;
   letter-spacing: -0.4px;
}

.mobile_html .mob_case_challenge .carousel-item {
   background: #101214;
   border: solid 1px rgba(0, 0, 0, 0.05);
   box-shadow: 5px 5px 16px 0 rgba(0, 0, 0, 0.2);
   padding: 25px 24px 48px;
   border-radius: 20px;
}

.mobile_html .mob_case_challenge h4 {
   color: #e4a400;
   font-size: 14px;
   font-weight: 600;
   margin-bottom: 15px;
}

.mobile_html .mob_case_challenge h5 {
   color: #b2b2b2;
   font-size: 14px;
   font-weight: 500;
   margin-bottom: 40px;
}

.mobile_html .mob_case_challenge h6 {
   color: #b2b2b2;
   font-size: 14px;
   font-weight: 500;
   margin-bottom: 10px;
}

.mobile_html .mob_case_challenge p {
   color: #b2b2b2;
   font-size: 12px;
   font-weight: 400;
   margin-bottom: 10px;
}

.mobile_html .mob_case_challenge .carousel-indicators [data-bs-target] {
   width: 8px;
   height: 8px;
   border-radius: 50%;
}

.mobile_html .mob_case_challenge .carousel-indicators {
   right: 30px;
   bottom: auto;
   left: auto;
   margin-right: 0;
   margin-bottom: 0;
   margin-left: 0;
   top: -38px;
}

.mobile_html .mob_case_challenge .carousel-control-next,
.mobile_html .mob_case_challenge .carousel-control-prev {
   top: -35px;
   bottom: auto;
   width: auto;
}

.mobile_html .mob_case_challenge .carousel-control-prev {
   left: auto;
   right: 74px;
}

.mobile_html .mob_case_challenge .carousel-control-next-icon,
.mobile_html .mob_case_challenge .carousel-control-prev-icon {
   width: 1.5rem;
   height: 1.5rem;
}

.mobile_html #openMoreFt {
   background: transparent !important;
   border: 2px solid;
}

.mobile_html .form-control {
   color: #fff !important;
}

.mobile_html .sub-srv-pro img {
   max-width: 50px;
   margin-bottom: 10px;
}

.mobile_html .sub-srv-pro h2 {
   font-size: 20px;
   margin-bottom: 10px;
}

.mobile_html .sub-srv-pro p {
   line-height: 24px;
   color: #fdfdfd;
   font-size: 14px;
}

.mobile_html .mob_bx.sub-srv-pro {
   padding: 20px;
}


.mobile_html .tp_txt {
   margin-bottom: 150px;
}

.mobile_html .tp_txt h4 {
   color: #f9f9f9;
   font-size: 14px;
   letter-spacing: 2.8px;
   text-transform: uppercase;
   font-family: 'Montserrat', sans-serif;
}

.mobile_html .tp_txt h4 span {
   display: inline-block;
   width: 8px;
   height: 8px;
   background: #6259ea;
   border-radius: 50%;
   margin-left: 5px;
}



.mobile_html .wdw-bx img {
   max-width: 70px;
   margin-bottom: 10px;
}

.mobile_html .wdw-bx h3 {
   color: #fff;
   font-size: 20px;
   line-height: 32px;
   max-width: 190px;
   margin-bottom: 10px;
}

.mobile_html .wdw-bx p {
   min-height: 280px;
   font-size: 14px;
   color: #efefef;
   line-height: 28px;
}

.mobile_html .wdw-bx {
   border: 1px solid #fff;
   padding: 30px;
   border-radius: 5px;
   background: #2d2d2d;
   margin-top: 20px;
}



.mobile_html .tst_cnt h5 {
   font-size: 11px;
   font-weight: 400;
   opacity: .7;
   min-height: 29px;
}

.mobile_html .suc_bx_mob .sbm_cnt a:after {
   content: "";
   position: absolute;
   left: 0;
   bottom: -5px;
   width: 60px;
   height: 1px;
   background: #e4a400;
}

.mobile_html .lTk_mob a {
   display: inline-block;
   font-size: 50px;
   color: #f9f9f9;
   text-decoration: none;
   border: solid 2px rgba(253, 253, 253, 0.3);
   padding: 10px 20px;
   border-radius: 5px;
}

.mobile_html .lTk_mob {
   text-align: center;
   margin: 100px 0;
}

.mobile_html .error-page {
   padding: 140px 0 60px;
}

.mobile_html .error-page img {
   max-width: 100%;
}

.mobile_html .error-page h2 {
   color: #f9f9f9;
   font-size: 35px;
   letter-spacing: 10px;
   text-transform: uppercase;
   font-family: 'Montserrat', sans-serif;
   margin-bottom: 5px;
   margin-top: 50px;
}

.mobile_html .error-page p {
   color: #f9f9f9;
   font-size: 16px;
   letter-spacing: .4px;
   margin-bottom: 20px;
   line-height: 30px;
}

.mobile_html .error-page p a {
   color: #e4a400;
   text-transform: uppercase;
   text-decoration: none;
}

.mobile_html .er_bx_cnt {
   max-width: 437px;
}

.mobile_html .mob_case_goals_tripp .mob_case_goals_bx {
   padding: 30px 10px;
}

.mobile_html .palette.palette_tripp li {
   flex: 0 0 33.33%;
   max-width: 33.33%;
}

.mobile_html .palette.palette_tripp li .color_bx {
   margin: 0 auto;
   width: 80px;
   height: 60px;
}

.mobile_html .case_tst_mb.text-center.ctn_sm {
   padding-top: 30px;
   padding-bottom: 0;
}

.mobile_html .case_tst_mb.text-center.ctn_sm p {
   min-height: 80px;
}

.mobile_html .case_wire_mob li {
   list-style: none;
   border-radius: 5px;
   overflow: hidden;
}

.mobile_html .aktv_case_testi_mob .case_tst_mb p {
   min-height: 70px;
}

.mobile_html .aktv_case_testi_mob .case_tst_mb {
   padding-top: 30px;
}

.mobile_html #contactForm textarea+i {
   top: 80px !important;
}

.mobile_html #contactForm textarea+label+i {
   top: 80px !important;
}

.mobile_html .header .header_min {
   transition: all ease .3s !important;
}

.mobile_html .header.nav-up .header_min {
   -webkit-transform: translateY(-100px);
   -ms-transform: translateY(-100px);
   transform: translateY(-100px);
   -webkit-transition: all ease .5s;
   -o-transition: all ease .5s;
   transition: all ease .5s;
}

.mobile_html .header.nav-down .header_min {
   -webkit-transform: translateY(0);
   -ms-transform: translateY(0);
   transform: translateY(0);
   -webkit-transition: all ease .5s;
   -o-transition: all ease .5s;
   transition: all ease .5s;
}

.mobile_html #project_enquery_mob h2 {
   color: #e4a400;
   font-size: 30px;
   max-width: 180px;
   margin-bottom: 40px;
   letter-spacing: 0.6px;
   line-height: 45px;
}

.mobile_html #project_enquery_mob h3 {
   font-size: 20px;
   letter-spacing: .4px;
   line-height: 48px;
}

.mobile_html #careerForm label>.fa {
   font-size: 12px !important;
   top: -1px !important;
   margin-left: 15px;
}

.mobile_html #jobFormAndContentDialog .modal-content .btn-close {
   background: #000;
}

.mobile_html #enquiryForm .form-group label {
   color: #f9f9f9;
   margin-bottom: 15px;
   margin-top: 0;
}

.mobile_html #project_enquery_mob .pr-en-content .pr_en_hd {
   font-weight: 600;
   font-size: 26px;
   line-height: 75px;
   letter-spacing: 0.02em;
   color: #E4A400;
}

.mobile_html #project_enquery_mob .pr-en-content .pr_en_sub_hd {
   font-weight: 600;
   font-size: 20px;
   line-height: 25px;
   display: flex;
   align-items: center;
   letter-spacing: 0.02em;
   color: #C7C7C7;
   padding: 10px 0px;
   max-width: 250px;
}

.mobile_html #project_enquery_mob .pr-en-content .pr_en_sub_title {
   font-weight: 500;
   font-size: 20px;
   line-height: 45px;
   letter-spacing: 0.02em;
   color: #F9F9F9;
   padding: 5px 0px;
}

.mobile_html #project_enquery_mob .pr-en-content .pr_en_social_title {
   font-weight: 500;
   font-size: 22px;
   line-height: 45px;
   letter-spacing: 0.02em;
   color: #E4A400;
   padding: 5px 0px;
}

.mobile_html #project_enquery_mob .pr-en-content .social-icon .ceo-info {
   display: flex;
   align-items: center;
   padding: 10px 0px;
}

.mobile_html #project_enquery_mob .pr-en-content .social-icon .ceo-info .social-img {
   width: 80px;
}

.mobile_html #project_enquery_mob .pr-en-content .social-icon .ceo-info .social-img img {
   width: 100%;
}

.mobile_html #project_enquery_mob .pr-en-content .social-icon .ceo-info .title {
   font-weight: 500;
   font-size: 15px;
   line-height: 45px;
   letter-spacing: 0.02em;
   color: #E8E8E8;
   padding: 0px 20px;
   width: 60%;
}



.mobile_html .mobile_serv .accordion-item .accordion-collapse {
   border-top: 1px solid #222;
}

@media (max-width:800px) {
   .mobile_html .mn_add {
      border: none;
   }
}

.mobile_html #project_enquery_mob .pr-en-content .pr_en_sub_hd {
   font-weight: 600;
   font-size: 20px;
   line-height: 25px;
   display: flex;
   align-items: center;
   letter-spacing: 0.02em;
   color: #C7C7C7;
   padding: 10px 0px;
   max-width: 250px;
}

.mobile_html #project_enquery_mob .pr-en-content .pr_en_sub_title {
   font-weight: 500;
   font-size: 20px;
   line-height: 45px;
   letter-spacing: 0.02em;
   color: #F9F9F9;
   padding: 5px 0px;
}

.mobile_html #project_enquery_mob .pr-en-content .pr_en_social_title {
   font-weight: 500;
   font-size: 22px;
   line-height: 45px;
   letter-spacing: 0.02em;
   color: #E4A400;
   padding: 5px 0px;
}

.mobile_html #project_enquery_mob .pr-en-content .social-icon .ceo-info {
   display: flex;
   align-items: center;
   padding: 10px 0px;
}

.mobile_html #project_enquery_mob .pr-en-content .social-icon .ceo-info .title {
   font-weight: 500;
   font-size: 15px;
   line-height: 45px;
   letter-spacing: 0.02em;
   color: #E8E8E8;
   padding: 0px 20px;
   width: 60%;
}

.mobile_html .goa-trip {
   background: #0b0b0c;
   padding: 60px 0 60px;
   position: relative;
}

.mobile_html .goa-trip h3 {
   font-size: 20px;
   color: #fff;
   line-height: 38px;
   margin-bottom: 6px;
}

.mobile_html .goa-trip p {
   color: #e4a400;
   font-size: 14px;
   line-height: 28px;
}

.mobile_html .goa-trip img {
   max-width: 200px;
}

.mobile_html .partyJoin {
   padding: 50px 0 0;
   background-image: url(https://d2e3cmtjkfw2a3.cloudfront.net/static/media/career/goa-bg.png);
   background-size: cover;
   background-color: #000;
}

.mobile_html .partyJoin img {
   max-width: 100%;
   margin: 30px -30px 0;
}

.mobile_html .partyJoin h4 {
   color: #fff;
   font-size: 20px;
   line-height: 30px;
   margin-bottom: 15px;
   font-weight: 500;
}

.mobile_html .partyJoin h4 span {
   color: #e4a400;
}

.mobile_html .mobile_serv .accordion-item .accordion-collapse {
   border-top: 1px solid #222;
}

@media (max-width:800px) {
   .mobile_html .mn_add {
      border: none;
   }
}

@media (max-width:450px) {
   .mobile_html .tmr_lst {
      padding-top: 40px;
   }

}