.hm_banner {
    position: relative;
    /* height: calc(90vh + 90vh/4); */
    overflow: hidden;
    padding-top: 0;
}

@media only screen and (min-width: 1170px) {
    .hm_banner {
        min-height: 750px;
        padding-top: 140px;
    }

}



#bannerVideo {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    z-index: -1;
    opacity: 0.1;
}

.ban_caption {
    /* position: absolute; */
    position: relative;
    left: 50%;
    top: 23%;
    /* width: 900px; */
    width: 100%;
    transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.ban_caption h2 {
    font-family: 'Assistant', sans-serif;
    font-display: swap;
    font-size: 22px;
    line-height: 33px;
    font-weight: 600;
    margin-bottom: 30px;
}

.ban_caption h3 {
    font-family: 'Assistant', sans-serif;
    font-display: swap;
    font-size: 44px;
    font-weight: 700;
    line-height: 50px;
    color: #f1b308;
    margin: 0;
    text-transform: uppercase;
    height: 70px;
}

.ban_caption h1 {
    font-size: 22px;
    line-height: 32px;
    font-weight: 600;
    font-family: 'Assistant', sans-serif;
    font-display: swap;
    margin: 10px 0px;
    position: relative;
}

.ban_caption .ln {
    height: 2px;
    width: 96px;
    background-color: #f1b308;
    display: block;
    position: absolute;
    left: 50%;
    bottom: -20px;
}

[data-aos="ln-hd"] {
    transform: translateX(-50%) scaleX(0);
    -webkit-transform: translateX(-50%) scaleX(0);
}

[data-aos="ln-hd"].aos-animate {
    transform: translateX(-50%) scaleX(1);
    -webkit-transform: translateX(-50%) scaleX(1);
}

.tp_bx {
    position: relative;
    border: 1px dashed #ffff;
    padding: 10px 42px;
    margin-bottom: 25px;
    /* max-height: 80px; */
    height: 80px;
}

.title-border {
    width: 350px;
    height: 2px;
    background-color: #f1b308;
}


.gray_sec {
    background-color: #222;
    position: relative;
}

.rt_bx {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
}

.gray_sec::before,
.gray_sec::after,
.rt_bx::before,
.rt_bx::after {
    width: 100%;
    bottom: 0;
    content: '';
    position: absolute;
    right: 0;
    clip-path: polygon(0 100%, 100% 0, 100% 100%, 20% 99%);
}

.gray_sec::before,
.rt_bx::before {
    height: 22px;
    background: rgb(255 255 255 / 5%);
    z-index: 1;
}

.gray_sec::after,
.rt_bx::after {
    height: 44px;
    background: rgb(0 0 0 / 17%);
}

.rt_bx::before,
.rt_bx::after {
    bottom: auto;
    top: 0;
    clip-path: polygon(0 0, 78% 0, 100% 0, 0 100%);
}

.what_we_do h2 {
    font-family: 'Assistant', sans-serif;
    font-size: 3rem;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 10px;
}

.what_we_do h2 span {
    font-size: 4.875rem;
    display: block;
}

.what_we_do p {
    line-height: 1.5;
    font-size: 1.125rem;
    max-width: 550px;
    margin-bottom: 25px;
}

.what_we_do .or_para {
    line-height: 1.5;
    font-size: 1.4rem;
    max-width: 500px;
    color: #f1b308;
    font-family: 'Assistant', sans-serif;
    font-weight: 700;
}

.what_we_do .lc_idea {
    line-height: 1.5;
    font-size: 1.125rem;
    display: flex;
    font-weight: 700;
    align-items: center;
    margin-bottom: 10px;
    margin-top: 30px;
}

.what_we_do .lc_idea i {
    margin-right: 10px;
}

.serv_tb {
    display: none;
}

.what_we_do {
    width: 599px;
    max-width: 47%;
    position: sticky;
    top: 60px;
}

.what_we_do_grph {
    width: 600px;
    max-width: 53%;
}

/*home new banner */
#hm_banner_logo .img-box-h img {
    margin: auto;
}

.hm-content {
    position: absolute;
    top: 16%;
    left: 10%;
}

.hm-heading {
    font-weight: 700;
    font-size: 90px;
    line-height: 114px;
    padding-bottom: 5px;
}

.hm-heading .ln {
    height: 2px;
    width: 150px;
    background-color: #f1b308;
    display: block;
    position: absolute;
    margin: 0 auto;
}

.hm-description {
    font-weight: 600;
    font-size: 22px;
    line-height: 32px;
    padding: 20px 0px;

}

.hm-btn-main {
    margin-top: 20px;
}

.hm-btn-main.srvc-btn-cta-dsktp {
    display: block;
}

.hm-btn-main.srvc-btn-cta-mob {
    display: none;
}

.hm-btn-main .hm-port-btn {
    background-color: transparent;
    border: 1px solid #f1b308;
    color: #fff;
    border-radius: 50px;
    padding: 12px 70px 15px;
    font-size: 16px;
    font-weight: 600;
    transition: all 0.6s ease-in-out;
    margin-left: 20px;
}

.hm-btn-main .hm-port-btn:hover {
    background-color: #f1b308;
    transition: all 0.6s ease-in-out;
    color: black;
}

.hm-btn-main .btn.btn-primary {
    font-weight: 600;
    border: 0;
    border-radius: 50px;
    letter-spacing: 1.6px;
    padding: 13px 25px;
    color: #101214;
    overflow: hidden;
}

.hm-btn-main .btn.btn-primary:hover {
    color: #fff;
}

.hm-btn-main .btn.btn-primary.btn_line.btn-white {
    background-color: transparent !important;
}

.hm-btn-main .btn.btn-primary.btn-white:hover {
    border: none;
    border: 1px solid whitesmoke;
    background-color: transparent !important;
}

.hm-btn-main .btn.btn-primary.btn-ylw {
    background-color: #e4a400;
    box-shadow: 0 4px 13px rgb(234 234 234 / 25%);
    font-size: 16px;
}

.hm-btn-main .btn_line:before {
    border-radius: 50px !important;
    background: transparent !important;
}

.hm-btn-main .btn_line svg {
    display: none;
}

.hm-btn-main .btn_line:hover svg {
    top: 10px;
    display: none;
}

.hm-btn-main .btn.btn-primary.btn-white {
    font-size: 16px;
    background-color: transparent;
    color: #fff;
    border: 1px solid;
    margin-left: 20px;
    display: unset;
}

.banner-logo {
    padding-left: 40px;
}

.hm-btn-main .btn.btn-primary:focus {
    box-shadow: unset !important;
}

.hm-banner-logos {
    position: absolute;
    bottom: 0;
    /* margin-bottom: 30px; */
}

.hm-banner-logo-row {
    display: flex;
    justify-content: space-between;
}

.hm-banner-img {
    width: 160px;
}

.hm-btn-wrap .btn-ylw {
    border: unset !important;

}

.hm-btn-wrap .btn-white {
    width: 190px;
}

.banner-logo-slider {
    width: 100%;
    position: relative;
}

.blockChain_slider.hm-banner-slider .inner_item {
    background-color: transparent;
}

.banner-logo-slider::before {
    position: absolute;
    content: '';
    border: 1px dashed #f1b308;
    opacity: 0.3;
    width: 100%;
    top: 45%;

}

.blockChain_slider.hm-banner-slider .slick-slide {
    opacity: 1;
}

.banner-logo-slider .hm_logo_slider .item {
    width: 150px;
    height: 150px;
}

.banner-logo-slider .hm_logo_slider .item .inner_item .light {
    display: block;
    transition: all .5s ease-in-out;
}

.banner-logo-slider .hm_logo_slider .item .inner_item:hover .light {
    display: none;
    transition: all .5s ease-in-out;
}

.banner-logo-slider .hm_logo_slider .item .inner_item .dark {
    display: none;
    transition: all .5s ease-in-out;
}

.banner-logo-slider .hm_logo_slider .item .inner_item:hover .dark {
    display: block;
    transition: all .5s ease-in-out;
}


/* new banner logo home page */
.banner-logo-new {
    position: relative;
    display: flex;
    justify-content: space-around;
    width: 100%;
}

.banner-logo-new::before {
    position: absolute;
    content: '';
    border: 1px dashed #f1b308;
    opacity: 0.3;
    width: 100%;
    top: 45%;
    z-index: -1;
}

.banner-logo-new .inner_item {
    width: 150px;
    height: 150px;
}

.banner-logo-new .inner_item .light {
    display: block;
    transition: all .5s ease-in-out;
}

.banner-logo-new .inner_item:hover .light {
    display: none;
    transition: all .5s ease-in-out;
}

.banner-logo-new .inner_item .dark {
    display: none;
    transition: all .5s ease-in-out;
}

.banner-logo-new .inner_item:hover .dark {
    display: block;
    transition: all .5s ease-in-out;
}



/*service section*/
.srvc-home {
    display: block;
}




.srvc-mob {
    display: none;
}

.srvc-mob .mobile_serv {
    margin: 50px 0px;
}

.srvc-mob .mobile_serv .mob_stand .heading {
    font-weight: 700;
    font-size: 30px;
    line-height: 40px;
}

.srvc-mob .mobile_serv .mob_stand {
    margin-top: 50px;
}

.srvc-mob .mobile_serv .hm-mob-srvc-btn a {
    background-color: transparent;
    border: 1px solid white;
    border-radius: 30px;
    padding: 15px 32px;
    overflow: hidden;
}

.srvc-mob .accordion-content .title {
    color: white;
    font-size: 20px;
    padding-top: 20px;
}

.srvc-mob .accordion-content .description {
    font-size: 16px;
    padding-top: 15px;
    font-weight: 200;
}

.srvc-mob .tech-img-srvc {
    text-align: center;

}

.srvc-mob .tech-img-srvc a {
    color: white;
}

.srvc-mob .tech-img-srvc img {
    padding: 0 28px;
}

.srvc-mob .tech-img-srvc span {
    font-size: 12px;
}

.srvc-mob .accordion-button {
    height: 100px;
    /* width: 350px; */
    /* border-radius: 25px !important; */
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    background-color: black;
    color: white;
    transition: all 0.5s ease-in-out;
    border: 1px solid #F1B308;
}

.srvc-mob .accordion-item {
    background-color: transparent;

}

.srvc-mob .accordion-body {
    background-color: #1d1d1d;
    padding-bottom: 40px;
}

.srvc-mob .accordion-button::before {
    content: '';
    position: absolute;
    height: 104%;
    width: 102%;
    z-index: -1;
    border-radius: 25px;
}

.accordion-button:not(.collapsed) {
    color: white;
    background: linear-gradient(91.88deg, #D25292 0.88%, #E0A7C3 108.17%);
    border: none;
}

.srvc-mob .accordion-button:after {
    flex-shrink: 0;
    width: 1.25rem;
    height: 1.25rem;
    margin-left: auto;
    content: "";
    background-image: url(https://d2e3cmtjkfw2a3.cloudfront.net/static/media/home/arrow-up.png);
    background-repeat: no-repeat;
    background-size: 1.25rem;
    transition: transform .2s ease-in-out;
}

/* latest service section home page */
.srvc-sec-home {
    background-color: black;
    padding: 80px 0px;
}

.srvc-sec-home .srvc-card-main-wrap .srvc-card-details {
    background: #282828;
    border-radius: 20px;
    display: flex;
    margin-top: 40px;
    margin-right: 10px;
    min-height: 465px;
}

.srvc-sec-home .srvc-home-heading {
    font-size: 40px;
    font-weight: 700;
    text-transform: capitalize;
    letter-spacing: 1px;
    color: #f1b308;
}

.portfolio-main-sec .port-home-heading {
    font-size: 40px;
    font-weight: 700;
    text-transform: capitalize;
    letter-spacing: 1px;
    color: #f1b308;
}

.srvc-sec-home .srvc-card-main-wrap .srvc-card-details .srvc-card-content {
    padding: 40px 51px 40px 38px;
}

.srvc-sec-home .srvc-card-main-wrap .srvc-card-details .srvc-card-content .heading {
    font-weight: 600;
    font-size: 37px;
    line-height: 50px;
    color: #F9F9F9;
}

.srvc-sec-home .srvc-card-main-wrap .srvc-card-details .srvc-card-content .description {
    font-weight: 500;
    font-size: 14px;
    line-height: 28px;
    color: #9C9C9C;
    padding: 20px 0px;
}

.srvc-sec-home .srvc-card-main-wrap .srvc-card-details .srvc-card-icon-list {
    background: #000000;
    box-shadow: 4px 0px 12px rgba(135, 132, 132, 0.3);
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-width: 130px;
}

.srvc-sec-home .srvc-card-main-wrap .srvc-card-details .srvc-card-icon-list .srvc-list-icon a {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px 10px;
    color: white !important;
}

.srvc-sec-home .srvc-card-main-wrap .srvc-card-details .srvc-card-icon-list .srvc-list-icon .srvc-name {
    padding-top: 6px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #F9F9F9;
}

.srvc-sec-home .srvc-card-main-wrap .srvc-card-details .srvc-card-content .btn.btn-primary {
    background-color: transparent !important;
    border: 1px solid #FFFFFF;
    filter: drop-shadow(0px 4px 13px rgba(234, 234, 234, 0.25));
    border-radius: 22.5px;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #F1B308;
    padding: 13px 65px;
    overflow: hidden;
}

.srvc-sec-home .srvc-card-main-wrap .srvc-btn-home .btn.btn-primary:active {
    color: white !important;
    border: none !important;
}

.srvc-sec-home .srvc-card-main-wrap .srvc-card-details .srvc-card-content .btn-primary:focus {
    box-shadow: unset !important;
    color: black;
    border: 1px solid transparent !important;
    background-color: transparent !important;
}

.srvc-sec-home .srvc-card-main-wrap .srvc-btn-home .btn.btn-primary:hover {
    border: 1px solid transparent;
    color: black !important;
}

.srvc-sec-home .srvc-card-main-wrap .srvc-btn-home .btn.btn-primary.hvr-sweep-to-right::before {
    background: #e4a400;
    overflow: hidden;
    color: #000 !important;
}

.srvc-sec-home .srvc-card-main-wrap .srvc-btn-home .btn.btn-primary:visited {
    background-color: transparent !important;

}

.latest_project_sec {
    background-color: #0e0f0f;
    padding-bottom: 90px;
    /* overflow: hidden; */
}

.project-img {
    display: none;
}

.activeSlide .project-img {
    display: block;
    position: absolute;
    width: 105%;
}

.who_we_are {
    overflow: hidden;
}

.who_we_are p {
    line-height: 1.67;
}

.who_we_are .btn_line {
    margin: 25px 0 50px 0;
}

#clin_ls {
    margin: 25px -70px 0 -70px;
    width: calc(100% + 140px);
}

#clin_ls .item {
    /*background: #2c2c2c !important;*/
    width: 180px !important;
    height: 167px;
    display: flex !important;
    align-items: center;
    justify-content: center;
}

#clin_ls .item img {
    max-width: 100%;
    display: block;
}

#clin_ls .slick-slide {
    background: #2c2c2c !important;
}

#clin_ls .item:nth-child(2n+0),
#clin_ls .slick-slide:nth-child(2n+0) {
    background: #0e0e0e !important;
}

.landing-block-8 #clin_ls .slick-slide {
    background: transparent !important;
}

.landing-block-8 #clin_ls .item:nth-child(2n+0),
#clin_ls .slick-slide:nth-child(2n+0) {
    background: transparent !important;
}

.reimagining_entrepreneurial_sec {
    overflow: hidden;
}

.reimagining_entrepreneurial_sec h2 {
    display: block;
    line-height: 1.3;
    padding-bottom: 0;
}

.reimagining_entrepreneurial_sec h2::before {
    display: none;
}

.reimagining_entrepreneurial_sec h2 span {
    display: block;
    color: #fff;
}

.reimagining_entrepreneurial_sec p {
    max-width: 400px;
    line-height: 1.5;
}

.services_ls_bx {
    display: flex;
    width: 200vw;
    margin: 0 -70px;
    flex-wrap: nowrap;
}

.latest_project_sec .btn_line {
    margin: 70px auto 0;
    display: table;
    font-size: 1rem;
    padding: 12px 45px;
}

.latest_project_sec .btn_line svg {
    top: 9px;
}

.services_ls_bx.lt {
    margin-top: 30px;
}

.services_ls_bx.rt {
    margin-bottom: -30px;
}


.hm-salesforce-crm-sec {
    padding: 50px 0px 50px;
    background: #000000;
}

.hm-salesforce-crm-sec .title-main {
    font-size: 40px;
    font-weight: 700;
    text-transform: capitalize;
    letter-spacing: 1px;
    color: #f1b308;
    padding-left: 6%;
    margin-bottom: 100px;
}

.hm-salesforce-crm-sec .crm-card-wrapper {
    /* border: 4px solid #1D1D1D; */
    border: 0.2px solid #f1b308;
    padding: 40px 20px;
    position: relative;
}

.hm-salesforce-crm-sec .crm-card-wrapper .crm-content-wrap {
    text-align: center;
    margin: -5% auto;
    max-width: 1000px;
    background: #000000;
}

.hm-salesforce-crm-sec .crm-card-wrapper .crm-content-wrap .title {
    font-weight: 600;
    font-size: 32px;
    line-height: 46px;
    color: #FFFFFF;
}

.hm-salesforce-crm-sec .crm-card-wrapper .crm-content-wrap .title span {
    color: rgba(241, 179, 8, 1);
}

.hm-salesforce-crm-sec .crm-card-wrapper .crm-content-wrap .description {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
    padding: 20px 0px;
}

.hm-salesforce-crm-sec .crm-card-wrapper .crm-card-box {
    padding-top: 80px;
}

.hm-salesforce-crm-sec .crm-card-wrapper .crm-card-wrap .crm-light-card {
    background: #4E4E4E;
    border-radius: 2px;
    padding: 20px 0px;
    text-align: center;
}

.hm-salesforce-crm-sec .crm-card-wrapper .crm-card-wrap .crm-icon {
    padding: 10px 0px 30px;
}

.hm-salesforce-crm-sec .crm-card-wrapper .crm-card-wrap .crm-icon img {
    filter: brightness(100%) contrast(100%) saturate(100%) blur(0px) hue-rotate(0deg);
}

.hm-salesforce-crm-sec .crm-card-wrapper .crm-card-wrap:hover .crm-icon img {
    filter: brightness(100%) contrast(100%) saturate(0%) blur(0px) hue-rotate(0deg);
    transition-duration: 0.6s;
}

.hm-salesforce-crm-sec .crm-card-wrapper .crm-card-wrap .crm-light-card .crm-title {
    background-color: #222222;
    font-weight: 500;
    font-size: 16px;
    line-height: 28px;
    color: #FFFFFF;
    padding: 18px 0px;
}

.hm-salesforce-crm-sec .crm-card-wrapper .crm-card-wrap .crm-dark-card {
    background: #222222;
    border-radius: 2px;
    padding: 20px 0px;
    text-align: center;
}

.hm-salesforce-crm-sec .crm-card-wrapper .crm-card-wrap .crm-dark-card .crm-title {
    background: #4E4E4E;
    font-weight: 500;
    font-size: 16px;
    line-height: 28px;
    color: #FFFFFF;
    padding: 18px 0px;
}


.hm-salesforce-product-sec {
    padding: 50px 0px 100px;
    background: #000000;
}

.hm-salesforce-product-sec .product-content-wrap {
    margin: 0 auto;
    text-align: center;
    position: relative;
    padding-bottom: 80px;
}

.hm-salesforce-product-sec .product-content-wrap::after {
    position: absolute;
    content: '';
    width: 180px;
    height: 4px;
    background-color: #fff;
    left: 44%;
}

.hm-salesforce-product-sec .product-content-wrap .title {
    font-weight: 600;
    font-size: 40px;
    line-height: 46px;
    text-align: center;
    color: #FFFFFF;
    padding-bottom: 30px;
}

.hm-salesforce-product-sec .product-content-wrap .title span {
    color: rgba(241, 179, 8, 1);
}

.hm-salesforce-product-sec .product-card-wrap {
    text-align: center;
}

.hm-salesforce-product-sec .product-card-wrap .product-card-light {
    background: #FFFFFF;
    border-radius: 2px;
    padding: 30px 12px;
}

.hm-salesforce-product-sec .product-card-wrap .product-icon {
    padding: 10px 0px 30px;
}

.hm-salesforce-product-sec .product-card-wrap .product-card-light .product-card-title {
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    color: #222222;
    padding-bottom: 10px;
}

.hm-salesforce-product-sec .product-card-wrap .product-card-dark {
    background: #222222;
    border-radius: 2px;
    padding: 30px 12px;
}

.hm-salesforce-product-sec .product-card-wrap .product-card-dark .product-card-title {
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    color: #FFFFFF;
    padding-bottom: 10px;
}

.hm-salesforce-cta-sec {
    background: #222121;
    padding: 45px 0px 60px;
}

.hm-salesforce-cta-sec .cta-content-wrap {
    margin: 0 auto;
    text-align: center;
    max-width: 950px;
}

.hm-salesforce-cta-sec .cta-content-wrap .title {
    font-weight: 600;
    font-size: 28px;
    line-height: 30px;
    text-align: center;
    color: #F1B308;
}

.hm-salesforce-cta-sec .cta-content-wrap .description {
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    color: #FFFFFF;
    padding: 20px 0px 30px;
    position: relative;
}

.hm-salesforce-cta-sec .cta-content-wrap .hm-btn-main {
    margin-top: 30px;
}



/*home page */



.domains_ls {
    align-items: center;
    color: #3e3f3f;
    counter-reset: section;
    display: flex;
    position: relative;
    will-change: transform;
    width: 100%;
}

.domains_ls:last-child {
    counter-reset: section
}

.domains_ls li {
    align-items: center;
    display: flex;
    position: relative;
    white-space: pre;
    font-family: 'Assistant', sans-serif;
    font-weight: 400;
}

.domains_ls li::before {
    content: counter(section);
    counter-increment: section;
    left: 0;
    position: absolute;
    text-align: center
}

.domains_ls li::after {
    background: #3e3f3f;
    content: '';
    height: 1px;
    width: 62px
}

.services_ls_bx.lt ol:nth-child(1) {
    animation: services1a 60s linear -20s infinite;
}

.services_ls_bx.lt ol:nth-child(2) {
    animation: services1b 60s linear 10s infinite
}

.services_ls_bx.rt ol:nth-child(1) {
    animation: services2a 60s linear -20s infinite
}

.services_ls_bx.rt ol:nth-child(2) {
    animation: services2b 60s linear 10s infinite
}

.services_ls_bx.rt .domains_ls {
    counter-reset: section 4
}

@keyframes services1a {
    0% {
        -webkit-transform: translate3d(100vw, 0, 0);
        transform: translate3d(100vw, 0, 0)
    }

    100% {
        -webkit-transform: translate3d(100vw, 0, 0) translate3d(-200%, 0, 0);
        transform: translate3d(100vw, 0, 0) translate3d(-200%, 0, 0)
    }
}

@keyframes services1b {
    0% {
        -webkit-transform: translate3d(100vw, 0, 0) translate3d(-100%, 0, 0);
        transform: translate3d(100vw, 0, 0) translate3d(-100%, 0, 0)
    }

    100% {
        -webkit-transform: translate3d(100vw, 0, 0) translate3d(-300%, 0, 0);
        transform: translate3d(100vw, 0, 0) translate3d(-300%, 0, 0)
    }
}

@keyframes services2a {
    0% {
        -webkit-transform: translate3d(-100%, 0, 0);
        transform: translate3d(-100%, 0, 0)
    }

    100% {
        -webkit-transform: translate3d(100%, 0, 0);
        transform: translate3d(100%, 0, 0)
    }
}

@keyframes services2b {
    0% {
        -webkit-transform: translate3d(-200%, 0, 0);
        transform: translate3d(-200%, 0, 0)
    }

    100% {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0)
    }
}

.awards_sec .lt {
    max-width: 850px;
    padding-left: 100px;
    /* max-width: 50%; */
}

.awards_sec .lt p {
    line-height: 1.67;
}

.awards_sec .lt h2 {
    /* width: 250px; */
    max-width: 100%;
}



.awards_im figure img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
}

.awards_im {
    width: 100px;
    text-align: center;
    line-height: 1.67;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 30px;
}

.awards_im figure {
    height: 113px;
    margin: 0 0 20px 0;
}


.blog_sec {
    overflow: hidden;
}

#blog_sld {
    display: flex;
    margin: 0 0 -20px -20px;
}

.blog_bx {
    width: 380px;
    margin: 20px;
}

.blog_bx figure a {
    display: block;
    width: 100%;
    height: 240px;
    overflow: hidden;
    border-radius: 4px;
}

.blog_bx figure a img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

#blog_sld .item:hover .blog_bx figure a img {
    transform: scale(1.2);
    -webkit-transform: scale(1.2);
}

.blog_bx figure {
    margin-bottom: 30px;
}

.blog_bx .nm {
    font-size: 1.75rem;
    color: #fff;
    font-family: 'Assistant', sans-serif;
    font-weight: 700;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    margin-bottom: 20px;
    line-height: 1.4;
}

.blog_bx p {
    font-size: 1rem;
    line-height: 1.67;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #e9e9e9;
}

.blog_bx .ln_btn {
    display: inline-flex;
    padding: 0 0 5px;
    font-size: 1.125rem;
    margin: 20px 0 0;
    color: #f1b308;
    position: relative;
}

.see_all_nv .arrow {
    position: absolute;
    width: 32px;
    height: 32px;
    right: -42px;
    top: -8px;
    opacity: 0;
    -webkit-transition: all .6s cubic-bezier(.19, 1, .22, 1);
    transition: all .6s cubic-bezier(.19, 1, .22, 1)
}

.see_all_nv .arrow i {
    font-style: normal;
    position: absolute;
    top: 4px;
    left: -12px;
    -webkit-transition: all .6s cubic-bezier(.19, 1, .22, 1);
    transition: all .6s cubic-bezier(.19, 1, .22, 1)
}

.see_all_nv {
    position: relative;
    color: #fff;
    font-size: 1.125rem;
    display: block;
}

.see_all_nv:hover {
    color: #f1b308;
}

.see_all_nv:hover .arrow {
    opacity: 1
}

.see_all_nv .arrow i span {
    position: relative;
    left: 0;
    top: -7px;
    font-size: 31px;
    padding: 0;
    -webkit-transition: all .6s cubic-bezier(.19, 1, .22, 1);
    transition: all .6s cubic-bezier(.19, 1, .22, 1)
}

.see_all_nv .arrow svg {
    position: absolute;
    top: 7px;
    left: 5px
}

.see_all_nv .arrow-icon--circle {
    transition: stroke-dashoffset .3s ease;
    stroke-dasharray: 95;
    stroke-dashoffset: 95
}

.see_all_nv:hover .arrow-icon--circle {
    stroke-dashoffset: 0
}

.see_all_nv:hover .arrow i {
    left: -3px;
}

.nw_projects h2 {
    font-weight: 600;
    padding-bottom: 0;
}

.nw_projects h2::before {
    display: none;
}

.nw_projects p {
    font-weight: 700;
    margin-bottom: 35px;
}

.mouse_ani {
    position: absolute;
    left: 50%;
    bottom: -20px;
    transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
}

.mouse_ani .ln {
    width: 20px;
    height: 120px;
    background: #222;
    display: block;
    margin: 13px 0 0 0;
    border-radius: 30px;
    transform: scaleY(0);
    -webkit-transform: scaleY(0);
    transform-origin: top center;
}

#lst_project_sl .item {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-left: 70px;
    align-items: center;
    overflow: hidden;
    height: 100vh;
    padding-top: 150px;
    padding-bottom: 70px;
}

#lst_project_sl .item .lt,
#lst_project_sl .item .rt {
    width: 50%;
}

#lst_project_sl .item .lt {
    max-width: 550px;
}

#lst_project_sl .item .rt {
    max-width: 672px;
}

#lst_project_sl .item .rt figure img {
    max-width: 100%;
}

.project_del h3 {
    font-size: 4.25rem;
    font-family: 'Assistant', sans-serif;
    font-weight: 700;
}

.project_del .shd {
    font-size: 1.375rem;
    color: #5f5f5f;
    margin: 10px 0 35px;
    display: block;
}

.project_del p {
    font-size: 1.125rem;
    line-height: 1.5;
    margin-bottom: 30px;
}

.project_del .btn_line {
    font-size: 1rem;
    height: 47px;
}

.project_del .btn_line:hover svg {
    transform: scale(0.8);
    -webkit-transform: scale(0.8);
    margin-top: -3px;
}

#lst_project_sl .owl-dots .owl-dot span {
    display: block;
    width: 24px;
    height: 4px;
    background: #d8d8d8;
    border-radius: 13px;
    transition: all 0.3s ease-out;
}

#lst_project_sl .owl-dots .owl-dot {
    padding: 10px 3px !important;
}

#lst_project_sl .owl-dots {
    margin-left: 70px;
    position: absolute;
    bottom: 80px;
    opacity: 0;
}

#lst_project_sl .owl-dots .owl-dot.active span {
    background: #cf9a09;
}

.first_ph {
    transform: scale(4.3);
    z-index: 99;
    position: relative;
    transition: all 0.1;
}

#lst_project_sl .owl-stage-outer {
    overflow: visible;
}



.ovrd {
    position: absolute;
    content: '';
    top: -90px;
    left: 0;
    width: 700px;
    height: 100vh;
    background-color: #0e0f0f;
    z-index: 2;
    opacity: 1;
    pointer-events: none;
    transition: all 0.5s cubic-bezier(0, 0, 0, 1.01);
    -webkit-transition: all 0.5s cubic-bezier(0, 0, 0, 1.01);
}

.serv_tabs_ls {
    flex-wrap: wrap;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, 1fr);
    grid-column-gap: 15px;
    grid-row-gap: 15px;
}

.serv_tabs_ls .slick-slide:nth-child(1),
.serv_tabs_ls li:nth-child(1) {
    grid-area: 1 / 1 / 2 / 2;
}

.serv_tabs_ls .slick-slide:nth-child(2),
.serv_tabs_ls li:nth-child(2) {
    grid-area: 1 / 2 / 2 / 3;
    top: 90px;
}

.serv_tabs_ls .slick-slide:nth-child(3),
.serv_tabs_ls li:nth-child(3) {
    grid-area: 2 / 1 / 3 / 2;
}

.serv_tabs_ls .slick-slide:nth-child(4),
.serv_tabs_ls li:nth-child(4) {
    grid-area: 2 / 2 / 3 / 3;
    top: 90px;
}

.serv_tabs_ls .slick-slide:nth-child(5),
.serv_tabs_ls li:nth-child(5) {
    grid-area: 3 / 1 / 4 / 2;
}

.serv_tabs_ls .slick-slide:nth-child(6),
.serv_tabs_ls li:nth-child(6) {
    grid-area: 3 / 2 / 4 / 3;
    top: 90px;
}

.serv_tabs_ls li,
.serv_tabs_ls .slick-slide {
    width: 280px;
    position: relative;
}

.serv_tabs_ls li a,
.serv_tabs_ls .slick-slide a {
    cursor: default;
    padding: 0 10px 46px;
    display: block;
    overflow: hidden;
    text-align: center;
    color: #fff;
    border-radius: 8px;
    background-color: #0f1012;
    border: 1px solid transparent;
    height: 100%;
    box-shadow: 0 2px 4px 0 rgb(0 0 0 / 50%);
}

.serv_tabs_ls li a i,
.serv_tabs_ls .slick-slide a i {
    width: 232px;
    display: flex;
    height: 232px;
    background: #2c2c2c;
    margin: -86px auto 30px;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    padding-top: 75px;
}

.serv_tabs_ls li a .hd,
.serv_tabs_ls .slick-slide a .hd {
    font-size: 1.5rem;
    font-weight: 700;
    font-family: 'Assistant', sans-serif;
    color: #f1b308;
    display: block;
    margin-bottom: 15px;
    padding: 0 50px;
    min-height: 107px;
}

.serv_tabs_ls li a p,
.serv_tabs_ls .slick-slide a p {
    font-size: 0.9rem;
    padding: 0 20px;
    line-height: 1.67;
}

.serv_tabs_ls li a p.hide,
.serv_tabs_ls .slick-slide a p.hide {
    position: absolute;
    z-index: -1;
    opacity: 0;
    visibility: hidden;
}

.serv_tabs_ls li a:hover,
.serv_tabs_ls .slick-slide a:hover {
    border-color: #242424;
    box-shadow: 0 2px 22px 0 rgb(0 0 0 / 50%);
}

@media only screen and (max-width: 767px) {
    .hm-btn-main.srvc-btn-cta-dsktp {
        display: none;
    }

    .hm-btn-main.srvc-btn-cta-mob {
        display: block;
    }

    .hm-content {
        left: 7%;
    }

    .hm-btn-wrap {
        display: flex;
        flex-direction: column;
    }

    .hm-btn-wrap .btn-white {
        width: 250px;
    }

    .hm-btn-main .btn.btn-primary:hover {
        color: black;
    }

    .hm-btn-main .hm-port-btn {
        margin-top: 30px;
        width: 100%;
        text-align: center;
        margin-left: 0px;
    }

    .banner-logo {
        padding-left: 10px;
    }

    /*home page banner new */
    .banner-logo-new::before {
        display: none;
    }

    .banner-logo-new {
        display: grid !important;
        grid-template-columns: 1fr 1fr 1fr;
    }

    .banner-logo-new .inner_item {
        width: 100px;
        height: 100px;
        margin: auto;
        margin-bottom: 20px;
    }

    .banner-logo-new .inner_item img {
        width: 100%;
    }


    .serv_tabs_ls .slick-slide:nth-child(2),
    .serv_tabs_ls li:nth-child(2) {
        grid-area: 1 / 2 / 2 / 3;
        top: unset;
    }

    .serv_tabs_ls .slick-slide:nth-child(4),
    .serv_tabs_ls li:nth-child(4) {
        grid-area: 2 / 2 / 3 / 3;
        top: unset;
    }

    .serv_tabs_ls .slick-slide:nth-child(6),
    .serv_tabs_ls li:nth-child(6) {
        grid-area: 3 / 2 / 4 / 3;
        top: unset;
    }

    .hm-salesforce-crm-sec .crm-content-wrap::after {
        left: 30%;
        width: 150px;
    }

    .hm-salesforce-product-sec .product-content-wrap::after {
        width: 150px;
        left: 30%;
    }

    .hm-salesforce-crm-sec {
        padding: 20px 10px 20px;
    }

    .hm-salesforce-crm-sec .title-main {
        margin-bottom: 20px;
        font-size: 26px;

    }

    .hm-salesforce-crm-sec .crm-card-wrapper .crm-content-wrap .title {
        font-size: 22px;
        line-height: 30px;
    }

    .hm-salesforce-product-sec .product-content-wrap .title {
        font-size: 26px;
        padding-bottom: 10px;
    }

    .hm-salesforce-cta-sec .cta-content-wrap .title {
        font-size: 22px;
    }

    .hm-salesforce-cta-sec .cta-content-wrap .description {
        font-size: 14px;
        line-height: 24px;
    }


}

.slick-dots li.slick-active button:before {
    opacity: .75;
    color: #fff;
}

.slick-dots li button:before {
    font-size: 20px;
    color: #fff;
}

.slick-dots li button {
    color: #e2e2e2;
}

.lst_para {
    margin: 30px auto 10px;
    display: table;
}

#productsSl {
    margin: 0 auto;
    position: relative;
}

.products_lst::before {
    width: 348px;
    height: 550px;
    background: #222;
    content: '';
    position: absolute;
    left: 50%;
    top: 0;
    border-radius: 4px;
    transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
}

.lst_project_inner {
    padding: 15px;
    width: 348px;
    height: 550px;
}

.lst_project_inner figure {
    margin: 0 0 10px;
    padding: 0;
    border-radius: 4px;
    overflow: hidden;
    height: 303px;
    opacity: 0.5;
    transition: all 0.3s ease;
}

.lst_project_inner figure img {
    object-fit: cover;
    object-position: center;
    width: 100%;
    height: 100%;
}

.slt_caption .nv,
.categoryTitle {
    font-family: 'Assistant', sans-serif;
    display: table;
    font-size: 2.5rem;
    font-weight: 700;
    color: #fff;
    margin-bottom: 10px;
    opacity: 0;
    visibility: hidden;
    transition-delay: 0s !important;
}

.slt_caption .hq,
.SubsectionTitle {
    color: #f1b308;
    font-family: 'Assistant', sans-serif;
    display: table;
    font-size: 1.125rem;
    font-weight: 700;
    margin-bottom: 10px;
    opacity: 0;
    visibility: hidden;
    transition-delay: 0s !important;
}

.slt_caption p,
.Subsectiondescription {
    font-size: 0.925rem;
    line-height: 1.67;
    opacity: 0;
    visibility: hidden;
    transition-delay: 0s !important;
}

.slt_caption p.hide,
.hide_para {
    position: absolute;
    z-index: -1;
    opacity: 0 !important;
    visibility: hidden !important;
}

#productsSl .slick-center .lst_project_inner figure {
    opacity: 1;
}

#productsSl .slick-center .slt_caption .nv,
#productsSl .slick-center .slt_caption .hq,
#productsSl .slick-center .slt_caption p {
    opacity: 1;
    visibility: visible;
    transition: all 0.3s cubic-bezier(0, 0, 0.18, 0.87);
    -webkit-transition: all 0.3s cubic-bezier(0, 0, 0.18, 0.87);
    transition-delay: 0.3s !important;
    -webkit-transition-delay: 0.3s !important;
}

.products_lst {
    margin: 0 -70px;
    position: relative;
}

#productsSl .cen-lf .lst_project_inner,
#productsSl .cen-rf .lst_project_inner {
    padding-top: 0;
}

#productsSl .cen-rf .lst_project_inner figure {
    height: 318px;
    clip-path: polygon(0 15px, 100% 0, 100% 100%, 0% 100%);
}

#productsSl .cen-lf .lst_project_inner figure {
    height: 318px;
    clip-path: polygon(0 0, 100% 15px, 100% 100%, 0% 100%);
}

.pr_sld_btn {
    position: absolute;
    bottom: 0;
    width: 65px;
    height: 65px;
    border: 1px solid #fff;
    border-radius: 50%;
    display: flex !important;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.3s cubic-bezier(0, 0, 0.18, 0.87);
    -webkit-transition: all 0.3s cubic-bezier(0, 0, 0.18, 0.87);
}

.pr_sld_btn:hover {
    background-color: #fff;
}

.pr_sld_btn:hover svg {
    fill: #000;
}

.pr_sld_btn svg {
    fill: #fff;
    width: 38px;
}

.pr_sld_btn.pr_prev {
    bottom: 140px;
    left: 27vw;
}

.pr_sld_btn.pr_next {
    bottom: 140px;
    right: 27vw;
    transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
}

.pr_sld_btn.pr_prev:hover {
    -webkit-animation: scale_lf .5s linear 1;
    animation: scale_lf .5s linear 1;
    -webkit-animation-delay: .1s;
    animation-delay: .1s;
}

@-webkit-keyframes scale_lf {
    0% {
        transform: scale(1)
    }

    15% {
        transform: scale(1.2)
    }

    to {
        transform: scale(1)
    }
}

@keyframes scale_lf {
    0% {
        transform: scale(1)
    }

    15% {
        transform: scale(1.2)
    }

    to {
        transform: scale(1)
    }
}

.pr_sld_btn.pr_next:hover {
    -webkit-animation: scale_rf .5s linear 1;
    animation: scale_rf .5s linear 1;
    -webkit-animation-delay: .1s;
    animation-delay: .1s;
}

@-webkit-keyframes scale_rf {
    0% {
        transform: scale(1) rotate(180deg);
    }

    15% {
        transform: scale(1.2) rotate(180deg);
    }

    to {
        transform: scale(1) rotate(180deg);
    }
}

@keyframes scale_rf {
    0% {
        transform: scale(1) rotate(180deg);
    }

    15% {
        transform: scale(1.2) rotate(180deg);
    }

    to {
        transform: scale(1) rotate(180deg);
    }
}

#blog_sld .item {
    transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    -webkit-transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

#blog_sld .item:hover {
    background: #181818;
    border-radius: 4px;
}

.who_we_are .hd_s .ln,
.blog_sec .hd_s .ln,
.awards_sec .hd_s .ln {
    transform-origin: left bottom;
    left: 48px;
}

.ofc_img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    /* transform: scale(1.3);
    -webkit-transform: scale(1.3); */
    transition: none !important;
    -webkit-transition: none !important;

}

.career_section {
    overflow: hidden;
    padding-bottom: 50px;
}

/* .ofc_img {
    height: 600px;
} */

.career_section .career_section_inner {
    /* border: 1px solid #fccc02; */
    margin-top: 70px;
    padding-bottom: 80px;
}

.career_section_caption {
    margin: 60px auto 0;
    display: table;
    text-align: center;
}

.career_section_caption h3 {
    font-size: 2.75rem;
    font-family: 'Assistant', sans-serif;
    font-weight: 600;
}

.career_section_caption p {
    color: #f1b308;
    font-size: 1.5rem;
    font-weight: 700;
    margin: 30px 0;
}

#themes {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    height: 580px;
    background: #2D2D2D;
    overflow: hidden;
}

#themes .column {
    position: relative;
    /* width: 20%; */
    width: 25%;
    height: 580px;
    display: inline-block;
    transition: width 0.6s cubic-bezier(0.16, 1, 0.3, 1);
    -webkit-transition: width 0.6s cubic-bezier(0.16, 1, 0.3, 1);
}

.contact_form_sec {
    padding: 90px 70px;
}

#themes .column.hover .box:after {
    opacity: 0.6
}

#themes .column.hover .box .animated {
    opacity: 1;
    transform: translate3d(0, 0, 0)
}

#themes .column .box {
    width: 100%;
    height: 580px;
    display: block
}

#themes .column .box .theme-bg {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: cover;
    transform: translateX(-100%);
    filter: grayscale(1);
    -webkit-filter: grayscale(1);
    transition: all 0.3s ease-out;
    -webkit-transition: all 0.3s ease-out;
}

#themes .column .box .text {
    position: absolute;
    bottom: 40px;
    left: 0;
    padding: 0 20px;
    width: 100%;
    z-index: 199;
    display: flex;
    justify-content: space-between;
    color: #fff;
    z-index: 91;
    transition: all 0.3s ease-out;
    -webkit-transition: all 0.3s ease-out;
}

#themes .column .box .text .title {
    font-size: 2rem;
    line-height: 1.3;
    color: #fff;
    font-family: 'Assistant', sans-serif;
    font-weight: 600;
}

#themes .column .box .text .title b {
    display: block;
    color: #f1b308;
    font-size: 1.5rem;
}

#themes .column .box .animated {
    width: 330px;
    opacity: 0;
    transition: opacity 0.5s, transform .5s;
    transform: translate3d(-50px, 0, 0)
}

#themes .column .box .theme-bg {
    transform: translateY(0px);
}

#themes .column .box::before {
    width: 100%;
    height: 100%;
    content: '';
    left: 0;
    bottom: 0;
    position: absolute;
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#000000+0,0e0f0f+100&0+0,0.65+100 */
    background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(14, 15, 15, 0.65) 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(14, 15, 15, 0.65) 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(14, 15, 15, 0.65) 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid: DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#a60e0f0f', GradientType=0);
    /* IE6-9 */
    z-index: 9;
}

#themes .column .box .text .address_del {
    position: absolute;
    right: 40px;
    top: 0;
    line-height: 1.67;
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s ease-out;
    -webkit-transition: all 0.3s ease-out;
}

#themes .column .box .text .address_del i svg {
    width: 22px;
    height: 22px;
    display: block;
    margin-bottom: 3px;
}

.get_in_head {
    display: table;
    margin: 0 auto;
    padding: 120px 0 80px;
    text-align: center;
}

.get_in_head h3 {
    font-size: 2.75rem;
    font-family: 'Assistant', sans-serif;
    font-weight: 600;
}

.get_in_head p {
    color: #f1b308;
    font-size: 1.5rem;
    font-weight: 700;
    margin: 30px 0 0;
}

#getin_touch_popup .popup_inside_section {
    width: 760px;
    max-width: calc(100% - 30px);
}

#getin_touch_popup .getin_touch_pop_ins {
    padding: 16px 21px 38px 30px;
    border-radius: 4px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    background-color: #222;
}


#otherLandingPageFormPopup .intl-tel-input {
    display: block;
}

#otherLandingPageFormPopup .form_grp {
    margin-bottom: 10px;
}

#otherLandingPageForm #touchEmail-error,
#otherLandingPageForm #touchName-error {
    color: red;
}

#otherLandingPageFormPopup #touchEmail-error,
#otherLandingPageFormPopup #touchName-error {
    color: red;
}

#otherLandingPageFormPopup input[type="text"]:focus,
#otherLandingPageFormPopup input[type="text"]:active {
    background: transparent !important;
}

#otherLandingPageFormPopup .form-control:focus {
    background-color: transparent !important;
    color: #fff !important;
}

.height-unset {
    height: unset !important;
    overflow: unset !important;
}

.getin_touch_form .form_grp {
    position: relative;
}

.getin_touch_form .form_grp i {
    position: absolute;
    left: 10px;
    top: 12px;
}

.getin_touch_form form label {
    font-family: 'Assistant', sans-serif;
    font-weight: normal;
}

.getin_touch_form form label.error {
    color: red;
}

.getin_touch_form .inp_with_ico .flag-container {
    height: 50px;
}

.getin_touch_form form input,
.getin_touch_form form textarea {
    border: 1px solid #979797 !important;
    border-radius: 8px;
    background: transparent;
    padding: 12px 10px 12px 45px;
    color: #fff;
}

.getin_touch_form form input:hover,
.getin_touch_form form textarea:hover {
    border-color: #fff !important;
}

.getin_touch_form form .inp_with_ico {
    position: relative;
}

.getin_touch_form form .inp_with_ico .intl-tel-input {
    width: 100%;
}

.getin_touch_form form .inp_with_ico .form-control:focus {
    background-color: transparent !important;
}

.getin_touch_form form textarea {
    padding: 15px;
}

.getin_touch_form .form_grp i svg {
    width: 24px;
    height: 24px;
}

.getin_touch_form .form_grp.rt {
    display: flex;
    justify-content: flex-end;
    margin: 0;
}

.getin_touch_pop_ins .head {
    display: table;
    margin: 0 auto 20px;
    font-size: 2.25rem;
}

.getin_touch_pop_ins .head .ln {
    left: 50%;
}

.getin_touch_form .form_grp button {
    background: #f1b308;
    color: #010101;
    font-family: 'Lato', sans-serif;
    border: 0;
    padding: 12px 55px;
    cursor: pointer;
}

.getin_touch_pop_ins .pr {
    width: 540px;
    margin: 0 auto;
    text-align: center;
    max-width: 90%;
    font-size: 1.125rem;
    line-height: 1.5;
    font-weight: 300;
    font-family: 'Assistant', sans-serif;
    /* margin-bottom: 30px; */
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type="number"] {
    -moz-appearance: textfield;
}

.getin_touch_form form {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-between;
}

.getin_touch_form .form_grp {
    width: 100%;
    margin-bottom: 25px;
}

.getin_touch_form .form_grp.half {
    width: calc(50% - 15px);
}

.getin_touch_form .form_grp.half .intl-tel-input {
    display: unset;
}

.getin_touch_form form input {
    color: white;
}

.getin_touch_form .form-control:focus {
    color: white;
}

.three_line_para {
    max-width: 1300px;
}

.lst_tk_btn {
    background: transparent;
    min-width: 180px;
    justify-content: center;
}

section#redesign-main {
    margin-top: 0px;
}

.body-content {
    margin-top: 3em;
    outline: none;
}

.body-content .ui-container {
    display: flex;
    background-size: cover;
    background-position: center top;
}

.body-content .ui-container .row-wide {
    width: 100%;
}

.content-module {
    margin-bottom: 0rem;
}

.section-container.section-container-8 {
    overflow: hidden;
}

.section-container {
    width: 100%;
    position: relative;
}

.body-content .ui-container .row {
    width: 100%;
    margin: 0 auto;
    padding-top: 1.25em;
    padding-bottom: 1.25em;
}

@media screen and (min-width: 1000px) {
    .body-content .ui-container .row {
        max-width: 78.125rem;
    }

}



.carousel {
    position: relative;
    width: 100%;
}

.carousel .slide_container {
    height: 570px;
}

.slide_container {
    width: 410px;
    height: 350px;
    position: relative;
    -webkit-perspective: 1500px;
    perspective: 1450px;
    margin: auto;
    margin-top: 60px;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    padding-left: 15px;
    padding-right: 15px;
}

.carousel .slide_container:before {
    content: " ";
    display: block;
    border-radius: 4px;
    background: rgba(255, 255, 255, 0.04);
    width: 84%;
    height: 97%;
    position: absolute;
    top: -2.6%;
    left: 11.5%;
    z-index: -1;
}

.cards {
    z-index: 9;
    position: relative;
    display: block;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    -webkit-transition: margin .5s;
    -webkit-transition: all .5s;
    -o-transition: all .5s;
    transition: all .5s;
    -webkit-transform: translateZ(900px);
    transform: translateZ(900px);
}

.cards li {
    display: block;
    position: absolute;
    width: 310px;
    height: 300px;
    border-radius: 8px;
    -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, .1), 0 0 10px rgba(0, 0, 0, .1);
    box-shadow: 0px 2px 2px rgba(0, 0, 0, .1), 0 0 10px rgba(0, 0, 0, .1);
    padding: 20px 10px 0 10px;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}

.cards li {
    overflow: hidden;
    padding: 0;
}

.carousel-img {
    width: 100%;
}

.cards .img-container img {
    -webkit-transition: -webkit-transform 0.6s ease;
    transition: -webkit-transform 0.6s ease;
    -o-transition: transform 0.6s ease;
    transition: transform 0.6s ease;
    transition: transform 0.6s ease, -webkit-transform 0.6s ease;
}

.arrow {
    cursor: pointer;
    -webkit-transition: margin .5s;
    -webkit-transition: -webkit-transform .2s;
    transition: -webkit-transform .2s;
    -o-transition: transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
}

.section-container-8 .arrow:hover {
    -webkit-transform: scale(1.2);
    -ms-transform: scale(1.2);
    transform: scale(1.2);
}

.section-container-8 .cta-more-box {
    position: relative;
    left: 0;
    top: 0;
    width: 70px;
    height: 70px;
    color: #fff;
    border: 1px solid #fff;
    border-radius: 50%;
    -webkit-transition: all 0.4s ease;
    -o-transition: all 0.4s ease;
    transition: all 0.4s ease;
    display: inline-block;
}

.cta-more-box.arrow.slide-left {
    position: absolute;
    top: 60%;
    left: -17%;
}

.section-container-8 .cta-more-arrow {
    position: absolute;
    top: 28px;
    -webkit-transition: all 0.4s ease;
    -o-transition: all 0.4s ease;
    transition: all 0.4s ease;
}

.slide-left .img-arrow-left {
    width: 2em;
    position: absolute;
    left: 20px;
    -webkit-transform: rotateY(180deg);
    transform: rotateY(180deg);
}

.carousel-text-content {
    padding-left: 15px;
}

.carousel .slide_container .next-active {
    display: none;
}

.section-container-8 .text-content h4.categoryTitle {
    position: relative;
    top: 5px;
    opacity: 0;
}

.section-container-8 .text-content.isactive h4.categoryTitle {
    transition: all 0.6s ease-out;
    top: 0px;
    opacity: 1;
    transition-delay: 0.1s;
    visibility: visible;
}

.content-module a {
    font-size: 1rem;
    margin-bottom: 1.6rem;
}

.section-container-8 .text-content h3.SubsectionTitle {
    position: relative;
    top: 5px;
    opacity: 0;
}

.section-container-8 .text-content.isactive h3.SubsectionTitle {
    transition: all 0.6s ease-out;
    top: 0px;
    opacity: 1;
    transition-delay: 0.2s;
    visibility: visible;
}

.section-container-8 .text-content p:nth-child(3) {
    position: relative;
    top: 5px;
    opacity: 0;
}

.section-container-8 .text-content.isactive p {
    transition: all 0.6s ease-out;
    top: 0px;
    opacity: 1;
    visibility: visible;
    transition-delay: 0.3s;
}

.carousel .slide_container .top-active {
    position: absolute;
    top: 56%;
    padding: 0% 12%;
    opacity: 1;
}

.carousel .slide_container .top-active.move-right {
    transition: opacity 0.5s ease-out;
    opacity: 0
}

.carousel .slide_container .top-active.move {
    transition: opacity 0.5s ease-out;
    opacity: 0
}

.cta-more-box.arrow.slide-right {
    position: absolute;
    left: 106%;
    top: 60%;
}

.slide-right .img-arrow-right {
    width: 2em;
    position: absolute;
    left: 20px;
}

.close_column {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    display: none;
    transition: all 0.5s ease-in;
    -webkit-transition: all 0.5s ease-in;
    transition-delay: 0.8s;
    -webkit-transition-delay: 0.8s;
}


/* portfolio section home page */

.right-box-content .description {
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    color: #FFFFFF;
    padding: 20px 0px;
}

.store-img a,
.store-img a img {
    width: 100%;
}

.project-technology .tech-list-port {
    padding-left: 0px;
}

.project-technology li {
    padding-right: 30px;
    margin: 10px 15px;
    flex: 0 0 25%;
    list-style: none;
}

.right-box-content .title {
    font-weight: 600;
    font-size: 20px;
    line-height: 32px;
    color: white;
}

.project-case-btn {
    font-weight: 600;
    font-size: 20px;
    line-height: 32px;
    color: #FCCC02 !important;
    cursor: default;
}

.project-sec-1 {
    overflow: hidden;
    /* background-image: linear-gradient(170deg, rgba(0, 0, 0, 1) 21%, rgba(199, 34, 41, 1) 84%); */
    background-image: linear-gradient(170deg, #000 21%, #098089 84%);
    position: relative;
}

.project-sec-2 {
    overflow: hidden;
    position: relative;
    /* background-image: linear-gradient(170deg, rgba(0, 0, 0, 1) 21%, rgba(9, 128, 137, 1) 84%); */
    background-image: linear-gradient(170deg, rgba(0, 0, 0, 1) 21%, rgba(7, 193, 104, 1) 84%);
}

.project-sec-3 {
    overflow: hidden;
    position: relative;
    /* background-image: linear-gradient(165deg, rgba(0, 0, 0, 1) 21%, rgba(1, 94, 153, 1) 84%); */
    background-image: linear-gradient(170deg, rgba(0, 0, 0, 1) 21%, rgba(239, 119, 117, 1) 84%);
}

.project-sec-4 {
    overflow: hidden;
    position: relative;
    background-image: linear-gradient(165deg, rgba(0, 0, 0, 1) 21%, rgba(199, 34, 41, 1) 84%);
}

.project-sec-5 {
    overflow: hidden;
    position: relative;
    /* background-image: linear-gradient(165deg, rgba(0, 0, 0, 1) 21%, rgba(237, 100, 56, 1) 84%); */
    background-image: linear-gradient(165deg, rgba(0, 0, 0, 1) 21%, rgba(130, 100, 242, 1) 84%);
}

.project-wrap {
    min-height: 100vh;
}

.project-wrap-col-1 {
    display: flex;
    align-items: center;
}

.left-box-content {
    text-align: center;
    padding: 20px;
    background: #222222;
    border-radius: 20px;
}

.project-logo-img-khatabook {
    margin-top: -100px
}

.project-logo-img-tg {
    margin-top: -68px
}

.project-logo-img-oberit {
    margin-top: -98px
}

.project-logo-img-myshift {
    margin-top: -80px
}

.project-logo-img-sesh {
    margin-top: -65px
}

.project-type {
    font-weight: 600;
    font-size: 22px;
    line-height: 32px;
    color: #E4A400;
    padding-top: 10px;
}

.store-img-box {
    margin-top: 20px;
}

.store-img-box .right-title {
    text-align: left;
    padding-left: 10px;
}

.project-technology {
    margin-top: 20px;
}

.project-technology .right-title {
    text-align: left;
    padding-left: 10px;
}

.tech-list-project {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 10px;
}

.project-wrap-col-2 {
    position: relative;
}

.project-wrap-col-2 .project-img-wrap {
    clip: rect(auto, auto, auto, auto);
    height: 100%;
    position: absolute;
    top: 0;
    width: 100%;
    left: 0;
    right: 0;
    margin: 0 auto;
}

.project-wrap-col-2 .project-img-wrap .center-img-box {
    max-width: 100%;
    max-height: 100%;
    width: 100%;
    position: fixed;
}

.project-wrap-col-2 .project-img-wrap .center-img-box .phone img {
    max-width: 100%;
    height: auto;
    position: fixed;
    left: 50%;
    top: 55%;
    transform: translate(-50%, -50%);
}

.project-wrap-col-3 {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.project-wrap .project-btn-main .btn.btn-primary {
    background: #E4A400;
    border-radius: 24.5px;
    box-shadow: 0 4px 13px rgb(234 234 234 / 25%);
    padding: 13px 25px;
    color: white;
    border: 1px solid transparent !important;
    overflow: hidden;
    margin-top: 40px;
    text-transform: uppercase;
    letter-spacing: 0.1em;
}

.project-wrap .project-btn-main .btn.btn-primary:hover {
    color: black !important;
    background-color: #e4a400 !important;
}

.project-wrap .project-btn-main .btn.btn-primary:active {
    background-color: #e4a400;
}

.project-slider-mob {
    display: none;
}

.project-slider-mob .testimonial-card .testimonial-list .testimonial-wrapper {
    background: transparent !important;
    border: none;
    height: 100%;
    width: unset;
    position: relative;
    padding: 30px 0px 10px;
}

.project-slider-mob .project-mob-sec .project-name {
    font-size: 30px;
    line-height: 32px;
    text-align: center;
    padding: 50px 0px 20px;
}

.project-slider-mob .project-slider-mob .testimonial-card .testimonial-card-slider .slide {
    height: 100%;
}

.project-slider-mob .mob-store-img {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.project-slider-mob .project-img-mob {
    text-align: center;
}

.project-slider-mob .project-img-mob img {
    display: inline-block !important;
}

.project-slider-mob .testimonial-card .testimonial-list .testimonial-wrapper {
    margin-top: 10px !important;
}

.project-slider-mob .testimonial-card-slider {
    height: 650px !important;
}

.project-slider-mob .testimonial-card ul li {
    margin-top: 0px !important;
}

.project-slider-mob .testimonial-card-slider .slick-list {
    height: 100% !important;
}

#themes .column.hover .close_column {
    transition-delay: 0s;
    -webkit-transition-delay: 0s;
    z-index: 99;
}

@media (min-width: 767px) and (max-width: 768px) {

    .carousel .container:before {
        width: 98% !important;
        left: 3% !important;
    }

    .cards li {
        width: 245px !important;
        height: 244px !important;
        left: 0%;
    }

    .cta-more-box.arrow.slide-left {
        left: -27% !important;
    }

    .cta-more-box.arrow.slide-right {
        left: 105% !important;
    }

    .carousel .container .top-active {
        top: 44% !important;
        left: -2%;
    }

    h3.SubsectionTitle {
        height: auto !important;
    }

    .carousel .container .cta-more-center {
        bottom: -20px !important;
        left: 54% !important;
        width: 65% !important;
    }

    .explore-container {
        width: 46%;
        left: 27%;
    }


}

@media (min-width: 1023px) and (max-width: 1024px) {
    .carousel .container:before {
        width: 84% !important;
        left: 13% !important;
    }

    .cards li {
        width: 224px !important;
        height: 200px !important;
        left: 5%;
    }

    .cta-more-box.arrow.slide-left {
        left: -20% !important;
    }

    .cta-more-box.arrow.slide-right {
        left: 103% !important;
    }

    .carousel .container .top-active {
        top: 37% !important;
        left: 6%;
    }

    h3.SubsectionTitle {
        height: auto !important;
    }

    .carousel .container .cta-more-center {
        bottom: -5px !important;
        left: 55% !important;
        width: 65% !important;
    }
}

@media screen and (max-width: 767px) {

    /* hm-new banner design */
    .hm_banner::before {
        opacity: 0.4;
    }

    .hm-heading {
        font-size: 32px;
        line-height: 60px;
    }

    .hm-btn-main .btn.btn-primary.btn-white {
        margin-top: 20px;
        margin-left: 0;
    }

    .section-container-7 .shared-container {
        -webkit-transform: none;
        -ms-transform: none;
        transform: none;
    }

    .section-container-2 .cta-more .cta-more-label {
        margin-left: 42px;
    }

    .cards li {
        width: 230px;
        height: 240px;
    }

    .slide_container {
        width: 320px;
        height: 290px;
    }

    .carousel-img {
        height: auto;
        width: 100%;
    }
}

.section-container .new-row {
    width: 100%;
    margin: 0 auto;
    padding-top: 4.25em;
    padding-bottom: 6.25em;
}

.section-container .new-row ul,
.section-container .new-row ol {
    margin-top: 0.625rem;
    margin-bottom: 1.6rem;
    margin-left: 1.875rem;
    padding-left: 1.25rem;
}


/*Carousel ipad*/

@media screen and (min-width: 1000px) {}

@media screen and (min-width: 1000px) {
    .new-row {
        max-width: 100% !important;
    }

    .section-container .new-row ul,
    .section-container .new-row ol {
        margin-bottom: 1.6rem;
        margin-left: 1.875rem;
        padding-left: 1.25rem;
    }
}

@media only screen and (max-width: 999px) and (min-width: 768px) {
    .slide-right .img-arrow-right {
        position: unset;
        width: 50px;
        padding-left: 20px;
        padding-bottom: 10px;
    }

    .slide-left .img-arrow-left {
        position: unset;
        width: 50px;
        padding-right: 20px;
        padding-bottom: 10px;
    }

    .carousel .container:before {
        width: 98% !important;
        left: 3% !important;
    }

    .cards li {
        width: 262px !important;
        height: 244px !important;
        left: 0%;
    }

    .carousel .slide_container:before {
        left: 15%;
    }

    .slide_container {
        width: 350px;
        height: 280px;
    }

    .cards li {
        width: 220px;
        height: 200px;
    }

    .section-container .new-row ul,
    .section-container .new-row ol {
        margin-left: 3.075rem;
        padding-left: 3.25rem;
    }


    /* new code for responsive */
    .hm_banner {
        height: 90vh;
        padding-top: 0px;
    }

    .banner-logo-new .inner_item {
        width: 100px;
        height: 100px;
        margin: auto;
        margin-bottom: 20px;
    }

    .banner-logo-new .inner_item img {
        width: 100%;
    }

    .hm-content {
        top: 16%;
    }

    .hm-salesforce-product-sec .product-card-wrap .product-card-dark .product-card-title {
        font-size: 18px;
        height: 68px;
    }

    .hm-salesforce-product-sec .product-card-wrap .product-card-light .product-card-title {
        font-size: 18px;
        height: 68px;
    }

    .hm-salesforce-product-sec .product-card-wrap .product-card-light {
        padding: 5px;
    }

    .hm-salesforce-product-sec .product-card-wrap .product-card-dark {
        padding: 5px;
    }

    .our-motive-sec .our-motive-about {
        font-size: 16px;
        line-height: 26px;
    }

    .srvc-sec-home .srvc-home-heading {
        font-size: 32px;
    }

    .hm-salesforce-crm-sec .title-main {
        font-size: 32px;
        margin-bottom: 50px;
    }

    .hm-salesforce-product-sec .product-content-wrap .title {
        font-size: 32px;
    }

    .hm-salesforce-product-sec .product-content-wrap::after {
        left: 38%;
    }

    .our-motive-sec .our-motive-head .heading {
        font-size: 32px;
    }

    .our-motive-sec .review-list .review-no {
        font-size: 32px;
    }

    .our-motive-sec .review-list .review-no-title {
        font-size: 18px;
        line-height: 32px;
    }

    .reimagining_entrepreneurial_sec h2 {
        font-size: 32px;
    }

    .awards_sec .lt h2 {
        font-size: 32px;
    }

    .blog-sec .hd_s {
        font-size: 32px;
    }

    header .container {
        max-width: 950px;
    }

    footer .container {
        max-width: 950px;
    }

    .project-slider-mob {
        display: block;
    }

    .portfolio-main-sec {
        display: none;
    }
}

@media only screen and (max-width: 1040px) and (min-width: 1000px) {
    .slide_container {
        width: 310px;
    }

    .cards li {
        width: 220px;
        height: 200px;
    }

    .section-container .new-row ul,
    .section-container .new-row ol {
        margin-left: 3.075rem;
        padding-left: 3.25rem;
    }
}

@media all and (min-width:700px) and (max-width:1366px) {
    .bxx::before {
        top: -48px;
    }

    .bxx::after {
        bottom: -48px;
    }
}

@media all and (min-width:700px) and (max-width:1440px) {
    .bxx::before {
        top: -40px;
    }

    .bxx::after {
        bottom: -40px;
    }

    .project-wrap-col-2 .project-img-wrap .center-img-box .phone img {
        top: 58%;
    }
}

@media only screen and (max-width: 1320px) and (min-width: 1025px) {
    .zoom {
        overflow: hidden;
    }

    .srvc-sec-home .srvc-card-main-wrap .srvc-card-details .srvc-card-content .heading {
        font-size: 30px;
    }

    .srvc-sec-home .srvc-card-main-wrap .srvc-card-details .srvc-card-content .description {
        min-height: 210px;
    }
}




@media all and (max-width:1500px) {


    .project-logo-img-myshift {
        margin-top: -70px
    }

    .project-technology li {
        margin: 10px 7px;
    }

    .domains_ls li::before {
        letter-spacing: -.5px
    }

    .blog_bx .nm {
        font-size: 1.55rem;
        margin-bottom: 15px;
    }

    .blog_bx figure {
        margin-bottom: 20px;
    }

    .blog_bx figure a {
        height: 220px;
    }

    .blog_bx {
        width: 330px;
    }

    .blog_bx p {
        font-size: 0.9rem;
    }

    .project-logo-img-oberit {
        margin-top: -58px
    }
}

@media all and (max-width:1366px) {
    .ban_caption h2 {
        font-size: 2.45rem;
    }

    .ban_caption h3 {
        font-size: 3.225rem;
    }

    .tp_bx {
        min-height: 100px;
    }

    .what_we_do h2 {
        font-size: 2.7rem;
    }

    .what_we_do h2 span {
        font-size: 4.175rem;
    }

    .what_we_do .or_para {
        font-size: 1.2rem;
        max-width: 430px;
    }

    .what_we_do p {
        font-size: 1.025rem;
        max-width: 500px;
    }

    .what_we_do .lc_idea {
        font-size: 0.925rem;
        margin-bottom: 20px;
    }

    .slt_caption .nv,
    .categoryTitle {
        font-size: 2.5rem;
    }

    .career_section_caption h3,
    .get_in_head h3 {
        font-size: 2.35rem;
    }

    .career_section_caption p,
    .get_in_head p {
        font-size: 1.2rem;
    }

    #themes .column .box .text .title {
        font-size: 1.2rem;
        height: 90px;
    }

    #themes .column.hover .box .text .address_del {
        font-size: 12px;
    }

    #themes .column .box .text .title b {
        font-size: 1.3rem;
    }

    .awards_im {
        margin: 0 20px;
    }


    .btn_line:hover svg {
        right: 12px;
        /* top: 14px; */
    }
}

@media all and (max-width:900px) {
    .domains_ls li {
        letter-spacing: -2px
    }

    .hm_banner {
        height: 85vh;
        padding-top: 0px;
    }

    .hm-content {
        top: 25%;
    }

    .hm-heading {
        font-size: 50px;
        line-height: 70px;
    }

    .title-border {
        width: 300px;
    }

}

@media all and (max-width:600px) {
    .hm-heading {
        font-size: 50px;
    }

    .section-container .new-row {
        padding-top: 0px;
    }

    .domains_ls li {
        font-size: 2.5rem;
        line-height: 1.5;
        margin-right: 25px;
        padding-left: 30px;
    }

    .domains_ls li::before {
        font-size: 1.5rem;
        left: -7px;
        line-height: 1.5;
        width: 35px
    }

    .domains_ls li::after {
        margin-left: 17px;
        width: 20px;
    }

    .domains_ls {
        width: 150%;
    }

    .title-border {
        width: 270px;
    }

}

@media all and (max-width:350px) {
    .domains_ls li {
        padding-left: 3px;
    }

    .domains_ls li::before {
        left: -22px;
    }

    .domains_ls li::after {}

    .hm-salesforce-crm-sec .title-main {
        font-size: 22px;

    }
}

@media all and (min-width:601px) and (max-width:1500px) {
    .domains_ls li {
        margin-right: 34px
    }

    .domains_ls li::before {
        font-size: 1.5rem;
        left: 0;
        line-height: 1.5;
    }

    .domains_ls li::after {
        margin-left: 30px
    }
}

@media all and (min-width:601px) and (max-width:1200px) {
    .domains_ls li {
        line-height: 1.5;
        padding-left: 25px;
        font-size: 2rem;
    }

    .mouse_ani .ln {
        height: 80px;
    }


}

@media all and (min-width:1000px) {
    .hm_banner {
        height: 70vh;
    }
}

@media all and (min-width:901px) {
    .domains_ls li {
        letter-spacing: -4px;
        padding-left: 25px;
    }
}

@media all and (min-width:1201px) and (max-width:1500px) {
    .domains_ls li {
        font-size: 3.55rem;
        line-height: 1.5;
        padding-left: 20px;
    }
}

@media all and (min-width:1501px) {
    .hm_banner {
        height: 100vh;
        padding-top: 0px;
    }

    .hm-content {
        top: 25%;
    }

    .domains_ls li {
        line-height: 1.6;
        font-size: 48px;
        margin-right: 30px;
        padding-left: 25px;
    }

    .domains_ls li::before {
        font-size: 2.125rem;
        left: 0;
        letter-spacing: -1px;
        line-height: 56px;
    }

    .domains_ls li::after {
        margin-left: 50px;
    }
}

@media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {


    .what_we_do p {
        max-width: 400px;
    }

    .what_we_do_grph {
        max-width: 60%;
    }

    .what_we_do {
        max-width: 38%;
    }

    .get_in_touch {
        overflow: hidden;
    }

    .products_lst {
        margin: 0 -15px;
    }

    .pr_sld_btn.pr_next {
        right: 20vw;
    }

    .pr_sld_btn.pr_prev {
        left: 20vw;
    }

    #themes .column .box .text {
        padding: 0 20px;
    }

    .carousel .slide_container .top-active {
        top: 44%;
    }

    .slt_caption .nv,
    .categoryTitle {
        font-size: 26px;
    }

    .section-container .new-row ul,
    .section-container .new-row ol {
        margin-left: 1.675rem;
    }

    .carousel .slide_container {
        height: 510px;
    }

}

@media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {

    .what_we_do,
    .what_we_do_grph {
        width: 100%;
        max-width: 100%;
    }



    .what_we_do {
        position: static;
        margin-bottom: 40px;
    }

    .serv_tabs_ls {
        width: 580px;
        max-width: 100%;
        margin: 0 auto;
    }



    .pr_sld_btn.pr_next {
        right: 20vw;
    }

    .pr_sld_btn.pr_prev {
        left: 20vw;
    }

    .awards_sec .rt {
        max-width: 65%;
    }


    .blog_sec_inner {
        overflow: hidden;
    }

    .get_in_touch {
        overflow: hidden;
    }

    .hm_banner {
        min-height: 90vh;
        overflow: hidden;
        padding-top: 0px;
    }

    .ofc_img img {
        transform: scale(1);
        -webkit-transform: scale(1);
    }

    .ban_caption {
        top: 25%;
        width: 100%;
    }

    .products_lst {
        margin: 0 -15px;
    }

    .pr_sld_btn.pr_next {
        right: 15vw;
    }

    .pr_sld_btn.pr_prev {
        left: 15vw;
    }

    .nw_projects .hd_s {
        text-align: center;
    }

    .nw_projects {
        overflow: hidden;
    }

    .awards_sec .lt h2 {
        font-size: 2.2rem;
    }

    .awards_im figure {
        height: 75px;
    }

    .awards_im {
        margin: 0 10px;
    }

    .awards_im .aw_tx {
        font-size: 0.9rem;
    }

    .career_section .career_section_inner {
        border-color: #c29109;
    }

    #themes .column .box .text {
        padding: 0 10px;
    }

    #themes .column .box .text .title {
        font-size: 1.3rem;
        height: 90px;
    }

    #themes .column .box .text .title b {
        font-size: 1rem;
    }


    .career_section_caption h3,
    .get_in_head h3 {
        font-size: 1.85rem;
    }

    .get_in_head {
        padding: 60px 0 65px;
    }

    .ofc_img {
        height: 400px;
    }

    .career_section .career_section_inner {
        padding-bottom: 85px;
    }

    .what_we_do h2 span {
        font-size: 3.975rem;
    }

    .bxx::before {
        top: -45px;
    }

    .bxx::after {
        bottom: -45px;
    }

    #clin_ls .item {
        width: 150px;
        height: 135px;
    }


    .mouse_ani .ln {
        display: none;
    }

    .mouse_ani {
        bottom: 20px;
    }

    #themes .column .box .text .address_del {
        right: auto;
        left: 20px;
        bottom: 250px;
        top: auto;
    }

}

.ban_caption .ln.hide.aos-init.aos-animate {
    display: none;
}

@media only screen and (max-width: 767px) {
    .ban_caption .ln.hide.aos-init.aos-animate {
        display: block;
    }

    .ban_caption h1 strong {
        border: 0 !important;
    }
}

@media (max-width: 1000px) {
    .block_chain .sec-11-h2 {
        top: 6% !important;
    }

    .categoryTitle,
    .slt_caption .nv {
        font-size: 24px;
    }


}

@media (min-width: 768px) {
    #themes .column.hover {
        width: 55%
    }

    #themes .column.hover .box .theme-bg {
        filter: grayscale(0);
        -webkit-filter: grayscale(0);
    }

    #themes .column.hover .box .text {
        opacity: 1;
        visibility: visible;
    }

    #themes .column.hover .box .text .address_del {
        opacity: 1;
        visibility: visible;
        transition-delay: 0.1s;
        -webkit-transition-delay: 0.1s;
    }

}

@media (min-width: 768px) {
    #themes .column.not_hover {
        width: 15%
    }
}

@media only screen and (max-width: 767px) {
    .srv-card-content .btn_line:hover svg {
        right: -3px;
    }

    .srvc-sec-home .srvc-card-main-wrap .srvc-card-details .srvc-card-content {
        word-break: break-word;
        padding: 20px 15px;
    }

    .srvc-sec-home .srvc-card-main-wrap .srvc-card-details .srvc-card-content .heading {
        font-size: 22px;
        line-height: 32px;
    }

    .srvc-sec-home .srvc-card-main-wrap .srvc-card-details .srvc-card-content .description {
        line-height: 24px;
    }

    .srvc-sec-home .srvc-card-main-wrap .srvc-card-details .srvc-card-content .btn.btn-primary {
        padding: 12px 40px;
    }

    .project-slider-mob {
        display: block;
    }

    .portfolio-main-sec {
        display: none;
    }

    .what_we_do,
    .what_we_do_grph {
        width: 100%;
        max-width: 100%;
    }


    .what_we_do {
        position: static;
        margin-bottom: 40px;
    }

    .serv_tabs_ls {
        display: block;
        margin: 0 0 0 -15px;
        width: calc(100% + 30px);
        max-width: calc(100% + 30px);
        padding-left: 0;
    }

    .serv_tabs_ls .slick-slide {
        top: 0 !important;
        margin: 0 10px;
    }

    .serv_tabs_ls .slick-slide a p {
        min-height: 120px;
    }

    .serv_tabs_ls .slick-dots li {
        width: auto;
        margin: 0;
    }

    .serv_tabs_ls .slick-dots {
        bottom: -45px;
        z-index: 1;
    }


    .pr_sld_btn.pr_next {
        right: 20vw;
    }

    .pr_sld_btn.pr_prev {
        left: 20vw;
    }

    .awards_sec .container {
        flex-wrap: wrap;
    }

    .awards_sec .rt {
        max-width: 100%;
        width: 100%;
        flex-wrap: wrap;
        display: flex;
        justify-content: center;
    }

    .awards_sec .lt {
        max-width: 100%;
        width: 100%;
        margin-bottom: 40px;
        padding-left: 20px;
    }

    .blog_sec_inner {
        overflow: hidden;
        padding-top: 70px;
    }

    .get_in_touch {
        overflow: hidden;
    }

    .hm_banner {
        overflow: hidden;
        min-height: 100vh !important;
        padding-top: 0px;
    }

    .ofc_img img {
        transform: scale(1);
        -webkit-transform: scale(1);
    }

    .ban_caption {
        top: 15%;
        width: 100%;
    }

    .products_lst {
        margin: 0 -15px;
    }

    .pr_sld_btn.pr_next {
        right: 15vw;
    }

    .pr_sld_btn.pr_prev {
        left: 15vw;
    }

    .nw_projects .hd_s {
        text-align: center;
    }

    .nw_projects {
        overflow: hidden;
    }

    .awards_sec .lt h2 {
        font-size: 2.2rem;
    }

    .awards_im figure {
        height: 75px;
    }

    .awards_im {
        margin: 0 0 30px;
        width: 30%;
    }

    .awards_im .aw_tx {
        font-size: 1rem;
    }

    .awards_im:last-child {
        margin-bottom: 0;
    }

    .career_section .career_section_inner {
        border-color: #c29109;
    }

    #themes .column .box .text {
        padding: 20px;
        height: 100%;
        left: 0;
        bottom: 0;
    }

    #themes .column .box.hover .text .title {
        transform: rotate(0deg) translateX(0px);
        -webkit-transform: rotate(0deg) translateX(0px);
        transform-origin: bottom left;
        -webkit-transform-origin: bottom left;
        bottom: 20px;
        left: 20px;
    }

    #themes .column .box .text .title {
        font-size: 1.3rem;
        transform: rotate(-90deg) translateX(-320px);
        -webkit-transform: rotate(-90deg) translateX(-320px);
        transform-origin: bottom left;
        -webkit-transform-origin: bottom left;
        width: 280px;
        display: block;
        position: absolute;
        left: 60px;
        height: unset;
    }

    #themes .column .box .text .title b {
        font-size: 1rem;
    }

    #themes,
    #themes .column .box,
    #themes .column {
        height: 400px;
    }

    .career_section_caption h3,
    .get_in_head h3 {
        font-size: 1.85rem;
    }

    .get_in_head {
        padding: 60px 0 65px;
    }

    .ofc_img {
        height: 180px;
    }

    .career_section .career_section_inner {
        padding-bottom: 85px;
    }

    .what_we_do h2 span {
        font-size: 3.975rem;
    }

    .bxx::before {
        top: -32px;
    }

    .bxx::after {
        bottom: -32px;
    }

    #clin_ls .item {
        width: 100px;
        height: 95px;
    }


    .ban_caption h1 {
        font-size: 1.55rem;
        text-align: center;
        line-height: 1.3;
        width: 260px;
        max-width: 100%;
        margin-bottom: 35px;
    }

    .ban_caption h2 {
        font-size: 1.85rem;
        text-align: center;
        width: 260px;
        margin: 0 auto 30px;
        line-height: 1.3;
        max-width: 100%;
    }

    .ban_caption h3 {
        font-size: 1.725rem;
        height: 50px;
    }

    .tp_bx {
        min-height: 66px;
    }

    .services_ls_bx {
        margin: 0 -15px;
    }

    #themes .column.hover .box .theme-bg {
        filter: grayscale(0);
        -webkit-filter: grayscale(0);
    }

    #themes .column.hover {
        width: 50%
    }

    #themes .column {
        height: unset !important;
    }

    #themes .column.not_hover {
        width: 12.5%
    }

    #themes .column.hover .box .text {
        opacity: 1;
        visibility: visible;
    }

    #themes .column.hover .box .text .address_del {
        opacity: 1;
        visibility: visible;
        transition-delay: 0.1s;
        -webkit-transition-delay: 0.1s;
        font-size: 12px;
    }

    #themes .column.not_hover .box .text .address_del {
        opacity: 0;
        visibility: hidden;
    }

    #themes .column .box .text .address_del {
        right: auto;
        left: 0;
        top: auto;
        top: 0px;
        width: 100%;
        padding: 20px;
        text-align: left;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .awards_sec {
        overflow: hidden;
    }

    .lst_para {
        text-align: center;
        line-height: 1.5;
        font-size: 1.2rem;
    }

    .lst_project_inner {
        width: 298px;
        height: 500px;
    }

    .products_lst::before {
        width: 302px;
        height: 500px;
    }

    .lst_project_inner figure {
        height: 280px;
    }

    .slt_caption .nv,
    .categoryTitle {
        font-size: 20px;
    }

    .slt_caption p,
    .Subsectiondescription {
        font-size: 0.8rem;
    }

    .pr_sld_btn {
        display: none !important;
    }

    #productsSl .slick-dots {
        bottom: -35px;
    }

    .latest_project_sec .btn_line {
        margin: 70px auto 0;
        left: calc(50% - 3px);
        bottom: -57px;
    }

    .latest_project_sec {
        padding: 50px 0;
    }

    .who_we_are p {
        font-size: 1.1rem;
    }

    .nw_projects h2 {
        font-size: 1.3rem;
        line-height: 1.5;
    }

    .getin_touch_pop_ins .pr {
        width: 100%;
    }

    .getin_touch_form .form_grp.half {
        width: 100%;
    }

    .mouse_ani .ln {
        display: none;
    }

    .mouse_ani {
        bottom: 100px;
    }

    .section-container .new-row ul,
    .section-container .new-row ol {
        margin-left: 0.575rem;
    }

    .carousel .slide_container:before {
        left: 8%;
    }

    .carousel .slide_container .top-active {
        top: 52%;
        padding: 0% 12% 0% 14%;
    }

    .slide-left .img-arrow-left,
    .slide-right .img-arrow-right {
        width: 1.5em;
    }

    .carousel .slide_container {
        height: 520px;
    }

    .cta-more-box.arrow.slide-right {
        left: 99%;
    }

    .section-container-8 .cta-more-box {
        width: 40px;
        height: 40px;
    }

    .cta-more-box.arrow.slide-left {
        left: -11%;
    }

    .section-container-8 .cta-more-arrow {
        top: 15px;
        width: 20px;
    }

    .slide-right .img-arrow-right {
        left: 9px;
    }

    .slide-left .img-arrow-left {
        left: 9px;
    }

    #themes .column.not_hover .box .text .title {
        left: 44px;
    }

    .close_column {
        display: block;
    }

    .srvc-main-content {
        padding: 0;
    }

    .srvc-home {
        display: none;
    }

    .srvc-mob {
        display: block;
    }
}

@media only screen and (max-width: 755px) {
    #themes .column.hover {
        width: 62%;
    }
}

@media only screen and (max-width: 500px) {
    .hm_banner {
        min-height: 90vh !important;
        height: 100%;
        padding-top: 100px;
    }

    .ban_caption h3 {
        font-size: 26px;
    }

    .hm-description {
        font-size: 22px;
        line-height: 30px;
        max-width: 350px;
    }

    .blockChain_slider.hm-banner-slider .inner_item {
        width: 100px;
        height: 100px;
    }

    .blockChain_slider.hm-banner-slider .slick-slide img {
        min-width: 120px;
    }

    .banner-logo-slider::before {
        top: 30%;
    }

    #themes .column.hover {
        width: 62%
    }

    .srvc-sec-home .srvc-card-main-wrap .srvc-card-details .srvc-card-content .btn.btn-primary {
        padding: 12px 20px;
    }
}



@media only screen and (max-width: 450px) {
    .ban_caption h3 {
        font-size: 22px;
    }
}

@media only screen and (max-width: 300px) {
    .hm_banner {
        height: 100vh !important;
    }

    .ban_caption h3 {
        font-size: 22px;
    }

    .hm-heading {
        font-size: 30px;
        line-height: 51px;
    }

    .hm-btn-main .btn.btn-primary {
        padding: 5px 13px;
    }

    .srv-card-content .btn_line:hover svg {
        right: -3px;
    }
}