/*new banner portfolio */
.port-banner {
  background-image: url(https://d2e3cmtjkfw2a3.cloudfront.net/static/media/portfolio/port-banner-img.svg);
  background-repeat: no-repeat;
  background-size: cover;
  object-fit: cover;
}

.landing-block-4.port-banner .block-4-img:before {
  background-color: transparent;
}

.port-banner .block-4-header {
  max-width: 550px !important;
}

.port-banner .heading {
  font-weight: 700;
  font-size: 42px;
  line-height: 44px;
  color: #F1B308;
}

.port-banner .description {
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
}

.port-banner .port-banner-img {
  margin-top: 40px;
  display: flex;
}

.salesforce-banner-img {
  margin-top: 40px;
  /* display: flex; */
}



.port-sec-1 .main-content {
  margin-bottom: 70px;
}

.port-sec-1 .main-content .title {
  border-left: 2px solid #34BDB7;
  color: #FFC400;
  font-weight: 500;
  font-size: 50px;
  line-height: 50px;
  margin-top: 70px;
  padding-left: 20px;
  padding-bottom: 25px;
}

.port-sec-1 .main-content .para {
  font-weight: 500;
  font-size: 20px;
  line-height: 32px;
  color: #EBEAE9;
  margin: 20px 0;
  padding-left: 20px;
}




.dropdown-port-p {
  width: 300px;
  display: inline-block;
  background-color: #272727;
  border-radius: 27.5px;
  border: 1px solid #F1B308;
  transition: all .5s ease;
  position: relative;
  font-size: 14px;
  color: white;
  height: 50px;
  text-align: left
}

.dropdown-port-p .select {
  cursor: pointer;
  display: block;
  padding: 12px 30px;
}

.dropdown-port-p .select>i {
  font-size: 13px;
  color: white;
  cursor: pointer;
  transition: all .3s ease-in-out;
  float: right;
  line-height: 20px
}

.dropdown-port-p:active {
  background-color: #272727;

}

.dropdown-port-p.active:hover,
.dropdown-port-p.active {

  background-color: #272727;
}

.dropdown-port-p.active .select>i {
  transform: rotate(90deg)
}

.dropdown-port-p .dropdown-menu-port-p {
  position: absolute;
  background-color: #272727;
  width: 100%;
  left: 0;
  margin-top: 1px;
  border-radius: 10px;
  overflow: hidden;
  display: none;
  max-height: 144px;
  overflow-y: auto;
  z-index: 9;
  border: 1px solid #4a4949;

}

.dropdown-port-p .dropdown-menu-port-p.scrollbar {
  background-color: black;
  width: 10px;
}

.dropdown-port-p .dropdown-menu-port-p::-webkit-scrollbar {
  width: 6px;
  margin: 8px 0px;
}

.dropdown-port-p .dropdown-menu-port-p::-webkit-scrollbar-track {
  background-color: black;
  margin: 8px 0px;
}

.dropdown-port-p .dropdown-menu-port-p::-webkit-scrollbar-thumb {
  background-color: rgb(79, 79, 86);
  border-radius: 20px;
  border: 1px solid black;
  margin: 8px 0px;
}

.dropdown-port-p .dropdown-menu-port-p li {
  padding: 10px;
  transition: all .2s ease-in-out;
  cursor: pointer
}

.dropdown-port-p .dropdown-menu-port-p {
  padding: 0;
  list-style: none
}

.dropdown-port-p .dropdown-menu-port-p li:hover {
  background-color: #554f4f;
}

.dropdown-port-p .dropdown-menu-port-p li:active {
  background-color: #272727;
}

.port-sec-1 .port-drop-down {
  background-color: #272727;
}

.port-sec-1 select {
  padding: 10px;
  margin: 20px 0px;
  background-color: #272727;
  border-radius: 2px;
  position: relative;
  color: white;
  width: 100%;
  border-radius: 22.5px;
  border: 1px solid #F1B308;
}

.type-list {
  height: 100%;
}

.type-list img {
  cursor: pointer;
  padding: 15px;
  opacity: 0.5;
}

.type-list img:hover {
  opacity: 1;
}

.type-list img.active {
  opacity: 1;
}

.port-sec-1 select.category {
  max-width: 310px;
}

.port-sec-2 {
  position: relative;
  margin-bottom: 35px;
}

.port-sec-2 .project-list {
  position: relative;
  padding: 30px;
  height: 800px;
  background-color: #000000;
  transition: all 0.7s ease-in-out;
  margin-top: 35px;
}

.port-sec-2 .project-list:hover {
  box-shadow: 0px 4px 14px rgba(201, 201, 201, 0.1);
}

.port-sec-2 .project-list .project-logo {
  /* max-height: 220px;  */
  min-height: 70px;
}

.project-logo .logo-web {
  display: block;
}

.project-logo .logo-mob {
  display: none;
}

.port-sec-2 .project-list .project-content .heading {
  font-weight: 500;
  font-size: 25px;
  line-height: 32px;

}

.port-sec-2 .project-list .project-content .heading.light {
  color: white;
}

.port-sec-2 .project-list .project-content .heading.dark {
  color: #000000;
}


.port-sec-2 .project-list .project-content .title {
  font-weight: 400;
  font-size: 24px;
  line-height: 36px;
  padding: 10px 0px;
  color: #FFC400;

}

.port-sec-2 .project-list .project-content .sub-title {
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #E9E9E9;
}

.port-sec-2 .project-list .project-content .title.light {
  color: #9C9C9C;
}

.port-sec-2 .project-list .project-content .title.dark {
  color: #292929;
}

.port-sec-2 .project-list .project-tech .description {
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 27px;
  color: #E1E1E1;
  padding-bottom: 20px;
}

.port-sec-2 .project-list .project-tech {
  padding-top: 20px;
  position: relative;
}

.port-sec-2 .project-list .project-tech a {
  margin-top: 40px;
  z-index: 9;
  position: relative;
  padding: 0 10px;
}

.port-sec-2 .project-list .project-tech .heading {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  padding-top: 20px;
}

.port-sec-2 .project-list.dse-color {
  background-image: linear-gradient(180deg, #6479AD 0%, #95ABAF 100%);
}

.port-sec-2 .project-list.chatmaite-color {
  background-image: linear-gradient(180deg, #FB493F -17.31%, #FFCC9A 116.31%);
}

.port-sec-2 .project-list.healthyluxe-color {
  background-image: linear-gradient(180deg, #6A584C 0%, #F5D9C0 100%);
}

.port-sec-2 .project-list.datapig-color {
  background-image: linear-gradient(180deg, #134083 0%, #F2ACA4 100%);
}

.port-sec-2 .project-list.otb-color {
  background-image: linear-gradient(180deg, #597051 -0.54%, #E3EBEE 100%);
}

.port-sec-2 .project-list.tripp-color {
  background-image: linear-gradient(180deg, #0287AC -36.83%, #FFFFFF 141.99%);
}

.port-sec-2 .project-list.myshift-color {
  background-image: linear-gradient(180deg, #1D3461 -4.92%, #C4D1E1 100%);
}

.port-sec-2 .project-list.xebia-color {
  background-image: linear-gradient(180deg, #6A1D60 0%, #FFFFFF 103.37%);
}

.port-sec-2 .project-list.newbe-color {
  background-image: linear-gradient(180deg, #000000 -3.44%, #8CDADB 141%);
}

.port-sec-2 .project-list.vibe-color {
  background-image: linear-gradient(180deg, #051324 -1.51%, rgba(249, 112, 91, 0.6) 114.62%);
}

.port-sec-2 .project-list.monogamish-color {
  background-image: linear-gradient(180deg, #734040 -23.74%, #FFFFFF 153.46%);
}

.port-sec-2 .project-list.requestly-color {
  background-image: linear-gradient(180deg, #BA534D 0%, #FFFFFF 105.7%);
}

.port-sec-2 .project-list.pagarkhata-color {
  background-image: linear-gradient(180deg, #C82128 -6.87%, #FFFFFF 102.03%);
}

.port-sec-2 .project-list.zeze-color {
  background-image: linear-gradient(180deg, #343258 -13.62%, #EEE2E5 121.23%);
}

.port-sec-2 .project-list.aktv-color {
  background-image: linear-gradient(180deg, #CC1D0D -39.85%, #FFFFFF 124.27%);
}

.port-sec-2 .project-list.firehouse-color {
  background-image: linear-gradient(180deg, #9C1A24 -39.85%, #FFFFFF 116.21%);
}

.port-sec-2 .project-list.khatabook-color {
  background-image: linear-gradient(180deg, #C72229 -10.23%, #FFFFFF 102.48%);
}

.port-sec-2 .project-list.sesh-color {
  background-image: linear-gradient(180deg, #2C3D8D -15.68%, #FFFFFF 136.35%);
}

.port-sec-2 .project-list.pharmasses-color {
  background-image: linear-gradient(180deg, #93A9B8 0%, #4CACB4 117.62%);
}

.port-sec-2 .project-list.tg-color {
  background-image: linear-gradient(180deg, #2E9360 -1.59%, #FFFFFF 123.26%);
}

.port-sec-2 .project-list.uber-color {
  background-image: linear-gradient(180deg, #558435 -12.39%, #FFFFFF 106.38%);
}

.port-sec-2 .project-list .project-description {
  opacity: 0;
}

.port-sec-2 .project-list:hover .project-description {
  opacity: 1;
}

.port-sec-2 .project-list .project-img {
  display: block;
  position: absolute;
  top: 10%;
  width: auto;
  opacity: 1;
  animation: fadeup 0.5s ease-in-out;

}

.port-sec-2 .project-list .project-img img {
  width: 100%;

}

.port-sec-2 .project-list .case-btn .btn_line.b {
  display: flex;
  justify-content: center;
  padding: 10px 20px !important;
  width: max-content;
  margin-left: auto;
  text-transform: uppercase;
  font-weight: 300;
}

.port-sec-2 .project-list .case-btn .btn_line {
  border: 1px solid #F1B308;
}

.port-sec-2 .project-list .btn_line:hover svg {
  right: 3px !important;
}

.project-list .case-btn {
  transition: all 1s ease-in-out;
  /* opacity: 0; */
  opacity: 1;
  margin: 25px 0px 35px;

}

.project-list .btn-line {

  border: 1px solid #fff;
  color: #fff;
  font-weight: 600;
  border-radius: 50px;
  position: relative;
  overflow: hidden;
  z-index: 1;
  display: inline-flex;
  transition: all .5s;
}

.project-list .btn-line:hover {
  animation: scale .5s linear 1;
  -webkit-animation-delay: .1s;
  animation-delay: .1s;
  border-color: #E4A400;
  ;
  color: #010101;
  border-color: transparent;
}

.project-list .btn_line:before {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: #f1b308;
  content: '';
  transition: transform 0.5s cubic-bezier(0.19, 1, 0.22, 1);
  z-index: -1;
}

.project-list .hvr-sweep-to-right::before {
  background: #e4a400 !important;
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transform-origin: 0 50%;
  transform-origin: 0 50%;
  -webkit-transition-property: transform;
  transition-property: transform;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;

}

.project-list .hvr-sweep-to-right {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgb(0 0 0 / 0%);
  position: relative;
  -webkit-transition-property: color;
  transition-property: color;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
}

.project-list .btn.btn-primary {
  background: #fff;
  text-transform: uppercase;
  font-weight: 600;
  border: 0;
  border-radius: 50px;
  letter-spacing: 1.6px;
  padding: 13px 12px;
  color: #101214;
  overflow: hidden;
}


.project-list .btn.btn-primary:hover {
  color: #fff;
}

.project-list .btn.btn-primary:focus {
  box-shadow: none;
}

@keyframes fadein {
  0% {
    opacity: 0;

  }

  100% {
    opacity: 1;

  }
}

@keyframes fadeout {

  100% {
    opacity: 0;

  }

  0% {
    opacity: 1;

  }
}

.project-list .tech-img {
  /* width: 100%; */
  text-align: right;
  opacity: 1;
  /* transition: all 1s ease-in-out; */

}

.project-list .tech-img a {
  padding: 0px 7px;
}

.project-list .tech-img a img {
  height: 28px;
}

.port-sec-2 .project-list:hover .project-img {
  opacity: 0;
  animation: fadedown 0.7s ease-in-out forwards;

}

@keyframes fadedown {
  0% {
    opacity: 1;
    transform: translateY(0);

  }

  100% {
    opacity: 0;

    transform: translateY(200px);
  }
}

@keyframes fadeup {
  0% {
    opacity: 0;
    transform: translateY(200px);

  }

  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

@media (min-width: 2200px) {
  .port-sec-2 .project-list {
    height: 900px !important;
  }
}


@media (max-width: 1400px) {
  .port-sec-2 .project-list {
    height: 600px;
  }
}

@media (max-width: 950px) {

  .port-sec-1 .main-content .title {
    font-size: 30px;
    line-height: 40px;
    padding-bottom: 0;
    margin-top: 40px;
  }

  .port-sec-2 .project-list .project-content .title {
    padding: 0px;
  }

  .project-list .case-btn {
    margin: 30px 0px;
  }
}

@media (max-width: 767px) {
  .project-logo .logo-web {
    display: none;
  }

  .project-logo .logo-mob {
    display: block;
  }
}

@media (max-width: 457px) {
  .project-list .hvr-sweep-to-right::before {
    display: none;
  }

  .project-list .btn-primary:active:focus {
    box-shadow: none;
  }



  .port-sec-2 .project-list .col-6.project-logo img {
    width: 100%;
  }

  .project-list .btn.btn-primary {
    padding: 10px 9px;
    color: black !important;
  }



  .port-sec-1 .main-content {
    margin-bottom: 40px
  }

  .port-sec-1 .main-content .title {
    font-size: 30px;
    line-height: 40px;
    padding-bottom: 0;
    margin-top: 40px;
  }

  .port-sec-1 .main-content .para {
    font-size: 16px;
    line-height: 25px;
    font-weight: 200;
  }

  .type-list img {
    padding: 10px;
    cursor: pointer;
  }

  .port-sec-2 .project-list {
    height: 520px;
  }

  .project-list .case-btn {
    opacity: 1;
  }

  .port-sec-2 .project-list .project-tech .description {
    font-size: 14px;
    line-height: 20px;
  }

  .dropdown-port-p .select {
    padding: 15px 15px;
    font-size: 12px;
  }

  .dropdown-port-p .select>i {
    line-height: 20px;
  }

  .project-list .case-btn {
    margin: 10px 0px;
  }

  .port-sec-2 .project-list .project-content .title {
    font-size: 20px;
    line-height: 30px;
  }
}

@media (max-width: 420px) {
  .type-list img {
    padding: 10px 5px;
  }
}

@media (max-width: 350px) {
  .project-list .tech-img a {
    padding: 0 5px;
  }
}

@media (max-width: 300px) {
  .port-sec-2 .project-list {
    min-height: 400px;
  }
}