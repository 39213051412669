footer {
    background-color: #222222;
    padding: 100px 0 0;
    color: #fff;
    font-family: 'Lato', sans-serif !important;
    font-display: swap
}

footer .fln {
    height: 1px;
    width: 100%;
    background-color: rgb(216 216 216 / 12%);
    display: block;
    margin-top: 50px;
}

.copy_nav {
    display: flex;
}

.copy_nav a {
    display: block;
    color: rgb(255 255 255 / 80%);
    padding: 10px 0;
    position: relative;
}

.copy_nav li {
    padding: 0 15px;
    position: relative;
}

.copy_nav li:first-child::before {
    display: none;
}

.copy_nav li::before {
    position: absolute;
    left: 0;
    top: 10px;
    height: 14px;
    width: 1px;
    background: rgb(255 255 255 / 80%);
    content: '';
}

.copyright {
    color: rgb(255 255 255 / 80%);
    padding: 30px 0;
}

.footer_upper_side {
    display: flex;
    justify-content: space-between;
}

.footer_section h3 {
    font-size: 1.5rem;
    font-weight: 700;
    margin-bottom: 30px;
}

.foot_nav {
    padding: 0;
    margin: 0;
}

.foot_nav.location-foot-list li {
    list-style: none;
}

.foot_nav.location-foot-list li img {
    padding-right: 10px;
}

.foot_nav li {
    list-style-type: disc;
    display: list-item;
    list-style-position: inside;
}

.foot_nav li a,
.foot_nav li .foot_location {
    display: inline-block;
    color: rgb(255 255 255 / 80%);
    padding: 8px 0;
    position: relative;
}

.copy_nav a:hover,
.foot_nav li:hover a,
.foot_nav li:hover .foot_location,
.foot_nav li:hover::marker {
    color: #FDCB04;
}

.copy_nav a:before,
.foot_nav li a::before,
.foot_nav li .foot_location::before {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 1px;
    background: #FDCB04;
    content: '';
    transform: scaleX(0) translateZ(0);
    transform-origin: 100% 100%;
    -webkit-transition: transform 0.5s cubic-bezier(0.19, 1, 0.22, 1);
    transition: transform 0.5s cubic-bezier(0.19, 1, 0.22, 1);
}

.copy_nav a:hover:before,
.foot_nav li:hover .foot_location:before,
.foot_nav li:hover a::before {
    transform: scaleX(1) translateZ(0);
    transform-origin: 0 0;
    -webkit-transform: scaleX(1) translateZ(0);
    -webkit-transform-origin: 0 0;
}

.social_nv {
    position: relative;
    display: flex;
    margin-bottom: 50px;
}

.social_nv li {
    float: left;
    padding: 0 8px 0 0;
}

.social_nv li a {
    font-size: 1.5rem;
    width: 40px;
    height: 40px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 1;
    overflow: hidden;
}

.social_nv li:hover a {
    color: #000
}

.social_nv li a .svg-icon-white {
    display: block;
}

.social_nv li a:hover .svg-icon-white {
    display: none;
}

.social_nv li a .svg-icon-black {
    display: none;
}

.social_nv li a:hover .svg-icon-black {
    display: block;
}


.social_nv li a::after {
    background-color: #FDCB04;
    position: absolute;
    content: "";
    width: 100%;
    display: block;
    height: 100%;
    transform: scaleX(0) translateZ(0);
    transform-origin: 100% 100%;
    -webkit-transition: transform 0.4s cubic-bezier(0.19, 1, 0.22, 1);
    transition: transform 0.4s cubic-bezier(0.19, 1, 0.22, 1);
    left: 0;
    top: 0;
    z-index: -1;
}

.social_nv li:hover a::after {
    transform: scaleX(1) translateZ(0);
    transform-origin: 0 0;
    -webkit-transform: scaleX(1) translateZ(0);
    -webkit-transform-origin: 0 0;

}

.footer_section h3.wrt a {
    margin-bottom: 15px;
    display: inline-block;
    color: rgb(255 255 255 / 80%);
    padding: 8px 0;
    position: relative;
}

.footer_section h3.wrt a:hover {
    color: #e4a400;
}

.footer_section h3.wrt a::after {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 1px;
    background: #FDCB04;
    content: '';
    transform: scaleX(0) translateZ(0);
    transform-origin: 100% 100%;
    -webkit-transition: transform 0.5s cubic-bezier(0.19, 1, 0.22, 1);
    transition: transform 0.5s cubic-bezier(0.19, 1, 0.22, 1);
}

.footer_section h3.wrt:hover a::after {
    transform: scaleX(1) translateZ(0);
    transform-origin: 0 0;
    -webkit-transform: scaleX(1) translateZ(0);
    -webkit-transform-origin: 0 0;
}


.ft_ls_sec .cnt_nv svg {
    top: 10px;
}

@media only screen and (max-width: 1366px) {
    .footer_section h3 {
        font-size: 1.3rem;
    }
}

@media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    footer {
        padding: 60px 0 0;
    }

    .footer_section h3 {
        font-size: 1.1rem;
    }

    .footer_upper_side {
        flex-wrap: wrap;
    }

    .footer_upper_side .footer_section {
        width: 33.3%;
    }

    .footer_upper_side .footer_section.sol_email {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-top: 40px;
    }

    .social_nv {
        margin-bottom: 0;
    }
}

@media only screen and (max-width: 767px) {
    .social_nv li a::after {
        background-color: transparent !important;

    }

    .social_nv li:hover a {
        color: white !important;
    }

    footer {
        padding: 60px 0 0;
    }

    .footer_section h3 {
        font-size: 1.1rem;
    }

    .footer_upper_side {
        flex-wrap: wrap;
    }

    .footer_upper_side .footer_section {
        width: 100%;
    }

    .footer_upper_side .footer_section.sol_email {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-top: 40px;
        flex-wrap: wrap;
    }

    .social_nv {
        margin-bottom: 0;
    }

    .copy_nav_wrap {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        line-height: 1.67;
        padding-bottom: 20px;
    }

    .foot_nav {
        display: none;
    }

    .copyright {
        padding: 30px 0 10px;
        width: 250px;
        max-width: 100%;
    }

    .footer_section.sol_email h3 {
        margin-bottom: 10px;
    }

    .footer_section.sol_email .ft_ls_sec:first-child {
        margin-bottom: 30px;
        width: 100%;
    }

    .footer_upper_side .footer_section.sol_email .foot_nav {
        display: block;
    }

    .footer_section h3.mb_drop {
        border-bottom: 1px solid #fff;
        padding: 20px 0;
        margin-bottom: 15px;
        position: relative;
        justify-content: space-between;
        display: flex;
        align-items: center;
    }

    .footer_section h3.mb_drop i {
        position: relative;
        display: block;
        width: 15px;
        height: 15px;
    }

    .footer_section h3.mb_drop i::before,
    .footer_section h3.mb_drop i::after {
        position: absolute;
        left: 0;
        top: 50%;
        width: 100%;
        content: '';
        background: #fff;
        height: 2px;
        margin-top: -1px;
    }

    .footer_section h3.mb_drop i::after {
        transform: rotate(90deg);
        -webkit-transform: rotate(90deg);
    }

    .footer_section h3.mb_drop.active i::after {
        opacity: 0;
        visibility: hidden;
    }

    .ft_ls_sec .cnt_nv {
        margin: 0;
    }

    .footer_upper_side .footer_section.sol_email .foot_nav {
        display: block !important;
    }
}