.developer-sec-1 {
    padding: 150px 0 40px
}

.developer-sec-1 .developer-form {
    position: relative;
    max-width: 650px;
}

.developer-sec-1 .developer-form::before {
    position: absolute;
    content: '';
    background: url(https://d2e3cmtjkfw2a3.cloudfront.net/static/media/developer/php/sub-head-img.svg);
    background-repeat: no-repeat;
    background-position: top right;
    top: -16%;
    opacity: 0.3;
    width: 100%;
    height: 100%;
    right: 5%;
}

.developer-sec-1 .main-title {
    max-width: 450px;
}

.developer-sec-1 .heading {
    font-weight: 500;
    font-size: 42px;
    line-height: 60px;
    color: #FFFFFF;
}

.developer-sec-1 .description {
    font-weight: 400;
    font-size: 14px;
    line-height: 25px;
    color: #E9E9E9;
    border-left: 3px solid #75D4E9;
    padding-left: 20px;
    margin: 30px 0 60px;
}

.developer-form .new_cn_frm {
    padding: 0;
    background-color: rgba(104, 104, 104, 0.2);
    border-radius: 20px;
}

.developer-form .form-heading {
    font-weight: 400;
    font-size: 30px;
    line-height: 45px;
    color: #DEDEDE;
}

.developer-form .form-para {
    font-weight: 400;
    font-size: 16px !important;
    line-height: 24px !important;
    color: #F1B308 !important;
}

.developer-form .form-processing {
    display: none;
    width: 100%;
    height: 100%;
    z-index: 1;
    position: absolute;
    align-items: center;
    justify-content: center;
}

.developer-form .cnt_form {
    padding: 20px 50px;
    margin: 0;
    border: 1px solid #373737;
    border-radius: 20px;
}

.developer-form form label {
    font-weight: 500;
    font-size: 16px;
}

.developer-form form #touchName {
    color: white;
}

.developer-form form #touchName-error {
    color: red;
}

.developer-form form #touchEmail {
    color: white;
}

.developer-form form #touchEmail-error {
    color: red;
}

.developer-form form #touchMessage {
    color: white;
}

.developer-form form #touchMessage-error {
    color: red;
}

.developer-form .form-group {
    margin-bottom: 25px;
    position: relative;
}

.developer-form .form-group label {
    color: #f9f9f9;
    
}

.developer-form .form-group textarea.form-control {
    background: transparent;
    border: 1px solid #373737;
    
}

.developer-form .form-group input.form-control {
    background: transparent;
    border: 1px solid #373737;
    height: 45px;
}

.developer-form .form-control {
    color: #fff !important;
}

.developer-form .form-group i {
    position: absolute;
    bottom: 13px;
    right: 13px;
    color: #e4a400;
}

.developer-form .btn.btn-dark.btn-line {
    background: transparent;
    color: #fdfdfd;
    border: 2px solid #fdfdfd;
    border-radius: 50px;
    padding: 15px 80px;
    margin-top: 30px;
}

.developer-form .btn.btn-primary.hvr-sweep-to-right:before {
    background: #e4a400;
}

.developer-form .btn.btn-primary {
    background: #fff;
    text-transform: uppercase;
    font-weight: 600;
    border: 0;
    border-radius: 50px;
    letter-spacing: 1.6px;
    padding: 13px 25px;
    color: #101214;
    overflow: hidden;
}

.developer-form .text-right {
    text-align: right;
}

.developer-sec-2 {
    margin: 60px 0;
}

.developer-sec-2 .sec-2 .number {
    font-weight: 400;
    font-size: 45px;
    line-height: 80px;
    text-align: center;
    color: #82BACE;
}

.developer-sec-3 {
    margin: 60px 0;
}

.developer-sec-4 .hvr-sweep-to-right {
    margin-top: -5px;
}

.developer-sec-6 {
    position: relative;
    margin: 50px 0px;
}

.developer-sec-6 .container {
    border: 2px solid #3E3E3E;
    padding: 20px 50px;
}

.developer-sec-6 .title-card {
    position: absolute;
    top: -15%;
    background-color: #101214;
    max-width: 400px;
}

.developer-sec-6.aws-wrap {
    padding: 100px 0px;
}

.developer-sec-6 .title-card.aws-card-title {
    top: 5% !important;
}

.salesforce-developer.developer-sec-6 {
    margin: 100px 0px;
}

.salesforce-developer.developer-sec-6 .title-card {
    top: -25%;
}

.developer-sec-6 .title-card .title .heading {
    font-size: 40px;

}

.developer-sec-6 .para .description {
    margin-top: 80px;
    color: #ABABAB;
    font-size: 16px;
}

.developer-sec-6 .developer-list .list-ul {
    margin-top: 50px;
}

.developer-sec-6 .developer-list .list-ul .list-li {
    padding: 15px 0;
}

.developer-sec-6 .developer-list .list-ul .list-li .title {
    font-size: 16px;
    padding-left: 15px;
    color: #FFFFFF;
    font-weight: 400;
}

.developer-sec-7 {
    margin-top: 80px;
}

.developer-sec-7 .choice-desktop {
    display: block;
}

.developer-sec-7 .choice-mob {
    display: none;
}

.developer-sec-7 .heading {
    font-size: 35px;
    line-height: 50px;
}

.developer-sec-7 .para {
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: #ABABAB;
    margin: 30px 0px;
}

.developer-sec-7 .choice-box .choice-list-ul .choice-list-li {
    padding: 17px 0px;
}

.developer-sec-8 {
    margin: 80px 0px 50px;
}

.developer-sec-8 .heading {
    font-size: 35px;
    line-height: 50px;
}

.developer-sec-8 .para {
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: #ABABAB;
    margin: 30px 0px;
}

.developer-sec-8 .step-card {
    background-color: #1E1E20;
    margin-top: 50px;
    text-align: center;
    padding: 40px 30px;
    height: 100%;
}

.developer-sec-8 .step-card.pink-border {
    border-top: 4px solid #D9605E;
}

.developer-sec-8 .step-card.yellow-border {
    border-top: 4px solid #DEC57B;
}

.developer-sec-8 .step-card.blue-border {
    border-top: 4px solid #75D4E9;
}

.developer-sec-8 .step-card.purple-border {
    border-top: 4px solid #CD8DF7;
}

.developer-sec-8 .step-card .number {
    font-weight: 500;
    font-size: 64px;
    line-height: 96px;
    color: #575757;
}

.developer-sec-8 .step-card .title {
    font-size: 18px;
    padding: 25px 0px;
}

.developer-sec-9 .developer-sec-9-content {
    text-align: center;
    margin: 0 auto;
    padding: 20px 0px 50px;
}

.developer-sec-9 .developer-sec-9-content .title {
    font-weight: 500;
    font-size: 40px;
    line-height: 60px;
    color: #FFFFFF;
}

.developer-sec-9 .developer-sec-9-content .title span {
    color: #E4A400;
}

.developer-sec-9 .sec-9-card-wrap {
    text-align: center;
    background: #1C1C1C;
    border: 1px solid rgba(255, 255, 255, 0.36);
    padding: 40px 25px;
    height: 480px;
}

.developer-sec-9 .sec-9-card-wrap .sec-9-card .card-title {
    font-weight: 500;
    font-size: 30px;
    line-height: 54px;
    text-align: center;
    color: #FFFFFF;
    padding-top: 15px;
}

.developer-sec-9 .sec-9-card-wrap .sec-9-card .card-description {
    font-weight: 400;
    font-size: 15px;
    line-height: 28px;
    text-align: center;
    color: #FFFFFF;
}

.developer-sec-10 {
    padding: 50px 0px;
}

.developer-sec-10 .sec-10-content .title {
    font-weight: 500;
    font-size: 40px;
    line-height: 60px;
    color: #FFFFFF;
    padding-left: 12%;
    padding-bottom: 50px;
}

.developer-sec-10 .sec-10-content .title span {
    color: #E4A400;
}

.developer-sec-10 .sec-10-crads-wrapper {
    max-height: 520px;
    overflow-y: scroll;

}

.developer-sec-10 .sec-10-crads-wrapper::-webkit-scrollbar {
    border: none;
    background-color: rgba(255, 255, 255, 0.11);
    border-radius: 20px;
    width: 8px;
}

.developer-sec-10 .sec-10-crads-wrapper::-webkit-scrollbar-corner {
    background: transparent;
}

.developer-sec-10 .sec-10-crads-wrapper::-webkit-scrollbar-thumb {
    background: white;
    border-radius: 5px;
    border: 1px solid black;
    margin: 8px 0px;
}

.developer-sec-10 .sec-10-crads-wrapper .sec-10-card-wrap {
    margin: 20px 0px;

}

.developer-sec-10 .sec-10-crads-wrapper .sec-10-card-wrap .sec-10-card {
    background: rgba(255, 255, 255, 0.11);
    padding: 28px 23px 16px 22px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    counter-increment: number 0;
    height: 130px;
}

.developer-sec-10 .sec-10-crads-wrapper .sec-10-card-wrap .sec-10-card::after {
    position: absolute;
    content: '';
    background-color: transparent;
    border-radius: 50%;
    top: -10%;
    left: -4%;
    height: 30px;
    width: 30px;
    z-index: -8;
    font-weight: 500;
    font-size: 40px;
    line-height: 40px;
    color: #E4A400;
}

.developer-sec-10 .sec-10-crads-wrapper .sec-10-card-wrap .sec-10-card .card-name {
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    color: #FFFFFF;
    text-align: center;
}

.desktop_html .sub-services-top .sales-banner-logos img {
    position: unset !important;

}

@media (max-width: 1440px) {
    .developer-sec-9 .sec-9-card-wrap {
        height: 460px;
    }

    .developer-sec-6.aws-wrap {
        padding: 50px 0px;
    }

    .developer-sec-6 .title-card.aws-card-title {
        top: -6% !important;
    }
}

@media (max-width: 767px) {
    .developer-sec-1 {
        padding: 120px 0 40px
    }

    .developer-sec-1 .heading {
        font-size: 35px;
        line-height: 50px;
    }

    .developer-form .cnt_form {
        margin-top: 30px;
        padding: 20px 20px;
    }

    .developer-sec-1 .developer-form::before {
        top: 5%;
    }

    .developer-sec-2 .sec-2 .number {
        font-size: 40px;
    }

    .developer-sec-3 .sec-3_mid_text .sec-3-h2 {
        font-size: 24px;
    }

    .developer-sec-3 {
        margin: 0;
    }

    .developer-sec-3 .sec-3-h3 {
        font-size: 30px;
        line-height: 45px;
    }

    .developer-sec-4 .cnt_nv {
        margin: 30px auto;
    }

    .sales .sec-6-but .cnt_nv {
        padding: 12px 22px 13px !important;
    }

    .developer-sec-6 .title-card {
        max-width: 250px;
        top: -5%;
    }

    .developer-sec-6 .title-card.hire-title-card {
        max-width: 280px !important;
    }

    .developer-sec-6 .para .description {
        margin-top: 110px;
        margin-bottom: 30px
    }

    .developer-sec-6 .title-card .title .heading {
        font-size: 30px;
    }

    .developer-sec-6 .developer-list .list-ul {
        margin-top: 0px;
    }

    .developer-sec-6 .developer-list .list-ul .list-li {
        padding: 10px 0px;
    }

    .developer-sec-7 .choice-desktop {
        display: none;
    }

    .developer-sec-7 .choice-mob {
        display: block;

    }

    .developer-sec-7 .choice-mob .choice-list-mob {
        margin-top: 30px;
    }

    .developer-sec-7 .choice-mob .choice-list-mob ul {
        border-left: 2px solid #CD8DF7;
        margin-left: 0px;
        max-height: 300px;
        overflow: scroll;
    }

    .developer-sec-7 .choice-mob .choice-list-mob ul li {
        padding: 16px 0px 20px 26px;
        position: relative;


    }

    .developer-sec-7 .choice-mob .choice-list-mob ul li::before {
        position: absolute;
        content: '';
        left: -4%;
        text-indent: -1em;
        height: 2px;
        top: 42%;
        width: 22px;
        background-color: #CD8DF7;
    }

    .developer-sec-8 .step-card {
        padding: 0;
        margin: 20px 0;
    }

    .developer-sec-9 .developer-sec-9-content {
        padding: 10px 0px 30px;
    }

    .developer-sec-9 .developer-sec-9-content .title {
        font-size: 30px;
        line-height: 45px;
    }

    .developer-sec-9 .sec-9-card-wrap {
        margin-top: 20px;
        height: 450px;
    }



    .salesforce-developer.developer-sec-6 .title-card {
        top: -9% !important;
    }

    .developer-sec-6 .title-card.aws-card-title {
        top: 0 !important;
    }

    .developer-sec-6.aws-wrap .para .description {
        margin-top: 50px;
    }
}