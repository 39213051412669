header {
    position: fixed;
    width: 100%;
    /* overflow: hidden; */
    top: 0;
    left: 0;
    z-index: 999;
    font-family: 'Lato', sans-serif !important;
    font-display: swap;
    transition: transform 0.3s;
    -webkit-transition: transform 0.3s;
}

header.stickey_header_up {
    -webkit-transform: translateY(-85px);
    -ms-transform: translateY(-85px);
    transform: translateY(-85px);
}

header.stickey_header_down {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
}



nav ul {
    display: flex;
    align-items: center;
}

nav ul li {
    margin: 0 8px;
    padding-top: 20px;
    padding-bottom: 20px;
}

nav ul li.contact-au a {
    color: #f1b308 !important;
}

.menu-item.mob-contact-details {
    display: none;
}

.menu-item.mob-social-info {
    display: none;
}

nav ul li .menu-tag-wrap img {
    display: none;
}

nav ul li .menu-tag-wrap a {
    font-size: 18px;
}


nav ul li a {
    display: block;
    font-size: 1rem;
    padding: 10px 0;
    color: #fff;
    position: relative;
    margin: 0px 6px;

}

nav ul li a:hover,
nav ul li a.active {
    color: #FDCB04;
}

nav ul li a.active {
    font-weight: 700;
}

nav ul li a:before,
nav ul li a.active:before {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 1px;
    background: #FDCB04;
    content: '';
    transform: scaleX(0) translateZ(0);
    transform-origin: 100% 100%;
    -webkit-transition: transform 0.5s cubic-bezier(0.19, 1, 0.22, 1);
    transition: transform 0.5s cubic-bezier(0.19, 1, 0.22, 1);
}

nav ul li a:hover:before,
nav ul li a.active:before {
    transform: scaleX(1) translateZ(0);
    transform-origin: 0 0;
    -webkit-transform: scaleX(1) translateZ(0);
    -webkit-transform-origin: 0 0;
}

.service-menu {
    display: none;
}

.service-menu-wrap::before {
    position: absolute;
    content: '';
    height: 20px;
    background: transparent;
    width: 4%;
    bottom: 0;
    left: 52%;
}

.service-menu-wrap::before:hover .service-menu {
    display: block;
}

.service-menu-wrap ul {
    display: inline-block;
}

.service-menu-wrap ul li {
    margin: 0px;
    text-align: center;
    padding: 4px 0px;
}

.service-menu-wrap ul .srvc-menu-heading {
    font-weight: 500;
    font-size: 30px;
    line-height: 45px;
    color: #FFFFFF;
    text-align: center;
    padding: 10px 0px;
}

.service-menu-wrap .tab-sales-title-wrapper {
    border-radius: 10px;
    background: linear-gradient(180deg, #312f2fba -5.5%, #000 60.15%);
    width: 100%;
    max-height: 580px;
    overflow-y: scroll;
}

.service-menu-wrap .mb-service {
    padding: 0;
    border: none;
    text-align: center;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    padding: 20px 10px;
    color: #979797;
    width: 100%;
}

.service-menu-wrap .mb-service.active {
    color: white !important;
    border: none !important;
    background-image: linear-gradient(270deg, rgba(0, 0, 0, 0.7) -50.79%, rgba(61, 61, 61, 0.870313) 97.42%, #6C6C6C 190.69%);
}

.headertabcontent {
    display: none;
}

.headertabcontent.active {
    display: block;
}

.service-menu-wrap .sub-menu-list-wrapper {
    margin-top: 25px;
    margin-left: 5%;
}

.service-menu-wrap .sub-menu-list-wrapper .sub-menu-heading {
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    text-transform: capitalize;
    color: #E2E2E2;
}

.service-menu-wrap .sub-menu-list-wrapper .sub-menu-description {
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    color: #F1B308;
    max-width: 650px;
    padding-top: 10px;
}

.header-main-srvc-link {
    display: flex;
    justify-content: end;
    padding-right: 50px;
}

.header-main-srvc-link span {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #F9F9F9;
}

.header-main-srvc-link a {
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    color: #F1B308;
    padding: 0;
    display: inline-block;
}

.header-main-srvc-link a:before {
    background-color: transparent !important;
}

.service-menu-wrap .sub-menu-list-wrapper .menu-sub-srvc-tab-content {
    padding: 15px 0px;
    display: flex;
    align-items: center;
}

.service-menu-wrap .menu-sub-srvc-tab-content .menu-icon-content {
    padding-left: 20px;
}

.menu-sub-srvc-tab-content .menu-icon-content .menu-title {
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    margin: 0px;
    padding: 0px;
}

.service-menu-wrap .menu-sub-srvc-tab-content .menu-icon-content .menu-title a {
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    color: white !important;
    margin: 0px;
    padding: 0px;
}

.service-menu-wrap .menu-sub-srvc-tab-content .menu-icon-content .menu-title a:hover {
    color: #F1B308 !important;
}

.sub-menu-list-wrapper .menu-title a:before {
    background-color: transparent !important;
}

.bottom-cta-box {
    position: absolute;
    width: 100%;
    background: #000000;
    bottom: 0;
    /* z-index: -2; */
    border-radius: 0px 0px 10px 10px;
    margin-left: -30px;
}

.service-menu-wrap .bottom-cta-box {
    margin-left: 0;
}

.bottom-cta-header-left {
    padding: 20px 0px;
    /* box-shadow: 0px -1px 18px rgba(132, 132, 132, 0.25); */
    box-shadow: 5px -5px 6px rgb(132 132 132 / 11%), 5px 6px 6px rgb(132 132 132 / 6%);
    border-radius: 0px 0px 10px 0px;
    width: 100%;
    text-align: center;
}

.bottom-cta-header-left .title {
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    text-align: center;
    color: #F1B308;
    padding-bottom: 20px;
}

.bottom-cta-header-right {
    box-shadow: 0px -1px 12px rgba(132, 132, 132, 0.25);
    padding: 20px 0px;
    /* box-shadow: 0px -1px 18px rgba(132, 132, 132, 0.25); */
    /* box-shadow: 5px -5px 6px rgb(132 132 132 / 11%), 5px 6px 6px rgb(132 132 132 / 6%); */
    border-radius: 0px 0px 10px 0px;
    width: 100%;
    text-align: center;
    background-color: #1f1f1f !important;
}

.bottom-cta-header-right .title {
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    text-align: center;
    color: #F1B308;
    padding-bottom: 20px;
}

.header-botm-btn .btn_line:hover {
    border: 1px solid transparent !important;
    color: white !important;

}

.service-menu-wrap .menu-sub-srvc-tab-content .menu-icon-content .menu-description {
    font-weight: 400;
    font-size: 14px;
    line-height: 28px;
    color: #D9D9D9;
    padding-top: 10px;
}

.tech-menu-header {
    margin: 15px 50px;
}

.tech-srvc-list .title a {
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    color: #F1B308 !important;
}

.tech-srvc-list .title a:before {
    background-color: transparent !important;
}

.tech-srvc-list .menu-sub-srvc-tab-content {
    padding: 4px 0px;
    display: flex;
    align-items: center;
}

.tech-srvc-list .menu-icon-content .menu-title {
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    margin: 0px;
    padding: 0px;
    color: white !important;
}

.tech-srvc-list .menu-sub-srvc-tab-content .menu-icon-content .menu-title a {
    font-weight: 700;
    font-size: 16px;
    line-height: 28px;
    color: #E2E2E2 !important;
}

.tech-srvc-list .menu-icon-content .menu-description {
    padding-top: 0px !important;
}

nav .service-menu-wrap:hover .service-menu {
    display: block;
    /* background-image: linear-gradient(180.22deg, #6C6C6C -5.85%, #000000 47.15%); */
    background-image: linear-gradient(180deg, #6c6c6c -5.5%, #000 60.15%);
    width: 1150px;
    left: -500px;
    /* right: -10%; */
    top: 80px;
    z-index: -1;
    border-radius: 10px;
}

.salesforce-srvc-menu-wrap {
    margin: 25px 10px;
}

.sales-menu-wrap {
    display: flex;
    align-items: center;
    padding: 10px;
}

.sales-menu-wrap .icon-name-sales a {
    padding-left: 10px;
    font-weight: 400;
    font-size: 14px !important;
    line-height: 28px;
    color: #BABABA;
}

.sales-menu-wrap .icon-name-sales a:hover {
    color: #F1B308;
}

.sales-menu-wrap a:before {
    background-color: transparent !important;
}


.service-menu .sub-menu-head .heading {
    font-weight: 500;
    font-size: 30px;
    line-height: 45px;
    text-align: center;
    text-transform: capitalize;
    color: #FFFFFF;
    padding-bottom: 10px;
}

.service-menu .sub-menu-head .title {
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    text-transform: capitalize;
    color: #F1B308;
    text-align: center;
    padding-bottom: 10px;
}

.service-menu .service-menu-title {
    padding: 15px 30px;
    display: inline-block;
}

.service-menu .service-menu-title li {
    padding: 6px 0px;
}

.service-menu .list-heading {
    padding-bottom: 10px;
}

.service-menu .list-heading a {
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    text-transform: capitalize;
    color: #FFFFFF;
    padding: 0;
    transition: all ease-in .3s;
}

.service-menu .list-heading a::before {
    background: transparent;
    position: absolute;
    content: '';
}

.service-menu .list-heading a:hover {
    color: #F1B308;
}

.service-menu .list-menu a {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #C7C7C7;
    padding: 0;
    transition: all ease-in .3s;
}

.service-menu .list-menu a:hover {
    color: #F1B308;
}

.service-menu .list-menu a::before {
    position: absolute;
    background: none !important;
    content: '';
}

.menu-section {
    margin-bottom: 20px;
}

.service-menu-title .menu-border {
    position: absolute;
    width: 70px;
    height: 3px;
    border-radius: 10px;
    margin-left: 5px;
}

.service-menu-title .menu-border.mobile {
    background-color: #3BD6D0;
}

.service-menu-title .menu-border.website {
    background-color: #8CCF49;
    ;
}

.service-menu-title .menu-border.ui-ux {
    background-color: #cadaec;
}

.service-menu-title .menu-border.ar-vr {
    background-color: #FFBFAB;
}

.service-menu-title .menu-border.wearable {
    background-color: #5DC1D8;
}

.service-menu-title .menu-border.digital {
    background-color: #E9F3FE;
}

.service-menu-title .menu-border.software {
    background-color: #5f81a9;
}

.service-menu-title .menu-border.blockchain {
    background-color: #FFD485;
}

.service-menu-title .menu-border.wearable {
    background-color: #FFD485;
}

.bottom-link {
    margin-top: 70px;
}

.bottom-link .heading {
    font-weight: 500;
    font-size: 22px;
    line-height: 27px;
    color: #F1B308;
}

.bottom-link a {
    padding: 0px;
}

.bottom-link a::before {
    background: transparent;
    position: absolute;
    content: '';
}


.contact-menu {
    display: none;
}

nav ul li.contact-btn-nav a.active {
    color: black;
}

.contact-btn-nav a.contact-btn-a {
    color: #000;
    background-color: #F1B308;
    padding: 10px 36px;
    border-radius: 40px;
    font-size: 16px;
    line-height: 24px;
    font-family: Poppins, sans-serif;
    font-display: swap;
    font-weight: 600;
}

@media (max-width: 500px) {
    .contact-btn-nav a.contact-btn-a {
        font-weight: 500;
    }
}

.contact-btn-nav a.contact-btn-a:hover {
    color: #000;
}

.contact-btn-nav a::before {
    height: unset !important;
}

.contact-menu-wrap::before {
    position: absolute;
    content: '';
    height: 20px;
    background: transparent;
    width: 4%;
    bottom: 0;
    left: 80%;
}

.contact-menu-wrap::before:hover .contact-menu {
    display: block;
}

nav ul li:hover .contact-menu {
    display: block;
    background-image: linear-gradient(180.22deg, #6C6C6C -5.85%, #000000 47.15%);
    width: 950px;
    left: -850px;
    /* right: -20%; */
    top: 80px;
    padding: 30px 0px;
    z-index: -1;
    border-radius: 10px;
    position: relative;
}

nav ul li:hover .contact-menu::before {
    position: absolute;
    content: url(https://d2e3cmtjkfw2a3.cloudfront.net/static/media/header/map.svg);
    top: 35%;
    left: 16%;
    z-index: -1;
}

.contact-menu .details {
    max-width: 200px;
    padding-top: 25px;
}

.contact-menu .details .title {
    font-weight: 500;
    font-size: 30px;
    line-height: 45px;
    text-transform: capitalize;
    color: #FFFFFF;
    padding-bottom: 20px;
}

.contact-menu .details .description {
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    text-transform: capitalize;
    color: #F1B308;
    padding-bottom: 20px;
}

.contact-menu .details .para {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-transform: capitalize;
    color: #B1B1B1;
}

.contact-menu .details .mail {
    color: #F1B308;
    font-size: 17px;
    margin-top: 20px;
    cursor: pointer;
}

.contact-menu .contact-location {
    display: inline-block;
}

.contact-menu .contact-location .heading {
    font-weight: 500;
    font-size: 30px;
    line-height: 45px;
    text-transform: capitalize;
    color: #FFFFFF;
    transition: all ease-in .3s;
    padding-bottom: 20px;
}

.contact-menu .contact-location .location {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-transform: capitalize;
    color: #E8E7E7;
    padding: 12px 0px;
    transition: all ease-in .3s;
}

.contact-menu .contact-location .location:hover {
    color: #F1B308;
}

.contact-menu .contact-link {
    display: inline-block;
}

.contact-menu .contact-link .heading {
    font-weight: 500;
    font-size: 30px;
    line-height: 45px;
    text-align: center;
    text-transform: capitalize;
    color: #FFFFFF;
    transition: all ease-in .3s;
    padding-bottom: 20px;
}

.contact-menu .contact-link .social-link {
    padding: 12px 0px;
}

.contact-menu .contact-link .social-link a {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-transform: capitalize;
    color: #DBDBDB;
    padding: 0;
    transition: all ease-in .3s;
    display: flex;
    align-items: center;
}

.contact-menu .contact-link .social-link .twitter .svg-icon {
    display: block;
}

.contact-menu .contact-link .social-link:hover .twitter .svg-icon {
    display: none;
}

.contact-menu .contact-link .social-link .svg-icon-blue {
    display: none;
}

.contact-menu .contact-link .social-link:hover .svg-icon-blue {
    display: block;
}

.contact-menu .contact-link .social-link:hover .twitter {
    color: #5842E2;
}

.contact-menu .contact-link .social-link:hover .facebook {
    color: #4267B2;
}

.contact-menu .contact-link .social-link:hover .linkdin {

    color: #0072b1;
}

.contact-menu .contact-link .social-link:hover .pink {
    color: #EA4C89;
}

.contact-menu .contact-link .social-link:hover .dribbble {
    color: #ea4c89;
}

.contact-menu .contact-link .social-link:hover .behance {
    color: #053eff;
}

.contact-menu .contact-link .social-link a::before {
    position: absolute;
    background: none;
    content: '';
}

.contact-menu .contact-link .social-link i {
    padding-right: 20px;
}

.contact-menu .contact-link .social-link img {
    padding-right: 20px;
    width: 35px;
}

.hire-developer-menu {
    display: none;
}

.hire-developer-menu-wrap::before {
    position: absolute;
    content: '';
    height: 30px;
    background: transparent;
    width: 5%;
    bottom: 0;
    left: 65%;
}

.hire-developer-menu-wrap:hover .hire-developer-menu {
    display: block;
    padding-left: 30px !important;
    background-image: url(https://d2e3cmtjkfw2a3.cloudfront.net/static/media/header/developer-header.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

nav ul li .hire-developer-menu.container {
    max-width: 1250px;
}

nav ul li:hover .hire-developer-menu {
    display: block;
    /* background-image: linear-gradient(180.22deg, #6C6C6C -5.85%, #000000 47.15%); */
    background-image: linear-gradient(180deg, #6c6c6c -5.5%, #000 60.15%);
    width: 1200px;
    left: -850px;
    /* right: -10%; */
    top: 80px;
    z-index: -1;
    border-radius: 10px;
    position: relative;
}

nav ul li .hire-developer-menu .header-botm-btn a {
    margin: 0 !important;
}

.hire-developer-menu .details {
    max-width: 280px;
}

.hire-developer-menu .details .title {
    font-weight: 500;
    font-size: 30px;
    line-height: 45px;
    color: #FFFFFF;
    padding: 40px 0px 25px;
}

.hire-developer-menu .details .description {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #F1B308;
    padding-bottom: 35px;
}

.hire-developer-menu .details .para {
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    color: #DFDFDF;
    padding-bottom: 35px;
}

.blog-menu {
    display: none;
}

.industries-menu {
    display: none;
}

.industries-menu-wrap::before {
    position: absolute;
    content: '';
    height: 30px;
    background: transparent;
    width: 4%;
    bottom: 0;
    left: 57%;
}

nav ul li:hover .industries-menu {
    display: block;
    /* background-image: linear-gradient(180.22deg, #6C6C6C -5.85%, #000000 47.15%); */
    background-image: linear-gradient(180deg, #6c6c6c -5.5%, #000 60.15%);
    width: 1000px;
    left: -500px;
    /* right: -10%; */
    top: 80px;
    z-index: -1;
    border-radius: 10px;
    position: relative;
}

.industries-menu .details {
    max-width: 300px;
}

.industries-menu .details .title {
    font-weight: 500;
    font-size: 30px;
    line-height: 45px;
    color: #FFFFFF;
    padding: 40px 0px 20px;

}

.industries-menu .details .description {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #F1B308;
    padding-bottom: 30px;
}

.cnt_nv {
    border: 1px solid transparent !important;
    background: #f1b308;
    color: #1f1f1f !important;
    font-weight: 700;
    padding: 12px 32px 13px;
    border-radius: 40px;
    /* border: 0; */
    overflow: hidden;
    border: 1px solid transparent !important;
}

.sales .cnt_nv {
    padding: 12px 9px 13px !important;
}

.cnt_nv.btn-primary:hover {
    background-color: #FDCB04;
}

.trnsprnt-border {
    border: none !important;
}

.cnt_nv:before {
    display: none;
}

.cnt_nv svg {
    top: 12px;
    fill: #1f1f1f;
}



header::before {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgb(0 0 0 / 70%);
    content: '';
    z-index: -1;
    -webkit-backdrop-filter: blur(8px);
    backdrop-filter: blur(8px);
}


.mb_close {
    position: absolute;
    right: 10px;
    top: 10px;
    width: 25px;
    height: 25px;
    display: none;
    z-index: 20;
    color: #f1b308 !important;
    font-size: 18px;
}

.mb_menu_bar {
    width: 31px;
    height: 31px;
    display: none;
}

.mb_menu_bar .hmb_st {
    stroke: #f1b308;
}

.mb_menu_bar .hmb_st1 {
    fill: #f1b308;
}

.mb_menu_bar svg {
    width: 100%;
    height: 100%;
}

.dropdown-wrapper {
    position: absolute;
    left: 0;
    width: 100%;
    opacity: 0;
    visibility: hidden;
    background: red;
    z-index: 99;
}

.dropdown-inner {
    border-top: 1px solid #e6e6e6;
    background: red;
    margin-top: 25px;
}

.dropdown-inner .flex-wrapper {
    justify-content: center;
}

header>.header_container nav li:hover .dropdown-wrapper {
    opacity: 1;
    visibility: visible;
}

.dropdown-wrapper .dropdown-list .list-menu ul {
    display: inline-block
}

.dropdown-wrapper .dropdown-list .list-menu ul li {
    color: white;
}

.about-menu {
    display: none;
}

.about-menu-wrap:hover .about-menu {
    display: block;
    /* background-image: linear-gradient(180deg,#6c6c6c -5.5%,#000 60.15%); */
    background-image: url(https://d2e3cmtjkfw2a3.cloudfront.net/static/media/header/about-header-bg.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: 1300px;
    left: -550px;
    top: 80px;
    z-index: -1;
    border-radius: 10px;
    padding: 40px 0px 40px 60px;
}

.about-menu-wrap .container {
    max-width: 1250px !important;
}

.about-menu .about-header-content {
    height: 300px;
}

.about-menu .about-header-content .about-title {
    font-weight: 500;
    font-size: 30px;
    line-height: 45px;
    text-transform: capitalize;
    color: #FFFFFF;
}

.about-menu-wrap .about-menu .about-header-content .about-description {
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    text-transform: capitalize;
    color: #F1B308;
    padding: 15px 0px 24px;
    max-width: 240px;
    height: 130px;
}

.about-menu-wrap .about-menu .about-header-content .about-sub-title {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-transform: capitalize;
    color: #D0D0D0;
    padding-bottom: 10px;
}

.about-menu-wrap .about-menu .about-page-link a {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-decoration-line: underline !important;
    text-transform: capitalize;
    color: #FFC400 !important;
    padding-top: 20px;
    margin: 0px;
}

.about-menu-wrap .about-menu .about-page-link a::before {
    background-color: transparent !important;
}

.about-menu-wrap .about-menu .about-header-content .about-list.li-list-wrap {
    padding-left: 20px;
}

.about-menu-wrap .about-menu .about-header-content .about-list ul {
    display: block;
}

.about-menu-wrap .about-menu .about-header-content .about-list ul li {
    padding: 5px 0px;
    margin: 0px;
}

.about-menu-wrap .about-menu .about-header-content .about-list ul.ul-wrap {
    padding-left: 20px;
}

.about-menu-wrap .about-menu .about-header-content .about-list ul li.new-li {
    list-style: disc;
    font-size: 16px;
}

.about-menu-wrap .about-menu .about-header-content .about-list ul li.new-li::marker {
    color: #FFC400 !important;
}

.menu-contact-mob {
    display: none;
}

.menu-social-mob {
    display: none;
}

@media (max-width: 1800px) {
    .service-menu-wrap::before {
        width: 5%;
        left: 54%;
    }

    .tech-menu-header {
        margin-top: 4%;
        margin-left: 7%;
    }

    .hire-developer-menu-wrap::before {
        width: 8%;
        left: 75%;
    }

    .industries-menu-wrap::before {
        width: 5%;
        left: 59%;
    }

    .contact-menu-wrap::before {
        width: 6%;
        left: 90%;
    }
}

@media (max-width: 1400px) {
    .service-menu-wrap::before {
        left: 48%;
    }

    .industries-menu-wrap::before {
        width: 7%;
        left: 53%;
    }

    .hire-developer-menu-wrap::before {
        width: 10%;
        left: 71%;
    }

    .contact-menu-wrap::before {
        width: 5%;
        left: 90%;
    }
}

@media (max-width: 1200px) {
    .service-menu-wrap::before {
        left: 48%;
        width: 5%;
    }

    .contact-menu-wrap::before {
        width: 6%;
        left: 86%;
    }
}



@media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    nav ul li {
        margin: 0 0 0 20px;
    }

    nav ul li a {
        font-size: 0.9rem;
        width: 60%;
    }

    .header_container {
        padding-top: 20px;
        padding-bottom: 20px;
    }

}

@media (max-width: 950px) {
    .header_container {
        padding-top: 20px;
        padding-bottom: 20px;
    }

    .contact-menu {
        display: none !important;
    }

    .about-menu {
        display: none !important;
    }

    .service-menu {
        display: none !important;
    }

    .hire-developer-menu {
        display: none !important;
    }

    .industries-menu {
        display: none !important;
    }

    .blog-menu {
        display: block;
    }

    nav ul li {
        margin: 0;
        padding: 0;
    }

    nav ul li a {
        font-size: 24px;
        padding: 18px 0;
        border-bottom: 1px solid #2B2B2B;
    }

    header {
        position: fixed;
        overflow: visible;
        z-index: 999;
    }

    nav {
        position: fixed;
        height: 100%;
        left: 50%;
        top: 0;
        width: 50%;
        padding: 50px 20px 0;
        background: #1C1C1C;
        transform: scale(0);
        -webkit-transform: scale(0);
        visibility: hidden;
        opacity: 0;
        transition: all 0.3s cubic-bezier(0, 0, 0, 1.01);
        -webkit-transition: all 0.3s cubic-bezier(0, 0, 0, 1.01);
    }

    .open-menu nav {
        transform: scale(1);
        -webkit-transform: scale(1);
        visibility: visible;
        opacity: 1;
    }

    .open-menu header {
        transform: none !important;
        -webkit-transform: none !important;
        -ms-transform: none !important;
    }

    nav ul {
        display: flex;
        flex-direction: column;
        align-items: start;
        padding-left: 5%;
    }

    nav ul li {
        width: 90%;

    }

    nav ul li a {
        padding: 30px 0px;
    }

    .contact-btn-nav a.contact-btn-a {
        padding: 30px 0px;
        border-radius: 0;
        display: none;
    }

    .cnt_nv {
        margin: 40px auto;
        display: table;
    }

    .mb_close,
    .mb_menu_bar {
        display: block;
    }

    .mb_close {
        position: absolute;
        right: 11%;
        top: 3%;
        width: 30px;
        height: 30px;
    }

    .contact-btn-nav a.contact-btn-a {
        background-color: transparent;
    }

    .contact-btn-nav a.contact-btn-a {
        color: white;
    }

    nav ul li.contact-btn-nav a.active {
        color: white;
    }

    .menu-contact-mob {
        display: block;
    }

    .menu-contact-mob .contact-number-list-wrap {
        display: flex;
        align-items: center;
    }

    .menu-contact-mob .contact-number-list-wrap .flag-box img {
        padding-right: 15px;
    }

    .menu-social-mob {
        display: block;
    }

    .menu-social-mob .social-media-wrap ul {
        flex-direction: row;

    }

    .social-media-wrap ul {
        padding-left: 0px;
    }

    .nested-list {
        padding-left: 0px;
    }
}

@media (max-width: 950px) {
    .header_container {
        padding-top: 20px;
        padding-bottom: 20px;
    }

    .contact-menu {
        display: none !important;
    }

    .about-menu {
        display: none !important;
    }

    .service-menu {
        display: none !important;
    }

    .hire-developer-menu {
        display: none !important;
    }

    .industries-menu {
        display: none !important;
    }

    .blog-menu {
        display: block;
    }

    nav ul li {
        margin: 0;
        padding: 0;
    }

    nav ul li a {
        font-size: 24px;
        padding: 18px 0;
        border-bottom: 1px solid #2B2B2B;
        width: 88%;
    }

    header {
        position: fixed;
        overflow: visible;
        z-index: 999;
    }

    nav {
        position: fixed;
        height: 100%;
        left: 0;
        top: 0;
        width: 50%;
        padding: 50px 20px 0;
        background: #1C1C1C;
        transform: scale(0);
        -webkit-transform: scale(0);
        visibility: hidden;
        opacity: 0;
        transition: all 0.3s cubic-bezier(0, 0, 0, 1.01);
        -webkit-transition: all 0.3s cubic-bezier(0, 0, 0, 1.01);
    }

    .open-menu nav {
        transform: scale(1);
        -webkit-transform: scale(1);
        visibility: visible;
        opacity: 1;
    }

    .open-menu header {
        transform: none !important;
        -webkit-transform: none !important;
        -ms-transform: none !important;
    }

    nav ul {
        display: flex;
        flex-direction: column;
        align-items: start;
        /* padding-left: 10%; */
    }

    nav ul li {
        width: 100%;

    }

    nav ul li a {
        padding: 30px 0px;
        text-align: left;
    }

    .contact-btn-nav a.contact-btn-a {
        padding: 10px 0px;
        border-radius: 0;
    }

    .cnt_nv {
        margin: 40px auto;
        display: table;
    }

    .mb_close,
    .mb_menu_bar {
        display: block;
    }

    .mb_close {
        position: absolute;
        right: 6%;
        top: 2%;
        width: 40px;
        height: 22px;
    }

    .contact-btn-nav a.contact-btn-a {
        background-color: transparent;
    }

    .contact-btn-nav a.contact-btn-a {
        color: white;
    }

    nav ul li.contact-btn-nav a.active {
        color: white;
    }

    nav {
        width: 65%;
    }

    nav ul li .menu-tag-wrap img {
        display: block;
    }

    .menu-item.mob-contact-details {
        display: block;
    }

    .contact.list-wrap {
        display: flex;
        align-items: center;
    }

    .contact.list-wrap a {
        padding-left: 10px;
    }

    .menu-item.mob-social-info {
        display: block;
    }

    .social-info-wrap {
        display: flex;
        flex-direction: row;
    }

    .sub-menu-tab .inner-list {
        max-height: 90vh;
        overflow: scroll;
    }

    .sub-menu-tab .sub-page-link-menu a {
        font-size: 20px;
    }

    .sub-menu-tab .back-button a {
        font-size: 20px;
        color: #FFC400 !important;
    }

    .back-button {
        display: flex;
        align-items: center;
    }

    .back-button img {
        padding-right: 5px;
        height: 16px;
        width: 16px;
    }

    .service-menu-wrap .sub-menu-tab .sub-page-link-menu a {
        font-size: 18px !important;
    }

    .menu-contact-mob {
        display: block;
    }

    .menu-contact-mob .contact-number-list-wrap a {
        font-size: 14px;
    }

    .menu-social-mob {
        display: block;
    }

    .menu-social-mob .social-media-wrap ul {
        flex-direction: row;

    }

    .menu-contact-mob .contact-number-list-wrap {
        display: flex;
        align-items: center;
    }

    .menu-contact-mob .contact-number-list-wrap .flag-box img {
        padding-right: 15px;
    }
}



@media only screen and (max-width: 767px) {
    .header_container {
        padding-top: 20px;
        padding-bottom: 20px;
    }

    /* .career-menu{
        display: block;
    } */

    .contact-menu {
        display: none;
    }

    .service-menu {
        display: none;
    }

    .hire-developer-menu {
        display: none;
    }

    .industries-menu {
        display: none;
    }

    .blog-menu {
        display: block;
    }

    nav ul {
        text-align: center;
    }

    nav ul li {
        margin: 0;
        padding: 0;
    }

    nav ul li a {
        font-size: 1.3rem;
        padding: 15px 0;
        border-bottom: 1px solid #191b1e;
        width: 92%;
    }

    nav ul li a img {
        width: 13px;
        height: 13px;
    }

    header {
        position: fixed;
        overflow: visible;
        z-index: 999;
    }

    nav {
        position: fixed;
        height: 100%;
        left: 0;
        top: 0;
        width: 100%;
        padding: 30px 20px 0;
        background: #101214;
        transform: scale(0);
        -webkit-transform: scale(0);
        visibility: hidden;
        opacity: 0;
        transition: all 0.3s cubic-bezier(0, 0, 0, 1.01);
        -webkit-transition: all 0.3s cubic-bezier(0, 0, 0, 1.01);
    }

    .open-menu nav {
        transform: scale(1);
        -webkit-transform: scale(1);
        visibility: visible;
        opacity: 1;
        width: 75%;
    }

    .open-menu header {
        transform: none !important;
        -webkit-transform: none !important;
        -ms-transform: none !important;
    }

    nav ul {
        display: flex;
        flex-direction: column;
    }

    .cnt_nv {
        margin: 40px auto;
        display: table;
    }

    .mb_close,
    .mb_menu_bar {
        display: block;
    }

    .contact-btn-nav a.contact-btn-a {
        background-color: transparent;
    }

    .contact-btn-nav a.contact-btn-a {
        color: white;
    }

    nav ul li.contact-btn-nav a.active {
        color: white;
    }

    .open-menu nav {
        width: 85%;
    }

    nav ul li a {
        width: 95%;
    }
}

@media (max-width: 500px) {
    .mb_close {
        right: 13%;
    }
}

@media (max-width: 450px) {
    .contact-menu {
        display: none !important;
    }

    .about-menu {
        display: none !important;
    }

    .service-menu {
        display: none !important;
    }

    .hire-developer-menu {
        display: none !important;
    }

    .industries-menu {
        display: none !important;
    }

    nav ul li {
        margin: 0;
        padding: 0;
    }

    nav ul li a {
        font-size: 14px;

    }

    .open-menu nav {
        width: 90%;
    }

    nav ul li a {
        width: 78%;
    }

    nav ul li a {
        width: 88%;
    }

    nav ul li a {
        padding: 10px 0;
    }

    .ban_caption h3 {
        font-size: 22px;
    }
}

@media (max-width: 450px) {
    nav ul li a {
        font-size: 12px;
    }

}

@media (max-width: 400px) {
    nav ul li .menu-tag-wrap a {
        font-size: 16px;
    }

    .service-menu-wrap .sub-menu-tab .sub-page-link-menu a {
        font-size: 14px !important;
    }
}