.mobile-contact-details {
    display: none;
}

.landing-contact {
    padding-right: 50px;
}

.landing-contact .nav-contact .title {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #F9F9F9;
}

.landing-contact .nav-contact .number a {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #F1B308 !important;
}


.landing-block-3 {
    background: linear-gradient(181.69deg, #424042 1.41%, rgba(64, 64, 66, 0) 98.57%);
}

.landing-block-3 .landing-block-3-content {
    padding: 61px 0px;
}

.landing-block-3 .landing-block-3-content .block-3-header .heading {
    font-weight: 500;
    font-size: 45px;
    line-height: 64px;
    text-transform: uppercase;
}

.landing-block-3 .landing-block-3-content .block-3-header .description {
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: #F1B308;
    opacity: 0.78;
    margin-top: 30px;
}

.landing-block-3 .landing-sec-3-card {

    margin-top: 80px;
}

.landing-block-3 .landing-sec-3-card .landing-card {
    padding: 10px;
}

.landing-block-3 .landing-sec-3-card .landing-card img {
    border-radius: 30px;
}

.landing-block-3 .card-btn {
    margin-top: 40px;
}

.landing-block-3 .card-btn a {
    background: #E9B43F;
    box-shadow: 0px 4px 13px rgba(234, 234, 234, 0.25);
    border-radius: 29.2373px;
    color: black;
    border: none;
    margin-top: 30px;
    text-align: center;
}

.landing-block-3 .btn_line:hover svg {
    right: 14px;
    top: 14px;
}

.landing-block-3 .ft_ls_sec .cnt_nv {
    margin: 0 auto !important;
}

.landing-block-4 {
    background-color: #1D1D1D;
}

.landing-block-4 .landing-block-4-content {
    padding: 100px 0px;
}

.landing-block-4 .landing-block-4-content .block-4-header {
    max-width: 700px;
}

.landing-block-4 .landing-block-4-content .block-4-header .heading {
    font-weight: 500;
    font-size: 48px;
    line-height: 64px;
    text-transform: uppercase;
    color: #F1B308;
}

.landing-block-4 .landing-block-4-content .block-4-header .description {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
    padding-top: 30px;
}

.landing-block-4 .block-4-img img {
    position: relative;
    width: 100%;
}

.landing-block-4 .block-4-img:before {
    display: none;
    content: '';
    height: 250px;
    z-index: 0;
    width: 250px;
    top: 20%;
    left: 20%;
    position: absolute;
    border-radius: 50%;
    margin: 0 auto;
    background-color: rgba(0, 0, 0, 0.87);
    animation-name: stretch;
    animation-duration: 3.5s;
    animation-timing-function: ease-in-out;
    /* animation-direction: alternate; */
    animation-iteration-count: infinite;
    animation-play-state: running;
}

@keyframes stretch {
    0% {
        transform: scale(0);
        border-radius: 100%;
        opacity: 1;
    }


    100% {
        transform: scale(2.5);
        opacity: 0;
    }
}



.landing-block-6 {
    background-color: #0E0F0F;
}

.landing-block-6 .landing-cta {
    background-color: #000000;
    text-align: center;
    padding: 70px;
    margin-bottom: 50px;
}

.landing-block-6 .landing-border-cta {
    border: 1px solid #F1B308;
}

.landing-block-6 .landing-cta .landing-border-cta .title {
    font-weight: 300;
    font-size: 30px;
    line-height: 48px;
    color: #E9E9E9;
    max-width: 900px;
    margin: auto;
    background: #000000;
    margin-top: -25px;
}

.landing-block-6 .landing-cta .landing-border-cta .sub-title {
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;
    color: #F1B308;
    padding-top: 10px;
}

.landing-block-6 .landing-cta .landing-cta-btn {
    max-width: 250px;
    margin: auto;
    background: #000000;
    margin-bottom: -45px;
}

.landing-block-6 .btn_line:hover svg {
    right: 14px;
    top: 14px;
}

.landing-block-6.ft_ls_sec .cnt_nv {
    margin: 0 auto !important;
}

.landing-block-6 .landing-cta .landing-border-cta .cta-content {
    padding-bottom: 30px;
}

.landing-block-6 .landing-cta-btn a {
    background: #E9B43F;
    box-shadow: 0px 4px 13px rgba(234, 234, 234, 0.25);
    border-radius: 29.2373px;
    color: black;
    border: none;
    margin-top: 30px;
    text-align: center;
}

.landing-block-6 .ft_ls_sec .cnt_nv {
    margin: 0 auto !important;
}


.landing-block-8 {
    background-color: #000000;
    ;
}

.landing-block-8 #clin_ls .item:nth-child(2n+0),
#clin_ls .slick-slide:nth-child(2n+0) {
    background: transparent !important;
}

.landing-block-8 #clin_ls .slick-slide {
    background: transparent !important;
}

.landing-block-8 .landing-block-8-content {
    padding-left: 80px;
}

.landing-block-8 .award-block .title {
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
    text-align: center;
    color: #FFFFFF;
    padding-top: 20px;
}

.landing-block-8 .award-block .bg-black .img-box-h {
    min-height: 180px;
    display: flex;
    align-items: center;
}

.landing-block-8 .award-block .bg-black img {
    padding-top: 50px;
}

.landing-block-8 .award-block .bg-black .img-box-h {
    min-height: 180px;
    display: flex;
    align-items: center;
}

.landing-block-8 .award-block .bg-black {
    background: #0F1012;
    border-radius: 8px;
    height: 300px;
    width: 250px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
    padding: 10px 30px;
}

.landing-block-8 .award-block .bg-black::before {
    position: absolute;
    content: '';
    height: 180px;
    width: 180px;
    background-color: #2C2C2C;
    opacity: 0.4;
    border-radius: 50%;
    top: -42%;
}

.landing-block-8 .award-block .bg-black::after {
    position: absolute;
    content: "";
    color: #F1B308;
    top: 3%;
    font-weight: 400;
    font-size: 24px;
    line-height: 30px;
    text-align: center;
    color: #F1B308;
}

.awards_sec.landing-block-8 .award-block .bg-black {
    height: 200px;
    width: 150px;
    background-color: transparent;
}

.awards_sec.landing-block-8 .award-block .bg-black::before {
    display: none;
}

.awards_sec.landing-block-8 .award-block .bg-black {
    padding: 0px 10px;
}

.awards_sec.landing-block-8 .award-block .bg-black img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
}







.desktop_html .modal#otherLandingPageFormPopup .modal-dialog {
    width: 100%;
    max-width: 650px;
    margin: 0 auto;
}

.desktop_html .modal#otherLandingPageFormPopup .modal-content {
    height: auto;
}

@media (max-width:980px) {
    .landing-block-4 .landing-block-4-content {
        padding: 100px 0px 80px !important;
    }

    .landing-block-4 .landing-block-4-content .block-4-header .heading {
        font-size: 32px;
        line-height: 45px;
        padding-bottom: 10px;
    }
}


@media (max-width:450px) {
    .stickey_header_down {
        top: 0px;
    }

    .stickey_header_up {
        top: 80px;
    }

    .landing-contact {
        display: none;
    }

    .mobile-contact-details {
        display: block;
        text-align: center;
    }

    .mobile-contact-details .contact-number span {
        font-weight: 600;
        font-size: 22px;
        line-height: 24px;
        color: #F9F9F9;
    }

    .mobile-contact-details .contact-number a {
        font-weight: 500;
        font-size: 18px;
        line-height: 24px;
        color: #F1B308;
        padding-left: 20px;
    }

    .btn_line.b {
        padding: 12px 35.4px 12px !important;
    }

    .landing-block-1 .landing-block-main .left-sec {
        padding: 40px 22px 5px;
    }



    .landing-block-main .right-sec {
        display: flex;
        justify-content: center;
    }

    .card-img {
        width: 75%;
        margin: 0 auto;
    }

    .landing-block-2-card .landing-card .card-img .description {
        padding: 20px 30px 40px 20px;
        font-size: 13px;
    }



    .landing-block-2 .landing-block-2-content .block-2-header .heading {
        font-size: 28px;
        line-height: 50px;
    }

    .landing-block-2-card .landing-card .card-img {
        padding: 20px;
    }

    .landing-block-2-card .landing-card {
        padding: 10px 0px;
    }

    .landing-block-3 .landing-block-3-content .block-3-header .heading {
        font-size: 25px;
        line-height: 35px;
    }

    .landing-block-3 .landing-sec-3-card {
        margin-top: 0px;
    }

    .landing-block-3 .card-btn {
        margin-top: 30px;
    }

    .landing-block-3 .card-btn a.btn_line.b {
        padding: 10px 15px 13px !important;
        font-size: 1rem;
        margin-bottom: 40px;
    }

    .landing-block-3 .landing-block-3-content {
        padding: 20px 0px;
    }

    .landing-block-3 .landing-sec-3-card .landing-card {
        width: 100%;
        margin: 0 auto;
        padding: 15px 5px;
    }

    .landing-block-4 .landing-block-4-content {
        padding: 40px 0px;
    }

    .landing-block-4 .block-4-img {
        margin-top: 40px;
    }

    .landing-block-4 .landing-block-4-content .block-4-header .heading {
        font-size: 25px;
        line-height: 35px;
        padding-bottom: 30px;
    }

    .landing-block-4 .landing-block-4-content .block-4-header .description {
        padding-top: 0px;
    }

    .landing-block-5 .landing-block-5-content .block-5-header .heading {
        font-size: 35px;
        line-height: 35px;
        padding-top: 30px;
    }

    .landing-block-5 .landing-block-5-content {
        padding: 0;
    }

    .landing-block-6 .landing-cta {
        padding: 50px 0px;
    }

    .landing-block-6 .landing-cta .landing-border-cta .cta-content {
        padding-bottom: 0;
    }

    .landing-block-6 .landing-cta .landing-border-cta .sub-title {
        font-size: 15px;
        line-height: 29px;
    }

    .landing-block-6 .landing-cta .landing-border-cta .title {
        font-size: 15px;
        line-height: 23px;
        max-width: 300px;
    }

    .landing-block-6 .landing-cta .landing-cta-btn {
        margin-bottom: -65px;
        margin-top: 30px;
        background: none;
    }


    .landing-block-8 .landing-block-8-content {
        padding-left: 10px;
    }

    .landing-block-8 .award-img {
        display: none;
    }

    .landing-block-9 .developer-sec-2 {
        margin: 20px 0;
    }

    .landing-block-9-content {
        margin-bottom: 30px;
    }

    .landing-block-9 .landing-block-9-content .block-9-header .heading {
        font-size: 35px;
        line-height: 35px;
    }

    .landing-block-10 .landing-block-10-content .landing-block-10-description .heading {
        max-width: max-content;
        padding-left: 0;
        margin-top: 30px;
    }

    .landing-block-10 .landing-block-10-content .landing-block-10-description .sub-heading {
        padding-left: 0;

    }

    .landing-block-10 .landing-block-10-content .landing-block-10-description .para {
        padding-left: 0;
    }

    .landing-block-10 .landing-block-10-content .landing-block-10-description i {
        padding: 30px 0px 10px
    }
}