/*@font-face {font-family: 'FontAwesome'; src: url('../fonts/fontawesome-webfont.eot?v=4.7.0'); src: url('../fonts/fontawesome-webfont.eot?#iefix&v=4.7.0') format('embedded-opentype'), url('../fonts/fontawesome-webfont.woff2?v=4.7.0') format('woff2'), url('../fonts/fontawesome-webfont.woff?v=4.7.0') format('woff'), url('../fonts/fontawesome-webfont.ttf?v=4.7.0') format('truetype'), url('../fonts/fontawesome-webfont.svg?v=4.7.0#fontawesomeregular') format('svg'); font-weight: normal; font-style: normal;}

* { border: 0 none; outline: 0; padding: 0; margin: 0;  box-sizing: border-box;-webkit-appearance: value;-moz-appearance: value; appearance: value;}*/


/*html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
}
*/


/* HTML5 display-role reset for older browsers */

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block;
}

.fa {
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}




ol,
ul {
    list-style: none;
}

blockquote,
q {
    quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
    content: '';
    content: none;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

a,
img,
img a {
    text-decoration: none !important;
    border: 0;
    outline: 0 !important;
    transition: all ease-in .3s;
    -moz-transition: all ease-in .3s;
    -ms-transition: all ease-in .3s;
    -o-transition: all ease-in .3s;
    -webkit-transition: all ease-in .3s;
}

a,
button,
input {
    transition: all ease-in .3s;
    -moz-transition: all ease-in .3s;
    -ms-transition: all ease-in .3s;
    -o-transition: all ease-in .3s;
    -webkit-transition: all ease-in .3s;
}

:focus {
    outline: 0px;
}

table {
    border-collapse: collapse;
    border-spacing: 0px;
}

ol,
ul,
li {
    list-style-type: none;
    margin: 0px;
    padding: 0px;
}

.clear {
    clear: both;
}

.clearfix:after {
    clear: both;
}

.clearfix:before,
.clearfix:after {
    content: " ";
    display: table;
}

aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
article {
    display: block;
    margin: 0;
    padding: 0;
}

img {
    display: inline-block;
}

html,
body {
    height: 100%;
}

.fa-linkedin:before {
    content: "\f0e1";
}

.fa-behance:before {
    content: "\f1b4";
}

.fa-dribbble:before {
    content: "\f17d";
}

.fa-facebook-square:before {
    content: "\f082";
}

.fa-instagram:before {
    content: "\f16d";
}

body {
    background: #0e0f0f;
    font-size: 0.875rem;
    color: #fff;
    -webkit-text-stroke: 0px !important;
    -webkit-font-smoothing: antialiased !important;
    margin: 0;
}

input[type="text"],
input[type="email"],
input[type="number"],
input[type="password"],
textarea {
    padding: 11.5px 20px;
    color: #333;
    border: 1px solid #d9d1c3;
    border-radius: 2px;
    font-size: 1rem;
    appearance: none;
    -webkit-appearance: none;
    font-family: 'Assistant', sans-serif;
    font-display: swap;
    font-weight: 400;
    width: 100%;
    transition: all ease-in .3s;
    -moz-transition: all ease-in .3s;
    -ms-transition: all ease-in .3s;
    -o-transition: all ease-in .3s;
    -webkit-transition: all ease-in .3s;
}

input[type="text"]::-webkit-input-placeholder,
input[type="email"]::-webkit-input-placeholder,
input[type="number"]::-webkit-input-placeholder,
input[type="password"]::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
    color: #666666;
}

input[type="text"]:-ms-input-placeholder,
input[type="email"]:-ms-input-placeholder,
input[type="number"]:-ms-input-placeholder,
input[type="password"]:-ms-input-placeholder,
textarea:-ms-input-placeholder {
    color: #666666;
}

input[type="text"]::placeholder,
input[type="email"]::placeholder,
input[type="number"]::placeholder,
input[type="password"]::placeholder,
textarea::placeholder {
    color: #666666;
}

input[type="text"]:hover,
textarea:hover,
input[type="email"]:hover,
input[type="number"]:hover,
input[type="email"]:focus,
input[type="number"]:focus,
input[type="text"]:focus,
textarea:focus {
    border-color: #021134;
}

input[type="text"]:focus,
textarea:focus {
    outline: none;
}

.btn-effect {
    position: relative;
    overflow: hidden;
}

.btn-effect .text {
    position: relative;
    z-index: 2;
    display: block;
}

.btn-effect:after {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 800px;
    height: 800px;
    content: "";
    background: rgba(255, 255, 255, 0.40);
    border-radius: 50%;
    -ms-transform: translate(-50%, -50%) scale(0);
    transform: translate(-50%, -50%) scale(0);
    -webkit-transform: translate(-50%, -50%) scale(0);
    -ms-transform-origin: center center;
    transform-origin: center center;
    -webkit-transform-origin: center center;
    visibility: hidden;
    opacity: 0;
    z-index: 1;
}

@keyframes btneffect {
    from {
        transform: translate(-50%, -50%) scale(0);
        -webkit-transform: translate(-50%, -50%) scale(0);
        visibility: visible;
        opacity: 1;
    }

    to {
        transform: translate(-50%, -50%) scale(1);
        -webkit-transform: translate(-50%, -50%)scale(1);
        visibility: hidden;
        opacity: 0;
    }
}

@-webkit-keyframes btneffect {
    from {
        transform: translate(-50%, -50%) scale(0);
        -webkit-transform: translate(-50%, -50%) scale(0);
        visibility: visible;
        opacity: 1;
    }

    to {
        transform: translate(-50%, -50%) scale(1);
        -webkit-transform: translate(-50%, -50%)scale(1);
        visibility: hidden;
        opacity: 0;
    }
}

.btn-effect:hover:after,
.btn-effect:active:after {
    animation-duration: 1.5s;
    animation-fill-mode: both;
    animation-name: btneffect;
    -webkit-animation-duration: 1.5s;
    -webkit-animation-fill-mode: both;
    -webkit-animation-name: btneffect;
}

.page_progress {
    position: fixed;
    top: 0;
    left: 0;
    height: 3px;
    background-color: #f1b308;
    transition: all linear 0.1s;
    -webkit-transition: all linear 0.1s;
    min-width: 0%;
    z-index: 9999;
}


/* Common Stylev */

.wht_cl {
    color: #fff !important;
}

.thm_cl {
    color: #f1b308 !important;
}

sup {
    color: #973c13;
}

.ds_flex {
    display: flex;
}

.flex_wrap {
    flex-wrap: wrap;
}

.flex_spbetween {
    justify-content: space-between;
}

.flex_jsend {
    justify-content: flex-end;
}

.flex_alcenter {
    align-items: center;
}

.flex_altop {
    align-items: flex-start;
}

.flex_colum {
    flex-direction: column;
}

.ds_inlineb {
    display: inline-block;
}

.ds_table {
    display: table;
}


/* Font  */

.fn_sz_10 {
    font-size: 0.625rem;
}

.fn_sz_12 {
    font-size: 0.75rem;
}

.fn_sz_14 {
    font-size: 0.875rem;
}

.fn_sz_15 {
    font-size: 0.9375rem;
}

.fn_sz_16 {
    font-size: 1rem;
}

.fn_sz_18 {

    font-size: 1.125rem;
}

.fn_sz_20 {
    font-size: 1.25rem;
}

.fn_sz_24 {
    font-size: 1.5rem;
}

.fn_sz_26 {
    font-size: 1.625rem;
}

.fn_sz_30 {
    font-size: 1.875rem;
}

.fn_sz_32 {
    font-size: 2rem;
}

.fn_sz_44 {
    font-size: 2.75rem;
}

.fn_sz_48 {
    font-size: 3rem;
}

.fn_tr_up {
    text-transform: uppercase;
}

.fn_wt_bold {
    font-weight: 700;
}

.fn_wt_bbold {
    font-weight: 900;
}


/* Padding */

.pd_lr_15 {
    padding: 0 15px;
}

.pd_tb_15 {
    padding: 15px 0;
}

.pd_full_45 {
    padding: 45px;
}

.pd_full_25 {
    padding: 25px;
}

.pd_tb_60 {
    padding: 60px 0;
}


/* Margin */

.mr_auto {
    margin: 0 auto;
}

.mr_bt_20 {
    margin-bottom: 20px;
}

.mr_bt_45 {
    margin-bottom: 45px;
}

.mr_bt_35 {
    margin-bottom: 35px;
}

.mr_bt_30 {
    margin-bottom: 30px;
}

.mr_top_35 {
    margin-top: 35px;
}

.mr_top_25 {
    margin-top: 25px;
}

.mr_rt_30 {
    margin-right: 30px;
}


/* Button */

.ln_btn:before {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 1px;
    background: #f1b308;
    content: '';
    transform: scaleX(0) translateZ(0);
    transform-origin: 100% 100%;
    -webkit-transition: transform 0.5s cubic-bezier(0.19, 1, 0.22, 1);
    transition: transform 0.5s cubic-bezier(0.19, 1, 0.22, 1);
}

.ln_btn:hover:before {
    transform: scaleX(1) translateZ(0);
    transform-origin: 0 0;
    -webkit-transform: scaleX(1) translateZ(0);
    -webkit-transform-origin: 0 0;
}

form label {
    font-weight: 600;
    margin-bottom: 8px;
    display: inline-block;
    font-size: 18px;
}

.fr_divider {
    margin-top: 35px;
    margin-bottom: 35px;
}

.btn_line {
    /* border: 1px solid white !important; */
    border: 1px solid #f1b308 !important;
    color: #fff;
    font-weight: 600;
    border-radius: 50px !important;
    position: relative;
    overflow: hidden;
    z-index: 1;
    display: inline-flex;
    transition: all .5s;
    background-color: transparent;
    /* background-color: #f1b308 !important; */
}

.btn_line:hover {
    -webkit-animation: scale .5s linear 1;
    animation: scale .5s linear 1;
    -webkit-animation-delay: .1s;
    animation-delay: .1s;
    border-color: #f1b308;
    color: #010101;
    border: 1px solid transparent !important;
    background-color: #F1B308;
}

.btn_line.hvr-sweep-to-right:hover {
    color: #010101 !important;
}

.btn-primary:focus {
    background-color: #F1B308;
    box-shadow: none;
    border: 1px solid transparent !important;
}

.btn-primary:active:focus {
    box-shadow: none
}

@-webkit-keyframes scale {
    0% {
        transform: scale(1)
    }

    15% {
        transform: scale(1.05)
    }

    to {
        transform: scale(1)
    }
}

@keyframes scale {
    0% {
        transform: scale(1)
    }

    15% {
        transform: scale(1.05)
    }

    to {
        transform: scale(1)
    }
}

.btn_line:before {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: #f1b308;
    content: '';
    transform: scaleX(0) translateZ(0);
    transform-origin: 100% 100%;
    -webkit-transition: transform 0.5s cubic-bezier(0.19, 1, 0.22, 1);
    transition: transform 0.5s cubic-bezier(0.19, 1, 0.22, 1);
    z-index: -1;
}

.btn_line:hover:before {
    transform: scaleX(1) translateZ(0);
    transform-origin: 0 0;
    -webkit-transform: scaleX(1) translateZ(0);
    -webkit-transform-origin: 0 0;
}

.btn_line.b {
    font-size: 1.03rem;
    padding: 12px 41.4px 12px !important;
}


/* .btn_line.b:hover {
    padding: 13px 51.4px 15px 41.4px;
} */

.btn_line svg {
    width: 24px;
    height: 29px;
    fill: #fff;
    position: absolute;
    /* right: 35px; */
    right: 12px;
    top: 9px;
    transform: scale(1);
    transform-origin: center right;

}



.hd_s {
    font-weight: 700;
    font-family: 'Assistant', sans-serif;
    font-display: swap;
    color: #f1b308;
    display: inline-flex;
    position: relative;
    padding-bottom: 18px;
    margin-bottom: 25px;
}

.hd_center {
    display: table;
    margin: 0 auto;
}

.hd_s .ln {
    height: 2px;
    width: 96px;
    background-color: #f1b308;
    display: block;
    position: absolute;
    bottom: 0px;
    left: 0;
}

/* our motive section home page */
.our-motive-sec {
    background-image: url(https://d2e3cmtjkfw2a3.cloudfront.net/static/media/home/our-motive-bg.webp);
    background-position: right;
    background-repeat: no-repeat;
    padding: 60px 0px;
    background-size: cover;
}

.our-motive-sec .our-motive-head .heading {
    font-weight: 500;
    font-size: 40px;
    line-height: 60px;
    color: #f1b308;
    position: relative;
    padding-bottom: 20px;
}

.our-motive-sec .our-motive-head .heading .ln {
    height: 2px;
    width: 18%;
    background-color: #f1b308;
    display: block;
    position: absolute;
    bottom: 0px;
    transform-origin: left bottom;
    left: 9%;
}

.our-motive-sec .our-motive-about {
    font-weight: 400;
    font-size: 26px;
    line-height: 37px;
    color: #9C9C9C;
    padding-top: 34px;
}

.our-motive-sec .review-sec {
    display: flex;
    justify-content: center;
    padding-top: 30px;
}

.our-motive-sec .review-sec .clutch-sec,
.our-motive-sec .review-sec .glass-sec {
    margin: 0px 20px;
}

.our-motive-sec .our-motive-review-title {
    font-weight: 600;
    font-size: 24px;
    line-height: 35px;
    color: rgba(255, 255, 255, 0.92);
    padding: 72px 0px 65px;
}

.our-motive-sec .review-list .review-no {
    font-weight: 400;
    font-size: 50px;
    line-height: 83px;
    text-align: center;
    color: #F1B308;
}

.our-motive-sec .review-list .review-no span {
    font-size: 40px;
}

.our-motive-sec .review-list .review-no-title {
    font-weight: 400;
    font-size: 20px;
    line-height: 33px;
    color: #FFFFFF;
    text-align: center;
}

.hd_center .ln {
    left: 50%;
}

.wp_padding {
    padding: 120px 0 70px 0;
}

.modal-box {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1000;
    display: none;
    outline: 0;
    overflow-x: hidden;
    overflow-y: auto;
}

.popup_inside_section {
    position: relative;
    min-height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px 0;
    margin: 0 auto;
}

.js-modal-close {
    position: absolute;
    font-size: 30px;
    right: 10px;
    color: #fff;
    top: 10px;
    width: 30px;
    text-align: center;
}

@-webkit-keyframes scale_cl {
    0% {
        transform: scale(1)
    }

    15% {
        transform: scale(1.5)
    }

    to {
        transform: scale(1)
    }
}

@keyframes scale_cl {
    0% {
        transform: scale(1)
    }

    15% {
        transform: scale(1.5)
    }

    to {
        transform: scale(1)
    }
}

.js-modal-close:hover {
    -webkit-animation: scale_cl .5s linear 1;
    animation: scale_cl .5s linear 1;
    -webkit-animation-delay: .1s;
    animation-delay: .1s;
}

.getin_touch_pop_ins {
    position: relative;
}

.modal-overlay {
    opacity: 0;
    filter: alpha(opacity=0);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 900;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8) !important;
}

.popup_open,
.open-menu {
    height: 100vh;
    overflow: hidden;
}

@media only screen and (max-width: 1366px) {
    .btn_line.b {
        font-size: 1.05rem;
        padding: 13px 35.4px 13px !important;
    }

    .fn_sz_26 {
        font-size: 1.325rem;
    }

    .fn_sz_44 {
        font-size: 2.35rem;
    }
}

@media only screen and (max-width: 1170px) {
    .container {
        padding-left: 15px;
        padding-right: 15px;
    }
}

@media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {}

@media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    .wp_padding {
        padding: 80px 0;
    }

    .fn_sz_48 {
        font-size: 2.4rem;
    }

    .fn_sz_18 {
        font-size: 1.025rem;
    }
}

@media only screen and (max-width: 767px) {
    .fn_sz_24 {
        font-size: 1.3rem;
    }

    .fn_sz_30 {
        font-size: 1.575rem;
    }

    .hd_s {
        font-size: 2rem;
    }

    .page_progress {
        z-index: 9999;
    }

    .our-motive-sec .our-motive-head .heading {
        font-size: 30px;
        line-height: 44px;
        padding-bottom: 0px;
    }

    .our-motive-sec .our-motive-head .heading .ln {
        bottom: -10px;
    }

    .our-motive-sec .our-motive-about {
        font-size: 14px;
        line-height: 28px;
        padding-bottom: 34px;
    }

    .our-motive-sec .our-motive-review-title {
        font-size: 20px;
        padding: 30px 0px;
    }

    .our-motive-sec .review-list .review-no {
        font-size: 30px;
        line-height: 45px;
    }

    .our-motive-sec .review-list {
        padding-bottom: 20px;
    }

    .our-motive-sec .review-list .review-no-title {
        font-size: 22px;
    }

    .our-motive-sec .review-list .review-no span {
        font-size: 26px;
    }
}