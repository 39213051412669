.loader-container {
 position:fixed;
 width:100%;
 top:0;
 left:0;
 bottom:0;
 z-index:9999;
 height:100%;
 opacity:1;
 -webkit-transition:all .25s ease;
 -o-transition:all .25s ease;
 transition:all .25s ease
}
.loader-container.active {
 height:0
}
.loader-container:before {
 content:'';
 width:100%;
 height:100%;
 position:absolute;
 top:0;
 left:50%;
 -webkit-transform:translateX(-50%);
 -ms-transform:translateX(-50%);
 transform:translateX(-50%);
 background-color:#f6f6f6;
 -webkit-transition:all .4s ease;
 -o-transition:all .4s ease;
 transition:all .4s ease
}
.loader-container:before {
 animation:slideBgTop 1.1s forwards;
 -webkit-animation:slideBgTop 1.1s forwards;
 -webkit-animation-delay:.7s;
 animation-delay:.7s;
 -webkit-transition:all .4s ease;
 -o-transition:all .4s ease;
 transition:all .4s ease
}
@-webkit-keyframes slideBgTop {
 0% {
  -webkit-animation-timing-function:ease-in-out;
  -webkit-transform:translateY(0) translateX(-50%);
  transform:translateY(0) translateX(-50%)
 }
 50% {
  -webkit-transform:translateY(-50%) translateX(-50%) skewY(7deg);
  transform:translateY(-50%) translateX(-50%) skewY(7deg)
 }
 100% {
  -webkit-transform:translateY(-100%) translateX(-50%) skewY(0);
  transform:translateY(-100%) translateX(-50%) skewY(0);
  -webkit-animation-timing-function:ease-in-out
 }
}
@keyframes slideBgTop {
 0% {
  -webkit-animation-timing-function:ease-in-out;
  -webkit-transform:translateY(0) translateX(-50%);
  transform:translateY(0) translateX(-50%)
 }
 50% {
  -webkit-transform:translateY(-50%) translateX(-50%) skewY(7deg);
  transform:translateY(-50%) translateX(-50%) skewY(7deg)
 }
 100% {
  -webkit-transform:translateY(-100%) translateX(-50%) skewY(0);
  transform:translateY(-100%) translateX(-50%) skewY(0);
  -webkit-animation-timing-function:ease-in-out
 }
}
.loader-container.active {
  height: 0;
}