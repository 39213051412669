.desktop_html.main-srvc-page .sb_sr_top {
    max-width: 600px;
}

.desktop_html.main-srvc-page .sb_sr_top .srvc-btn-main .btn.btn-white {
    border: 1px solid #F1B308 !important;
}

.main-srvc-page .btn.btn-primary {
    text-transform: capitalize;
}

.desktop_html.main-srvc-page .sb_sr_top h5 {
    color: #f9f9f9;
    line-height: 80px;
    letter-spacing: 3.2px;
    font-size: 16px;
    text-transform: uppercase;
    font-family: "Montserrat", sans-serif;
}

.main-srvc-page.sales-force .btn_line {
    border: 1px solid white !important;
}

.main-srvc-page .latest-industry-domain .btn_line {
    border: 1px solid white !important;
}

.main-srvc-page .btn_line {
    border: 1px solid transparent !important;
}

.main-srvc-page .description {
    color: #9C9C9C !important;
    font-weight: 500;
    font-size: 16px;
    line-height: 32px;
}

.desktop_html.main-srvc-page .sb_sr_top .heading {
    font-size: 40px;
    color: #f9f9f9;
    font-weight: 600;
    line-height: 50px;
    margin-bottom: 20px;
    text-align: left;
}

.desktop_html .sb_sr_top span.blr {
    width: 10px;
    height: 10px;
    display: inline-block;
    background: #775DCA;
    border-radius: 15px;
}

.desktop_html .srvc-sec-1 .btn.btn-primary.btn-ylw {
    background-color: #e4a400;
    box-shadow: 0px 4px 13px rgba(234, 234, 234, 0.25);
    padding: 13px 25px;
}

.desktop_html .srvc-sec-1 .btn.btn-primary.btn-white {
    background-color: transparent;
    color: white;
    border: 1px solid transparent !important;
    /* margin-left: 20px; */
}

.desktop_html .srvc-sec-1 .btn.btn-primary.btn-white:focus {
    border: 1px solid transparent !important;
}

.desktop_html .srvc-sec-1 .btn.btn-primary.btn-white:active {
    border: 1px solid transparent !important;

}

.desktop_html .srvc-sec-1 .btn.btn-primary.btn-white.digital-btn {
    border: 1px solid white !important;
}

.desktop_html .srvc-sec-1 .btn.btn-primary.btn-white.digital-btn:hover {
    border: 1px solid transparent !important;
}

.desktop_html .btn-primary:focus {
    box-shadow: unset !important;
}

.desktop_html .btn.btn-primary.btn-white:hover {
    /* border: 1px solid transparent; */
    color: black;
    background-color: #F1B308;
}


.srvc-icon-mob {
    display: none;
}

.srvc-img-dsktp {
    display: block;
}

.main-srvc-page .block-card-slider .sec-3-right a {
    color: #e4a400;
    float: right;
    padding-right: 20px;
    display: none;
}

.main-srvc-page .block-card-slider .sec-3-right p a {
    color: #e4a400;
    float: none;
    display: inline !important;
    padding-right: 0px !important;
}

.main-srvc-page .block-card-slider .slick-current .sec-3-right a {
    display: block;
}

.main-srvc-page .block_chain {
    background-color: #000000;
}

.our-prcs .our-prcs-ani .our-prcs-heading {
    font-weight: 600;
    font-size: 50px;
    line-height: 76px;
}

.our-prcs .our-prcs-ani .our-prcs-heading.small-title {
    font-size: 34px;
    line-height: 52px;
    color: #e4a400;
}

.our-prcs .mobile_project {
    display: none;

}

.our-prcs {
    margin-top: 60px;
}

.our-prcs .our-prcs-ani {
    margin-bottom: 60px;
}

.our-prcs .our-prcs-ani .our-prcs-description {
    font-weight: 500;
    font-size: 16px;
    line-height: 28px;
    padding: 20px 0px 30px;
    color: #F9F9F9;
}

.our-prcs .our-prcs-ani .our-prcs-box {
    border: 3px solid #FFFFFF;
    border-radius: 0px 50px 50px 0px;
    border-left: 0;
    height: 200px;
    width: 100%;
    display: block;
    max-width: 900px;
    margin: auto;
    margin-left: 120px;
    position: relative;
}

.prcs-top {
    position: absolute;
    width: 105%;
    top: -53%;
    left: -8%;
    /* margin-top: -87px;
    margin-left: -110px; */
}

.our-prcs-box-wrapper {
    padding: 10% 0;
}

.dm-srvc-sec-4 .domain-sec-5-feature {
    max-height: 450px;
    overflow-y: scroll;
    overflow-x: hidden;
}

.dm-srvc-sec-4 .domain-sec-5-feature::-webkit-scrollbar {
    border: none;
    background-color: #353535;
    border-radius: 20px;
}

.dm-srvc-sec-4 .domain-sec-5-feature::-webkit-scrollbar-corner {
    background: transparent;
}

.dm-srvc-sec-4 .domain-sec-5-feature::-webkit-scrollbar-thumb {
    background: transparent;
    background-color: rgb(16, 15, 15);
    width: 5px;
    border-radius: 20px;
}

.dm-srvc-sec-3 .left-col-dm .heading {
    font-weight: 500;
    font-size: 40px;
    line-height: 60px;
    color: #E4A400;
    margin: 20px 0px;
}

.dm-srvc-sec-3 .left-col-dm .heading span {
    color: #fff;
}

.dm-srvc-sec-3 .left-col-dm .title {
    font-weight: 400;
    font-size: 25px;
    line-height: 40px;
    color: #FFFFFF;
    margin: 20px 0px;
}

.dm-srvc-sec-3 .left-col-dm .description {
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    color: #ABABAB;
    margin: 20px 0px;
}

.dm-srvc-sec-4.domain-sec-5 .domain-sec-5-content {
    max-width: 450px;
}

.dm-srvc-sec-5 {
    background-color: #000000;
}

.dm-srvc-sec-5 .dash-border {
    position: relative;
}

.dm-srvc-sec-5 .dash-border::before {
    position: absolute;
    content: '';
    border-top: 1px dashed #E4A400;
    width: 80%;
    top: 34%;
    margin: auto;
    left: 0;
    right: 0;
    opacity: 0.2;
}

.dm-srvc-sec-5 .dm-srvc-content .main-heading {
    font-weight: 500;
    font-size: 40px;
    line-height: 60px;
    color: #E4A400;

}

.dm-srvc-sec-5 .dm-srvc-content .title {
    font-weight: 400;
    font-size: 23px;
    line-height: 35px;
    color: #FFFFFF;
    padding-top: 32px;
}

.dm-srvc-sec-5 .dm-srvc-card {
    background: #212121;
    box-shadow: 0px 0px 23px #353535;
    height: 140px;
    width: 140px;
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: auto;
}

.dm-srvc-sec-5 .dm-srvc-card .card-heading {
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    color: #E4A400;
    text-align: center;
}

.dm-srvc-sec-5 .card-title {
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    text-align: center;
    color: #9C9C9C;
    padding-top: 30px;
    margin-bottom: 0px;
}

.dm-srvc-sec-5 .hover-circle {
    height: 40px;
    width: 40px;
    background-color: #212121;
    box-shadow: 0px 0px 23px #353535;
    border-radius: 50%;
    opacity: 0;
    position: absolute;
    top: -10%;
    color: #e4a400;
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all .5s ease-in-out;
}

.dm-srvc-sec-5 .dm-srvc-card {
    position: relative;
}

.dm-srvc-sec-5 .dm-srvc-card-box:hover .hover-circle {
    opacity: 1;
}

.digital-sub .left-sub-box .box-sec .icon-title {
    color: white;
    font-size: 15px;
    padding-top: 15px;
}

.digital-sub .left-sub-box .box-sec .icon-title:hover {
    color: #e4a400;
}

@media (max-width: 1300px) {
    .our-prcs-box-wrapper {
        padding: 10%;
    }
}

.prcs-bottom {
    position: absolute;
    width: 105%;
    bottom: -53%;
    left: -8%;
    /* margin-top: 38px;
    margin-left: -110px; */
}

.prcs-content,
.prcs-bottom .prcs-content {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 150px;
}

.prcs-content .icon img {
    border: 1px solid #FFFFFF;
    border-radius: 8px;
    padding: 10px;
    margin: 10px 0px;
}

.prcs-content .dot {
    height: 10px;
    width: 10px;
    margin: 9px 0px;
    border-radius: 50px;
    background: #FFFFFF;
    border: 1px solid #F1B308;
}

.prcs-content .title {
    font-weight: 400;
    font-size: 18px;
    line-height: 32px;
    text-align: center;
    letter-spacing: 1px;
    min-height: 65px;
    display: flex;
    align-items: center;
}

.our-prcs .mobile_html .mobile_project {
    overflow: hidden;
}

.our-prcs .mobile_html .mobile_project .pro_container {
    overflow-x: scroll;
}

::-webkit-scrollbar {
    height: 4px;
    width: 4px;
    border: none;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #F1B308;
    border-radius: 5px;
}

.our-prcs .mobile_html .mobile_project li {
    border: none;
    margin-left: 0;
    margin-right: 0;
}

.latest-industry-domain .indus-heading {
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    text-align: center;
    letter-spacing: 0.4px;
    text-transform: uppercase;
    color: white;
}

.latest-industry-domain .indus-description {
    font-weight: 500;
    font-size: 36px;
    line-height: 47px;
    text-align: center;
    max-width: 800px;
    margin: 0 auto;
}

.latest-industry-domain .carousel .slide_container {
    height: 500px !important;
    transform: rotateX(2deg);
}

.latest-industry-domain .carousel .slide_container .top-active {
    top: 65%;
    right: 0;
    left: 0;
    text-align: center !important;
    padding: 0 6% 0 12%;
}

.latest-industry-domain .cta-more-box.arrow.slide-left {
    top: 72%;
}

.latest-industry-domain .cta-more-box.arrow.slide-right {
    top: 72%;
}

.latest-industry-domain .categoryTitle {
    text-align: center !important;
    width: 100%;
}

.main-srvc-page .sr-cta .heading {
    font-weight: 600;
    font-size: 50px;
    line-height: 76px;
    letter-spacing: 0.02em;
    color: #F9F9F9;
    margin-bottom: 0px;
}

.main-srvc-page .sr-cta p {
    font-weight: 300;
    font-size: 26px;
    line-height: 48px;
    text-align: center;
    color: #E9E9E9;
    max-width: 950px;
    margin: 0 auto;
}

.main-srvc-page .sr-ctn-btn-pd {
    padding: 37px 0px;
}

.main-srvc-page .sr-ctn-btn-pd .btn_line {
    background-color: #e4a400;
    border: 1px solid transparent !important;

}


.main-srvc-page .hd_s.srvc-heading-main-srvc {
    font-weight: 700;
    font-size: 36px;
    line-height: 54px;
    text-transform: uppercase;
    color: #BFBFBF;
    letter-spacing: 10px;
}

.main-srvc-page .srvc-card-box {
    overflow: hidden;
    border-radius: 30px;
    height: 200px;
    /* background-image: linear-gradient(rgba(255, 255, 255, 1)
    rgba(0, 0, 0, 0)); */
    /* box-shadow: -10px 0px 17px 0px rgb(255 255 255 / 5%); */
    box-shadow: -1px -2px 1px 0 #5f5f60d1;
}

.main-srvc-page .srvc-card {
    background: #000000;
    border-radius: 30px;
    height: 195px;
    padding: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    /* box-shadow: -5px -3px 1px -1px rgb(255 255 255 / 5%);
    border-image: linear-gradient(180deg,#ababab,rgba(0,0,0,0)) 1;
    border-width: 2px;
    border-style: solid; */
}

.main-srvc-page .srvc-card .main-srvc-content {
    text-align: center;
}

.main-srvc-page .srvc-card .main-srvc-content .srvc-head-img {
    min-height: 65px;
}

.main-srvc-page .srvc-card-box a {
    color: white;
    font-weight: 500;
    font-size: 20px;
    line-height: 26px;
}

.main-srvc-page .srvc-card-box a .srvc-card .main-srvc-content .srvc-name {
    min-height: 65px;
    padding-top: 20px;
}


.main-srvc-page .sub-service-services.website-development .sr-box:hover {
    max-height: max-content;
}

.main-srvc-page .sub-service-services.mobile-development .sr-box:hover {
    max-height: max-content;
}

.main-srvc-page .sub-service-services.digital-marketing .sr-box:hover {
    max-height: max-content;
}

.main-srvc-page .sub-service-services.software-development .sr-box:hover {
    max-height: max-content;
}

/*testimonial */
.testimonial-block {
    background-color: #101214 !important;
}

.testimonial-block-career {
    background-color: black !important;
}

.testimonial-heading {
    font-weight: 500;
    font-size: 36px;
    line-height: 54px;
    color: #fff;
}

.testimonial-heading-ylw {
    font-weight: 500;
    font-size: 50px;
    line-height: 72px;
    color: #E9B43F;
}

.testimonial-title {
    font-weight: 400;
    font-size: 20px;
    line-height: 176.19%;
    color: #C4C4C4;
    padding: 20px 0px;

}

.testimonial-card {
    max-width: 1200px;
    overflow: hidden;
    margin: 0 auto;
    position: relative;
}

.testimonial-card .test-img-up {
    position: absolute;
    right: 0;
}

.testimonial-card .test-img-down {
    position: absolute;
    left: 0;
    bottom: 7%;
}

.testimonial-card-slider {
    height: 500px;

}

.testimonial-card .testimonial-list .testimonial-wrapper {
    background: #000000;
    border: 1px solid #373737;
    border-radius: 20px;
    height: 100%;
    width: unset;
    position: relative;
    /* transition: all ease 1.5s; */
    /* transition: all 0.5s ease-in-out; */

}

.testimonial-card ul li {
    cursor: pointer;

}

.testimonial-card-slider .slick-slide {
    transform: scale(.7);
    transition: all 1s ease;
}

.testimonial-card-slider .slick-slide.slick-current {
    transform: scale(1);
    transition: all 1s ease;
}

.testimonial-card-slider .slick-clone-current {
    transition: all 1s ease;
    transform: scale(1) !important;

}

.testimonial-card-slider .slick-clone-current-next,
.testimonial-card-slider .slick-clone-current-prev {
    transition: all 1s ease;
    transform: scale(0.7) !important;
}

.testimonial-card-slider .slick-track {
    padding-top: 20px;
}



.testimonial-card .testimonial-card-slider .slick-list .slick-track .slick-slide {
    display: flex;
    justify-content: center;
    /*transform: translate(10px, 175px); */
    /* margin-top: 60px; */
}

.testimonial-card .testimonial-card-slider .slick-list .slick-track .slick-center {
    /*transform: none; */
    margin-top: 0px;
}

.testimonial-card .testimonial-card-slider .slide {
    height: 520px;
    display: flex !important;
    align-items: center;
}

.testimonial-card .testimonial-list .testimonial-wrapper .test-content {
    /* display: flex;
    flex-direction: column;
    align-items: center; */
    padding: 20px;
    position: relative;
    margin-top: 90px;
    text-align: center;
}

.testimonial-card .testimonial-list .testimonial-wrapper .test-content .test-client-img {
    /* position: absolute;
    top: -35%; */
    overflow: hidden;
    z-index: 1;
    margin-top: -45%;
}

.testimonial-card .testimonial-list .testimonial-wrapper .test-content .test-client-img img {
    margin: auto;
}



.testimonial-card .testimonial-list .testimonial-wrapper .test-content .client-name {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #F9F9F9;
}

.testimonial-card .testimonial-list .testimonial-wrapper .test-content .client-project-name {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #B4B4B4;
    position: relative;
    padding-bottom: 10px;
}

.testimonial-card .testimonial-list .testimonial-wrapper .test-content .client-project-name::before {
    position: absolute;
    content: '';
    background-color: #F1B308;
    height: 2px;
    width: 60px;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
}



.testimonial-card .testimonial-list .testimonial-wrapper .test-content .client-project-description {
    font-weight: 500;
    font-size: 16px;
    line-height: 28px;
    color: #F9F9F9;
    text-align: center;
    /*display: none; */
    opacity: 1;
    /* transition: opacity 0.4s ease; */
    padding-bottom: 20px;
}

.testimonial-card .slick-center .testimonial-list .testimonial-wrapper .test-content .client-project-description {
    /*display: block; */
    opacity: 1;
    /* transition: opacity 3s ease; */
}

.testimonial-card .testimonial-list .testimonial-wrapper .test-content .project-rating {
    font-weight: 500;
    font-size: 16px;
    line-height: 34px;
    padding: 20px 0px;
}

.testimonial-card .testimonial-list .testimonial-wrapper .test-content .project-rating .fa {
    color: #e1574a;
    margin: 0 0 0 3px;
    display: inline-block;
}

.srvc-faq .faq-circle {
    top: 24%
}

.sr_faq.srvc-faq .accordion-faq ul.accordion-list li {
    padding: 20px 12px;
}

.sales-force .container {
    max-width: 1400px !important;

}

.sales-force .container-blog {
    max-width: 1200px !important;
    margin: 0 auto;
}



.sec-ylw-line {
    width: 100%;
    position: absolute;
    height: 3px;
    background-color: #F1B308;
    z-index: -1;
}

.ylw-heading {
    padding-left: 5px;
    background-color: black;
    max-width: 90%;
    padding-top: -10px;
    font-weight: 600;
    font-size: 36px;
    line-height: 54px;
    letter-spacing: 0.02em;
    margin-top: -25px;
}

.ylw-heading-long {
    max-width: 75%;
    padding-left: 5px;
    background-color: black;
    padding-top: -10px;
    font-weight: 600;
    font-size: 36px;
    line-height: 54px;
    letter-spacing: 0.02em;
    margin-top: -25px;
}

.sales-sec-1 .btn_line:hover {
    border: 1px solid transparent !important;
}

.sales-sec-1 {
    padding: 90px 90px 100px !important;
    background-image: url(https://d2e3cmtjkfw2a3.cloudfront.net/static/media/salesforce/sales-main.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: right;
    min-height: 100vh;
}

.sales-sec-1 .sb_sr_top {
    max-width: 750px !important;
}

.sales-sec-1 .sb_sr_top p {
    max-width: 600px;
}

.desktop_html .sub-services-top .salesforce-banner-img img {
    width: auto;
    position: unset !important;
    right: unset;
    top: 27%;
    margin: unset;
    border-radius: 15px;
}

.sales-sec-2 {
    padding: 80px 0px;
    position: relative;
    z-index: 0;
}

.sales-sec-2 .ylw-heading {
    background-color: #101214;
    max-width: 90%;
    padding-top: -10px;
    font-weight: 600;
    font-size: 36px;
    line-height: 54px;
    letter-spacing: 0.02em;
    margin-top: -25px;
}

.sales-sec-2 .tab {
    max-height: 600px;
    overflow: scroll;
    overflow-x: hidden;
}

.sales-sec-2 .tab::-webkit-scrollbar {
    border: none;
    background-color: black;
    border-radius: 20px;
}

.sales-sec-2 .tab::-webkit-scrollbar-corner {
    background: transparent;
}

.sales-sec-2 .tab::-webkit-scrollbar-thumb {
    background: transparent;
    background-color: #353535;
    width: 5px;
    border-radius: 20px;
}

.sales-sec-2 .tab li {
    margin: 5px 0px;
}

.sales-sec-2 .mb-service {
    border: 1px solid transparent;
    padding-left: 0px;
    padding: 39px 70px 38px 34px;
    position: relative;
    transition: all .6s ease-in-out;
    z-index: 0;
    width: 80%;
    border-radius: 10px;
}

.sales-sec-2 .mb-service .srvc-tab-link {
    color: #979797 !important;
    font-weight: 400;
    font-size: 19px;
    line-height: 36px;
}

.sales-sec-2 .mb-service.active .srvc-tab-link {
    color: white !important;
}

.sales-sec-2 .mb-service.active {
    border: 1px solid transparent;
    border-color: rgba(202, 224, 242, 0.4) !important;
    transition: all .6s ease-in;
}

.sales-sec-2 .srvc-tab-link {
    position: relative;
}

.sales-sec-2 .srvc-tab-link .right-arrow {
    position: absolute;
    content: url(https://d2e3cmtjkfw2a3.cloudfront.net/static/media/industries/right-arrow.svg);
    display: none;
    left: unset;
}

.sales-sec-2 button.active .right-arrow {
    display: block;
    right: 5px;
    top: 0;
    bottom: 0;
    height: 100%;
    opacity: 1;
    animation: forwards fadeInLeft 1s ease-in-out;
}

@keyframes fadeInLeft {
    from {
        opacity: 0;
        right: 5px;
    }

    to {
        opacity: 1;
        right: -35px;
    }
}

.sales-sec-2 .tabcontent {
    text-align: center;
}

.sales-sec-2 .tabcontent .title {
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    letter-spacing: 2px;
    color: #FFFFFF;
    padding-top: 20px;

}

.sales-sec-2 .tabcontent .description {
    font-weight: 500;
    font-size: 16px;
    line-height: 32px;
    color: #9C9C9C;
    padding: 20px 0px 40px;
}

.sales-sec-3 {
    background-color: black;
    padding: 50px 0px;
}

.sales-sec-3 .sec-3-content {
    text-align: center;
}

.sales-sec-3 .sec-3-content .heading {
    font-weight: 600;
    font-size: 40px;
    line-height: 50px;
    letter-spacing: 0.02em;
    color: #E4A400;
}

.sales-sec-3 .sec-3-content .sub-title {
    font-weight: 400;
    font-size: 18px;
    line-height: 32px;
    color: #9C9C9C;
    padding-bottom: 62px;
}

.sales-sec-3 .prcs-section {
    justify-content: space-between;
}

.sales-sec-3 .prcs-section .sales-process-card {
    text-align: center;
}

.sales-sec-3 .prcs-section .sales-process-card .title {
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    color: #FFFFFF;
    min-height: 80px;
    padding: 10px 0px 17px;
}

.sales-sec-3 .prcs-section .sales-process-card .description {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #9C9C9C;

}

.sales-sec-4 {
    padding: 80px 0px;
    background-image: url(https://d2e3cmtjkfw2a3.cloudfront.net/static/media/salesforce/sales-bg.png);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.sales-sec-4 .sales-sec-4-content {
    /* max-width: 500px; */
    padding-bottom: 40px;
}

.sales-sec-4 .sales-sec-4-content .title {
    font-weight: 600;
    font-size: 35px;
    line-height: 54px;
    letter-spacing: 2px;
    color: #fff;
}

.sales-sec-4 .sales-sec-4-card .sec-4-card {
    background: #000000;
    border: 1px solid #373737;
    border-radius: 20px;
    text-align: left;
    padding: 20px 40px 20px 50px;
    margin-bottom: 25px;
    position: relative;
    min-height: 115px;
    display: flex;
    align-items: center;
}

.sales-sec-4 .sales-sec-4-card .sec-4-card .sec-4-card-number {
    position: absolute;
    left: -2.5%;
    top: -30%;
    color: #444242;
    font-size: 120px;
    font-weight: 500;
}


.sales-sec-4 .sales-sec-4-card .sec-4-card:nth-child(2) {
    margin-top: 20px;
}

.sales-sec-4 .sales-sec-4-card .sec-4-card .title {
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    color: #FFFFFF;
    padding: 10px 0px;
}

.sales-sec-4 .sales-sec-4-card .sec-4-card .description {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #9C9C9C;
}

.sales-sec-5 {
    padding: 80px 0px;
    position: relative;
    z-index: 0;
    background-color: #101214 !important;
}

.sales-sec-5 .sales-padding-card {
    padding: 0px 80px;
}

.sales-sec-5 .sub-heading {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #9C9C9C;
    padding: 20px 0px;
}

.sales-sec-5 .experties-card {
    background: #000000;
    border: 1px solid #979797;
    border-radius: 20px;
    height: 121px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
}

.sales-sec-5 .experties-card .sub-title {
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    color: #FFFFFF;
    text-align: center;
}

.sales-dot-img {
    position: absolute;
    bottom: 5%;
    left: 5%;
}

.sales-sec-6 {
    padding: 80px 0px;
}

.sales-sec-6 .sales-sec-6-content {
    text-align: center;
    padding-bottom: 40px;
}

.sales-sec-6 .sales-sec-6-content .heading {
    font-weight: 600;
    font-size: 36px;
    line-height: 54px;
    letter-spacing: 0.02em;
    color: #FFFFFF;
    margin-bottom: 0;
}

.sales-sec-6 .sales-sec-6-content .sub-heading {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #9C9C9C;
    padding-bottom: 34px;
    padding-top: 15px;
}

.sales-industries {
    border: 1px solid #414141;
}

.sales-industries:nth-child(1),
:nth-child(2),
:nth-child(3),
:nth-child(4),
:nth-child(5) {
    border-top: none;
}

.sales-industries:nth-child(5),
:nth-child(10),
:nth-child(15) {
    border-right: none;
}

.sales-industries:nth-child(1),
:nth-child(6),
:nth-child(11) {
    border-left: none;
}

.sales-industries:nth-child(11),
:nth-child(12),
:nth-child(13),
:nth-child(14),
:nth-child(15) {
    border-bottom: none;
}

.sales-industries .sales-indus-list {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 40px;

}

.sales-industries .sales-indus-list .indus-list-title {
    font-weight: 500;
    font-size: 20px;
    line-height: 26px;
    text-align: center;
    color: #FFFFFF;
    padding-top: 15px;
    min-height: 68px;
    display: flex;
    align-items: center;
}

.sales-sec-7 {
    background-color: black;
    padding: 50px 0px;
}

.sales-sec-7 .sales-sec-7-content .heading {
    font-weight: 600;
    font-size: 31px;
    line-height: 54px;
    text-align: center;
    letter-spacing: 2px;
    color: #FFFFFF;
}

.sales-sec-7 .sales-sec-7-content .sub-heading {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #9C9C9C;
    text-align: left;
    padding-left: 10px;
}

.sales-sec-7 .needs-card-sec {
    display: flex;
    justify-content: center;
}

.sales-sec-7 .needs-card {
    background: #101214;
    border-radius: 8px;
    padding: 50px;
    text-align: center;
    margin-top: 35px;
    width: 100%;
}

.sales-sec-7 .needs-card .card-title {
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    color: #FFFFFF;
    padding-top: 10px;
    min-height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.sales-sec-choose {
    background-color: #101214;
    padding: 60px 0px;
}

.sales-sec-choose .choose-content {
    border: 2px solid #3E3E3E;
    padding: 50px;
    position: relative;

}

.sales-sec-choose .choose-content .title {
    position: absolute;
    top: -5%;
    background-color: #101214;
    font-size: 36px;
    line-height: 54px;
    letter-spacing: 2px;
    font-weight: 600;
    padding-right: 70px;
    padding-left: 20px;
    max-width: 600px;
}

.sales-sec-choose .choose-content .title span {
    color: #E4A400;
}

.sales-sec-choose .choose-content .description {
    color: #9C9C9C;
    font-size: 16px;
    font-weight: 500;
    line-height: 32px;
    padding-top: 140px;
}

.sales-sec-choose .choose-content .choose-number-wrap {
    display: flex;
    justify-content: space-around;
    padding: 40px 0px;
}

.sales-sec-choose .choose-content .choose-number-wrap .cards {
    text-align: center;
}

.sales-sec-choose .choose-content .choose-number-wrap .cards .number {
    color: #82BACE;
    font-size: 64px;
    font-weight: 500;
    line-height: 96px;
}

.sales-sec-choose .choose-content .choose-number-wrap .cards .sub-title {
    font-size: 24px;
    font-weight: 400;
    line-height: 33px;
    color: #FFFFFF;
}

.sales-cta-wrapper {
    background-color: black;
    padding: 40px 0px;
    position: relative;
}

.sales-cta-wrapper .sales-cta {
    text-align: center;
}

.sales-cta-wrapper .sales-cta .title {
    font-size: 36px;
    line-height: 54px;
    font-weight: 400;
    color: white;
    padding-bottom: 20px;
}

.sales-cta-wrapper .sales-cta .title span {
    font-weight: 600;
    color: #E4A400;
}

.sales-cta-wrapper .sales-cta .description {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: #9C9C9C;
}

.sales-cta-form {
    max-width: 1200px;
    margin: 0 auto;
}

.sales-cta-form #salesForm {
    display: flex;
    justify-content: space-between;
    padding-top: 40px;
}

.sales-cta-form .form-group input {
    width: 280px;
}

.sales-cta-form .form-group input::placeholder {
    color: white;
}

.sales-cta-form .form-group input {
    color: white;
    border: none;
    border-bottom: 1px solid #373737;
    background-color: transparent;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    width: 280px;
}

.sales-cta-form .form-group:focus-visible {
    border: none;
    border-bottom: 1px solid #373737;
}

.sales-cta-form .form-group input:focus-visible {
    border: none;
    border-bottom: 1px solid #373737;
}

.sales-cta-form #salesForm button {
    background-color: #E4A400;
    color: black;
    padding: 10px 50px;
    border-radius: 50px;
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    height: 60px;
}


/* salesforce sub pages */
.sales-sub-sec-1 {
    text-align: center;
    background-image: url(https://d2e3cmtjkfw2a3.cloudfront.net/static/media/salesforce/sales-sub/implementation-main.svg);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 160px 0px 20px !important;
    min-height: 100vh;
}

.sales-sub-sec-1 .sales-banner-logos {
    margin-top: 100px;
    position: absolute;
    bottom: 0;
    width: 100%;
}

.sales-sub-sec-1 .sales-sub-sec-1-content span.blr {
    width: 10px;
    height: 10px;
    display: inline-block;
    background: #775dca;
    border-radius: 15px;
}

.sales-sub-sec-1 .sales-sub-sec-1-content .main-title {
    font-weight: 500;
    font-size: 16px;
    line-height: 80px;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    color: #F9F9F9;
}

.sales-sub-sec-1 .sales-sub-sec-1-content .heading {
    font-weight: 600;
    font-size: 50px;
    line-height: 58px;
    margin: 10px 0px;
}

.sales-sub-sec-1 .sales-sub-sec-1-content .description {
    padding: 20px 0px 40px;
}

.sales-sub-sec-2 {
    padding: 100px 0px 50px !important;
    position: relative;
    z-index: 0;
}

.sales-sub-sec-2 .ylw-heading {
    background-color: #101214;
}

.sales-sub-sec-2 .sub-heading {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #9C9C9C;
    padding-left: 20px;
    padding-top: 20px;
}

.sales-sub-sec-2 .mb-service {
    text-align: center;
    background: #000000;
    border: 1px solid #979797;
    border-radius: 20px;
    padding: 0;
    height: 121px;
    width: 100%;
    margin-top: 40px;
}

.sales-sub-sec-2 .mb-service.active {
    border: 1px solid #E4A400;
}

.sales-sub-sec-2 .srvc-tab-link {
    padding: 0px 50px;
}

.sales-sub-sec-2 .gtabs {
    max-width: 1300px;
    margin-top: 75px;
}

.sales-sub-sec-2 .gtabs .title {
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    color: #FFFFFF;
}

.sales-sub-sec-2 .gtabs .description {
    font-weight: 500;
    font-size: 16px;
    line-height: 32px;
    color: #9C9C9C;
    padding-top: 18px;
}

.sales-sub-sec-2 .gtabs .description ul {
    padding-left: 20px;
}

.sales-sub-sec-2 .gtabs .description ul li {
    font-size: 16px;
    font-weight: 500;
    line-height: 32px;
    color: #9C9C9C;
    list-style: disc;
}

.sales-sub-sec-3 {
    background-color: #000000;
    padding: 80px 25px;
}

.sales-sub-sec-3 .sales-sub-sec-3-content {
    font-weight: 500;
    font-size: 36px;
    line-height: 50px;
    letter-spacing: 0.02em;
    color: #FFFFFF;
    text-align: center;
    padding-bottom: 60px;
}

.sales-sub-benefit {
    text-align: center;
}

.sales-sub-benefit .sales-sub-no {
    font-weight: 600;
    font-size: 60px;
    line-height: 90px;
    color: #FFFFFF;
}

.sales-sub-benefit .sale-sub-no-title {
    font-weight: 600;
    font-size: 21px;
    line-height: 33px;
    color: #82868C;
}

.sales-sub-sec-4 {
    padding: 80px 0px;
    background-image: url(https://d2e3cmtjkfw2a3.cloudfront.net/static/media/salesforce/sales-bg.png);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    z-index: 0;
}

.sales-sec-2.sales-sub-sec-4 .srvc-tab-link .right-arrow {
    content: '' !important;
    display: flex;
    align-items: center;
}

.sales-sec-2.sales-sub-sec-4 .srvc-tab-link .right-arrow img {
    display: none;
}

.sales-sec-2.sales-sub-sec-4 .mb-service.active .srvc-tab-link .right-arrow img {
    display: block;
}

.sales-sub-sec-4 .ylw-heading {
    background-color: #08090b;

}

.sales-sub-sec-4 .mb-service {
    border: 1px solid transparent;
    border-color: rgba(202, 224, 242, .4) !important;
    transition: all .6s ease-in;
    margin-top: 30px;
    border-radius: 8px;
}

.sales-sub-sec-4 .mb-service.active {
    background-color: black;
}

.sales-sub-sec-4 .tab {
    max-height: unset !important;
    overflow: hidden;
    width: 100%;
}

.sales-sub-sec-4 .tab .mb-service {
    width: 85%;
}

.salestab-content {
    background: #000000;
    border-radius: 8px;

}

.sales-sub-sec-4 .app-head {
    display: flex;
    align-items: center;
    height: 100%;
}

.salestabcontent {
    display: none;
}

.salestabcontent:nth-child(1) {
    display: block;
}

.sales-sub-sec-4 .salestab-content {
    text-align: left;
    padding: 40px 60px 40px 30px !important;
    max-width: 650px;
    min-height: 480px;
}

.sales-sub-sec-4 .salestab-content .description {
    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
    color: #9C9C9C;
}

.sales-sub-sec-4 .salestab-content .description a {
    color: #F1B308 !important;
}

.salestab-content .description .salestab-content-list {
    padding-left: 20px;
}

.salestab-content .description .salestab-content-list li {
    list-style: disc;
}

.sales-sub-sec-6 {
    padding: 50px 0px;
}

.sales-sub-sec-6 .sales-sub-srvc .title {
    font-weight: 600;
    font-size: 36px;
    line-height: 54px;
    letter-spacing: 0.02em;
    color: #FFFFFF;
    text-align: center;
}

.sales-sub-sec-6 .sales-sub-srvc .sub-title {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #9C9C9C;
    text-align: center;
    padding: 10px 0px;
}

.sales-sub-srvc-right {
    display: flex;
    align-items: center;
    justify-content: center;
}

.sales-sub-srvc-content {
    max-width: 500px;
}

.sales-sub-sec-6 .sales-sub-srvc-content .sales-sub-srvc-content-list li {
    list-style: disc !important;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    color: #D8D8D8;
    padding: 15px 0px;
}

.sales-sub-sec-7 {
    padding: 80px 0px;
    background: #000000;
    text-align: center;
}

.sales-sub-sec-7 .heading {
    font-weight: 600;
    font-size: 36px;
    line-height: 54px;
    letter-spacing: 0.02em;
    color: #FFFFFF;
}

.sales-sub-sec-7 .solution-card {
    padding: 40px 0px;
}

.sales-sub-sec-7 .solution-card .solution-title {
    font-weight: 500;
    font-size: 20px;
    line-height: 26px;
    padding-top: 10px;
}

@media (max-width: 1440px) {
    .padding-container-max {
        padding: 0 10%;
    }

    .sales-sub-sec-1 {
        padding: 100px 0px 80px !important;
        min-height: 100vh;
    }

    .sales-sub-sec-1 .sales-sub-sec-1-content .main-title {
        line-height: 40px;
    }

    .sales-sub-sec-1 .sales-sub-sec-1-content .heading {
        margin: 10px 0px;
    }

    .sales-sub-sec-1 .sales-sub-sec-1-content .description {
        padding: 10px 0px 20px;
    }

    .sales-sub-sec-2 .srvc-tab-link {
        padding: 0px 10px;
        font-size: 18px;
    }
}

@media (max-width: 1350px) {
    .sales-sec-2 .mb-service .srvc-tab-link {
        font-size: 16px;
    }

    .sales-sec-2 .mb-service {
        padding: 39px 50px 38px 34px;
    }
}

@media (max-width: 1200px) {
    .sales-sec-2 .mb-service {
        padding: 39px 50px 38px 10px;
    }
}


@media (max-width: 1050px) {
    .prcs-top {
        left: -9%;
        top: -54%;
    }

    .prcs-bottom {
        left: -9%;
    }

    .our-prcs .our-prcs-ani .our-prcs-box {
        margin: auto !important;
    }

    .prcs-content .dot {
        margin: 11px 0px;
    }

    .sales-sub-sec-2 .srvc-tab-link {
        padding: 0px 10px;
        font-size: 16px;
    }

    .sales-sec-2 .mb-service {
        padding: 39px 40px 38px 10px;

    }

    .sales-sec-2 .mb-service .srvc-tab-link {
        font-size: 14px;
    }

}

@media (max-width: 991px) {
    .dm-srvc-sec-5 .dm-srvc-card-box {
        margin-bottom: 50px;
    }

    .srvc-icon-mob-top img {
        opacity: 0 !important;
        top: 20% !important;
        right: 0 !important;
        position: absolute;
    }

    .srvc-icon-mob-down img {
        opacity: 0 !important;
        bottom: 0 !important;
        right: 0 !important;
        position: absolute;
        top: 80% !important;
    }

    /* .desktop_html .sub-services-top img {
        opacity: 1;
    } */
    .srvc-icon-mob {
        display: block;
    }

    .our-prcs .mobile_project {
        display: block;

    }

    .our-prcs .our-prcs-ani .our-prcs-box {
        display: none;
    }

    .our-prcs-box-wrapper {
        padding: 0;
    }

    .testimonial-card .testimonial-list .testimonial-wrapper .test-content {
        margin-top: 275px;
    }

    .sub-service-services .sr-box .sr-sub-head {
        align-items: start;
        padding-top: 15px;
    }

    .sub-service-services .sr-box .sr-sub-head .heading {
        font-size: 18px;
    }

    .sub-service-services .sr-box:hover .sr-description .description {
        font-size: 14px;
    }

    .testimonial-card .testimonial-list .testimonial-wrapper .test-content .test-client-img {
        margin-top: -47%;
    }

    .desktop_html .sub-services-top .salesforce-banner-img img {
        opacity: 1 !important;
    }

    .desktop_html .sub-services-top img {
        opacity: 1 !important;
    }

    .sales-sub-sec-1 {
        min-height: 80vh;
        padding: 180px 0px 80px !important;
    }

    .sales-sec-1 .slaes-header-content {
        padding-top: 100px;

    }

    .sales-sub-sec-2 .srvc-tab-link {
        font-size: 18px;
        padding: 0px 20px;
    }

    .sales-sec-3 .prcs-section .sales-process-card .description {
        min-height: 100px;
    }

    .sales-sec-4 .sales-sec-4-card .sec-4-card.mob-card-3 {
        margin-top: 20px;
    }

    .sales-sub-sec-1 {
        min-height: 95vh;
    }

    .sales-sub-sec-1 .sales-sub-sec-1-content {
        padding-top: 100px;
    }

    .desktop_html .sub-services-top .sales-banner-logos img {
        opacity: 1 !important;
    }

    .sales-sub-sec-2 .srvc-tab-link {
        padding: 0;
        font-size: 20px;
    }

    .sales-sub-benefit .sales-sub-no {
        font-size: 32px;
    }

    .sales-sec-5 .experties-card .sub-title {
        font-size: 18px;
    }

    .sales-sec-4 .sales-sec-4-card .sec-4-card {
        min-height: 170px;
    }

    .sales-sec-4 .sales-sec-4-card .sec-4-card .sec-4-card-number {
        left: -5%;
        top: 5%;
    }

    .sales-cta-form .form-group input {
        width: 150px;
    }

    .sales-cta-form #salesForm button {
        padding: 10px 35px;
    }

    .sales-sec-choose .choose-content .choose-number-wrap .cards .number {
        font-size: 38px;
    }

    .sales-sec-choose .choose-content .choose-number-wrap .cards .sub-title {
        font-size: 18px;
    }

    .sales-cta-wrapper {
        padding: 40px 0px 60px;
    }

    .sales-cta-wrapper .sales-dot-img {
        bottom: 3%;
    }

    .sales-sub-benefit .sales-sub-no {
        font-size: 40px;
    }
}

@media (max-width: 850px) {
    .testimonial-card .testimonial-list .testimonial-wrapper .test-content .test-client-img {
        margin-top: -50%;
    }

    .latest-industry-domain .carousel .slide_container .top-active {
        padding: 0% 6% 0% 10%;
    }

    .desktop_html .sub-services-top .salesforce-banner-img img {
        height: 100px;
    }
}

@media (max-width: 800px) {
    .testimonial-card .testimonial-list .testimonial-wrapper .test-content .test-client-img {
        margin-top: -55%;
    }

    .sales-sub-sec-1 {
        min-height: 95vh;
    }

    .sales-sub-sec-1 .sales-sub-sec-1-content {
        padding-top: 50px;
    }
}

@media (max-width: 768px) {

    .srvc-icon-mob-top img {
        opacity: 0 !important;
        top: 20% !important;
        right: 0 !important;
        position: absolute;
    }

    .srvc-icon-mob-down img {
        opacity: 0 !important;
        bottom: 0 !important;
        right: 0 !important;
        position: absolute;
        top: 80% !important;
    }

    .testimonial-card .testimonial-list .testimonial-wrapper .test-content {
        margin-top: 90px;
    }

    .our-prcs .mobile_project {
        display: block;
    }

    .our-prcs .our-prcs-ani .our-prcs-box {
        display: none;
    }

    .main-srvc-page .sub-service-services.software-development .sr-box:hover {
        max-height: unset !important;
        height: unset !important;
    }

    .srvc-icon-mob {
        display: block;
    }

    .sub-service-services .sr-box {
        overflow: hidden;
    }

    .sub-service-services .sr-box,
    .sub-service-services .sr-box .sr-sub-head {
        margin-top: 0px;
    }

    .custom-software-development-wrapper .active {
        max-height: max-content !important;
        height: max-content !important;
        transition: all 1s ease-in-out;

    }

    .custom-software-development-wrapper .sr-box:hover .sr-description {
        margin: 0px;
        overflow: hidden;
        opacity: 0 !important;
        height: 0 !important;
    }

    .custom-software-development-wrapper .sr-box.active .sr-description {
        margin: 15px;
        height: max-content !important;
        opacity: 1 !important;
    }

    .custom-software-development-wrapper .sr-box.active .description {
        opacity: 1 !important;
        height: max-content !important;

    }

    .custom-software-development-wrapper .sr-box.active .heading {
        color: #e4a400;
    }

    .domain-sec-5 .domain-sec-5-content .heading {
        font-size: 30px;
        line-height: 45px;
    }

    .dm-srvc-sec-5 .dm-srvc-content .main-heading {
        font-size: 26px;
        line-height: 39px;
    }

    .dm-srvc-sec-5 .dm-srvc-content .title {
        font-size: 20px;
        line-height: 33px;
    }

    .dm-srvc-sec-5 .card-title {
        padding-bottom: 30px;
    }

    .dm-srvc-sec-5 .dash-border::before {
        display: none;
    }

    .sales-sec-1 {
        padding: 100px 20px 50px !important;
    }

    .desktop_html .sub-services-top .salesforce-banner-img img {
        height: 80px;
    }

    .sales-sec-1 .sb_sr_top h5 {
        margin-bottom: 0px;
    }

    .sales-sec-2 .tab {
        max-height: 500px;
        margin: auto;
    }

    .sales-sec-2 .mb-service {
        padding: 33px 50px 35px 15px;
        width: 95%;
        text-align: left;
    }

    .sales-sec-2 .tab-wrapper .tab-content img {
        height: 200px;
    }

    /* .sales-sec-2 button.active .right-arrow {
        right: -50px;
    } */

    .sales-sec-2 .sub-sr-tab-icon {
        height: unset;
        display: none;
    }

    .sales-sec-2 .tab-wrapper .tab-content {
        padding-top: 10px;
        text-align: center !important;
    }

    .sales-sec-2 .tab li {
        text-align: center;
    }

    .sales-sec-2 .tab-content .description {
        padding: 15px 0px;
        font-size: 12px;
        line-height: 22px
    }

    .block_chain.sales-sec-2 .sec-3_mid_text .sec-3-h2 {
        margin-top: -30px;
    }

    .sales-sec-2 .mb-service .srvc-tab-link {
        font-size: 15px;
        line-height: 28px;
    }

    .sales-sec-3 {
        padding: 50px 0px;
    }

    .sales-sec-3 .sec-3-content .heading {
        font-size: 24px;
        line-height: 40px;
        margin-bottom: 10px !important;
    }

    .sales-sec-3 .sec-3-content .sub-title {
        font-size: 14px;
        line-height: 22px;
    }

    .sales-sec-3 .prcs-section .sales-process-card .description {
        font-size: 11px;
        line-height: 16px;
        padding-bottom: 20px;
    }

    .sales-sec-3 .prcs-section .sales-process-card .title {
        min-height: 50px;
    }

    .sales-sec-4 {
        padding: 50px 0px;
    }

    .sales-industries .sales-indus-list {
        padding: 40px 10px;
    }

    .sales-sec-4 .sales-sec-4-content .title {
        font-size: 24px;
        text-align: center;
        line-height: 40px;
    }

    .sales-sec-4 .sales-sec-4-content .description {
        font-size: 14px;
        line-height: 22px;
        text-align: center;
        padding: 10px 0px 20px;
    }

    .sales-sec-4 .sales-sec-4-card .sec-4-card .title {
        font-size: 18px;
        line-height: 25px;
    }

    .sales-sec-4 .sales-sec-4-card .sec-4-card.mob-card-3 {
        margin-top: 20px;
    }

    .sales-sec-4 .sales-sec-4-card .sec-4-card .description {
        font-size: 12px;
        line-height: 18px;
    }

    .sales-sec-5 {
        padding: 50px 0px 80px;
    }

    .sales-sec-5 .experties-card {
        height: 80px;
    }

    .sales-sec-5 .experties-card .sub-title {
        font-size: 16px;
        line-height: 24px;
    }

    .sales-sec-5 .sub-heading {
        text-align: center;
    }

    .sales-sec-5 .sales-dot-img {
        bottom: 2%;
    }

    .sales-sec-6 {
        padding: 50px 0px;
    }

    .sales-sec-6 .sales-sec-6-content .heading {
        font-size: 24px;
    }

    .sales-industries .sales-indus-list .indus-list-title {
        font-size: 16px;
        line-height: 20px;
    }

    .sales-industries {
        border: 1px solid #414141;
    }

    .sales-industries:nth-child(1),
    :nth-child(2),
    :nth-child(3) {
        border-top: none;
    }

    .sales-industries:nth-child(3),
    :nth-child(6),
    :nth-child(9),
    :nth-child(12),
    :nth-child(15) {
        border-right: none;
    }

    .sales-industries:nth-child(1),
    :nth-child(4),
    :nth-child(7),
    :nth-child(10),
    :nth-child(13) {
        border-left: none;
    }

    .sales-industries:nth-child(13),
    :nth-child(14),
    :nth-child(15) {
        border-bottom: none;
    }

    .sales-sec-7 {
        padding: 50px 0;
    }

    .sales-sec-7 .sales-sec-7-content .heading {
        font-size: 24px;
        text-align: center;
        line-height: 40px;
        margin-bottom: 10px !important;
    }

    .sales-sec-7 .sales-sec-7-content .sub-heading {
        font-size: 14px;
        line-height: 22px;
        text-align: center;
    }

    .sales-sec-7 .needs-card {
        padding: 20px;
    }

    .sales-sec-7 .needs-card .card-title {
        font-size: 16px;
        line-height: 23px;
    }

    .sales-sub-sec-1 .sales-sub-sec-1-content .heading {
        font-size: 40px;
        line-height: 45px;
    }

    .ylw-heading {
        text-align: center;
        max-width: 100%;
        font-size: 24px !important;
        line-height: 40px !important;
    }

    .sales-sub-sec-2 .sub-heading {
        padding-top: 20px;
    }

    .sales-sub-sec-2 .tab-content {
        text-align: center;
    }

    .sales-sub-sec-2 .tab-content .title {
        display: none;
    }

    .sales-sub-sec-2 .tab-content img {
        height: 200px;
        padding: 20px;
    }

    .sales-sub-sec-2 .tab-content .description {
        font-size: 12px;
        line-height: 22px;
        padding-top: 10px;
    }

    .sales-sub-sec-2 .gtabs {
        display: none;
    }

    .sales-sub-sec-3 .sales-sub-sec-3-content {
        font-size: 28px;
        line-height: 40px;
    }

    .sales-sub-sec-4 .tab .mb-service {
        width: 100%;
    }

    .sales-sub-sec-4 .salestab-content {
        padding: 40px 20px !important;
    }

    /* .sales-sec-2.sales-sub-sec-4 button.active .right-arrow{
        right: -20% !important;
    } */
    .sales-sub-benefit .sales-sub-no {
        font-size: 30px;
        line-height: 80px;
    }

    .sales-sub-benefit .sale-sub-no-title {
        font-size: 19px;
        line-height: 25px;
    }

    .sales-sub-sec-6 .sales-sub-srvc .title {
        font-size: 25px;
        line-height: 35px;
    }

    .sales-sub-sec-6 .sales-sub-srvc-content .sales-sub-srvc-content-list {
        padding-left: 25px;
    }

    .sales-sub-sec-7 .heading {
        font-size: 25px;
        line-height: 40px;
    }

    .sales-sec-2 .tab-wrapper .tab-content .title {
        display: none;
    }

    .sales-sec-4 .sales-sec-4-content {
        margin: 0 auto;
        max-width: 750px;
    }

    .sales-cta-form #salesForm {
        flex-direction: column;
    }

    .sales-cta-form #salesForm .form-group {
        margin-bottom: 20px;
    }

    .sales-cta-form .form-group input {
        width: 100%;
    }

    .sales-cta-form #salesForm button {
        width: 150px;
        margin: 0 auto;
        padding: 10px 20px;
    }

    .sales-cta-wrapper .sales-cta .title {
        font-size: 32px;
        line-height: 48px;
    }

    .sales-sec-choose .choose-content .title {
        max-width: 550px;
    }
}



@media (max-width: 650px) {
    .testimonial-card .testimonial-list .testimonial-wrapper .test-content .test-client-img {
        margin-top: -38%;
    }

    .sales-sec-choose .choose-content .title {
        width: 450px;
    }

    .sales-sec-choose .choose-content .description {
        padding-top: 200px;
    }
}

@media (max-width: 600px) {
    .testimonial-card .testimonial-list .testimonial-wrapper .test-content .test-client-img {
        margin-top: -52%;
    }

    .sales-sec-2 .tab-wrapper .tab-content .title {
        display: none !important;
    }

    .sales-sec-choose .choose-content .title {
        font-size: 24px;
        padding-right: 10px;
        line-height: 44px;
    }

    .sales-sec-choose .choose-content .description {
        padding-top: 120px;
    }

    .sales-sec-choose .choose-content .choose-number-wrap {
        padding: 20px 0px;
    }

    .sales-sec-choose .choose-content .choose-number-wrap .cards .number {
        font-size: 28px;
    }

    .sales-sec-choose .choose-content .choose-number-wrap .cards .sub-title {
        font-size: 14px;
    }

    .sales-sec-choose .choose-content .description {
        padding-top: 80px;
    }

    .sales-sec-choose .choose-content .title {
        width: 400px;
    }

    .sales-sec-4 .sales-sec-4-card .sec-4-card .sec-4-card-number {
        font-size: 100px;
        left: -8%;
    }
}

@media (max-width: 500px) {
    .testimonial-card .testimonial-list .testimonial-wrapper .test-content .test-client-img {
        margin-top: -44%;
    }

    .desktop_html .sub-services-top .salesforce-banner-img img {
        height: 70px;
    }

    .sales-sec-choose .choose-content .title {
        width: 340px;
    }

    .sales-sec-choose .choose-content .description {
        padding-top: 130px;
    }
}


@media (max-width: 450px) {
    .our-prcs .mobile_project {
        display: block;

    }


    .srvc-img-dsktp {
        display: none;
    }

    .srvc-icon-mob {
        display: block;
    }

    .desktop_html .srvc-btn-main .btn.btn-primary {
        letter-spacing: 1.2px;
        padding: 10px 12px !important;
        font-size: 12px;
    }

    .desktop_html.main-srvc-page .btn.btn-primary.btn-white {
        margin-left: 10px;
    }

    .desktop_html .sub-services-top img {
        opacity: 0 !important;
    }

    .desktop_html .sub-services-top .srvc-icon-mob-top img {
        opacity: 1 !important;
        top: 20% !important;
        right: 0 !important;
        position: absolute;
    }

    .desktop_html .sub-services-top .srvc-icon-mob-down img {
        opacity: 1 !important;
        bottom: 0 !important;
        right: 0 !important;
        position: absolute;
        top: 80% !important;
    }

    .latest-industry-domain .indus-description {
        font-size: 21px;
        line-height: 33px;
    }

    .latest-industry-domain .carousel .slide_container {
        height: 400px !important;
    }

    .latest-industry-domain .cta-more-box.arrow.slide-left {
        left: 15px;
        bottom: -12%;
        top: unset;
    }

    .latest-industry-domain .cta-more-box.arrow.slide-right {
        left: 75%;
        bottom: -12%;
        top: unset;

    }

    .testimonial-card .testimonial-card-slider .slide {
        height: 580px;
    }

    .testimonial-card ul li {
        margin-top: 70px;
    }

    .testimonial-card .slick-current.slick-active.slick-center .testimonial-list .testimonial-wrapper {
        width: calc(100vw - 60px);
        height: 100%;
        padding: 30px 0px;
    }

    .testimonial-card .testimonial-list .testimonial-wrapper .test-content .test-client-img {
        margin-top: -60%;
    }

    .testimonial-card .testimonial-list .testimonial-wrapper .test-content .client-project-description {
        font-size: 15px;
        line-height: 24px;
    }

    .testimonial-card .testimonial-list .testimonial-wrapper {
        margin-top: 70px;
    }


    .our-prcs .our-prcs-ani .our-prcs-box {
        display: none;
    }


    .our-prcs .our-prcs-ani .our-prcs-heading {
        font-weight: 600;
        font-size: 30px;
        line-height: 48px;
        text-align: left;
    }

    .our-prcs .our-prcs-ani .our-prcs-heading.small-title {
        font-size: 24px;
    }

    .our-prcs .our-prcs-ani .our-prcs-description {
        font-weight: 400;
        font-size: 16px;
        line-height: 28px;
        color: #F9F9F9;
        opacity: 0.9;
        text-align: left;
    }

    .prcs-content .icon img {
        padding: 20px;
    }

    .main-srvc-page .sr-cta .heading {
        font-weight: 600;
        font-size: 30px;
        line-height: 26px;
        letter-spacing: 0.02em;
        padding: 20px 0px;
    }

    .main-srvc-page .sr-cta p {
        font-weight: 500;
        font-size: 15px;
        line-height: 20px;
    }

    .our-prcs-box-wrapper {
        padding: 0;
    }

    .our-prcs .our-prcs-ani {
        margin-bottom: 0;
    }

    .main-srvc-page .srvc-card-box {
        margin: 15px 0px;
    }

    .main-srvc-page.sales-force .btn_line.b {
        padding: 13px 20px 13px !important;
    }

    .desktop_html.main-srvc-page .sb_sr_top .heading {
        font-size: 32px;
    }

    .sales-sub-sec-1 .sales-sub-sec-1-content {
        padding-top: 20px;
    }

    .sales-sub-sec-1 .sales-sub-sec-1-content .heading {
        font-size: 36px;
    }

    .sales-sec-choose .choose-content .title {
        width: 280px;
    }
}

@media (max-width: 400px) {
    .testimonial-card .testimonial-list .testimonial-wrapper .test-content .test-client-img {
        margin-top: -68%;
    }

}

@media (max-width: 350px) {
    .testimonial-card .testimonial-list .testimonial-wrapper .test-content .test-client-img {
        margin-top: -68%;
    }

    .desktop_html .sub-services-top .salesforce-banner-img img {
        height: 60px;
    }
}

@media (max-width: 300px) {
    .latest-industry-domain .indus-description {
        font-size: 18px;
        line-height: 33px;
    }

}