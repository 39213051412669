.indus-sec1 {
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  margin-top: 80px;
  overflow: hidden;
}

.head-img-wrapper-indus-1 {
  position: relative;
  height: 60vh;
}

.head-img-wrapper-indus-1 img {
  width: 100%;
}

.head-img-wrapper-indus-1:hover img {
  transform: scale(1.4);
  transition: all 1s ease-in-out;

}

.indus-sec1 .heading-text {
  position: absolute;
  top: 25%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.indus-sec1 .heading-text .heading {
  font-weight: 500;
  font-size: 50px;
  line-height: 75px;
  color: #FFFFFF;
}

.indus-sec1 .heading-text .description {
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #ABABAB;
  margin-top: 10px;
}

.indus-section-1-block {
  position: relative;
  padding: 10px;
  overflow: hidden;
  height: 150px;
}

.indus-section-1 img {
  margin-top: 88px;
}

.overlapSlideText {
  position: relative;
  width: 100%;
}

.overlapSlideText .shape {
  width: 100%;
  height: 0;
  border-bottom: 22vh solid black;
  border-right: 355vh solid transparent;
}

.overlapSlideText .shape-content {
  background-color: black;
  margin-top: -5em;
  border-radius: 0 30% 0 0;
}


.industrial-sec {
  background-color: #000000;
  padding-top: 20px;
}

.indus-sec3 .sec3-head {
  font-weight: 600;
  font-size: 20px;
  color: #979797;
  min-height: 40px;
}

.indus-sec3 .sec3-para {
  font-weight: 200;
  margin-top: 8px;
  font-size: 13px;
  color: #E9E9E9;
}

.indus-sec3 .mb-service {
  border: none;
  background: #000000;
  padding-left: 20px;
}

.indus-section-2-content {
  position: relative;
}

.indus-section-2-title {
  position: absolute;
  top: 20%;
}

.indus-section-2-title .heading {
  font-weight: 500;
  font-size: 36px;
  line-height: 54px;
  color: #e4a400;
}

.indus-section-2-title .description {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #ababab;
  margin-top: 20px;
}

.head-ind-sec2 {
  text-transform: capitalize !important;
  letter-spacing: normal !important;
  font-size: 36px;
}

.overlapSlideText .description {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #ABABAB;
}

/*btn animation */
.indus-sec3 .tab button {
  background-color: black;
  border: 0;
  box-sizing: border-box;
  vertical-align: middle;
  position: relative;
  border-radius: 10px;
  min-height: 140px;
}

.indus-sec3 .tab button.left-box {
  padding: 20px 45px 20px 30px;
  text-align: left;
}

.indus-sec3 .tab button.right-box {
  text-align: right;
  padding: 20px 45px 20px 60px;
}

.indus-sec3 .tab button::before {
  box-sizing: inherit;
  content: '';
  position: absolute;
  opacity: 0;
  z-index: -1;
  transition: all 0.4s ease-in;
}

.indus-sec3 .tab button {
  border: 1px solid black;
}

.indus-sec3 .tab button.active {
  border-color: rgba(153, 197, 234, 0.5) !important;

}

.domain-sec-6 .domain-content .tab button.active {
  border-color: transparent !important;

}

.indus-sec3 .tabcontent {
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 100%;
  animation: tabopcity 1s ease-in forwards;
}

@keyframes tabopcity {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.indus-sec3 .sub-sr-tab-icon {
  height: auto;
}

.indus-sec3 .sec3-para {
  position: relative;
  min-height: 60px;
}

.indus-sec3 .sec3-para .right-arrow {
  position: absolute;
  content: url(https://d2e3cmtjkfw2a3.cloudfront.net/static/media/industries/right-arrow.svg);
  display: none;
  left: unset;
}

.indus-sec3 button.active .sec3-para .right-arrow {
  display: block;
  right: -35px;
  top: 0;
  animation: fadeInLeft 1s ease-in-out;
}


@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translateX(300px);
  }

  to {
    opacity: 1;
  }
}


.indus-sec3 .sec3-para .left-arrow {
  position: absolute;
  content: url(https://d2e3cmtjkfw2a3.cloudfront.net/static/media/industries/left-arrow.svg);
  display: none;
  left: unset;
}

.indus-sec3 button.active .sec3-para .left-arrow {
  display: block;
  left: -45px;
  top: 0;
  animation: fadeInRight 1s ease-in-out;
}


@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translateX(300px);
  }

  to {
    opacity: 1;
  }
}


/*btn animation */

.indus-sec3 .tab button.active {

  color: white !important;

}

.indus-sec3 .tab button.active .sec3-head {
  color: white;
}

.indus-sec3 .tab button.active .sec3-para {
  color: white;

}

.cta-box .cta-content-col .cta-heading {
  font-weight: 400;
  font-size: 34px;
  line-height: 48px;
  color: #E9E9E9;
  margin-bottom: 30px;
}

.industrial-sec .carousel-control-next {
  right: 0;
  left: 66%;
  top: 70%;
  width: max-content;
}

.industrial-sec .carousel-control-prev {
  left: unset;
  right: 46%;
  top: 70%;
  width: max-content;
}

.industrial-sec .carousel-indicators {
  margin-left: 55%;
  width: min-content;
  top: 83%;
}

.industrial-sec .our_lp h5 {
  font-size: 20px;
  color: #e4a400;
  font-family: "Montserrat", sans-serif;
  font-display: swap;
  margin-bottom: 30px;
  margin-top: 50px;
}

.industrial-sec .protect_img {
  width: -webkit-fill-available;
}

.industrial-sec .our_lp .prj_head {
  font-size: 50px;
  margin-bottom: 30px;
  font-weight: 500;
}

.industrial-sec .our_lp p {
  font-size: 16px;
  margin-bottom: 60px;
}

.industrial-sec .our_lp {
  padding: 35px;
}

.industrial-section .heading {
  font-size: 36px;
}

.industrial-section .description {
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  color: #ababab;
  text-align: left;
}

.indus-work {
  display: flex;
  justify-content: center;
  align-items: center;
}

.indus-work .heading {
  font-weight: 500;
  font-size: 36px;
  line-height: 54px;
  color: #ffffff;
  padding-bottom: 30px;
}

.indus-work .description {
  font-weight: 400;
  font-size: 14px;
  line-height: 28px;
  color: #ababab;
  padding-bottom: 30px;
}

.sec-indus-img .img2 {
  border-radius: 0% !important;
  position: relative;
  right: 11%;
  height: 13.5em;
  margin-top: 1em;
  width: 19em;
}

.sec-indus-img .img1 {
  border-radius: 0% !important;
  height: 16em;
  width: -webkit-fill-available;
}

.sec-indus-img {
  margin-bottom: 75px;
}

.cta-box {
  overflow: hidden;

}

.cta-box .cta-arrow {
  display: flex;
  justify-content: end;
  align-items: flex-end;
  margin-right: -27%;
}



/*sub domain page */


.domain-sec-1 .domain-sec-1-des {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: calc(100% - 90px);
  position: relative;
  opacity: 0.7;
}

.domain-sec-1 .sub-services-top {
  margin-top: 80px;
}

.domain-sec-1 .domain-sec-1-des .sb_sr_top {
  max-width: 600px;
}

.domain-sec-1 .domain-sec-1-des .sb_sr_top::before {
  position: absolute;
  content: '';
  border: 2px dashed #e4a400;
  height: 300px;
  width: 200px;
  left: -11%;
}

.domain-sec-1 .domain-sec-1-des .col-md-7 {
  margin-bottom: 29px;
  padding-top: 29px;
}

.domain-sec-1 .domain-sec-1-des .heading {
  font-size: 45px;
  color: #f9f9f9;
  font-weight: 600;
  line-height: 50px;
  margin-bottom: 20px;
  text-align: left;
}

.domain-sec-1 .domain-sec-1-des .description {
  font-size: 18px;
  line-height: 30px;
  margin-bottom: 30px;
  color: #E9E9E9;
}

.domain-sec-1 .header-img .head-img-1 {
  width: 100%;
  height: 50%;
  z-index: 3;
  position: relative;
  top: 10%;
  left: 20%;
}

.domain-sec-1 .header-img .head-img-1 img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.domain-sec-1 .header-img .head-img-1::before {
  width: 75%;
  height: 95%;
  position: absolute;
  content: '';
  border: 3px dashed #e4a400;
  margin-left: 0%;
  margin-top: 30px;
  z-index: -1;
}

.domain-sec-1 .header-img .head-img-2 {
  width: 100%;
  height: 40%;
  z-index: 1;
  position: relative;
  left: -10%;
}

.domain-sec-1 .header-img .head-img-2 img {
  height: 100%;
}

.domain-sec-1 .header-img .head-img-2::before {
  width: 65%;
  height: 90%;
  position: absolute;
  content: '';
  border: 3px dashed #e4a400;
  margin-left: -17px;
  margin-top: -20px;
  z-index: -1;
}

.domain-sec-1 .head-content {
  padding-left: 30px;
  margin-top: 40px;
}

.domain-sec-1 .head-content .title {
  font-weight: 500;
  font-size: 25px;
  line-height: 35px;
  color: #E4A400;
}

.domain-sec-1 .head-content .description {
  font-weight: 500;
  font-size: 15px;
  line-height: 25px;
  color: #E9E9E9;
}

.domain-sec-2 .domain-sec-2-box {
  background-color: black;
}

.domain-sec-2 .domain-sec-2-box .domain-sec-2-content .heading {
  font-weight: 500;
  font-size: 32px;
  line-height: 54px;
  color: #E4A400;
}

.domain-sec-2 .domain-sec-2-box .domain-sec-2-content .description {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  padding-top: 20px;
  color: #ABABAB;
}

.domain-sec .app-head .heading {
  font-weight: 500;
  font-size: 36px;
  line-height: 54px;
}

.domain-sec .app-head .heading span {
  color: #e4a400;
}

.domain-sec .app-head .description {
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  color: #ABABAB;
}

.domain-sec-3 .why-content .why-icon-title {
  margin: auto;
  padding-top: 10px;
}

.domain-sec-4 .sr-box:hover {
  height: 270px;
}

.domain-sec-5 {
  position: relative;
  background-color: black;
}

.domain-sec-5 .domain-circle {
  height: 500px;
  width: 500px;
  position: absolute;
  border: 1px solid rgba(109, 108, 108, 0.6);
  background-color: transparent;
  border-radius: 50%;
  left: -18%;
  top: 15%;
  z-index: -1;
}

.domain-sec-5 .domain-sec-5-content {
  max-width: 450px;
}

.domain-sec-5 .domain-sec-5-content .heading {
  font-weight: 600;
  font-size: 36px;
  line-height: 50px;
  color: #FFFFFF;

}

.domain-sec-5 .domain-sec-5-content .heading span {
  color: #E4A400;
}

.domain-sec-5 .domain-sec-5-content .description {
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  color: #CFCFCF;
  padding-top: 20px;
}

.domain-sec-5 .domain-sec-5-feature .feature-block .feature-content {
  padding-left: 40px;
}

.domain-sec-5 .domain-sec-5-feature .feature-block .feature-content .heading {
  font-weight: 500;
  font-size: 30px;
  line-height: 45px;
  color: #E4A400;
}

.domain-sec-5 .domain-sec-5-feature .feature-block .feature-content .description {
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
  color: #9C9C9C;
}

.domain-sec-6 .domain-content {
  overflow: hidden;
  min-height: 450px;
}

.domain-sec-6.indus-sec3 .tab button {
  background-color: transparent;
  border: none;
  padding: 20px 0px 5px;
}

.domain-sec-6.indus-sec3 .tab button {
  min-height: max-content;
}

.domain-sec-6.indus-sec3 .tab button .sec3-head {
  font-weight: 300;
  font-size: 20px;
  line-height: 24px;
  color: #9C9C9C;
  position: relative;
}

.domain-sec-6.indus-sec3 .tab button .sec3-head.line-left::after {
  position: absolute;
  content: url(https://d2e3cmtjkfw2a3.cloudfront.net/static/media/industries/domain/line.svg);
  display: none;
  top: -10%;
  left: unset;

}

.domain-sec-6.indus-sec3 .tab button.active .sec3-head.line-left::after {
  display: block;
  animation: fadeLeft 1s ease-in-out;
  right: -110px;
}

@keyframes fadeLeft {
  from {
    opacity: 0;
    transform: translateX(-300px);
  }

  to {
    opacity: 1;
  }
}



.domain-sec-6.indus-sec3 .tab button .sec3-head.line-right::after {
  position: absolute;
  content: url(https://d2e3cmtjkfw2a3.cloudfront.net/static/media/industries/domain/line.svg);
  display: none;
  top: -15%;
  left: unset;
}

.domain-sec-6.indus-sec3 .tab button.active .sec3-head.line-right::after {
  display: block;
  animation: fadeRight 1s ease-in-out;
  left: -110px;

}

@keyframes fadeRight {
  from {
    opacity: 0;
    transform: translateX(300px);
  }

  to {
    opacity: 1;
  }
}

.domain-sec-6.indus-sec3 .tab button.active .sec3-head {
  color: white;
}

.domain-sec-6.indus-sec3 .app-head .right-content {
  text-align: right;
}

.domain-sec-6.indus-sec3 .domain-title .heading {
  font-weight: 500;
  font-size: 36px;
  line-height: 143.5%;
  color: #E4A400;
}

.domain-sec-6.indus-sec3 .domain-title .description {
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
  color: #9C9C9C;
  margin-top: 15px;
}

.domain-type-game-sec {
  padding: 100px 0px;
}

.domain-type-game-sec .type-content .title {
  font-weight: 500;
  font-size: 36px;
  line-height: 54px;
  color: #FFFFFF;
}

.domain-type-game-sec .type-content .title span {
  color: #E4A400;
}

.domain-type-game-sec .type-content .description {
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  color: #ABABAB;
  padding: 30px 0px;
}

.domain-type-game-sec .type-card-wrap {
  background: linear-gradient(180deg, #282828 0%, rgba(40, 40, 40, 0) 100%);
  border: 1px solid #282828;
  border-radius: 12px;
}

.domain-type-game-sec .type-card-wrap .type-card:hover .type-card-content .card-number {
  background: -webkit-linear-gradient(-86deg, #fab802 0%, rgba(241, 179, 8, 0) 100%);
  -webkit-background-clip: text;
  -webkit-text-stroke: 2px transparent;
  transition: all 1s ease-in-out;
}

.domain-type-game-sec .type-card-wrap .type-card:hover .type-card-content .card-title {
  color: #E4A400;
  transition: all 1s ease-in-out;
}

.domain-type-game-sec .type-card-wrap .type-card .type-card-content {
  display: flex;
  align-items: center;
  justify-content: start;
  padding: 30px 10px;
}

.domain-type-game-sec .type-card-wrap .type-card .type-card-content .card-number {
  background: -webkit-linear-gradient(-86deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%);
  -webkit-background-clip: text;
  -webkit-text-stroke: 1px transparent;
  color: #232d2d;
  font-weight: 500;
  font-size: 96px;
  line-height: 96px;
  padding: 0px 20px;
  transition: all 1s ease-in-out;
}

.domain-type-game-sec .type-card-wrap .type-card .type-card-content .card-title {
  font-weight: 600;
  font-size: 22px;
  line-height: 36px;
  color: #FFFFFF;
  padding-left: 15px;
  transition: all 1s ease-in-out;
}

.game-feature {
  padding: 100px 0px;
}

.game-feature .feature-content .title {
  font-weight: 500;
  font-size: 36px;
  line-height: 54px;
  color: #FFFFFF;
}

.game-feature .feature-content .title span {
  color: #E4A400;
}

.game-feature .feature-content .description {
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  color: #ABABAB;
  padding: 20px 0px;
}

.game-feature .feature-list-wrap .feature-list ul li {
  font-weight: 500;
  font-size: 22px;
  line-height: 32px;
  color: #FFFFFF;
  padding: 10px 0px;
  filter: brightness(100%) contrast(100%) saturate(0%) blur(0px) hue-rotate(0deg);
  transition-duration: 1s;
}

.game-feature .feature-list-wrap .feature-list ul li:hover {
  filter: brightness(100%) contrast(100%) saturate(100%) blur(0px) hue-rotate(0deg);
  transition-duration: 1s;
  color: #E4A400;
}


/* neha */

@media (max-width: 1440px) {
  .domain-sec-1 .header-img .head-img-1::before {
    height: 89%;
  }


  .overlapSlideText .shape-content {
    border-radius: 0 36% 0 0;
  }

  .overlapSlideText .shape {
    border-bottom: 22vh solid black;
    border-right: 390vh solid transparent;
  }

  .domain-sec-1 .domain-sec-1-des .sb_sr_top::before {
    left: -12%;
  }
}

@media (max-width: 1300px) {
  .overlapSlideText:before {
    height: 7em;
    top: -7%;
  }

  .sec-indus-img {
    padding: 10px !important;
  }

  .sec-indus-img .img2 {
    width: 15em;
  }

  .indus-sec3 .mb-service {
    padding: 15px;
    padding-left: 30px !important;
  }

  .indus-sec3 .sec3-head {
    font-size: 16px;
  }

  .indus-sec3 .sec3-para {
    font-size: 12px;
  }

  .indus-sec1-block:after {
    top: 83%;
  }

  .industrial-sec .our_lp h5 {
    margin-bottom: 30px;
    margin-top: 0;
  }

  .industrial-section .container h2 {
    font-size: 20px;
  }

  .industrial-sec .our_lp .prj_head {
    margin-bottom: 30px;
  }

  .industrial-sec .carousel-control-next {
    left: 72%;
    top: 90%;
  }

  .industrial-sec .carousel-control-prev {
    right: 44%;
    top: 90%;
  }

  .industrial-sec .carousel-indicators {
    margin-left: 57%;
  }

  .industrial-sec .our_lp p {
    margin-bottom: 30px;
  }
}

@media (max-width: 1200px) {
  .animateUpImg {
    margin-top: 7rem;
  }
}

@media (max-width: 1050px) {
  .head-img-wrapper-indus-1 {
    height: 100vh;
  }

  .indus-sec1 .heading-text {
    top: 10%;
  }

  .animateUpImg {
    margin-top: 0px;
  }

  .indus-section-2 {
    margin-top: 10px;
  }

  .indus-section-2-title {
    top: -20%;
    position: relative;
  }

  .overlapSlideText .shape-content {
    border-radius: 0 36% 0 0;
  }

  .overlapSlideText .shape {
    border-bottom: 22vh solid black;
    border-right: 163vh solid transparent;
  }

  .domain-sec-1 .header-img .head-img-1::before {
    width: 80%;
    height: 75%;
    margin-left: -20px;
  }

  .domain-sec-1 .header-img .head-img-2::before {
    width: 95%;
    height: 100%;
    margin-left: -17px;
  }

  .domain-sec-1 .domain-sec-1-des .sb_sr_top::before {
    left: -17%;
  }

}

@media (max-width: 767px) {

  .head-img-wrapper-indus-1 {
    height: 50vh;
  }

  .indus-sec1 .heading-text {
    top: 8%;
  }

  .indus-sec1 .heading-text .heading {
    font-size: 30px;
  }

  .indus-sec1 .heading-text .description {
    font-size: 16px;
  }

  .head-img-wrapper-indus-1 img {
    width: 100%;
    height: 100%;
  }

  .overlapSlideText .shape {
    display: none;
  }

  .overlapSlideText .shape-content {
    padding-top: 20px;
  }

  .indus-section-1 img {
    margin-top: 64px;
  }

  .overlapSlideText {
    background-color: black;
    position: relative;
    top: 40px;
    padding: 0px;
    margin-bottom: 50px;
  }

  .overlapSlideText .our-work .our-work-border-heading {
    font-size: 25px;
    margin-top: 20px;
  }

  .indus-section-2 {
    margin-top: 0px;
  }

  .indus-section-2-title .heading {
    font-size: 25px;
    line-height: normal;
    margin-top: 20px;
  }


  .animateUpImg img {
    width: 100%;
  }

  .sec-indus-img .img2 {
    width: 20em;
  }

  .indus-sec3 {
    margin-top: 50px;
  }

  .indus-sec3 .tab button.active::before {
    height: 103%;
  }

  .indus-sec3 .mb-service {
    padding-left: 30px !important;
  }

  .indus-sec3 .domain-content {
    display: none;
  }

  .domain-mobile img {
    opacity: 0.3;
    border-radius: 20px;
  }

  .domain-mobile .domain-title {
    top: 10%;
    text-align: center;
    margin: 20px 30px;
  }

  .domain-mobile .domain-title img {
    margin-top: 20px;
    opacity: 1;
    height: 100px;
  }

  .domain-mobile .domain-title .heading {
    font-weight: 600;
    font-size: 35px;
    line-height: 36px;
    min-height: 60px;
  }

  .mobile_html.domain-mobile .gallery .owl-nav {
    display: none !important;
  }

  .domain-mobile .domain-title .description {
    margin-top: 30px;
    font-weight: 300;
    font-size: 20px;
    line-height: 35px;
    min-height: 140px;
  }

  .game-feature .feature-list-wrap .feature-list ul li {
    font-size: 16px;
    padding: 5px 0px;
  }

  .domain-type-game-sec .type-content .title {
    font-size: 30px;
    line-height: 35px;
  }

  .game-feature .feature-content .title {
    font-size: 30px;
    line-height: 35px;
  }

  .indus-sec3 .sec3-head {
    font-size: 16px;
  }

  .cta-box .cta-content-col {
    background-image: url(https://d2e3cmtjkfw2a3.cloudfront.net/static/media/industries/cta-img.svg);
    background-repeat: no-repeat;
    padding-top: 50px;
    background-position: right;
  }

  .cta-box .cta-arrow-col {
    display: none;
  }

  .indus-sec3 .our-work .our-work-border-heading {
    font-size: 25px;
  }

  .indus-sec3 .sec3-para {
    font-size: 12px;
  }

  .indus-sec1 {
    background-size: cover;
  }

  .industrial-sec .our_lp h5 {
    font-size: 12px;
  }

  .industrial-sec .our_lp .prj_head {
    font-size: 25px;
  }

  .industrial-sec .our_lp p {
    font-size: 15px;
  }

  .industrial-sec .carousel-indicators {
    margin-left: 48%;
    top: 96%;
  }

  .industrial-sec .carousel-control-next {
    left: 82%;
    top: 95%;
  }

  .industrial-sec .carousel-control-prev {
    right: 53%;
    top: 95%;
  }

  .industrial-sec .protect_img {
    width: inherit;
  }


  /*domain sub page */

  .domain-sec-1 .domain-sec-1-des .heading {
    font-weight: 500;
    font-size: 32px;
    line-height: 44px;
  }

  .domain-sec-1 .header-img .head-img-1 {
    display: none;
  }

  .domain-sec-1 .header-img .head-img-2 {
    display: none;
  }

  .domain-sec-1 .head-content {
    margin-top: 10px;
  }

  .domain-sec-1 .head-content .title {
    font-weight: 500;
    font-size: 28px;
    line-height: 36px;
  }

  .domain-sec-2 .domain-sec-2-box .domain-sec-2-content .heading {

    font-size: 22px;
    line-height: 30px;
  }

  .domain-sec-5 .domain-circle {
    left: -90%;
  }

  .domain-sec-5 .domain-sec-5-feature .feature-block .feature-content .heading {

    font-size: 22px;
    line-height: 30px;

  }

  .domain-sec-5 .domain-sec-5-feature .feature-block .feature-content .description {
    padding-top: 15px;
  }

  .domain-sec-5 .domain-sec-5-content {
    margin-bottom: 30px;
  }

  .domain-sec-5 .domain-sec-5-feature .feature-block .feature-content {
    padding-left: 0px;
  }

  .domain-sec-1 .domain-sec-1-des .sb_sr_top {
    padding: 50px 0px 0px 24px;
  }

  .domain-sec-1 .domain-sec-1-des .sb_sr_top::before {
    left: -45%;
  }

  .domain-sec-1 .header-img .head-img-1::before {
    width: 50%;
    margin-left: 80px;
  }

  .domain-sec-1 .header-img .head-img-2::before {
    width: 50%;
  }

  .domain-sec-2 .domain-sec-2-box .sec-2-img {
    text-align: center;
  }

  .domain-sec-2 .domain-sec-2-content {
    padding: 30px 0px;
  }

  .domain-sec .app-head .heading {
    font-size: 30px;
    line-height: 35px;
  }

}

@media (max-width: 400px) {
  .head-img-wrapper-indus-1 {
    height: 45vh;
  }

  .indus-sec1 .heading-text .heading {
    line-height: 36px;
  }

  .domain-mobile .domain-title .description {
    font-size: 15px;
    line-height: 20px;
  }

  .domain-mobile .domain-title img {
    margin-top: 0;
  }
}