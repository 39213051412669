/* @import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800&family=Poppins:wght@100;200;300;400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Assistant:wght@400;600;700&family=Lato:wght@300;400;700&display=swap"); */

/* font-family: 'Assistant', sans-serif; font-family: 'Lato', sans-serif; */

body {
    padding: 0;
    margin: 0;
    background-color: #101214 !important;
    font-family: "Poppins", sans-serif;
    font-display: swap;
}

ul,
li,
ol,
p,
span,
strong,
img,
a,
h1,
h2,
h3,
h4,
h5,
h6 {
    padding: 0;
    margin: 0;
}

#scroll-top-button {
    display: inline-block;
    width: 50px;
    height: 50px;
    border-radius: 50px;
    text-align: center;
    position: fixed;
    bottom: 3%;
    right: 30px;
    transition: background-color .3s,
        opacity .5s, visibility .5s;
    opacity: 0;
    visibility: hidden;
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
}

#scroll-top-button.show {
    z-index: 0;
    opacity: 1;
    visibility: visible;
    cursor: pointer;
}



a:focus,
a:hover,
button:focus,
button:hover {
    outline: none;
}

a:hover {
    color: #fff;
}

.desktop_html .container {
    max-width: 1100px;
}

.loader-container {
    display: none !important;
}


/* Chrome, Safari, Edge, Opera */

.desktop_html input::-webkit-outer-spin-button,
.desktop_html input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}


/* Firefox */

.desktop_html input[type="number"] {
    -moz-appearance: textfield;
}

.btn-close:focus {
    box-shadow: 0 0 0 !important;
}

.desktop_html .btn.btn-primary.let_tk {
    background: transparent;
    color: #fff;
    border: solid 2px #fff;
    font-weight: normal;
    font-size: 13px;
    padding: 9px 24px;
    margin-top: -7px;
}

.desktop_html .text-center {
    text-align: center;
}

.desktop_html .text-left {
    text-align: left;
}

.desktop_html .text-right {
    text-align: right;
}

.desktop_html .navbar-dark .navbar-nav .nav-link {
    position: relative;
    color: #c6c6c6;
    font-size: 15px;
    padding: 0;
    margin: 3px 20px;
}

.desktop_html .navbar-dark .navbar-nav .nav-link.active::after {
    content: "";
    position: absolute;
    bottom: -5px;
    left: 4px;
    width: 26px;
    height: 2px;
    background: #e4a400;
}

.desktop_html .navbar-dark .navbar-nav .nav-link:hover::after {
    content: "";
    position: absolute;
    bottom: -5px;
    left: 4px;
    width: 26px;
    height: 2px;
    background: #ededed80;
}

.desktop_html .header .navbar.navbar-expand-lg.navbar-dark.bg-dark {
    padding: 55px 0 25px;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 99;
    background: #000 !important;
}


/*.desktop_html .header .navbar.navbar-expand-lg.navbar-dark.bg-dark {padding: 25px 0;position: fixed;left: 0;top: 0;width: 100%;z-index: 99; background: #000 !important;}*/

.desktop_html .navbar-dark .nav-btn {
    border: 2px solid #fff;
    color: #dedede;
    text-decoration: none;
    padding: 10px 30px;
    border-radius: 30px;
    font-size: 13px;
    margin-left: 40px;
    text-transform: uppercase;
}

.desktop_html .top_hm {
    height: 200vh;
    background: url(https://d2e3cmtjkfw2a3.cloudfront.net/static/media/others/Screen_hm.jpg);
    background-position: top left;
    background-size: cover;
}

.desktop_html .hero_slide {
    color: #fff;
    background: linear-gradient(to top, rgba(0, 0, 0, 0.8), #000000);
    height: 100vh;
    padding: 30vh 0;
    position: relative;
}

.desktop_html .hero_slide p {
    color: #f9f9f9;
    font-family: "Montserrat", sans-serif;
    font-display: swap;
    letter-spacing: 3.2px;
    text-transform: uppercase;
    font-weight: 500;
    line-height: 5;
}

.desktop_html .hero_slide p span {
    border-bottom: 2px solid #e4a400;
}

.desktop_html .hero_slide h1 {
    color: #f9f9f9;
    font-size: 35px;
    font-weight: 600;
    line-height: 1.44;
    max-width: 645px;
    margin-bottom: 30px;
}

.desktop_html .btn.btn-primary {
    background: #fff;
    /* background: #e4a400 !important; */
    text-transform: uppercase;
    font-weight: 600;
    border: 0;
    border-radius: 50px;
    letter-spacing: 1.6px;
    padding: 13px 25px;
    color: #101214;
    overflow: hidden;
}

.desktop_html .btn.btn-primary {
    padding: 10px 25px;
}

.desktop_html .btn.btn-primary:hover {
    color: #fff;
}

.desktop_html .btn.btn-primary {
    padding: 13px 25px;
}

.desktop_html .btn.btn-primary.hvr-sweep-to-right:before {
    background: #e4a400;
}

.desktop_html .scroll {
    position: absolute;
    right: 0px;
    bottom: 50px;
}

.desktop_html .hero_slide .container {
    position: relative;
}

.desktop_html .services_main {
    background: #101214;
    padding: 140px 0 60px;
}

.desktop_html .services_main .nav-tabs {
    border: 0;
}

.desktop_html .services_main .nav-tabs .nav-item {
    flex: 0 0 50%;
    text-align: center;
    padding: 10px;
}

.desktop_html .services_main .nav-tabs .nav-item button {
    margin: 0 !important;
    background: #101214;
    display: block;
    width: 100%;
    padding: 30px 60px;
    border: 1px solid transparent;
    box-shadow: 5px 5px 16px 0 rgba(0, 0, 0, 0.3);
    border-radius: 20px;
    color: #9c9c9c !important;
}

.desktop_html .services_main .nav-tabs .nav-item button.active {
    color: #fff !important;
    box-shadow: none;
    border: 1px solid #333333;
}

.desktop_html .sr_bx {
    background: #101214;
    border-radius: 20px;
    padding: 60px;
    color: #fdfdfd;
    border: 1px solid #333333;
}

.desktop_html .sr_bx h3 {
    font-size: 28px;
    font-weight: 500;
    margin-bottom: 15px;
}

.desktop_html .sr_bx p {
    color: #9c9c9c;
    line-height: 1.75;
    font-size: 13px;
    font-weight: 500;
    min-height: 130px;
    margin-bottom: 10px;
}

.desktop_html .heading {
    text-align: center;
    margin-bottom: 30px;

}

.desktop_html .heading h2 {
    color: #f9f9f9;
    font-size: 50px;
    letter-spacing: 1px;
    margin-bottom: 5px;
}

.desktop_html .heading .srm_hd {
    color: #f9f9f9;
    font-size: 50px;
    letter-spacing: 1px;
    margin-bottom: 5px;
}

.desktop_html .heading p {
    color: #e4a400;
    font-size: 20px;
    font-family: "Montserrat", sans-serif;
    font-display: swap;
}

.desktop_html .services_main .heading {
    margin-bottom: 120px;
}

.desktop_html .hero_btm p {
    font-size: 15px;
    margin-bottom: 10px;
    color: #9c9c9c;
}

.desktop_html .services_main .hero_btm {
    text-align: center;
    margin-top: 120px;
}

.desktop_html .latest_work .carousel-indicators [data-bs-target] {
    width: 10px;
    height: 10px;
    border-radius: 50%;
}

.desktop_html .latest_work .carousel-indicators {
    right: auto;
    bottom: 30px;
    margin-right: 0;
    margin-bottom: 0;
    margin-left: 0;
    left: calc(50% + 140px);
    position: absolute;
}

.desktop_html .latest_work .carousel-control-next,
.desktop_html .latest_work .carousel-control-prev {
    top: auto;
    bottom: 32px;
    width: auto;
    left: calc(50% + 210px);
}

.desktop_html .latest_work .carousel-control-prev {
    left: calc(50% + 110px);
}



.desktop_html .latest_work .carousel-control-next-icon,
.desktop_html .latest_work .carousel-control-prev-icon {
    width: 1.5rem;
    height: 1.5rem;
}

.desktop_html .latest_work {
    height: 100vh;
}

.desktop_html .latest_work .col-md-6 {
    position: relative;
}

.desktop_html .latest_work .protect_img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    background-repeat: no-repeat;
    background-size: cover;
}

.desktop_html .latest_work .carousel-item {
    height: 100vh;
}

.desktop_html .latest_work .our_lp {
    background: rgba(0, 0, 0, 0.9);
    height: 100vh;
    color: #fdfdfd;
    padding: 10vh 15vh;
}

.desktop_html .latest_work .our_lp h5 {
    font-size: 20px;
    color: #e4a400;
    font-family: "Montserrat", sans-serif;
    font-display: swap;
    margin-bottom: 50px;
}

.desktop_html .latest_work .our_lp .prj_head {
    font-size: 50px;
    margin-bottom: 50px;
    font-weight: 500;
}

.desktop_html .latest_work p {
    color: #9c9c9c;
    line-height: 28px;
    margin-bottom: 30px;
    min-height: 200px;
}

.desktop_html .all_clients li {
    list-style: none;
    flex: 0 0 25%;
    margin-top: 70px;
    text-align: center;
}

.desktop_html .all_clients ul {
    display: flex;
    flex-wrap: wrap;
}

.desktop_html .sec_client {
    padding: 160px 0;
    position: relative;
    background: #101214;
}

.desktop_html p.startup {
    color: #e4a400;
    font-weight: 500;
    margin-bottom: 20px;
    font-size: 20px;
}

.desktop_html .startup_dir {
    position: absolute;
    right: 0;
}



.desktop_html #tech_sld img {
    width: auto;
    margin: 0 auto;
    display: block;
}



.desktop_html .srv_testimonials {
    padding: 140px 0 160px;
}

.desktop_html .srv_testimonials h2 {
    text-align: center;
    font-size: 40px;
    color: #f9f9f9;
}

.desktop_html .srv_testimonials h2 span {
    margin: 0 0 0 -52px;
}

.desktop_html .srv_testimonials .srv_hd_cnt {
    text-align: center;
    max-width: 562px;
    margin: 10px auto;
    font-size: 20px;
    line-height: 32px;
    color: #9c9c9c;
}

.desktop_html .da_srv_bx {
    background: #101214;
    box-shadow: -9px -9px 20px 0 rgba(27, 27, 27, 0.2), 9px 9px 20px 0 rgba(0, 0, 0, 0.5);
    max-width: 610px;
    padding: 50px;
    margin: 70px auto;
    border-radius: 15px;
    color: #f9f9f9;
}

.uber-top-pdng {
    padding-top: 65px !important;
}

.desktop_html .tst_img {
    width: 80px;
    height: 80px;
    overflow: hidden;
    border-radius: 50%;
    border: 2px solid #fdcb04;
    margin-right: 20px;
}

.desktop_html .tst_img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.desktop_html .tst_cnt {
    flex: 0 0 calc(100% - 100px);
}

.desktop_html .tst_cnt h4 {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 5px;
    margin-top: 15px;
}

.desktop_html .tst_cnt h5 {
    font-size: 16px;
    font-weight: 500;
    opacity: 0.7;
}

.desktop_html .tst_rating {
    margin-top: 15px;
}

.desktop_html .tst_rating .fa {
    color: #e1574a;
    margin: 0 0 0 3px;
}

.desktop_html .tst_msg {
    margin-top: 20px;
    font-size: 18px;
    text-align: center;
    min-height: 64px;
}

.desktop_html .da-cluch-bx {
    border: 1px solid #373737;
    text-align: center;
    border-radius: 10px;
    margin-top: -160px;
    padding-top: 200px;
    /* padding-bottom: 50px; */
}

.desktop_html .da-cluch-bx ul {
    min-height: 200px;
    max-height: 300px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.desktop_html .da-cluch-bx li {
    margin: 0 10px;
    list-style: none;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #b7b8b8;
    max-width: 130px;
}

.desktop_html .da-cluch-bx img {
    max-height: 100px;
    margin: 52px;
    margin-bottom: 0;
    margin-top: 0;
}

.desktop_html .da-cluch-bx li p {
    margin-top: 15px;
}

.desktop_html .lets_talk {
    background: url(https://d2e3cmtjkfw2a3.cloudfront.net/static/media/others/technologies_bg.jpg);
    background-position: top left;
    background-size: cover;
    padding: 150px 0;
    position: relative;
    background-attachment: fixed;
}

.desktop_html .lets_talk input.form-control {
    background: transparent;
    border: solid 1px #818181;
    border-radius: 50px;
    height: 59px;
    padding: 10px 20px;
}

.desktop_html .lets_talk textarea.form-control {
    background: transparent;
    border: solid 1px #818181;
    border-radius: 15px;
    height: 120px;
    padding: 10px 20px;
}

.desktop_html .lets_talk .form-group {
    margin-bottom: 30px;
}

.desktop_html .lets_talk .form-group label {
    color: #fdfdfd;
    font-size: 16px;
    margin-bottom: 10px;
}

.desktop_html .btn.btn-dark.btn-line {
    background: transparent;
    color: #fdfdfd;
    border: 2px solid transparent;
    border-radius: 50px;
    padding: 15px 80px;
    margin-top: 30px;
}

.desktop_html .lets_talk h3 {
    color: #fdfdfd;
    font-size: 50px;
    font-weight: 600;
    margin-bottom: 20px;
}

.desktop_html .lets_talk p {
    color: #afafaf;
    max-width: 400px;
    line-height: 28px;
    margin-bottom: 30px;
}

.desktop_html .lets_talk p a {
    display: table;
    color: #e4a400;
    text-decoration: none;
    position: relative;
}

.desktop_html .cn_content {
    padding-top: 10px;
}

.desktop_html .footer {
    padding: 55px 0 60px;
    background: #000;
}

.desktop_html .footer .foot_heading {
    color: #fdfdfd;
    font-size: 20px;
    margin-bottom: 20px;
}

.desktop_html .footer li {
    list-style: none;
    margin-bottom: 14px;
}

.desktop_html .footer li a {
    color: #9c9c9c;
    text-decoration: none;
}

.desktop_html .footer li a:hover,
.desktop_html .footer p a:hover {
    color: #fff;
}

.desktop_html .footer p a {
    color: #9c9c9c;
    text-decoration: none;
    margin-bottom: 14px;
    display: block;
}

.desktop_html .footer p.ftcnt {
    max-width: 280px;
    color: #9c9c9c;
    line-height: 26px;
    margin-bottom: 25px;
}

.desktop_html .lg_footer {
    margin-bottom: 50px;
}

.desktop_html .footer .social li {
    display: inline-block;
    padding: 0 20px 0 0px;
    font-size: 25px;
    margin: 0;
}

.desktop_html .footer .social li a {
    color: #fdfdfd;
}

.desktop_html .footer .social li:first-child a:hover {
    color: #0077b5;
}

.desktop_html .footer .social li:nth-child(2) a:hover {
    color: #053eff;
}

.desktop_html .footer .social li:nth-child(3) a:hover {
    color: #ea4c89;
}

.desktop_html .footer .social li:nth-child(4) a:hover {
    color: #3b5998;
}

.desktop_html .footer .social li:last-child a:hover {
    color: #bc2a8d;
}

.desktop_html .footer .da_copy {
    color: #dbdbdb;
    margin-top: 50px;
    font-size: 13px;
}



.desktop_html .right_cover {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 8;
}


.desktop_html .crt_bx {
    max-width: 705px;
    margin: 50px auto 70px;
    border-radius: 10px;
    box-shadow: 5px 5px 16px rgba(0, 0, 0, 0.3);
    background: #101214;
    position: relative;
    min-height: 250px;
}


.desktop_html .crt_bx .col-md-5 {
    align-self: end;
    -webkit-align-self: flex-end;
}

.desktop_html .crt_bx img {
    /*position: absolute;bottom: 0;left: 0;*/
    max-width: 320px;
}

.desktop_html .career_testimonial::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    height: 50%;
    width: 100%;
    background: #101214;
}



.desktop_html .pos_bx {
    position: relative;
}

.desktop_html .pos_bx::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 400px;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), #101214);
}

.desktop_html .opn_pos .heading.text-left {
    margin: 0;
    text-align: left;
    background: #101214;
    padding: 80px 0;
}

.desktop_html .lyr_tp {
    position: relative;
    z-index: 9;
}

.desktop_html .pos_bx .accordion-button:not(.collapsed) {
    background: transparent !important;
    color: #fff;
    border: 0;
}

.desktop_html .pos_bx .accordion-button {
    background: transparent !important;
    color: #f9f9f9;
    font-size: 30px;
    padding: 10px 0px;
    font-weight: 600;
}

.desktop_html .accordion-button:not(.collapsed)::after {
    background: transparent !important;
    transform: rotate(0deg) !important;
    flex-shrink: 0;
    width: 1.25rem;
    height: 1.25rem;
    margin-left: auto;
    content: "\f106";
    transition: transform 0.2s ease-in-out;
    background: transparent !important;
    font-family: fontAwesome;
}

.desktop_html .accordion-button::after {
    content: "\f107";
    font-family: fontAwesome;
    background: transparent !important;
    transform: rotate(0deg) !important;
}

.desktop_html .opn_pos {
    padding-bottom: 150px;
}

.desktop_html .pos_bx .accordion-item {
    margin-bottom: 40px;
    background-color: rgba(16, 18, 20, 0.6);
    border: solid 1px #222222;
    border-radius: 20px;
    padding: 25px 50px;
}

.desktop_html .pos_bx .accordion-collapse .accordion-body {
    margin-top: 30px;
    border-top: 1px solid #222222;
    padding: 0 30px 30px;
}

.desktop_html .pos_bx .accordion-collapse .accordion-body .col-md-5 {
    width: 36.667%;
}

.desktop_html .accordion-button:focus {
    box-shadow: 0 0 0;
}

.desktop_html .positions_more {
    flex: 0 0 40px;
    width: 40px;
    text-align: center;
}

.desktop_html .positions_more a {
    transition: all 0.5s ease 0s;
    -webkit-transition: all 0.5s ease 0s;
    -moz-transition: all 0.5s ease 0s;
    border: solid 1px #282828;
    color: #fdcb04;
    width: 39px;
    display: block;
    margin: 0 auto;
    height: 39px;
    border-radius: 50%;
    line-height: 35px;
    font-size: 13px;
}

.desktop_html .positions_more a:hover {
    background: #333;
}

.desktop_html .positions {
    display: flex;
    margin-top: 50px;
}

.desktop_html .positions_cn h2 {
    color: #fdfdfd;
    font-size: 20px;
    margin-bottom: 5px;
}

.desktop_html .positions_cn p {
    color: #fdfdfd;
    font-size: 14px;
    margin-top: 4px;
    opacity: 0.7;
    line-height: inherit;
}

.desktop_html .positions_cn {
    width: calc(100% - 40px);
    padding-right: 10px;
}

.desktop_html .gallery {
    background: url(https://d2e3cmtjkfw2a3.cloudfront.net/static/media/others/g_bg.jpg);
    background-position: top left;
    background-size: cover;
    background-attachment: fixed;
    padding: 100px 0;
}

.desktop_html .gallery .owl-carousel .owl-stage {
    display: flex;
    align-items: center;
}

.desktop_html .gallery .owl-nav {
    display: block !important;
    color: #fff;
    position: absolute;
    left: 0;
    top: 0;
    font-size: 35px;
    width: 100%;
    height: 100%;
    opacity: 0;
}

.desktop_html .gallery .owl-carousel .owl-nav button.owl-prev,
.desktop_html .gallery .owl-carousel .owl-nav button.owl-next {
    width: 45%;
    height: 100%;
}

.desktop_html .gallery .owl-nav .owl-next {
    position: absolute;
    right: 0;
}

.desktop_html .gallery .owl-nav .owl-prev {
    position: absolute;
    left: 0;
}

.desktop_html .join_tribe .btn.btn-primary {
    margin-top: 30px;
}

.desktop_html .join_tribe {
    padding: 80px 0;
}


.desktop_html .ab_top {
    text-align: center;
    max-width: 640px;
    margin: 0 auto 80px;
}

.desktop_html .ab_stand h2 {
    color: #f9f9f9;
    font-size: 40px;
    margin-bottom: 50px;
}

.desktop_html .ab_stand p {
    font-size: 20px;
    color: #fdfdfd;
    line-height: 32px;
}

.desktop_html .ab_stand {
    padding: 118px 0;
}



.desktop_html .cl_bx {
    padding: 60px;
    max-width: 550px;
    border-radius: 20px;
    margin: 50px auto 90px;
    border: 1px solid #333;
}

.desktop_html .noteworthy h2 {
    color: #f9f9f9;
    font-size: 50px;
    line-height: 90px;
    padding: 70px 50px;
}

.desktop_html .cl_bx h3 {
    color: #f9f9f9;
    font-size: 32px;
    margin-bottom: 7px;
}

.desktop_html .cl_bx h6 {
    color: #f9f9f9;
    font-size: 14px;
}

.desktop_html .cl_bx .fa {
    color: #e1574a;
    margin-left: 4px;
}

.desktop_html .cl_bx p {
    font-size: 16px;
    line-height: 32px;
    color: #f9f9f9;
    margin-top: 24px;
    min-height: 100px;
}

.desktop_html .noteworthy {
    padding-bottom: 60px;
}



.desktop_html .ab_stand {
    position: relative;
    background: url(https://d2e3cmtjkfw2a3.cloudfront.net/static/media/others/technologies_bg.jpg);
    background-position: top left;
    background-size: cover;
    background-attachment: fixed;
}

.desktop_html .cnt_ab_hd {
    max-width: 963px;
    margin: -130px auto 0;
    background: url(https://d2e3cmtjkfw2a3.cloudfront.net/static/media/others/Aboutus_bg.png);
    background-position: top left;
    background-size: cover;
    z-index: 9;
    position: relative;
    color: #f9f9f9;
    padding: 50px 80px;
    border-radius: 20px;
}

.desktop_html .cnt_ab_hd h3 {
    color: #e4a400;
    font-size: 30px;
    margin-bottom: 10px;
}

.desktop_html .cnt_ab_hd h2 {
    font-size: 30px;
    font-weight: 500;
    line-height: 40px;
}

.desktop_html .new_cn_frm {
    padding: 85px 0;
}

.desktop_html .new_cn_frm h2 {
    color: #f9f9f9;
    font-size: 50px;
    line-height: 64px;
    margin-bottom: 20px;
}

.desktop_html .new_cn_frm p {
    color: #b6b6b6;
    font-size: 20px;
}

.desktop_html .form-group {
    margin-bottom: 25px;
    position: relative;
}

.form-control:focus {
    box-shadow: none !important;
}

.desktop_html .form-group i {
    position: absolute;
    bottom: 13px;
    right: 13px;
    color: #e4a400;
}

.desktop_html .form-group .white-i {
    color: #B6B6B6 !important;
}

.desktop_html .srv_talk.lets_talk .form-group i {
    bottom: 24px;
}

.desktop_html .form-group input.form-control {
    background: transparent;
    border: 1px solid #373737;
    height: 45px;
    margin-bottom: 30px;
}

.desktop_html .form-group textarea.form-control {
    background: transparent;
    border: 1px solid #373737;
    height: 100px;
}

.desktop_html .cnt_form {
    /* border: 1px solid #373737; */
    border: none !important;
    padding: 10px 80px;
    border-radius: 20px;

}

.desktop_html .form-group label {
    color: #f9f9f9;
    margin-bottom: 10px;
    margin-top: 20px;
}

.desktop_html .new_cn_frm .enq_con h3 {
    color: #f9f9f9;
    font-size: 20px;
    line-height: 40px;
}

.desktop_html .new_cn_frm .enq_con p {
    color: #b6b6b6;
    font-size: 16px;
    max-width: 210px;
    line-height: 32px;
    margin-bottom: 10px;
}

.desktop_html .new_cn_frm .enq_con a {
    color: #e4a400;
    font-size: 16px;
    line-height: 28px;
    text-decoration: none;
}

.desktop_html .top_con {
    margin-bottom: 30px;
}

.desktop_html .enq_con {
    padding-bottom: 30px;
}

.desktop_html .top_con h1 {
    font-weight: 600;
    font-size: 60px;
    line-height: 90px;
    color: #FFFFFF;
}

.desktop_html .top_con h2 {
    font-weight: 600;
    font-size: 60px;
    line-height: 90px;
    color: #FFFFFF;
}

.desktop_html .top_con p {
    font-weight: 500;
    font-size: 18px;
    line-height: 30px;
    color: #F2F2F2;
    max-width: 300px;
}

.desktop_html .top_con .sec-12-p {
    max-width: 100%;
}

.new_cn_frm .cn-frm-title {
    /* padding-left: 60px; */
    padding-bottom: 30px;
}

.desktop_html .new_cn_frm .btn.btn-dark.btn-line {
    background: 0 0;
    color: #fdfdfd;
    border-radius: 50px;
    padding: 15px 80px;
    margin-top: 10px;
}

.desktop_html .tmr_lst li {
    list-style: none;
    width: 450px;
    padding: 20px 40px;
    box-shadow: 9px 9px 20px 0 rgba(11, 11, 11, 0.3), -9px -9px 20px 0 rgba(27, 27, 27, 0.3);
    border-radius: 20px;
    background: #101214;
    position: relative;
    margin-bottom: 50px;
}

.desktop_html .tmr_lst li .d-img {
    width: 120px;
    height: 120px;
    overflow: hidden;
    border-radius: 50%;
    margin-right: 20px;
}

.desktop_html .tmr_lst li .d-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.desktop_html .tmr_lst li h5 {
    color: #f9f9f9;
    font-size: 20px;
    margin-bottom: 5px;
}

.desktop_html .tmr_lst li p {
    color: #9c9c9c;
    font-size: 16px;
    margin-bottom: 5px;
}

.desktop_html .tmr_lst li p span {
    color: #e4a400;
}

.desktop_html .mn_add {
    border-left: 2px solid #505050;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    float: left;
    height: 100%;
    width: 100%;
}

.desktop_html .cnt_ad {
    width: 339px;
    float: right;
    margin-bottom: 50px;
}

.desktop_html .cnt_ad h5 {
    color: #f9f9f9;
    font-size: 20px;
    margin-bottom: 10px;
}

.desktop_html .cnt_ad h6 {
    color: #e4a400;
    font-size: 15px;
    margin-bottom: 16px;
}

.desktop_html .cnt_ad p {
    font-size: 15px;
    color: #b6b6b6;
    margin-bottom: 40px;
}

.desktop_html .tmr_lst li .d-cnt {
    width: calc(100% - 150px);
    padding-left: 20px;
}

.desktop_html .tmr_lst li a {
    color: #fff;
    background: #ffb700;
    width: 35px;
    display: block;
    height: 35px;
    text-align: center;
    line-height: 35px;
    font-size: 20px;
    border-radius: 50%;
    position: absolute;
    left: 130px;
    bottom: 40px;
}

.desktop_html .tmr_lst li .siman-col a.contact {
    left: 192px;
    background: transparent;
    width: unset;
    bottom: 5px;
    font-size: 14px;
    color: #ffb700;
}

.desktop_html .tmr_lst li .siman-col a.contact i {
    color: #ffb700;
    font-size: 18px;
}

.desktop_html .tmr_lst {
    padding-top: 20px;
}

.desktop_html .connectUs {
    padding: 110px 0;
}

.desktop_html .connectUs h2 {
    font-size: 50px;
    color: #f9f9f9;
    font-weight: 600;
    margin-bottom: 10px;
}

.desktop_html .connectUs span a {
    color: #e4a400;
    font-size: 16px;
    letter-spacing: 1.6px;
    margin-bottom: 20px;
    display: inline-block;
    text-decoration: navajowhite;
    font-weight: 600;
}

.desktop_html .connectUs p {
    color: #b6b6b6;
    font-size: 20px;
    margin-bottom: 30px;
}

.desktop_html .connectUs a {
    color: #e4a400;
    font-size: 16px;
    letter-spacing: 1.6px;
    margin-bottom: 20px;
    display: inline-block;
    text-decoration: navajowhite;
    font-weight: 600;
}

.desktop_html .modal .modal-dialog {
    width: 100%;
    max-width: 100%;
    margin: 0 0;
}

.desktop_html .modal .modal-content {
    background-color: #101214;
    height: 100vh;
    overflow: auto;
}

.desktop_html .modal .btn-close {
    top: 30px;
    color: #fff !important;
    opacity: 1;
    background-color: #e4a400;
    padding: 10px 10px;
    border-radius: 50%;
    position: absolute;
    right: 40px;
}

.desktop_html #project_enquery .pr_en_hd {
    color: #e4a400;
    font-size: 40px;
    letter-spacing: 1px;
}

/* new project enquiry form start*/

.nw_pr_rn_frm .modal#project_enquery .btn-close {
    top: 30px;
    border: 1px solid #ffb700 !important;
    opacity: 1;
    background: transparent;
    padding: 5px 10px 15px;
    border-radius: 50% !important;
    position: absolute;
    right: 40px;
}

.nw_pr_rn_frm .modal .btn-close {
    top: 30px;
    color: #e4a400 !important;
    opacity: 1;
    background-color: #e4a400;
    padding: 10px 10px;
    border-radius: 50%;
    position: absolute;
    right: 40px;
}

.nw_pr_rn_frm .modal#project_enquery .btn-close:hover {
    border: 1px solid #e4a400;
    background: #e4a400;
}

.nw_pr_rn_frm .modal-content {
    height: 100vh !important;
    margin-top: 30px !important;
    background-image: url(https://d2e3cmtjkfw2a3.cloudfront.net/static/media/forms/pr-en-img.png);
    background-repeat: no-repeat;
    background-position: left;
    background-color: #000000 !important;
    box-shadow: 5px -5px 6px rgb(132 132 132 / 12%), 5px 6px 6px rgb(132 132 132 / 10%);

}

.nw_pr_rn_frm .modal {
    overflow: hidden !important;
}

.nw_pr_rn_frm #project_enquery .modal-body {
    max-width: 1100px !important;
    padding: 20px 30px !important;
}

.nw_pr_rn_frm #project_enquery .pr-en-content {
    height: 100%;

}

.nw_pr_rn_frm .pr-en-content .pr_en_hd {
    font-weight: 600;
    font-size: 50px;
    line-height: 75px;
    letter-spacing: 0.02em;
    color: #E4A400;
}

.nw_pr_rn_frm .pr-en-content .pr_en_sub_hd {
    font-weight: 600;
    font-size: 25px;
    line-height: 40px;
    display: flex;
    align-items: center;
    letter-spacing: 0.02em;
    color: #C7C7C7;
    padding: 10px 0px;
    max-width: 340px;
}

.nw_pr_rn_frm .pr-en-content .pr_en_sub_title {
    font-weight: 500;
    font-size: 24px;
    line-height: 45px;
    letter-spacing: 0.02em;
    color: #F9F9F9;
    padding: 15px 0px;
}

.nw_pr_rn_frm .pr-en-content .pr_en_social_title {
    font-weight: 500;
    font-size: 28px;
    line-height: 45px;
    letter-spacing: 0.02em;
    color: #E4A400;
    padding: 5px 0px;
}

.nw_pr_rn_frm .pr-en-content .social-icon .ceo-info {
    display: flex;
    align-items: center;
    padding: 16px 0px;
}

.nw_pr_rn_frm .pr-en-content .social-icon .ceo-info .social-img {
    width: 80px;
    height: 80px;
}

.nw_pr_rn_frm .pr-en-content .social-icon .ceo-info .social-img img {
    width: 100%;
}

.nw_pr_rn_frm .pr-en-content .social-icon .ceo-info .title {
    font-weight: 500;
    font-size: 22px;
    line-height: 45px;
    letter-spacing: 0.02em;
    color: #E8E8E8;
    padding: 0px 20px 0px 15px;
    width: 50%;
}

.nw_pr_rn_frm .form-group textarea.form-control {
    background: transparent;
    border: 1px solid #373737;
    height: 100px;
}

.nw_pr_rn_frm .form-group textarea.form-control:focus {
    box-shadow: none;
}

#project_enquery .form-group {
    margin-bottom: 15px;
}

#project_enquery .form-group label#name-error .form-group {
    margin-bottom: 15px;
}

.nw_pr_rn_frm .form-check-input[type=radio] {
    border-radius: 25%;
    border: 2px solid #B6B6B6;
    background-color: transparent;
}

.nw_pr_rn_frm #project_enquery .form-check-input:focus {
    box-shadow: none;
}

.nw_pr_rn_frm .file_input_new {
    border-radius: 5px;
    border: 1px solid #373737;
    background: transparent !important;
}

.nw_pr_rn_frm #enquiryForm .btn.btn-dark.btn-line {
    border: 1px solid #ffb700 !important;
}

.hm-main-wrap .file_input_new {
    border-radius: 5px;
    border: 1px solid transparent;
    background: transparent !important;
}

.nw_pr_rn_frm .social-icon a {
    color: white !important;
}

.nw_pr_rn_frm .social-icon a:hover {
    color: #0072b1 !important;
}

.nw_pr_rn_frm .social-icon i {
    font-size: 32px;
}

.desktop_html.nw_pr_rn_frm .modal-body {
    padding: 60px 40px;
    max-width: 1170px;
    margin: 0 auto;
}

.desktop_html #project_enquery h3 {
    color: #f9f9f9;
    font-size: 30px;
    margin-bottom: 46px;
}

.desktop_html #project_enquery label {
    font-size: 18px;
    margin: 10px 0px;
}

.desktop_html #project_enquery .form-check.form-check-inline .form-check-label {
    margin: 0;
    font-size: 16px;
    color: #b6b6b6;
}

.desktop_html #project_enquery .form-check.form-check-inline {

    padding: 0px 28px 0px 30px;
}

.desktop_html .file_input_new {
    border: 1px solid #373737;
    color: #b6b6b6;
    font-size: 20px;
    padding: 12px 50px 12px 20px;
    border-radius: 5px;
    position: relative;
    background: #040405;
}

.hm-main-wrap .file_input_new {
    border: 1px solid transparent;
    color: #b6b6b6;
    font-size: 20px;
    padding: 12px 35px 12px 0px;
    border-radius: 5px;
    position: relative;
    background: #040405;
}

.desktop_html #project_enquery.modal .file_input_new {
    background: #101214;
}

.desktop_html #careerForm .file_input_new {
    background: #101214;
}

.desktop_html .file_input_new input {
    position: absolute;
    left: 0;
    top: 0;
    width: 80%;
    height: 100%;
    z-index: 9;
    opacity: 0;
    cursor: pointer;
}

.hm-main-wrap .file_input_new input {
    position: absolute;
    left: 0;
    top: 0;
    width: 80%;
    height: 100%;
    z-index: 9;
    opacity: 0;
    cursor: pointer;
}

.desktop_html #project_enquery .validation-mgs {
    color: #b6b6b6;
    padding: 25px;
}

.desktop_html .file_input_new p {
    color: #fff;
    line-height: 32px;
    font-size: 16px;
    width: 215px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.hm-main-wrap .file_input_new p {
    color: black;
    line-height: 32px;
    font-size: 16px;
    width: 215px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.desktop_html .file_input_new p a {
    color: #ffb700 !important;
}

.hm-main-wrap .file_input_new p a {
    color: #ffb700 !important;
}

.desktop_html .file_input_new p+.clearFile {
    position: absolute;
    right: 8px;
    top: 15px;
    background: #e4a400;
    color: #fff;
    font-size: 15px;
    display: inline-block;
    width: 25px;
    height: 25px;
    text-align: center;
    line-height: 25px;
    border-radius: 50%;
}

.hm-main-wrap .file_input_new p+.clearFile {
    position: absolute;
    right: 8px;
    top: 15px;
    background: #e4a400;
    color: black;
    font-size: 15px;
    display: inline-block;
    width: 25px;
    height: 25px;
    text-align: center;
    line-height: 25px;
    border-radius: 50%;
}

.desktop_html .file_input_new p+.clearFile i {
    color: #fff;
    position: absolute;
    right: 7px;
    top: 4px;
}

.hm-main-wrap .file_input_new p+.clearFile i {
    color: #fff;
    position: absolute;
    right: 7px;
    top: 4px;
}

.desktop_html .myExp .form-check.form-check-inline .form-check-label {
    margin: 0;
    font-size: 16px;
    color: #b6b6b6;
}

.desktop_html .myExp .form-check.form-check-inline {
    border: 1px solid #373737;
    border-radius: 10px;
    padding: 15px 40px 15px 70px;
}

.career-form .myExp .form-check.form-check-inline {
    border: none;
    border-radius: 1px;
    padding: 0px 20px !important;
}



.desktop_html .port_top_bg {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}



.desktop_html .tech_icn li {
    list-style: none;
    display: inline-block;
    margin-bottom: 14px;
}


.desktop_html #fp-nav:hover {
    width: 300px;
}

.desktop_html #fp-nav:hover strong {
    opacity: 1;
    width: auto;
    height: auto;
}

.desktop_html .srv_num .numPos {
    background: #101214;
    width: 50px;
    height: 50px;
    text-align: center;
    line-height: 50px;
    color: #fff;
    border-radius: 50%;
    font-size: 16px;
    margin-top: 90px;
    position: relative;
    z-index: 9;
    border: 1px solid #333333;
    margin-bottom: -40px;
}

.desktop_html .serv_all {
    margin-top: -123px;
    padding-bottom: 123px;
}

.desktop_html .srv_cnt {
    padding-left: 70px;
}

.desktop_html .serv_bx .srv_num::after {
    content: "";
    position: absolute;
    left: 25px;
    top: 123px;
    width: 1px;
    height: 100%;
    border-left: 1px dashed #333;
    z-index: 0;
}

.desktop_html .serv_bx {
    padding: 33px 0;
    position: relative;
    color: #efefef;
    font-size: 16px;
    line-height: 28px;
}

.desktop_html .serv_bx .card {
    padding: 30px 0px;
    border-radius: 27px;
    border: none;
    flex-direction: row;
}

.desktop_html .serv_bx .card-1 {
    background: linear-gradient(97.53deg, #363185 0.74%, #0E0F0F 97.55%);
}

.desktop_html .serv_bx .card-2 {
    background: linear-gradient(97.53deg, #E976AF 0.74%, #0E0F0F 97.55%);
}

.desktop_html .serv_bx .card-3 {
    background: linear-gradient(97.53deg, #CA9F4B 0.74%, #0E0F0F 97.55%);
}

.desktop_html .serv_bx .card-4 {
    background: linear-gradient(97.53deg, #C76DB3 0.74%, #0E0F0F 97.55%);
}

.desktop_html .serv_bx .card-5 {
    background: linear-gradient(97.53deg, #54B7FF 0.74%, #0E0F0F 97.55%);

}

.desktop_html .serv_bx .card-6 {
    background: linear-gradient(97.53deg, #3EAC78 7.89%, #0E0F0F 97.55%);
}

.desktop_html .serv_bx .card-7 {
    background: linear-gradient(97.53deg, #775DCA 0.74%, #0E0F0F 97.55%);

}

.desktop_html .srv_cnt h2 {
    color: #f9f9f9;
    font-size: 30px;
    margin-bottom: 30px;
}

.desktop_html .srv_cnt a {
    padding-left: 30px;
}

.desktop_html .serv_bx .sub_srv_page a {
    padding-left: 0px;
    color: white;
}

.service-block .service-cards .srv-card-content {
    background: #0A0C0D;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), -4px -4px 7px rgba(146, 143, 143, 0.25);
    border-radius: 14px;
    padding: 50px;
    min-height: 300px;
}

.service-block .service-cards .srv-card-content .srv-card-title {
    font-weight: 400;
    font-size: 30px;
    line-height: 45px;
    align-items: center;
    padding-bottom: 50px;
    min-height: 150px;
}

.service-block .service-cards .srv-card-content a.btn_line {
    border: 1px solid #E4A400 !important;
}

.desktop_html .srv_cnt p {
    margin-bottom: 20px;
}

.desktop_html .srv_cnt ul {
    padding-left: 15px;
}

.desktop_html .srv_cnt ul li {
    padding-bottom: 12px;
    list-style: circle;
}

.desktop_html .serv_bx:last-child .srv_num::after {
    display: none;
}

.desktop_html .srv_head {
    padding: 120px 0 40px;
}

.desktop_html .srv_head h2 {
    font-size: 50px;
    color: #fff;
    line-height: 72px;
    font-weight: 600;
    padding-top: 75px;
}

.desktop_html .srv_head .title {
    font-size: 50px;
    color: #fff;
    line-height: 72px;
    font-weight: 600;
    padding-top: 75px;
}


.desktop_html .srv_head p {
    height: 100px;
    color: #e4a400;
    font-size: 20px;
    line-height: 80px;
    letter-spacing: 0.6px;
}

.desktop_html .tb_nav_serv {
    height: 203px;
}

.desktop_html .tb_nav {
    text-align: center;
    background: #000;
    padding: 30px 0;
    margin-bottom: 123px;
}

.desktop_html .tb_nav li {
    list-style: none;
    display: inline-block;
    padding: 0 8px;
}

.desktop_html .tb_nav li a {
    color: #ededed;
    text-decoration: none;
}

.desktop_html .tb_nav li a.active {
    color: #e4a400;
}

.desktop_html .srv_talk.lets_talk {
    padding: 50px 0;
    background: transparent;
}

.desktop_html .srv_talk.lets_talk input.form-control {
    border-radius: 10px;
    height: 60px;
}

.desktop_html .srv_talk.lets_talk textarea.form-control {
    border-radius: 10px;
    height: 120px;
}

.desktop_html .srv_tm {
    padding: 160px 0;
    position: relative;
    background: url(https://d2e3cmtjkfw2a3.cloudfront.net/static/media/others/technologies_bg.jpg);
    background-position: top left;
    background-size: cover;
    background-attachment: fixed;
}

.desktop_html .midTm {
    background: url(https://d2e3cmtjkfw2a3.cloudfront.net/static/media/others/g_bg.png);
    background-size: cover;
    padding: 40px;
    border-radius: 5px;
    border: 1px solid #333;
}

.desktop_html .midTm img {
    margin: -90px 0;
}

.desktop_html .srv_tm h2 {
    color: #efefef;
    font-size: 40px;
    max-width: 370px;
    line-height: 53px;
    padding-bottom: 25px;
}

.desktop_html .srv_tm h2 span {
    color: #e4a400;
}

.desktop_html p {
    color: #efefef;
    line-height: 32px;
    font-size: 16px;
}

.desktop_html .cr_dt_bx {
    max-width: 700px;
    border: 1px solid #222222;
    padding: 20px;
    margin: 100px 0 0;
    border-radius: 5px;
}

.desktop_html .cr_dt_bx h2 {
    color: #f9f9f9;
    letter-spacing: 1px;
    font-size: 50px;
    margin-bottom: 10px;
}

.desktop_html .cr_dt_bx h4 {
    color: #e4a400;
    font-size: 30px;
    letter-spacing: 1px;
    margin-bottom: 40px;
}

.desktop_html .cr_dt_bx p {
    color: #b6b6b7;
}

.desktop_html .cr_dt h5 {
    color: #f9f9f9;
    font-size: 30px;
    margin-top: 100px;
    margin-bottom: 22px;
}

.desktop_html .cr_dt ul li {
    color: #b6b6b7;
    line-height: 28px;
    margin-bottom: 10px;
    margin-left: 15px;
}

.desktop_html .cr_frm h6 {
    color: #b6b6b6;
    padding: 19px 25px;
}

.desktop_html .cr_frm h2 {
    color: #f9f9f9;
    font-size: 50px;
    margin-top: 20px;
    margin-bottom: 40px;
}

/* new career form */
.career-form .careerform-img {
    background-image: url(https://d2e3cmtjkfw2a3.cloudfront.net/static/media/career/career-form.png);
    height: 100%;
    background-repeat: no-repeat;
}

.career-form .file_input_new {
    border: none;
    background-color: transparent !important;
}

.career-form .file_input_new .fileName {
    color: #ffb700;
}

.career-form .file_input_new a {
    text-decoration: underline !important;
}

.career-form .form-check-input {
    background-color: transparent !important;
    border: 1px solid white !important;
}

.career-form .form-check-input:checked {
    background-color: transparent !important;
    border: 1px solid white !important;
}

.career-form .form-check-input:focus {
    box-shadow: none !important;
}

.career-form {
    margin-top: 40px;
}

.career-form .cr_frm {
    background-color: black;
    border-radius: 0px 15px 15px 0px;
    padding: 20px;
    max-height: 710px;
    overflow: scroll;
    overflow-x: hidden;
}

.career-form .cr_frm::-webkit-scrollbar {
    border: none;
    background-color: black;
    border-radius: 20px;
}

.career-form .cr_frm::-webkit-scrollbar-corner {
    background: transparent;
}

.career-form .cr_frm::-webkit-scrollbar-thumb {
    background-color: rgb(79, 79, 86);
    border-radius: 20px;
    border: 1px solid black;
    margin: 8px 0px;
}

.career-form .cr_frm .btn.btn-primary.white {
    background: transparent !important;
    text-transform: uppercase;
    font-weight: 600;
    border: 1px solid white;
    border-radius: 50px;
    letter-spacing: 1.6px;
    padding: 15px 70px;
    color: white;
    overflow: hidden;
    margin-right: 50px;
    font-size: 20px;
}

.career-form .cr_frm .btn.btn-primary.white .hvr-sweep-to-right:hover {
    border: 1px solid transparent;
    background-color: transparent !important;
}

.career-form .cr_frm .btn.btn-primary.white.hvr-sweep-to-right::before {
    background: transparent !important;
}

.career-form .cr_frm .btn.btn-primary.yellow {
    background: #FFA500;
    text-transform: uppercase;
    font-weight: 600;
    border: 1px solid transparent;
    border-radius: 50px;
    letter-spacing: 1.6px;
    padding: 15px 70px;
    color: white;
    overflow: hidden;
    font-size: 20px;
}

.career-form .form-group .title {
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    color: #F1B308;
}

.desktop_html .logo-card {
    position: relative;
    width: 140px;
    height: 160px;
}

.desktop_html .logo-card .logo_bg {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: #1d1d1d;
    z-index: 0;
}

.desktop_html .logo-card img {
    position: relative;
    z-index: 9;
}


.desktop_html .form-control {
    color: #fff !important;
}

.desktop_html .form-group label.error {
    color: red !important;
    font-size: 14px !important;
    position: absolute;
    left: 0px;
    bottom: -55px;
    height: 39px;
    font-weight: bold;
}

.desktop_html .cnt_form .form-group label.error {
    bottom: -55px;
}

.desktop_html .intl-tel-input {
    display: block;
}

.desktop_html .selected-dial-code {
    color: #fff;
}




.desktop_html #getInTouchForm {
    position: relative;
}

.desktop_html .form-processing {
    display: none;
    width: 100%;
    height: 100%;
    z-index: 1;
    position: absolute;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    text-align: center;
}

.desktop_html .thankyou {
    padding: 100px 0 120px;
}

.desktop_html .thanks_bx h2 {
    font-size: 50px;
    letter-spacing: 1px;
    color: #f9f9f9;
    margin: 20px 0 10px;
    font-family: "Montserrat", sans-serif;
    font-display: swap;
}

.desktop_html .thanks_bx p {
    font-size: 20px;
    line-height: 36px;
    margin: 0 auto 20px;
}

.desktop_html .thanks_bx .btn.btn-dark.btn-line {
    margin: 0;
    padding: 10px 30px;
    margin-top: 15px;
}

.thankyou .btn_page .thanku-btn {
    margin-top: 0px !important;
    border: 1px solid white !important;
    padding: 12px 18px !important;
}

.thankyou .btn_page .thanku-btn:hover {
    border: 1px solid #FFA500 !important;
}

.desktop_html #project_enquery {
    width: 85%;
    left: 8%;
}

.desktop_html .modal#project_enquery .btn-close {
    top: 15px;
    border: 1px solid #fff;
    opacity: 1;
    background: transparent;
    padding: 8px 10px 12px;
    border-radius: 10px;
    position: absolute;
    right: -50px;
}

.desktop_html .modal#project_enquery .btn-close:hover {
    border: 1px solid #e4a400;
    background: transparent !important;
}


/* Thanks you page meeting button */
.meeting-btn {
    margin: 15px auto;
    background-color: #E4A400;
    padding: 12px 60px;
    border-radius: 35px;
    width: 434px;
}

.meeting-btn .btn-link {
    color: white;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    text-transform: uppercase;
}

.meeting-btn .btn-link img {
    padding-left: 10px;
}


/*Case Study CSS*/

.desktop_html .case_hero {
    margin-top: 91px;
    padding: 50px 0;
    text-align: center;
}


.desktop_html .case_hero .case_left_hero {
    padding: 122px 0 0;
}

.desktop_html .case_mob_hero_bx {
    display: none;
}

.desktop_html .case_hero .dsktp-main-img {
    display: block;
}

.desktop_html .case_hero .mob-main-img {
    display: none;

}

.desktop_html .case_left_hero img {
    margin-bottom: 114px;
}

.desktop_html .case_left_hero ul {
    display: flex;
}

.desktop_html .case_left_hero li {
    list-style: none;
    display: inline-block;
    width: 33.33%;
    flex: 0 0 33.33%;
    color: #f9f9f9;
    font-size: 16px;
}

.desktop_html .case_left_hero li span {
    display: block;
    color: rgba(249, 249, 249, 0.41);
    margin-bottom: 23px;
}

.desktop_html .case_tst_bx .title {
    font-weight: 700;
    font-size: 15px;
    line-height: 25px;
    text-align: center;
    color: #F9F9F9;
    min-height: 30px;
}

.desktop_html .case_tst_bx .description {
    /* font-weight: 500;
    font-size: 15px;
    line-height: 28px;
    text-align: center;
    color: #F9F9F9; */
    color: #fff;
    font-size: 14px;
    line-height: 25px;
    min-height: 100px;
}

.desktop_html .case_tst_bx {
    border: 1px solid #333333;
    border-radius: 20px;
    padding: 40px 22px 10px;
    margin-bottom: 20px;
    height: 100%;
    /* width: 350px; */
    /* min-height: 270px; */
}

.desktop_html .case_tst_bx.case_tst_bx-savalogy {
    border: 1px solid #333333;
    border-radius: 20px;
    padding: 40px 22px 10px;
    margin-bottom: 20px;
    min-height: 270px;
}

.desktop_html .case_tst_bx img {
    margin-bottom: 10px;
}

.desktop_html .case_testi {
    padding: 80px 0 120px;
}

.desktop_html .case_testi .tag-line {
    font-size: 32px;
    padding-bottom: 50px;
    text-align: center;
    color: #e4a400;
}

.desktop_html .case_about_us {
    background: url(https://d2e3cmtjkfw2a3.cloudfront.net/static/media/PagarKhata/BG.png);
    background-size: cover;
}

.desktop_html .case_about_sec {
    text-align: center;
    padding: 120px 0;
    max-width: 660px;
    margin: 0 auto;
}

.desktop_html .case_about_sec h2 {
    color: #fff;
    font-size: 40px;
    font-weight: 600;
    margin-bottom: 30px;
}

.desktop_html .case_about_sec p {
    line-height: 32px;
    color: #b2b2b2;
}

.desktop_html .case_process {
    padding: 180px 0;
    text-align: center;
}

.desktop_html .case_process_bx {
    max-width: 632px;
    margin: 0 auto;
    position: relative;
}

.desktop_html .case_process_bx .cp_n {
    width: 177px;
    height: 177px;
    position: absolute;
    background: #101214;
    border: solid 1px #2d2c2c;
    border-radius: 50%;
}

.desktop_html .case_process_bx .cp_n a {
    color: #d5d5d5;
    text-decoration: none;
    font-size: 20px;
    display: inline-block;
    position: absolute;
    width: 100%;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
}

.desktop_html .case_process_bx .cp_n a span {
    display: block;
    margin-bottom: 3px;
}

.desktop_html .case_process_bx .cp_n.cp_1 {
    left: 240px;
    top: -80px;
}

.desktop_html .case_process_bx .cp_n.cp_2 {
    right: -50px;
    top: 100px;
}

.desktop_html .case_process_bx .cp_n.cp_3 {
    right: -50px;
    bottom: 100px;
}

.desktop_html .case_process_bx .cp_n.cp_4 {
    left: 240px;
    bottom: -80px;
}

.desktop_html .case_process_bx .cp_n.cp_5 {
    left: -50px;
    bottom: 100px;
}

.desktop_html .case_process_bx .cp_n.cp_6 {
    left: -50px;
    top: 100px;
}

.desktop_html .case_scope {
    padding: 120px 0;
}

.scop-dsktp-img {
    display: block;
}

.scop-mob-img {
    display: none;
}

.desktop_html .case_scope h2 {
    color: #fff;
    font-size: 40px;
    margin-bottom: 60px;
}

.desktop_html .case_scope h2 img {
    position: absolute;
    right: 0;
}

.desktop_html .case_scope p {
    line-height: 32px;
    color: #b2b2b2;
    max-width: 640px;
}

.desktop_html .case_scope .description {
    line-height: 32px;
    color: #b2b2b2;
    max-width: 640px;
}

.desktop_html .case_scope.caratlane .case_splash {
    margin-top: 0px;
}

.desktop_html .case_scope.caratlane .description-top {
    line-height: 32px;
    color: #b2b2b2;
}

.desktop_html .case_scope.caratlane .description {
    padding: 30px 0px;
    display: block;
}

.desktop_html .case_scope.caratlane .description-mob {
    display: none;
    line-height: 32px;
    color: #b2b2b2;
    padding: 10px 0px;
}

.desktop_html .case_scope.caratlane .scop-list-desktop ul li {
    padding: 20px 0px 20px 50px;
    border: 1px solid #333333;
    border-radius: 20px;
    position: relative;
    color: #B2B2B2;
    font-size: 18px;
    font-weight: 400;
    line-height: 32px;
    margin-bottom: 20px;
    max-width: 460px;
    display: block;
}

.desktop_html .case_scope.caratlane .scop-list-desktop ul li::before {
    position: absolute;
    content: '';
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #B2B2B2;
    left: 6%;
    top: 42%;
}

.desktop_html .case_scope.caratlane .scop-list-mob ul li {
    padding: 5px 0px 5px 10px;
    position: relative;
    color: #B2B2B2;
    font-size: 12px;
    font-weight: 400;
    line-height: 32px;
    max-width: 460px;
    display: none;
}

.desktop_html .case_scope.caratlane .scop-list-mob ul li::before {
    position: absolute;
    content: '';
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background-color: #B2B2B2;
    left: -4%;
    top: 42%;
}

.desktop_html .case_scope .description ul {
    padding-left: 20px;
}

.desktop_html .case_scope .description ul li {
    font-weight: 400;
    font-size: 16px;
    line-height: 32px;
    color: #B2B2B2;
    list-style: disc;

}

.desktop_html .case_scope .case_splash {
    margin-top: 60px;
}

.desktop_html .case_goals {
    background: #000;
    padding: 120px 0;
}

.desktop_html .case_goals .row {
    max-width: 940px;
    width: 100%;
    margin: 0 auto;
}

.desktop_html .case_goals h2 {
    color: #fff;
    font-size: 40px;
    margin-bottom: 30px;
}

.desktop_html .case_goals h3 {
    color: #b2b2b2;
    font-size: 20px;
    line-height: 40px;
    max-width: 630px;
    margin: 0 auto 60px;
    font-weight: normal;
}

.desktop_html .case_goals_bx {
    background: #101214;
    padding: 40px;
    border-radius: 20px;
    box-shadow: 5px 5px 16px 0 rgba(0, 0, 0, 0.2);
    border: solid 1px rgba(0, 0, 0, 0.05);
}

.desktop_html .case_goals_tripp .case_goals_bx {
    padding: 40px 26px;
}

.desktop_html .case_goals_bx h4 {
    color: #f9f9f9;
    font-size: 20px;
    line-height: 34px;
    min-height: 70px;
    margin-bottom: 20px;
}

.desktop_html .case_goals_bx p {
    color: #9c9c9c;
    line-height: 32px;
    font-size: 16px;
}

.desktop_html .case_research {
    background: url(https://d2e3cmtjkfw2a3.cloudfront.net/static/media/PagarKhata/BG_SM.png);
    background-size: cover;
    text-align: center;
    padding: 120px 0;
}

.desktop_html .case_sec_head h2 {
    color: #fff;
    font-size: 40px;
    margin-bottom: 30px;
}

.desktop_html .case_sec_head p {
    line-height: 32px;
    color: #b2b2b2;
    max-width: 832px;
    margin: 0 auto 68px;
}

.desktop_html .case_challenge {
    max-width: 1039px;
    margin: 0 auto;
}

.desktop_html .case_challenge h3 {
    color: #e4a400;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 38px;
}

.desktop_html .case_challenge #case_chase {
    background: #101214;
    border: solid 1px rgba(0, 0, 0, 0.05);
    box-shadow: 5px 5px 16px 0 rgba(0, 0, 0, 0.2);
    padding: 65px 62px 65px 63px;
    border-radius: 20px;
}

.desktop_html .case_challenge h4 {
    color: #e4a400;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 15px;
}

.desktop_html .case_challenge h5 {
    color: #b2b2b2;
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 40px;
    line-height: 40px;
}

.desktop_html .case_challenge ul li {
    color: #b2b2b2;
    font-size: 16px;
    font-weight: 500;
    line-height: 30px;
    list-style: disc;

}

.desktop_html .case_challenge ul {
    margin-bottom: 20px;
    margin-left: 20px;
}

.desktop_html .case_challenge h6 {
    color: #b2b2b2;
    font-size: 20px;
    line-height: 40px;
    font-weight: 600;
    margin-bottom: 10px;
}

.desktop_html .case_challenge p {
    color: #b2b2b2;
    font-size: 20px;
    font-weight: 400;
    margin-bottom: 10px;
}

.desktop_html .case_challenge .carousel-indicators [data-bs-target] {
    width: 10px;
    height: 10px;
    border-radius: 50%;
}

.desktop_html .case_challenge .carousel-indicators {
    right: 40px;
    bottom: auto;
    left: auto;
    margin-right: 0;
    margin-bottom: 0;
    margin-left: 0;
    top: -70px;
}

.desktop_html .case_challenge .carousel-control-next,
.desktop_html .case_challenge .carousel-control-prev {
    top: -67px;
    bottom: auto;
    width: auto;
}

.desktop_html .case_challenge .carousel-control-prev {
    left: auto;
    right: 100px;
}

.desktop_html .case_challenge .carousel-control-next-icon,
.desktop_html .case_challenge .carousel-control-prev-icon {
    width: 1.5rem;
    height: 1.5rem;
}

.desktop_html .case_wire {
    padding: 120px 0;
}

.desktop_html .case_wire li img {
    border-radius: 7px;
}

.desktop_html .case_wire h2 {
    color: #fff;
    font-size: 40px;
    margin-bottom: 30px;
}

.desktop_html .case_wire p {
    line-height: 32px;
    color: #b2b2b2;
    font-size: 20px;
    max-width: 632px;
    margin: 0 auto 68px;
}

.desktop_html .case_wire li {
    flex: 0 0 20%;
    margin: 18px 0;
    list-style: none;
}

.desktop_html .case_care {
    background: url(https://d2e3cmtjkfw2a3.cloudfront.net/static/media/PagarKhata/);
    background-size: cover;
    text-align: center;
    padding: 180px 0;
}

.desktop_html .case_care h2 {
    color: #fff;
    font-size: 40px;
    margin-bottom: 50px;
}

.desktop_html .case_care p {
    line-height: 32px;
    color: #b2b2b2;
    max-width: 584px;
    margin: 0 auto;
}

.desktop_html .case_app {
    padding: 120px 0;
    text-align: center;
}

.desktop_html .case_app h2 {
    color: #fff;
    font-size: 40px;
    margin-bottom: 30px;
}

.desktop_html .case_app p {
    line-height: 32px;
    color: #b2b2b2;
    max-width: 584px;
    margin: 0 auto 30px;
    font-size: 20px;
}

.desktop_html .case_app li {
    list-style: none;
    display: inline-block;
    padding: 0 20px;
}

.desktop_html .case_conclusion {
    background: #000;
    padding: 120px 0;
}

.desktop_html .case_conclusion h2 {
    color: #fff;
    font-size: 40px;
    margin-bottom: 30px;
}

.desktop_html .case_conclusion p {
    line-height: 32px;
    color: #b2b2b2;
    max-width: 720px;
    margin: 0 auto;
    font-size: 16px;
}

.desktop_html .case_other {
    text-align: center;
    padding: 120px 0;
}

.desktop_html .case_other h2 {
    color: #f9f9f9;
    font-size: 40px;
    margin-bottom: 60px;
}

.desktop_html .case_ot_bx {
    position: relative;
}

.desktop_html .case_ot_bx .case_ot_cn {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.desktop_html .cob_img {
    min-height: 137px;
    margin-bottom: 45px;
    display: table;
}

.desktop_html .case_design {
    padding: 120px 0;
    background: #000;
}

.desktop_html .cd_bx h3 {
    color: #e4a400;
    font-size: 30px;
    line-height: 32px;
}

.desktop_html .fnts h2 {
    font-size: 200px;
    color: #9c9c9c;
    line-height: 172px;
    margin-right: 30px;
    font-weight: normal;
}

.desktop_html .fnts_detail h4 {
    font-size: 30px;
    color: #9c9c9c;
    line-height: 32px;
    margin-bottom: 10px;
}

.desktop_html .fnts_detail h5 {
    font-size: 25px;
    color: #9c9c9c;
    line-height: 32px;
    margin-bottom: 10px;
}

.desktop_html .fnts_detail p {
    font-size: 16px;
    color: #9c9c9c;
    line-height: 32px;
    margin-bottom: 10px;
}

.desktop_html .fnts_detail p span {
    display: inline-block;
    margin-right: 10px;
}

.desktop_html .color_bx {
    width: 115px;
    height: 115px;
    border-radius: 20px;
    margin: 0 auto;
}

.desktop_html .color_bx.icon-tg {
    height: 70px;
}

.desktop_html .palette li {
    list-style: none;
    flex: 0 0 20%;
    text-align: center;
    margin-bottom: 15px;
}

.desktop_html .palette li.myshift-logo-palette-1 {
    list-style: none;
    flex: 0 0 25%;
    text-align: center;
    margin-bottom: 15px;
    width: 50%;
}

.desktop_html .palette li.myshift-logo-palette-2 {
    list-style: none;
    flex: 0 0 75%;
    text-align: center;
    margin-bottom: 15px;
    width: 50%;
}

.desktop_html .palette li.gynios-logo-palette-1 {
    list-style: none;
    flex: 0 0 50%;
    text-align: center;
    margin-bottom: 15px;
    width: 50%;
    padding-right: 50px;
}

.desktop_html .palette li.gynios-logo-palette-2 {
    list-style: none;
    flex: 0 0 50%;
    text-align: center;
    margin-bottom: 15px;
    width: 50%;
    padding-right: 50px;
}

.desktop_html .palette li.tg-logo-palette-1 {
    list-style: none;
    flex: 0 0 25%;
    text-align: center;
    margin-bottom: 15px;
    width: 25%;
}

.desktop_html .palette li.tg-logo-palette-2 {
    list-style: none;
    flex: 0 0 25%;
    text-align: center;
    margin-bottom: 15px;
    width: 25%;
}

.desktop_html .palette li.tg-logo-palette-3 {
    list-style: none;
    flex: 0 0 40%;
    text-align: center;
    margin-bottom: 15px;
    width: 40%;
}

.desktop_html .palette li.oberit-logo-palette-1 {
    list-style: none;
    flex: 0 0 50%;
    text-align: center;
    margin-bottom: 15px;
    width: 50%;
}

.desktop_html .palette li.oberit-logo-palette-2 {
    list-style: none;
    flex: 0 0 50%;
    text-align: center;
    margin-bottom: 15px;
    width: 50%;
    padding-right: 50px;
}

.desktop_html .palette li.savvology-logo-palette-1 {
    list-style: none;
    flex: 0 0 60%;
    text-align: center;
    margin-bottom: 15px;
    width: 60%;
}

.desktop_html .palette li.savvology-logo-palette-2 {
    list-style: none;
    flex: 0 0 35%;
    text-align: center;
    margin-bottom: 15px;
    width: 35%;
}

.desktop_html .palette.palette_nozl li {
    list-style: none;
    flex: 0 0 25%;
    text-align: center;
    margin-bottom: 15px;
}

.desktop_html .palette.palette_tripp li {
    list-style: none;
    flex: 0 0 25%;
    text-align: center;
    margin-bottom: 15px;
}

.desktop_html .cd_bx {
    margin-bottom: 60px;
}

.desktop_html .cd_bx_img {
    max-height: 130px;
}

.desktop_html .lst_img {
    max-width: 600px;
    margin: 0 auto;
}

.desktop_html .lst_img li {
    list-style: none;
    flex: 0 0 20%;
    max-width: 20%;
    margin-bottom: 20px;
}

.case-othr-project {
    background-color: #222222;
    ;
    padding: 100px 0px;
}

.case-othr-project .title {
    font-weight: 500;
    font-size: 40px;
    line-height: 60px;
    text-align: center;
    color: #F9F9F9;
    padding-bottom: 20px;
}

.case-othr-project .case-pr-card {
    background-repeat: no-repeat;
    background-position: center;
    height: 450px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: column;
}

.case-othr-project .case-pr-card .logo {
    height: 95px;
    align-items: center;
    justify-content: center;
    display: flex;
}

.case-othr-project .case-pr-card .case-pro-btn .btn.btn-primary {
    border: 2px solid #FDFDFD;
    border-radius: 24.5px;
    background-color: transparent !important;
    border: 1px solid white;
    color: white !important;
    padding: 13px 75px;
    margin-top: 60px;
    font-size: 20px;
    line-height: 30px;
    font-weight: 600;
}

.case-othr-project .case-pr-card .case-pro-btn .btn.btn-primary:hover {
    border: 1px solid transparent !important;
}

.desktop_html .lst_img.lst_img_nzl li {
    list-style: none;
    flex: 0 0 25%;
    max-width: 25%;
    margin-top: 30px;
    margin-bottom: 20px;
}

.desktop_html .case_port {
    padding: 120px 0;
}

.desktop_html .case_port .container {
    max-width: 1350px;
}

.desktop_html .case_port .cp_slide .owl-item .item img {
    width: 300px;
    margin: 0 auto;
}

.desktop_html .case_port h4 {
    color: #e4a400;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 38px;
}

.desktop_html .case_port p {
    max-width: 639px;
}

.desktop_html .case_port .owl-next {
    line-height: 0px !important;
    position: absolute;
    right: -40px !important;
    color: #fff !important;
    font-size: 60px !important;
    top: 50%;
}

.desktop_html .case_port .owl-prev {
    line-height: 0px !important;
    position: absolute;
    left: -40px !important;
    color: #fff !important;
    font-size: 60px !important;
    top: 50%;
}

.desktop_html .tb_nav.sticky {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 10;
}

.desktop_html .header .navbar {
    transition: all ease 0.3s !important;
}

.desktop_html .header.nav-up .navbar {
    -webkit-transform: translateY(-100px);
    -ms-transform: translateY(-100px);
    transform: translateY(-100px);
    -webkit-transition: all ease 0.5s;
    -o-transition: all ease 0.5s;
    transition: all ease 0.5s;
}

.desktop_html .header.nav-down .navbar {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
    -webkit-transition: all ease 0.5s;
    -o-transition: all ease 0.5s;
    transition: all ease 0.5s;
}

.desktop_html .tb_nav.sticky.small {
    display: none;
}


.desktop_html .form-control {
    color: #fff !important;
}

.desktop_html .case_wire .row.otb_wire {
    max-width: 950px;
    margin: 0 auto;
}



.desktop_html .case_wire .row.otb_wire li {
    max-width: 25%;
    flex: 0 0 25%;
    list-style: none;
}


.desktop_html .palette_otb p span {
    display: inline-block;
    margin-right: 20px;
}

.desktop_html .case_wire .row.myshift_wire {
    margin: 0 auto;
}

.desktop_html .case_wire .row.myshift_wire li {
    max-width: 20%;
    flex: 0 0 25%;
    list-style: none;
}

.desktop_html .case_wire.dsktp-wirefrm {
    display: block;
}

.desktop_html .case_wire.mob-wirefrm {
    display: none;
}

/*Mobile App Development Services*/

.desktop_html .sub-services-top {
    padding: 150px 0 100px;
    position: relative;
}

.desktop_html .sb_sr_top {
    max-width: 500px;
}

.desktop_html .sb_sr_top h5 {
    color: #f9f9f9;
    line-height: 80px;
    letter-spacing: 3.2px;
    font-size: 16px;
    text-transform: uppercase;
    font-family: "Montserrat", sans-serif;
    font-display: swap;
}

.desktop_html .sb_sr_top .heading {
    font-size: 50px;
    color: #f9f9f9;
    font-weight: 600;
    line-height: 76px;
    margin-bottom: 20px;
    text-align: left;
}

.desktop_html .sb_sr_top p {
    color: #9c9c9c;
    font-size: 16px;
    line-height: 32px;
    margin-bottom: 42px;
}

.desktop_html .sb_sr_top p a {
    color: #E4A400 !important;
}

.desktop_html .sub-services-top img {
    width: auto;
    position: absolute;
    margin: auto;
    max-height: 604px;
}

.desktop_html .srOurProcess {
    text-align: center;
    color: #f9f9f9;
    padding: 50px 0;
}

.desktop_html .srOurProcess h2 {
    text-align: left;
    font-size: 50px;
    letter-spacing: 1px;
    line-height: 76px;
    margin-bottom: 10px;
}

.desktop_html .srOurProcess h6 {
    text-align: left;
    font-size: 16px;
    line-height: 28px;
    margin-bottom: 70px;
    max-width: 670px;
}

.desktop_html .srb_bx h3 {
    color: #fff;
    font-size: 20px;
    line-height: 32px;
}

.desktop_html .srb_bx p {
    color: #9c9c9c;
    font-size: 14px;
    line-height: 24px;
    max-width: 290px;
    margin: 0 auto;
}

.desktop_html .srb_bx {
    position: relative;
    margin-bottom: 30px;
}

.desktop_html .rgt-rnd-ln {
    position: relative;
    min-height: 428px;
}

.desktop_html .rgt-rnd-ln .rgt-ln {
    position: absolute;
    right: 0;
    top: 60px;
    max-width: 75%;
    max-height: 190px;
}

.desktop_html .srv_testimonials.sub_srv {
    padding: 50px 0;
}

.desktop_html .srv_testimonials.sub_srv .heading {
    text-align: left;
}

.desktop_html .srv_testimonials.sub_srv .heading h2 {
    text-align: left;
}

.desktop_html .SuccessStories {
    padding: 30px 0;
}

.desktop_html .SuccessStories h2 {
    color: #f9f9f9;
    font-size: 50px;
    letter-spacing: 1px;
    margin-bottom: 10px;
}

.desktop_html .SuccessStories h6 {
    font-size: 20px;
    color: #9b7000;
}

.desktop_html #hrv_slider {
    margin-top: 50px;
}

.desktop_html #hrv_slider li {
    list-style: none;
}

.desktop_html .hrv_slide {
    position: relative;
    transition: all 0.5s ease 0s;
    -webkit-transition: all 0.5s ease 0s;
    -moz-transition: all 0.5s ease 0s;
}

.desktop_html .hrv_slide .hrs_detail {
    opacity: 0;
    transition: all 0.5s ease 0s;
    -webkit-transition: all 0.5s ease 0s;
    -moz-transition: all 0.5s ease 0s;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    text-align: center;
}

.desktop_html .hrv_slide .hrs_detail .hrs_bx {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 100%;
    transform: translate(-50%, -50%);
}

.desktop_html .hrv_slide:hover {
    transform: scale(0.98);
}

.desktop_html .hrv_slide:hover .hrs_detail {
    opacity: 1;
}

.desktop_html .hrs_bx .btn.btn-primary {
    padding: 9px 12px;
    margin-top: 20px;
    font-size: 12px;
}

.desktop_html .hrs_bx h4 {
    color: #fff;
    font-size: 30px;
    margin-bottom: 10px;
}

.desktop_html .lets_talk_sus {
    padding: 50px 0;
}

.desktop_html .lets_talk_sus h3 a {
    display: inline-block;
    position: relative;
    overflow: hidden;
    font-size: 60px;
    font-weight: 600;
    color: #f9f9f9;
    letter-spacing: 2px;
}

.desktop_html .lets_talk_sus h3 a:before {
    position: absolute;
    content: attr(data-content);
    /* Prints the value of the attribute */
    top: 0;
    left: 0;
    color: #e4a400;
    overflow: hidden;
    transition: width 505ms ease;
    width: 0;
    white-space: nowrap;
}

.desktop_html .lets_talk_sus h3 a:hover:before {
    width: 100%;
}

.desktop_html .what_do_bx {
    position: relative;
    max-width: 710px;
    padding: 150px 0;
}

.desktop_html .what_do_bx::before {
    content: "";
    position: absolute;
    left: -210px;
    width: 440px;
    height: 440px;
    background: linear-gradient(to top, #5f56e7, #433cac);
    border-radius: 50%;
    top: 0;
}

.desktop_html .what_do_bx img {
    position: absolute;
    left: 220px;
    bottom: 0;
}

.desktop_html .what_do_bx h2 {
    position: relative;
    color: #f9f9f9;
    font-size: 50px;
    margin-bottom: 10px;
}

.desktop_html .what_do_bx p {
    position: relative;
    color: #f9f9f9;
    font-size: 16px;
    line-height: 32px;
}

.desktop_html .wdw-slider {
    padding: 40px 0 100px;
}

.desktop_html .wdw-bx {
    border: 1px solid #fff;
    padding: 30px;
    border-radius: 5px;
    background: #2d2d2d;
}

.desktop_html .wdw-bx img {
    max-width: 70px;
    margin-bottom: 10px;
}

.desktop_html .wdw-bx h3 {
    color: #fff;
    font-size: 20px;
    line-height: 32px;
    max-width: 190px;
    margin-bottom: 10px;
}

.desktop_html .wdw-bx p {
    min-height: 230px;
    font-size: 14px;
    color: #efefef;
    line-height: 28px;
}

.desktop_html #wdw-slide::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 30%;
    height: 100%;
    background: linear-gradient(to right, #101214, rgba(16, 18, 20, 0.5), rgba(16, 18, 20, 0.5));
    z-index: 9;
}

.desktop_html #wdw-slide::after {
    content: "";
    position: absolute;
    right: 0;
    top: 0;
    width: 30%;
    height: 100%;
    background: linear-gradient(to left, #101214, rgba(16, 18, 20, 0.5), rgba(16, 18, 20, 0.5));
    z-index: 9;
}

.desktop_html .error-page {
    padding: 140px 0 60px;
}

.desktop_html .error-page img {
    max-width: 100%;
}

.desktop_html .error-page h2 {
    color: #f9f9f9;
    font-size: 70px;
    letter-spacing: 14px;
    text-transform: uppercase;
    font-family: "Montserrat", sans-serif;
    font-display: swap;
    margin-bottom: 5px;
}

.desktop_html .error-page p {
    color: #f9f9f9;
    font-size: 20px;
    letter-spacing: 0.4px;
    margin-bottom: 20px;
    line-height: 36px;
}

.desktop_html .error-page p a {
    color: #e4a400;
    text-transform: uppercase;
    text-decoration: none;
}

.desktop_html .er_bx_cnt {
    max-width: 437px;
}

.desktop_html .case_testi_aktv .case_tst_bx {
    padding: 50px 40px;
}

.desktop_html .case_testi_aktv .case_tst_bx p {
    min-height: 70px;
}

.desktop_html .intl-tel-input .selected-flag .iti-arrow {
    right: 1px !important;
}

.desktop_html #web_min_gal.owl-carousel .owl-item img {
    width: auto;
    display: block;
    max-width: 100%;
    margin: 0 auto;
}



.desktop_html .desk_proEn {
    padding: 120px 0 100px;
}

.desktop_html #project_enquery_main .pr_en_hd {
    color: #e4a400;
    font-size: 50px;
    letter-spacing: 1px;
}

.desktop_html #project_enquery_main .modal-body {
    padding: 60px 40px;
    max-width: 1170px;
    margin: 0 auto;
}

.desktop_html #project_enquery_main h3 {
    color: #f9f9f9;
    font-size: 30px;
    margin-bottom: 46px;
}

.desktop_html #project_enquery_main label {
    font-size: 20px;
    margin-top: 20px;
}

.desktop_html #project_enquery_main .form-check.form-check-inline .form-check-label {
    margin: 0;
    font-size: 16px;
    color: #b6b6b6;
}

.desktop_html #project_enquery_main .form-check.form-check-inline {
    border: 1px solid #373737;
    border-radius: 10px;
    padding: 15px 40px 15px 70px;
}

.desktop_html #project_enquery_main h6 {
    color: #b6b6b6;
    padding: 25px;
}

.desktop_html .modal#jobFormAndContentDialog .btn-close {
    top: 30px;
    color: #fff !important;
    opacity: 1;
    background: #e4a400;
    padding: 10px 10px;
    border-radius: 50%;
    position: absolute;
    right: 40px;
    line-height: 0;
}

.desktop_html .case_tst_bx.htl_case p strong {
    display: block;
}

.desktop_html .case_tst_bx.htl_case p {
    min-height: 160px;
}

.desktop_html .lst_img.lst_img_hlt {
    max-width: 100%;
}

.desktop_html .lst_img_hlt p {
    font-size: 13px;
}

.modal#goa_trip .modal-dialog {
    max-width: 800px;
    background: transparent;
    border-radius: 5px;
    height: auto;
    margin: 0;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    padding: 0;
}

.modal#goa_trip .modal-dialog .modal-content {
    background: url(https://d2e3cmtjkfw2a3.cloudfront.net/static/media/career/bg.png);
    background-size: cover;
    height: auto;
    padding: 20px 20px 170px 20px;
}

.goa_cnt h3 {
    color: #fff;
    text-align: center;
    font-size: 32px;
    line-height: 55px;
}

.goa-tree {
    position: absolute;
    left: -114px;
    bottom: 8px;
}

.goa-umb {
    position: absolute;
    right: -105px;
    bottom: 22px;
}

.goa_cnt h5 {
    color: #e4a400;
    text-align: center;
    font-size: 18px;
    line-height: 29px;
    margin: -5px auto 30px;
    position: relative;
}

.goa_cnt h5::after {
    content: "";
    position: absolute;
    width: 100px;
    height: 1px;
    background: #e4a400;
    left: 50%;
    bottom: -9px;
    transform: translateX(-50%);
}

.goa_cnt .d-flex {
    margin-top: 29px;
}

.goa_cnt .goCnt p {
    font-size: 18px;
    color: #fff;
    max-width: 449px;
    margin: 0 auto 18px;
}

.goa_cnt .btn.btn-primary {
    background: #fff;
    text-transform: uppercase;
    font-weight: 600;
    border: 0;
    border-radius: 50px;
    letter-spacing: 1px;
    padding: 10px 25px;
    color: #101214;
    overflow: hidden;
    font-size: 14px;
}

.hvr-sweep-to-right::before {
    background: #e4a400 !important;
    overflow: hidden;
}

.goa_cnt .btn.btn-primary:hover {
    color: #fff;
}

.mob_mn {
    display: none;
}

.modal-backdrop.show {
    opacity: 0.9;
}


.desktop_html .desk_proEn .file_input_new {
    background: #101214;
    padding: 16px 70px 16px 20px;
}

.desktop_html .new_srv .container {
    max-width: 1240px;
}

.desktop_html .new_srv {
    padding: 100px 0;
    position: relative;
    background-image: url(https://d2e3cmtjkfw2a3.cloudfront.net/static/media/mobile-dev/bg.png);
    background-size: cover;
}

.desktop_html .new_srv .nav {
    flex-wrap: nowrap;
}

.desktop_html .new_srv .nav li button {
    align-items: center;
    text-align: left;
    border: 1px solid #bababa;
    border-radius: 5px;
    background: transparent;
    opacity: 0.5;
}


.desktop_html .new_srv .nav li button a {
    display: flex;
    padding: 20px 40px;
}

.desktop_html .new_srv .nav li button.active {
    opacity: 1;
    position: relative;
}



.desktop_html .new_srv .nav li button span {
    margin-left: 10px;
}

.desktop_html .new_srv .nav li button.active::after {
    content: "";
    position: absolute;
    left: 50%;
    bottom: -20px;
    border-width: 10px;
    border-style: solid;
    border-color: #bababa transparent transparent;
    transform: translateX(-50%);
}

.desktop_html .new_srv #myTabContent {
    padding: 40px 0 0;
}

.desktop_html .new_srv .md_sr_hd {
    font-size: 50px;
    color: #fff;
    font-weight: 600;
    margin-bottom: 20px;
    margin-top: 100px;
}

.desktop_html .new_srv .md_sr_hd+p {
    font-size: 14px;
    color: #cacaca;
    line-height: 28px;
}

.timer {
    top: 0;
    z-index: 999;
    position: fixed;
    font-weight: 500;
    left: 0;
    width: 100%;
    background: #f1b308;
    color: #333;
    padding-top: 5px;
    padding-bottom: 5px;
}

.timer p {
    color: #333;
    font-weight: 500;
    margin-right: 10px;
}

.timer li {
    list-style: none;
    display: inline-block;
    padding: 3px 0 0 3px;
    color: #333;
}

.timer ul {
    display: inline-block;
}

.timer li span {
    color: #333;
    font-weight: 500;
    margin-right: 5px;
    border-radius: 5px;
    background: rgba(255, 255, 255, 0.5);
    padding: 0 7px;
    min-width: 35px;
    display: inline-block;
    text-align: center;
}

.timer .d-flex>span {
    padding: 3px 10px;
}

.timer li:first-child span {
    display: inline;
}

.mobile_ani {
    display: none;
}

.doNotShow {
    opacity: 0;
    width: 0px;
    height: 0px;
    position: absolute;
    left: 0;
    top: 0;
    z-index: -100;
    overflow: hidden;
}


.termsConditions {
    padding: 150px 0 50px;
}

.conditions {
    box-shadow: 0 0 20px #393d42;
    background-color: #000;
    position: relative;
    padding: 60px;
    border-radius: 0 20px 20px 0px;
    border-left: 10px solid #f1b308;
}

.conditions h2 {
    color: #f1b308;
    margin-bottom: 30px;
}

.conditions h3 {
    color: #f1b308;
    font-size: 20px;
    margin-top: 50px;
    margin-bottom: 15px;
}

.conditions p {
    margin-bottom: 10px;
    font-size: 14px;
    line-height: 24px;
}

.blur_logo {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 0;
    opacity: 0.2;
    width: 600px;
}

@-webkit-keyframes scroll {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(calc(-500px * 12));
    }
}

@keyframes scroll {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(calc(-500px * 12));
    }
}

@-webkit-keyframes scrollRoll {
    0% {
        transform: translateX(calc(-500px * 12));
    }

    100% {
        transform: translateX(0);
    }
}

@keyframes scrollRoll {
    0% {
        transform: translateX(calc(-500px * 12));
    }

    100% {
        transform: translateX(0);
    }
}

.manualSlide {
    margin: auto;
    overflow: hidden;
    position: relative;
    width: 100%;
}

.manualSlide .manualSlideBox {
    display: flex;
    width: calc(500px * 15);
}

.manualSlide .item {
    width: 500px;
    padding: 20px;
    text-align: center;
    align-self: center;
}

.manualSlide .item img {
    max-width: 100%;
}

.manualSlide .leftHover {
    position: absolute;
    left: 0;
    top: 0;
    width: 50%;
    height: 100%;
    cursor: pointer;
    z-index: 9;
}

.manualSlide .rightHover {
    position: absolute;
    right: 0;
    top: 0;
    width: 50%;
    height: 100%;
    cursor: pointer;
    z-index: 9;
}



.lft_imf {
    position: absolute;
    max-width: 160px;
    left: 75%;
    top: 25px;
}

.rft_imf {
    position: absolute;
    max-width: 160px;
    right: 75%;
    top: 25px;
    transform: rotateY(180deg);
}

.lft_imf_big {
    position: absolute;
    max-height: 255px;
    right: -80px;
    top: 30px;
}

.desktop_html .srb_bx {
    display: none;
}

.desktop_html .srb_bx img {
    display: none;
}


.mb-service {
    font-family: Poppins;
    font-display: swap;
    font-weight: normal;
    font-size: 24px;
    text-align: inherit;
    color: #9c9c9c;
    background: transparent;
    border: transparent;
    padding: 25px;
    padding-left: 60px;
    border-left: 4px solid #9c9c9c;
}

.sub-service-services .mb-service.active {
    color: #f1b308 !important;
    border-left: 4px solid rgba(241, 179, 8, 0.83) !important;
}


.tabcontent {
    display: none;
}

.tabcontent:nth-child(1) {
    display: block;
}

.faq-Right {
    border-right: 0.5px solid #e4a400;
}

.faq-left {
    border-left: 0.5px solid #e4a400;
}

.faq-circle {
    position: absolute;
    width: 25px;
    height: 25px;
    left: 49%;
    top: 45%;
    border-radius: 50%;
    background: #e4a400;
}

.box_container {
    width: auto;
    height: auto;
    perspective: 1450px;
    margin: auto;
    margin-top: 60px;
    transform-style: preserve-3d;
    padding-left: 15px;
    padding-right: 15px;
}

.carousel .box_container:before {
    content: " ";
    display: block;
    border-radius: 4px;
    background: rgba(255, 255, 255, 0.04);
    width: 87%;
    height: 97%;
    position: absolute;
    /* top: -2.6%;
  left: 11.5%; */
    z-index: -1;
}

.carousel .box_container .top-active {
    padding: 12%;
}

.ios-row {
    display: flex;
    width: 100%;
}

#themes .column {
    position: relative;
    /* width: 20%; */
    width: 25%;
    height: 580px;
    display: inline-block;
    transition: width 0.6s cubic-bezier(0.16, 1, 0.3, 1);
    -webkit-transition: width 0.6s cubic-bezier(0.16, 1, 0.3, 1);
}

#themes .column .box-1 {
    width: 100%;
    height: 150px;
    display: block;
    padding: 30px;
}

#themes .column .box-1::before {
    width: 100%;
    height: 100%;
    content: "";
    left: 0;
    bottom: 0;
    border-radius: 4px;
    position: absolute;
    background: rgba(255, 255, 255, 0.04);
    filter: progid: DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#a60e0f0f', GradientType=0);
    z-index: 9;
}

#themes .column .box-1 .theme-bg {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: cover;
    transform: translateX(-100%) translateY(0px);
    filter: grayscale(1);
    -webkit-filter: grayscale(1);
    transition: all 0.3s ease-out;
    -webkit-transition: all 0.3s ease-out;
}

#themes .column .box-1 .text {
    /* position: absolute;
  bottom: 40px;
  left: 0; */
    /* padding: 0 20px; */
    width: 100%;
    z-index: 199;
    text-align: center;
    /* display: flex; */
    justify-content: space-between;
    color: #fff;
    z-index: 91;
    transition: all 0.3s ease-out;
    -webkit-transition: all 0.3s ease-out;
}

.theme-bx-mv {
    min-height: 360px;
    max-height: 400px;
}

#themes .column .box-1 .text .title {
    font-size: 2rem;
    line-height: 1.3;
    color: #fff;
    font-family: "Assistant", sans-serif;
    font-display: swap;
    font-weight: 600;
    margin: auto;
}

#themes .column .box-1 .text .address_del {
    /* position: absolute;
  right: 40px;
  top: 0; */
    line-height: 1.67;
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s ease-out;
    -webkit-transition: all 0.3s ease-out;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #9c9c9c;
}

#themes .column .box-1 .text .title b {
    display: block;
    color: #f1b308;
    font-size: 1.5rem;
}

#themes .column .box-1 .text .address_del i svg {
    width: 22px;
    height: 22px;
    display: block;
    margin-bottom: 3px;
}

.ios-sec-box #themes {
    background-color: transparent;
    height: auto;
    margin-top: 30px;
}

.ios-sec-box #themes .column {
    height: auto;
    margin-left: 20px;
    margin-right: 25px;
}

.ios-sec-box #themes .column.not_hover {
    width: 20%;
    height: 150px;
}

.ios-sec-box #themes .column.hover {
    width: 20%;
    min-height: 350px;
}

#themes .column.hover .box-1 .text .title {
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    text-align: center;
    color: rgba(241, 179, 8, 0.83);
}

#themes .column.hover .box-1 .text .address_del {
    margin-top: 20px;
    opacity: 1;
    visibility: visible;
    transition-delay: 0.1s;
    -webkit-transition-delay: 0.1s;
}

@media (min-width: 768px) {

    #themes .column.hover .box-1 .text {
        opacity: 1;
        visibility: visible;
    }

    #themes .column.hover .box-1 .theme-bg {
        filter: grayscale(0);
        -webkit-filter: grayscale(0);
    }

}

@media (max-width: 1366px) {
    #themes .column .box-1 .text .title {
        font-size: 1.7rem;
    }

    #themes .column .box-1 .text .title b {
        font-size: 1.3rem;
    }

}

@media (max-width: 991px) {
    .block_chain .sec-3 .prev-block {
        left: 15% !important;
    }

    .block_chain .sec-3 .next-block {
        right: 15% !important;
    }

    .desktop_html .case_challenge #case_chase {
        min-height: 500px;
    }

    .desktop_html .case_tst_bx .title {
        min-height: 50px;
    }

    #themes .column .box-1 .text {
        padding: 0 20px;
    }

    .faq-Right {
        border: none;
    }

    .faq-circle {
        display: none;
    }

    .faq-left {
        border: none;
    }

    .sr_faq .accordion-faq ul.accordion-list {
        padding-top: 0px !important;
        padding-bottom: 0px !important;
    }


    .getin_touch_form form textarea {
        max-height: 100px;
    }

    .desktop_html .srb_bx {
        display: block !important;
    }

    .desktop_html .srb_bx img {
        display: none !important;
    }

    .desktop_html img {
        max-width: 100%;
    }

    .desktop_html .case_left_hero li {
        font-size: 12px;
    }

    .desktop_html .case_left_hero li span {
        margin-bottom: 10px;
    }

    .desktop_html .case_left_hero img {
        max-width: 150px;
        margin-bottom: 90px;
    }

    .desktop_html .case_testi {
        padding: 20px 0 50px;
    }

    .desktop_html .case_testi .tag-line {
        font-size: 28px;
        padding-bottom: 30px;
        line-height: 40px;
    }

    .desktop_html .case_about_sec {
        padding: 70px 0;
    }

    .desktop_html p {
        font-size: 14px;
    }

    .desktop_html .case_process {
        padding: 0 0 80px;
    }

    .desktop_html .case_process img {
        display: none;
    }

    .desktop_html .case_process_bx .cp_n {
        width: 39%;
        height: inherit;
        position: static;
        background: #101214;
        padding: 15px 0;
        margin: 0 5% 15px;
        border: solid 0.5px #9c9c9c;
        border-radius: 15px;
        display: inline-block;
    }

    .desktop_html .case_process_bx .cp_n a {
        position: static;
        transform: translateY(0%);
        font-size: 15px;
    }

    .desktop_html .case_scope h2 img {
        display: none;
    }

    .desktop_html .case_wire h2,
    .desktop_html .lets_talk h3,
    .desktop_html .case_conclusion h2,
    .desktop_html .case_app h2,
    .desktop_html .case_care h2,
    .desktop_html .case_sec_head h2,
    .desktop_html .case_goals h2,
    .desktop_html .case_scope h2 {
        font-size: 30px;
        margin-bottom: 20px;
    }

    .desktop_html .case_goals_bx p {
        line-height: 28px;
        font-size: 14px;
    }

    .desktop_html .case_goals h3 {
        font-size: 15px;
        line-height: inherit;
        margin: 0 auto 40px;
        font-weight: normal;
    }

    .desktop_html .case_challenge p {
        font-size: 14px;
        margin-bottom: 10px;
    }

    .desktop_html .case_challenge h5 {
        font-size: 16px;
    }

    .desktop_html .case_wire p {
        line-height: inherit;
        font-size: 14px;
        margin: 0 auto 40px;
    }

    .desktop_html .palette li {
        flex: 0 0 25%;
    }

    .desktop_html .cd_bx img {
        max-width: 100% !important;
    }

    .desktop_html .lst_img li {
        flex: 0 0 33%;
        max-width: 33%;
    }

    .mob_mn {
        display: block;
    }

    .web_mn {
        display: none !important;
    }

    .desktop_html .d-mobile-none {
        display: none !important;
    }

    .desktop_html .d-desk-none {
        display: block !important;
    }

    .desktop_html .header_min {
        padding: 89px 0 22px
            /*32px 0*/
        ;
        position: fixed;
        z-index: 9;
        left: 0;
        top: 0;
        width: 100%;
        background: #000;
    }

    .desktop_html .header_min a+span {
        position: absolute;
        color: #414141;
        left: 65px;
        top: 4px;
        font-weight: 500;
        font-size: 15px;
    }

    .desktop_html .header_min .container {
        position: relative;
    }

    .desktop_html .mobile-menu {
        position: fixed;
        left: 0;
        top: 0;
        z-index: 999;
        width: 100%;
        background: #101214;
        height: 100%;
        text-align: right;
        overflow: auto;
        padding: 20px 0;
        display: none;
    }

    .desktop_html .mobile-menu a {
        color: #f9f9f9;
        text-decoration: none;
        font-size: 22px;
        font-weight: 500;
    }

    .desktop_html .mobile-menu li {
        padding: 15px 0;
        list-style: none;
        display: block;
    }

    .desktop_html .mobile-menu.active {
        display: block;
    }

    .desktop_html .mobile-menu .btn.btn-primary {
        text-transform: uppercase;
        overflow: hidden;
        background: #e4a400 !important;
        border: 0;
        border-radius: 50px;
        color: #fff;
        letter-spacing: 1.6px;
        padding: 11px 22px;
        font-size: 13px;
    }

    .desktop_html .header .header_min {
        transition: all ease 0.3s !important;
    }

    .desktop_html .header.nav-up .header_min {
        -webkit-transform: translateY(-100px);
        -ms-transform: translateY(-100px);
        transform: translateY(-100px);
        -webkit-transition: all ease 0.5s;
        -o-transition: all ease 0.5s;
        transition: all ease 0.5s;
    }

    .desktop_html .header.nav-down .header_min {
        -webkit-transform: translateY(0);
        -ms-transform: translateY(0);
        transform: translateY(0);
        -webkit-transition: all ease 0.5s;
        -o-transition: all ease 0.5s;
        transition: all ease 0.5s;
    }

    .modal#goa_trip .modal-dialog .modal-content {
        background-image: url(https://d2e3cmtjkfw2a3.cloudfront.net/static/media/career/bg.png);
        background-size: 140%;
        height: auto;
        padding: 10px 30px 120px 40px;
        background-repeat: no-repeat;
        background-color: #222;
        background-position: -50px bottom;
        overflow: hidden;
    }

    #goa_trip .modal-dialog {
        max-width: 100% !important;
        bottom: 0 !important;
        top: initial !important;
        transform: translate(0, 0) !important;
        left: 0 !important;
        margin: auto !important;
        position: absolute;
    }

    .goa-tree {
        left: -98px;
        bottom: -2px;
        max-height: 310px;
    }

    .goa-umb {
        right: -115px;
        bottom: 2px;
        max-width: 170px;
    }

    #goa_trip .btn-close {
        display: none;
        top: -75px !important;
        right: 50% !important;
        border: 2px solid #dedede;
        border-radius: 50%;
        padding: 1px;
        line-height: 0;
        font-size: 50px !important;
        cursor: pointer;
        z-index: 99;
        margin-right: -40px;
    }

    #goa_trip .mobile_tn {
        display: block !important;
    }

    .goa_cnt .btn.btn-primary.mob_mn.closeModal {
        padding: 12px 60px;
        max-width: 210px;
        margin: 0 auto;
        background: #f1b308;
    }

    .timer .d-flex>span {
        padding: 3px 5px;
    }

    .timer p {
        text-align: center;
        color: #333 !important;
    }

    .desktop_html #project_enquery .pr_en_hd {
        color: #e4a400;
        font-size: 30px;
        max-width: 180px;
        margin-bottom: 40px;
        letter-spacing: 0.6px;
        line-height: 45px;
        margin-top: 20px;
    }

    .desktop_html #project_enquery h3 {
        margin: 0;
        font-size: 20px;
        letter-spacing: 0.4px;
        line-height: 48px;
    }

    .desktop_html .form-group {
        margin-bottom: 35px;
        position: relative;
    }

    .desktop_html .form-group .fa.fa-star {
        font-size: 12px;
        position: absolute;
        bottom: 13px;
        right: 13px;
        color: #e4a400;
    }

    .desktop_html .form-group input.form-control {
        font-size: 12px;
        background: transparent;
        border: 1px solid #373737;
        height: 45px;
    }

    .desktop_html .form-group textarea.form-control {
        background: transparent;
        border: 1px solid #373737;
        height: 105px;
        font-size: 12px;
    }

    .desktop_html .cnt_form {
        margin: 0 0 80px;
    }

    .desktop_html .form-group label {
        color: #f9f9f9;
        margin-bottom: 15px;
        margin-top: 30px;
        font-size: 17px;
    }

    #enquiryForm .d-flex {
        display: block !important;
    }

    .desktop_html #project_enquery .modal-body {
        padding: 0 0;
    }

    .desktop_html #project_enquery .container {
        padding: 0 17px;
    }



    .desktop_html #project_enquery label {
        margin-top: 0;
        font-size: 17px;
        font-weight: 600;
    }

    .desktop_html #project_enquery .form-check.form-check-inline {
        border: 0;
        border-radius: 10px;
        padding: 0 0 0 30px;
        margin-bottom: 20px;
        font-weight: 600;
    }

    .desktop_html #project_enquery .form-check.form-check-inline .form-check-label {
        margin: 0;
        font-size: 16px;
        color: #fff;
    }

    .desktop_html #project_enquery .validation-mgs {
        padding: 25px 0;
    }

    .desktop_html #project_enquery {
        padding-top: 20px;
    }

    .timer p {
        text-align: center;
        color: #333 !important;
        font-size: 14px !important;
        line-height: 29px !important;
    }

    .desktop_html .lg_footer .btn.btn-primary {
        color: #fff;
        letter-spacing: 1.2px;
        padding: 10px 25px;
        font-size: 14px;
        background: #f1b308;
    }

    .desktop_html #project_enquery .form-group .btn.btn-dark.btn-line {
        padding: 12px 50px;
        margin-top: 0;
        background: #e4a400;
    }

    .desktop_html .modal#project_enquery .btn-close {
        border: 1px solid #333;
        border-radius: 50%;
        color: #e4a400 !important;
    }

    .desktop_html .new_srv_dm.new_srv .nav li button {
        padding: 10px 10px;
    }

    .desktop_html .new_srv_dm.new_srv .nav li {
        max-width: 50%;
        flex: 0 0 50%;
    }

    .desktop_html .sub-services-top img {
        position: absolute;
        margin-top: 10px;
        opacity: 0.1;
        z-index: 0;
    }

    .desktop_html .sb_sr_top {
        position: relative;
        z-index: 1;
    }

    .desktop_html .new_srv .nav li {
        max-width: 50%;
        flex: 0 0 50%;
    }

    .desktop_html .new_srv .nav {
        flex-wrap: wrap;
    }

    .desktop_html .new_srv .nav li button {
        font-size: 12px;
        min-height: 60px;
        opacity: 0.3;
    }

    .desktop_html .new_srv .nav li button.nav-link {
        padding: 0;
    }

    .desktop_html .new_srv .nav li button a {
        padding: 10px 6px;
    }

    .desktop_html .new_srv .nav li button img {
        max-height: 25px;
    }

    .desktop_html .new_srv .nav li button.active::after {
        display: none;
    }

    .desktop_html .new_srv .md_sr_hd {
        font-size: 20px;
        margin-top: 50px;
    }

    .desktop_html .new_srv .md_sr_hd+p {
        font-size: 13px;
        line-height: 24px;
    }

    .modal .modal-dialog {
        max-width: 700px;
    }

    .desktop_html .case_port .owl-prev {
        left: -10px !important;
    }

    .desktop_html .case_port .owl-next {
        right: 10px !important;
    }

    .meeting-btn {
        width: 350px;
        padding: 10px;
    }

    .meeting-btn .btn-link {
        font-size: 14px;
    }
}



/*reetu */

@font-face {
    font-family: "SignPainter HouseScript";
    src: url("../fonts/SignPainterHouseScript.eot");
    src: url("../fonts/SignPainterHouseScript.eot?#iefix") format("embedded-opentype"), url("../fonts/SignPainterHouseScript.woff2") format("woff2"), url("../fonts/SignPainterHouseScript.woff") format("woff"), url("../fonts/SignPainterHouseScript.ttf") format("truetype"), url("../fonts/SignPainterHouseScript.svg#SignPainterHouseScript") format("svg");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

.our-interns {
    padding: 80px 0;
}


.intern-content h2 {
    font-family: Poppins;
    font-display: swap;
    font-size: 25px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    max-width: 250px;
    text-align: left;
    margin: 0 auto;
    line-height: 30px;
    letter-spacing: normal;
    color: #fff;
}

.intern-content span {
    font-family: "SignPainter HouseScript";
    font-size: 60px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    display: block;
    color: #f1b442;
}

#intern-slider .item h4 {
    font-family: Poppins;
    font-size: 20px;
    font-weight: 600;
    line-height: 30px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #FFC400;
    padding: 20px;
}

#intern-slider .item h4 span {
    font-family: Poppins;
    font-size: 18px;
    font-weight: 500;
    line-height: 27px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    display: block;
    color: #fff;
}

.our-interns .item {
    border-radius: 4px;
    overflow: hidden;
    /* background: #222; */
}

#intern-slider-mob .item h4 {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #fff;
    padding: 15px;
}

#intern-slider .item h4 span {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    display: block;
    color: #fff;
}

#intern-slider-mob .item h4 span {
    display: block;
}


/*about-meet*/

.ab_meet .col-md-3 {
    flex: 0 0 auto;
    width: 25%;
}

.port-logo {
    display: flex;
    align-items: baseline;
    width: 100%;
}

.port-logo span {
    font-size: 40px;
    margin-left: 6px;
    position: relative;
    top: -5px;
}


.mob-port-logo span {
    top: 12px;
    position: relative;
    font-size: 22px;
}


/*reetu */

.aso-page .testimonial-block {
    padding: 50px 0px;
}

.aso-sec-1 {
    background-image: url(https://d2e3cmtjkfw2a3.cloudfront.net/static/media/digital/aso/aso-main.png);
    background-size: cover;
}

.aso-sec-1 .main_content {
    margin: auto;
}

.aso-sec-1 .main_content .heading {
    font-weight: 600;
    font-size: 50px !important;
    line-height: 75px;
}

.aso-sec-2 {
    padding: 50px 0px;
    background-image: url(https://d2e3cmtjkfw2a3.cloudfront.net/static/media/digital/aso/app-img.svg);
    background-repeat: no-repeat;
}

.aso-sec-2 .aso-sec-2-left .heading {
    font-weight: 600;
    font-size: 42px;
    line-height: 51px;
    color: #F1B308;
    padding-bottom: 30px;
}

.aso-sec-2 .aso-sec-2-left .description {
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
}

.aso-sec-2 .aso-sec-2-right .right-boxes .right-card-coln {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-top: 100px;
}

.aso-sec-2 .aso-sec-2-right .right-boxes::before {
    position: absolute;
    content: '';
    background-color: #D9D9D9;
    opacity: 0.1;
    width: 500px;
    height: 500px;
    border-radius: 50%;
    z-index: -1;
    /* left: 55%; */
}

.aso-sec-2 .aso-sec-2-right .card-box {
    background: #000000;
    /* box-shadow: hwb(0deg 70% 26% / 15%) -2.05px -2.05px 9.6px; */
    /* box-shadow: hwb(0deg 70% 26% / 15%) 1.95px 1.95px 2.6px; */
    /* box-shadow: -1px 2px 13px rgb(255 255 255 / 10%); */
    border-radius: 25px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 37px 0;
    max-width: 206px;
    box-shadow: -1px -1px 1px 0 #5f5f60d1;
}

.aso-sec-2 .aso-sec-2-right .card-box .numbr {
    font-weight: 400;
    font-size: 64px;
    line-height: 83px;
    color: #E9B43F;
    padding: 0px 30px
}

.aso-sec-2 .aso-sec-2-right .card-box .numbr sup {
    color: #ffffff;
    font-weight: 200;
    font-size: 35px;
    line-height: 52px;
    top: -34px;
}

.aso-sec-2 .aso-sec-2-right .card-box .title {
    font-weight: 300;
    font-size: 24px;
    line-height: 33px;
    color: #FFFFFF;
    padding: 0px 30px
}


.aso-sec-3 {
    background-color: #000000;
    padding-top: 50px;
}

.aso-sec-3 .aso-sec-3-content {
    text-align: center;
    margin: 0 auto;
    padding-bottom: 50px;
}

.aso-sec-3 .aso-sec-3-content .aso-sec-3-content-width {
    margin: 0 auto;
}

.aso-sec-3 .aso-sec-3-content .title {
    font-weight: 600;
    font-size: 42px;
    line-height: 54px;
    color: #F1B308;
}

.aso-sec-3 .aso-sec-3-content .description {
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    text-align: center;
    color: #FFFFFF;
    padding-top: 30px;
}

.aso-sec-3 .desktop_html .cl_bx {

    max-width: 900px;
    border-radius: 20px;

    border: none;
}

.aso-sec-3 .cl_bx .heading {
    text-align: left;
    font-weight: 600;
    font-size: 28px;
    line-height: 28px;
    color: #F1B308;

}

.aso-sec-3 .noteworthy {
    padding-bottom: 0px;
}

.aso-sec-3 .srvc-slider-img img {
    max-width: 300px;
}

.aso-sec-3 .cl_bx .right-content {
    display: flex;
    justify-content: center;
    flex-direction: column;
    min-height: 400px;
}

.aso-sec-3 .cl_bx .description {
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    text-align: left;
    color: #F9F9F9;
}

.aso-sec-3 .carousel-indicators {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 15%;
    z-index: 2;
    display: flex;
    justify-content: center;
    padding: 0;
    margin-right: 15%;
    margin-bottom: 1rem;
    margin-left: 15%;
    list-style: none;
}

.aso-sec-3 .carousel-indicators [data-bs-target] {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    padding: 0;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #fff;
    background-clip: padding-box;
    border: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: .5;
    transition: opacity .6s ease;
}

.aso-sec-3 .carousel-indicators .active {
    background-color: #ffb700;
    opacity: 1;
}

.aso-sec-3-img {
    display: flex;
    justify-content: center;
    align-items: center;
}

.aso-sec-3 .mob-aos-service {
    display: none;
}

.aso-sec-3 .aos-srvc-content {
    text-align: left;
}

.aso-sec-3 .aos-srvc-content .heading {
    color: #ffb700;
    padding-bottom: 10px;
    font-size: 20px;
}

.aso-sec-4 .service-block .service-cards .srv-card-content {
    background: #08122A;
    box-shadow: 0px 4px 8px rgba(112, 112, 112, 0.2), -4px -4px 8px rgba(112, 112, 112, 0.2);
    border-radius: 14px;
    padding: 50px;
    min-height: 300px;
    max-width: 600px;
}

.aso-sec-4 .service-block .service-cards .srv-card-content.blue-bx {
    background-image: linear-gradient(110.17deg, rgba(138, 226, 194, 0.8) -28.21%, #0B1227 102.07%);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), -4px -4px 7px rgba(146, 143, 143, 0.25);
}

.aso-sec-4 .service-block .service-cards .srv-card-content .srv-card-title {
    font-weight: 300;
    font-size: 22px;
    line-height: 28px;
    align-items: center;
    padding-bottom: 0px;
    min-height: 80px;
}

.aso-sec-4 .service-cards .srv-card-content .para {
    color: #ffb700;
    font-weight: 400;
    font-size: 24px;
    line-height: 48px;
    padding-bottom: 30px;
}

.aso-sec-5 {
    padding: 50px 0px;
}

.aso-sec-5 .custom-card-list-wrap-dsktp {
    display: block;
}

.aso-sec-5 .custom-card-list-wrap-mobile {
    display: none;
}

.aso-sec-5 .aso-sec-5-content-width {
    margin: 0 auto;
    max-width: 950px;
}

.aso-sec-5 .aso-sec-5-content .title {
    font-size: 42px;
    line-height: 54px;
    text-align: left;
    color: #E4A400;
    font-weight: 600;
    text-align: center;
}

.aso-sec-5 .aso-sec-5-content .description {
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    text-align: left;
    color: #fff;
    padding: 30px 0px;
    text-align: center;
}

.aso-sec-5 .aso-store-list .store-title .title {
    font-weight: 500;
    font-size: 20px;
    line-height: 32px;
    text-align: center;
    padding: 30px;
    border-bottom: 1px solid white;
    color: black;
    background-color: white;
    border-radius: 20px 20px 0px 0px;

}



.aso-sec-5 .aso-store-list {
    border-radius: 28px;
    min-height: 630px;
    position: relative;
    background-color: black;

    /* box-shadow: 0px 1px 21px rgba(135, 135, 135, 0.25); */
}

.aso-sec-5 .aso-store-list::before {
    content: '';
    position: absolute;
    height: 97%;
    width: 101%;
    z-index: -1;
    border-radius: 29px;
    background-image: linear-gradient(5deg, #e1e1e1bf 0, #000 75%);
    /* background-image: linear-gradient(0deg,#e1e1e1bf 0,#25262608 100%); */
    /* background: linear-gradient(90deg, rgba(255,255,255,0.01) 0%, rgba(255,255,255,0.27494747899159666) 92%); */
    margin-left: -1px;
    margin-top: 20px;
}

.aso-sec-5 .aso-store-list .store-menu-li {
    padding: 19px 20px;
    color: #C4C4C4;
    border-bottom: 0.5px solid rgba(255, 255, 255, 0.38);
    text-align: center;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
}

.aso-sec-5 .aso-store-list .store-menu-li:last-child {
    border-bottom: none;
}

.aso-sec-5 .aso-store-list.active .store-menu-li {
    color: #fff;

}

.aso-sec-6 {
    background-color: #000000;

}

.aso-sec-6 .aso-sec-6-content {
    padding: 50px 0px;
}

.aso-sec-6 .aso-sec-6-content-width {
    margin: 0 auto;
    padding-bottom: 20px;
}

.aso-sec-6 .aso-sec-6-content .title {
    font-size: 35px;
    line-height: 54px;
    color: #FFFFFF;
    font-weight: 600;
}

.aso-sec-6 .aso-sec-6-content .sub-title {
    font-weight: 600;
    font-size: 32px;
    line-height: 54px;
    color: #E4A400;
}

.aso-sec-6 .aso-sec-6-content .description {
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    color: #E4A400;
    padding: 20px 0px;
}

.aso-sec-6 .tech-box {
    /* background-color: white;
    border: 2px solid #E4A400;
    border-radius: 15px; */
    height: 130px;
    display: flex;
    justify-content: center;
    align-items: center;

}

.dsktp-aso-sec-7 {
    display: block;
}

.mobile-aso-sec-7 {
    display: none;
}

.aso-sec-7 {
    background-color: #000000;
    padding: 50px 0px;
}

.aso-sec-7 .aso-sec-7-contant .sub-heading {
    font-weight: 300;
    font-size: 25px;
    line-height: 35px;
    margin-top: 30px;
}

.aso-sec-7 .aso-sec-7-contant .heading {
    font-weight: 600;
    font-size: 35px;
    line-height: 54px;
    color: #F1B308;

}

.aso-sec-7 .aso-sec-7-contant .heading span {
    font-weight: 300;
    color: #fff;
}

.aso-sec-7 .aso-sec-7-contant .description {
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    color: #E9E9E9;
    padding: 26px 0px 56px;
}

.aso-sec-7 .aso-sec-7-list {
    padding: 39px 27px;
    height: 100%;
    background: #101214;
    border-radius: 20px;
}



.aso-sec-7 .aso-sec-7-list .title {
    font-weight: 500;
    font-size: 30px;
    line-height: 110%;
    text-align: center;
    min-height: 70px;
}

.aso-sec-7 .aso-sec-7-list .description {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    padding-top: 24px;
    color: #ffffff;
}

/* neha */

.digital-sub-sec-9 .heading {
    text-align: left !important;
}


.digital-sub .sec-6-hr {
    width: 100%;
}

.digital-sub .sec-6-but {
    width: 100%;
}

.digital-sub .line {
    border: 1px solid #E4A400;
    opacity: 1;
}

.digital-sub .right-sub-text {
    color: #D5AA4A;
    font-weight: 600;
    font-size: 50px;
}

.digital-sub .hvr-sweep-to-right {
    margin-top: -5px;
}

.digital-sub .left-sub-box {
    background: linear-gradient(89.02deg, rgba(39, 39, 39, 0.2914) 1.01%, rgba(39, 39, 39, 0.3149) 99.35%);
    padding: 35px 41px;
    text-align: center;
}

.digital-sub .left-sub-box h2 {
    color: #DFDFDF;
    font-weight: 500;
    font-size: 16px;
    text-align: center;
}

.digital-sub {
    padding-bottom: 50px;
}

.left-sub-box .box-sec {
    width: 80%;
    margin: auto;
}

.digital-col-sec2 {
    padding: 30px;
    padding-left: 0px;
}

.digital-col-sec2 .col-block {
    padding: 20px;
    border-width: 2px;
    height: 100%;
    border-style: solid;
    border-image: linear-gradient(180deg, #ABABAB, rgba(0, 0, 0, 0)) 1;
}

.digital-col-sec2 .col-block .col-text {
    font-weight: 500;
    font-size: 22px;
    color: #E4A400;
}

.digital-col-sec2 .col-block p {
    font-weight: 400;
    font-size: 18px;
    color: #ABABAB;
    margin-top: 25px;
}

.digital-sec1 {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    border-radius: 0 0 1000px 1000px;
    background-image: url('https://d2e3cmtjkfw2a3.cloudfront.net/static/media/ui/headDigital.png');
}

.top-digital-head.hm_banner {
    position: relative;
    height: calc(100vh + 100vh/4);
}

.top-digital-head.hm_banner::before {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgb(0 0 0 / 0.4);
    content: '';
}

.top-digital-head .ban_caption {
    position: absolute;
    left: 50%;
    top: 20%;
    width: 900px;
    transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.top-digital-head .ban_caption h1 {
    font-size: 50px;
    font-weight: 600;
    margin-bottom: 80px;
    position: relative;
    line-height: 75px;
}

.top-digital-head .ban_caption p {
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 30px;
    line-height: 24px;
}

.top-digital-head .digital-head-img img {
    border-radius: 0 0 1000px 1000px;
    width: 100%;
}

.hire-col-sec2 .col-block {
    padding: 20px;
    border-width: 2px;
    height: 100%;
    border-style: solid;
    border-image: linear-gradient(180deg, #ABABAB, rgba(0, 0, 0, 0)) 1;
}

.hire-col-sec2 .col-block .col-text {
    font-weight: 500;
    font-size: 24px;
    color: #E4A400;
    min-height: 75px;
}

.hire-col-sec2 .col-block p {
    font-weight: 400;
    font-size: 18px;
    color: #ABABAB;
    margin-top: 25px;
}

.input-div {
    text-align: -webkit-right;
}

.tab-hire {
    margin-top: 2.5rem;
}

.sideTab-hire {
    box-shadow: 0px 1px 13px rgba(108, 108, 108, 0.25);
}

.sideTab-hire .tabcontent {
    padding: 68px;
}

.sub-service-services .sideTab-hire-tab .tab-hire button.active {
    color: black !important;
    font-weight: bold;
    width: 100%;
    background-color: rgba(228, 164, 0, 0.9) !important;
    position: relative;
}

.tab-hire .mb-service {
    font-family: Poppins;
    font-weight: normal;
    font-size: 24px;
    text-align: center;
    color: #9c9c9c;
    background: transparent;
    padding: 50px;
    /* padding-left: 60px; */
    border: none;
    width: 100%;
    background-color: #202020;
    margin: 2px;
}

.tab-hire button.active:before {
    content: '';
    position: absolute;
    right: -68px;
    bottom: 0;
    width: 0;
    height: 0;
    border-left: 70px solid rgba(228, 164, 0, 0.9);
    border-top: 76px solid transparent;
    border-bottom: 61px solid transparent;
    z-index: 1;
}

.sideTab-hire .tech-icon .hire-side-img {
    display: block;
    /* width: fit-content; */
    padding: 8px;
}

.sec-3-hire {
    font-size: 40px;
    margin: 20px 0px;
}

.hire-col-sec2 {
    padding: 30px;
    padding-left: 0px;
}

.sec-2-but-row {
    /* padding: 20px; */
    width: 100%;
    justify-content: end;
}

.sec-2-but-row .sec-2-but {
    width: fit-content;
    color: #ABABAB;
    background: transparent;
    font-size: 18px;
    text-align: center;
    padding: 10px 0;
    /* border-width: 2px;
  border-style: solid;
  border-image: 
  linear-gradient(
    to bottom, 
    #E4A400, 
    #E4A400
  ) 1 100%; */
}

.sec-2-but-row .sec-2-but .link {
    color: white;
}

.hire-input {
    display: flex;
    width: 300px;
    border: 1px solid #404040;
    box-sizing: border-box;
    border-radius: 22.5px;
    padding: 11px 20px;
    margin-bottom: 50px;
}

.hire-input .fa {
    color: #979797;
    margin-right: 24px;
}

.hire-input input {
    border: 0;
    color: #fff;
    background: transparent;
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    -o-appearance: none;
    appearance: none;
    padding: 0 !important;
    width: 100%;
}

.hire-input input:focus {
    outline: 0;
}

.hire-input .input-placeholder {
    /* background-color: #eee; */
    display: inline-block;
    padding: 10px;
}

.hire-input .form-control:focus {
    background-color: transparent !important;
    color: #fff;
    box-shadow: none;
}

.sideTab-hire-tab {
    padding-right: 0;
    display: flex;
    justify-content: end;
    align-items: center;
}

.hire-isdesktop {
    display: block;
    /* padding: 90px 0px 100px; */
}

.hire-dev-mob-banner {
    display: none;
}

.hire-dev-mob-banner img {
    width: 100%;
}

.hire-isdesktop .hire-sec-1-des {
    background-image: url('https://d2e3cmtjkfw2a3.cloudfront.net/static/media/developer/header-img.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: calc(100% - 90px);
    position: relative;
    /* opacity: 0.5; */
}

.hire-isdesktop .sub-services-top {
    margin-top: 80px;
    padding: 150px 0 100px;
}

.hire-isdesktop .hire-sec-1-des .sb_sr_top {
    max-width: 600px;
}

.hire-isdesktop .hire-sec-1-des .col-md-7 {
    margin-bottom: 29px;
    padding-top: 29px;
}

.hire-isdesktop .hire-sec-1-des .heading {
    font-size: 45px;
    color: #f9f9f9;
    font-weight: 600;
    line-height: 50px;
    margin-bottom: 20px;
    text-align: left;
}

.hire-isdesktop .hire-sec-1-des .description {
    font-size: 16px;
    line-height: 30px;
    margin-bottom: 30px;
    color: #E9E9E9;
}

.hire-isdesktop .btn.btn-primary {
    background: #fff;
    text-transform: uppercase;
    font-weight: 600;
    border: 0;
    border-radius: 50px;
    letter-spacing: 1.6px;
    padding: 13px 25px;
    color: #101214;
    overflow: hidden;
}

.hire-isdesktop .btn.btn-primary {
    padding: 13px 25px;
}

.hire-ismobile {
    display: none;
}

.accordion-faq .left-box {
    margin-top: 40px;
}

.accordion-faq .content-title .heading {
    margin: 20px 0px;
    font-size: 18px;
    font-weight: 500;
}

.accordion-faq .content-title .description {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #8F8F8F;
}

.accordion-faq .title {
    font-size: 25px;
}

.accordion-faq .ylw-color {
    color: #F1B308;
}

.hire-developer .block-sec-work .block-sec-content .content-title .content-ul-list li:before {
    content: "";
    display: inline-block;
    margin-right: 10px;
    margin-bottom: 2px;
    height: 8px;
    width: 8px;
    text-indent: -1em
}

.hire-developer .block-sec-work .block-sec-content .content-title .content-ul-list li:nth-child(1)::before {
    background-color: #DEC57B;
}

.hire-developer .block-sec-work .block-sec-content .content-title .content-ul-list li:nth-child(2)::before {
    background-color: #75D4E9;
}

.hire-developer .block-sec-work .block-sec-content .content-title .content-ul-list li:nth-child(3)::before {
    background-color: #CD8DF7;
}

.hire-developer .block-sec-work .block-sec-content .content-title .content-ul-list li:nth-child(4)::before {
    background-color: #D9605E;
}

.hire-developer .block-sec-work .block-sec-content .right-box .content-title {
    margin-bottom: 60px;
}

.hire-developer .block-sec-work .block-sec-content .content-title .content-list .heading {
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
}



.h2inpre,
.h2inpos {
    /* background-color:#fff; */
    height: 50px;
    width: 20px;
    border-bottom: 1px solid #FFA500 !important;
    border-top: 1px solid #FFA500 !important;
}

.h2inpre {
    border-left: 1px solid #FFA500 !important;
}

.h2inpos {
    border-right: 1px solid #FFA500 !important;
    clear: right;
}

.top-digital-head {
    display: block !important;
    /* background: linear-gradient(180.26deg, rgba(14, 15, 15, 0.5) 15.9%, rgba(109, 109, 109, 0.055) 101.32%); */
    padding: 6em 4em 8em 4em;
}

.digital-sec1:before {
    position: absolute;
    content: '';
    background-color: rgb(0 0 0 / 0.7);
}

.digital-sec-2 .sec-3-line {
    width: 10%;
}

.slick-center .slick-current {
    width: 0 30px;
    top: 50%;
    transform: scale(1.3);
    transform-origin: center center;
}

.digital-sec-2 .sec-3_mid_text {
    width: 80%;
    margin-top: -6px;
    margin-left: 2em;
}

.digital-sec-2 .sec-3-h2 {
    font-size: 35px;
    line-height: 50px;
}

.digital-sec-2 .line {
    border: 1px solid #E4A400;
    opacity: 1;
}

.digital-sec-2 .sec-3-para {
    text-align: left;
    margin: 4em;
    margin-top: 2em;
    margin-bottom: 2em;
}

.digital-card-slider .slide.slider-order .sec-3-list .sec-3-left {
    order: 2
}

.digital-sec-2 .sec-3-list {
    display: flex;
    /* transition: all 2s ease; */
    transition: all 2s ease-in-out;
    /* transform: scale(1.25, 1.25); */
}

.block_chain {
    overflow: hidden;
}

.block_chain p {
    line-height: 1.67;
}

.block_chain .btn_line {
    margin: 25px 0 50px 0;
}

.block_chain .hd_s .ln,
.blog_sec .hd_s .ln,
.awards_sec .hd_s .ln {
    transform-origin: left bottom;
    left: 48px;
}

.block_chain p {
    font-size: 1.1rem;
}

.block-sec-2-head h2 {
    font-size: 64px !important;
    margin: 20px 0 20px;
}


.dashBorder_blockchain {
    overflow: hidden;
    position: relative;
    text-align: center;
    padding: 10px;
    margin-bottom: 20px;
    position: relative;
}

.dashBorder_blockchain:hover .sec-10-img {
    transform: scale(1.4);
    transition: transform 0.8s ease-in-out;
}

.dashBorder_blockchain .dot-border-line {
    width: 10px;
    height: 10px;
    position: absolute;
    bottom: 0;
    left: 0;
    margin-top: 20px;
    background-color: #E4A400;
    transition: all 0.8s ease-in-out;
}

.dashBorder_blockchain:hover .dot-border-line {
    width: 100%;
    transition: all 0.8s ease-in-out;
}

.blockChain_slider {
    padding: 0 !important;
}

.blockChain_slider .card {
    background-color: transparent;
}

.dashBorder_blockchain:hover .sec-10-h4 {
    color: #E4A400;
}

.block_chain .sec-2 .number {
    font-size: 64px;
    color: #E4A400;
    font-weight: 400;
}

.block_chain .sec-2 .text {
    font-size: 20px;
    font-weight: 400;
}

.block_chain .line {
    border: 1px solid #E4A400;
    opacity: 1;
}

.block_chain .sec-3_mid_text {
    width: 80%;
    margin-top: -6px;
    margin-left: 2em;
}

.block_chain .sec-3_mid_text .sec-3-h2 span {
    color: #ffb700;
}

.block_chain .sec-3-para {
    text-align: left;
    margin: 4em;
    margin-top: 2em;
    margin-bottom: 2em;
    color: #ABABAB;
}

.block_chain .sec-3-para a {
    color: #ffb700 !important;
}

.block_chain .sec-3-line {
    width: 10%;
}

.block_chain .sec-3-h4 {
    font-size: 40px !important;
    line-height: 50px;
    color: #D7D7D7 !important;
}

.block_chain .des-para {
    margin-top: 20px;
    width: 75%;
}

.block_chain .sec-des-para {
    text-align: left;
    font-size: 18px;
    color: rgba(171, 171, 171, 1);
    line-height: 27px;
}

.block_chain .sec-6-hr {
    width: 100%;
}

.block_chain .sec-6-but {
    width: 100%;
}

.block_chain .sec-11-h2 {
    background-color: #040504;
    width: fit-content !important;
    max-width: none !important;
    line-height: unset !important;
    position: absolute;
    top: 10%;
}

.block_chain .sec-11-p {
    margin-top: 110px;
    color: rgba(171, 171, 171, 1);
}

.block_chain .sec-7-img {
    background: none !important;
    display: flex;
    flex-direction: column;
}

.block_chain .sec-8-imgBox {
    margin: 20px 0 20px;
}

.block_chain .sec-8-imgText {
    font-size: 18px;
    margin-top: 20px;
    font-weight: 200;
}

.block_chain .sec-9-imgBox {
    border-right: 1px solid #E4A400;
}

.block_chain .sec-10-box {
    border: 2px dashed #4E4E4E;
    padding: 20px 20px 0px;
    height: 480px;
}


.block_chain .sec-10-h4 {
    font-size: 22px;
    font-weight: 500;
    transition: all 0.8s ease-in-out;
}

.block_chain .sec-10-img {
    margin-top: 40px;
    margin-bottom: 70px;
    min-height: 110px;
}

.block_chain .sec-10-p {
    font-size: 14px;
    padding-bottom: 50px;
}

.block_chain .sec-11-img {
    margin: 140px 0 -90px !important;
    border-top: 5px solid black;
    border-image: linear-gradient(to top, rgb(197, 71, 71) 50%, rgba(223, 31, 31, 0) 50%);
    border-image-slice: 1
}

.block_chain .isdesktop {
    display: block;
}

.block_chain .isMobile {
    display: none;
}

.block-sec-1-des {
    display: block;
    margin-top: 88px;
}

.blockchain-banner {
    background: linear-gradient(90deg, rgba(6, 6, 6, 1) 0%, rgba(0, 0, 0, 1) 23%, rgba(19, 17, 17, 1) 48%, rgba(8, 8, 8, 0) 100%);
    max-width: 700px !important;
    height: 100%;
    top: 0;
    bottom: 0;
}

.blockchain-content-wrap {
    padding-left: 10%;
}

.block-sec-1-des .video-container video {
    height: 88vh;
}

.block-sec-1-des .heading {
    font-size: 50px;
    color: #f9f9f9;
    font-weight: 600;
    line-height: 76px;
    margin-bottom: 20px;
    text-align: left;
}

.block-sec-1-mob {
    display: none;
}

.block-sec-1-mob .heading {
    font-size: 40px;
    color: #f9f9f9;
    font-weight: 600;
    line-height: 50px;
    margin-bottom: 20px;
    text-align: left;
}

.block-sec-1-mob video {
    height: 88vh;
}

.blockChain_slider .slick-slide img {
    margin: 0 auto;
    max-width: 130px;
    border-radius: 50%;
    width: 100%;
}

.blockChain_slider .item {
    padding: 15px;
    outline: none;
}

.blockChain_slider .inner_item {
    background: #222222;
    ;
    border-radius: 18px;
    text-align: center;
    /* padding: 45px 30px; */
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.blockChain_slider .slick-slide {
    transform: scale(0.8);
    opacity: 0.5;
    transition: all ease 0.5s;
    max-height: 400px;
    min-height: 300px;
}

.blockChain_slider .slick-slide.slick-current {
    transform: scale(1);
    opacity: 1;
    position: relative;
    left: 0px;
}

.blockChain_slider .dark {
    display: none;
}

.blockChain_slider .slick-center .dark {
    display: block;
}

.blockChain_slider .light {
    display: block;
}

.blockChain_slider .slick-center .light {
    display: none;
}

.blockChain_slider .slick-arrow {
    position: absolute;
    bottom: 0;
    z-index: 99;
    border: none;
    /* padding: 8px 15px; */
    height: 20px;
    border-radius: 50px;
    width: 100px;
    outline: none;
}

.blockChain_slider .slick-next {
    right: 0;
    top: 60%;
}

.blockChain_slider .slick-prev {
    left: 0;
    top: 60%;
}

.blockChain_slider .slick-list {
    /* padding-top: 55px !important; */
    margin-bottom: 95px !important;
}

.blockChain_slider .item-heading {
    display: block;
    opacity: 0;
    font-size: 20px;
    position: absolute;
    bottom: 15%;
    margin: auto;
    left: 0;
    right: 0;
}

.blockChain_slider .slick-current .item-heading {
    opacity: 1;
}

.slick-prev:before {
    content: url('https://d2e3cmtjkfw2a3.cloudfront.net/static/media/blockchain/leftarrow.png');

}

.slick-next:before {
    content: url('https://d2e3cmtjkfw2a3.cloudfront.net/static/media/blockchain/rightarrow.png');
}

.block_chain .sec-3-list {
    display: flex;
    /* transition: all 2s ease; */
    transition: all 2s ease-in-out;
    /* transform: scale(1.25, 1.25); */
}

.sec-3 ul li {
    cursor: pointer;

}

.digital-sec-2 .sec-3-left {
    width: 300px;
    height: 300px;
    background: #1C1C1C;
    margin-top: 70px;
}

.block_chain .sec-3-left {
    width: 300px;
    height: 300px;
    background: #1C1C1C;
    margin-top: 70px;
}

.digital-sec-2 .sec-3-right {
    width: 350px;
    height: 450px;
    background: #282828;
    box-shadow: 4px 4px 9px rgba(135, 132, 132, 0.25);
}

.block-card-slider .slick-slide {
    transform: scale(0.8);
    opacity: 0.5;
    transition: all ease 0.5s;
}

.block-card-slider .slick-slide.slick-current {
    transform: scale(1);
    opacity: 1;
    max-height: 420px;
}

.digital-card-slider .slick-slide {
    transform: scale(0.8);
    opacity: 0.5;
    transition: all ease 0.5s;
}

.digital-card-slider .slick-slide.slick-current {
    transform: scale(1);
    opacity: 1;
    max-height: 400px;
}

.digital-sec-2 .sec-3-right h2 {
    font-weight: 500;
    font-size: 22px;
    color: #D8D8D8;
    margin: 30px;
}

.digital-sec-2 .sec-3-right p {
    font-weight: 400;
    font-size: 14px;
    color: #ABABAB;
    margin: 30px;
}

.block_chain .sec-3-right {
    width: 370px;
    height: max-content;
    padding: 30px 0px;
    margin: 0px 40px;
    background: #282828;
    /* box-shadow: 4px 4px 9px rgba(135, 132, 132, 0.25); */
    border: 1px;
    border-radius: 10px;
}

.block_chain .sec-3 {
    margin-top: 6em;
    position: relative;
}

.block_chain .sec-3 .prev-block {
    position: absolute;
    left: 32%;
    top: 35%;
}

.block_chain .sec-3 .next-block {
    position: absolute;
    top: 35%;
    right: 32%;
}


.block_chain .sec-3-h3 {
    letter-spacing: 1px;
    margin-bottom: 5px;
    font-size: 40px;
    line-height: 50px;
    color: #D7D7D7 !important;
}

.block-card-slider .slick-center .sec-3-right {
    border-left: 2px solid #f1b308;
}

.block_chain .sec-3-right h3 {
    font-weight: 500;
    font-size: 22px;
    color: #D8D8D8;
    margin: 15px;
}

.block-card-slider .slick-center .sec-3-right h3 {
    color: #f1b308;
}

.block_chain .sec-3-right p {
    font-weight: 400;
    font-size: 14px;
    color: #ABABAB;
    margin: 30px;
}

.mobile_html_slide {
    display: none;
    margin-top: 30px;
}

.desktop_html_img {
    display: block;
}


/* neha */

@media (max-width: 1440px) {
    .desktop_html .modal#project_enquery .btn-close {
        right: 0;
    }

    .desktop_html #project_enquery {
        width: 80%;
        left: 10%;
    }

    .desktop_html .new_cn_frm {
        padding: 85px 0px;
    }

    .aso-sec-4 .service-block .service-cards .srv-card-content {
        max-width: 500px;
    }

    .desktop_html #project_enquery label {
        font-size: 16px !important;
        font-weight: 500 !important;
    }

    .block_chain .sec-10-box {
        height: 520px;
    }
}


@media (max-width: 1200px) {
    .top-digital-head {
        padding: 8em 4em 8em 4em;
    }


}

@media (max-width: 950px) {
    .left-sub-box .box-sec {
        width: 100%;
    }
}

@media (max-width: 767px) {
    .desktop_html .case_scope.caratlane .description {
        display: none;
    }

    .desktop_html .case_scope.caratlane .description-mob {
        display: block;
    }

    .desktop_html .case_scope.caratlane .scop-list-desktop ul li {
        display: none;
    }

    .desktop_html .case_scope.caratlane .scop-list-mob ul li {
        display: block;
    }

    .mobile_html .new_cn_frm {
        padding: 90px 0 30px !important;
    }

    .mobile_html .top_con {
        margin-bottom: 10px !important;
    }

    .top-digital-head .ban_caption h1 {
        font-size: 30px;
        margin-bottom: 50px;
        line-height: 45px;
    }

    .career-form .myExp .form-check.form-check-inline {
        padding: 0px 30px !important;
    }


    .digital-sec-2 .sec-3-left {
        width: 60px;
        height: 202px;
    }

    .digital-sec-2 .sec-3-right {
        width: 260px;
        height: 390px;
    }

    .digital-sec-2 .sec-3-right h2 {
        font-size: 16px;
        color: #D8D8D8;
        margin: 15px;
    }

    .digital-sec-2 .sec-3-right p {
        font-weight: 400;
        font-size: 12px;
        color: #ABABAB;
        margin: 15px;
    }

    .cta-more-box.arrow.slide-right {
        left: 98%;
        top: 102%;
        z-index: 99;
    }

    .cta-more-box.arrow.slide-left {
        left: -35px;
        z-index: 99;
        top: 102%;
    }

    .digital-sec-2 .sec-3-para {
        font-size: 12px !important;
        margin: 2em;
    }

    .top-digital-head {
        padding: 5em 0 3em 0;
    }

    .digital-col-sec2 .col-block .col-text {
        font-size: 16px;
    }

    .digital-col-sec2 .col-block p {
        font-size: 12px;
        margin-top: 15px;
        line-height: 18px;
    }

    .digital-col-sec2 {
        padding: 15px;
    }

    .left-sub-box .box-sec {
        width: 100%;
    }

    .digital-sub {
        padding-bottom: 0px;
    }

    .digital-sub .left-sub-box {

        padding: 20x;
        margin-top: 1em;
    }

    .digital-sub .left-sub-box .description {
        justify-content: center;
    }

    .digital-sub .right-sub-text {
        font-size: 30px;
    }

    .digital-sub .sec-6-hr {
        width: 10%;
    }


    .digital-sub-sec-9 .heading {
        text-align: center !important;
    }

    .block-card-slider .slide.slider-order .sec-3-list .sec-3-left {
        order: 2
    }

    .block_chain .sec-3 .prev-block {
        left: 5% !important;
    }

    .block_chain .sec-3 .next-block {
        right: 5% !important;
    }

    .square-blockChain img {
        float: right;
        margin: 5px !important;
    }

    .square-blockChain p {
        /* text-align: justify; */
        font-size: 25px;
    }

    .aso-page {
        overflow-y: hidden;
    }

    .aso-sec-1 {
        background-image: url(https://d2e3cmtjkfw2a3.cloudfront.net/static/media/digital/aso/aso-main-mob.svg);
        background-repeat: no-repeat;
        background-position: right;
        background-size: cover;
    }

    .aso-sec-1 .main_content {
        margin: 0;
        min-width: 360px;
    }

    .aso-sec-1 .main_content .heading {
        font-weight: 600;
        font-size: 30px !important;
        line-height: 45px;
        text-align: left;
    }

    .aso-sec-1 .sub-service .main_content .description {
        font-weight: 500;
        font-size: 16px;
        line-height: 32px;
        color: #E9E9E9;
        opacity: 0.9;
        margin: 0;
        text-align: left;
    }

    .aso-sec-2 .aso-sec-2-left .heading {
        font-weight: 600;
        font-size: 22px;
        line-height: 40px;
    }

    .aso-sec-2 .aso-sec-2-right .card-box .numbr {
        font-weight: 400;
        font-size: 30px;
        line-height: 39px;
        padding: 0 15px;
    }

    .aso-sec-2 .aso-sec-2-right .card-box .numbr sup {
        top: -7px;
        font-size: 30px;
    }

    .aso-sec-2 .aso-sec-2-right .card-box .title {
        font-weight: 400;
        font-size: 18px;
        line-height: 24px;
        padding: 0 15px;
    }

    .aso-sec-2 .aso-sec-2-right .right-boxes::before {
        width: 20em;
        height: 20em;
    }

    .aso-sec-2 .aso-sec-2-right .card-box {
        padding: 23px 0;
        max-width: 135px;
    }

    .aso-sec-3 .carousel-indicators {
        bottom: -5%;
    }

    .aso-sec-3 .srvc-slider-img img {
        max-width: 100%;
        padding-top: 100px;
    }

    .aso-sec-3 .aso-sec-3-content .title {
        font-size: 22px;
        line-height: 34px;
    }

    .aso-sec-3 .aso-sec-3-content .description {
        font-size: 14px;
        line-height: 22px;
        padding-top: 12px;
    }

    .aso-sec-3 .mob-aos-service {
        display: block;
    }

    .aso-sec-3 .cl_bx .description {
        font-size: 15px;
        line-height: 20px;
    }

    .aso-sec-3 .cl_bx .heading {
        font-size: 24px;
    }

    .aso-sec-3-img {
        align-items: start;

    }

    .aso-sec-4 .service-block .service-cards .srv-card-content {
        margin: 30px 0px;
    }

    .aso-sec-4 .service-block .service-cards .srv-card-content {
        padding: 20px;
    }

    .service-block .service-cards .srv-card-content .btn_line.b {
        padding: 13px 15px 13px !important;
    }

    .aso-sec-4 .service-block .service-cards .srv-card-content .srv-card-title {
        font-size: 25px;
    }

    .aso-sec-4 .service-cards .srv-card-content .para {
        font-size: 20px;
    }

    .aso-sec-5 .custom-card-list-wrap-dsktp {
        display: none;
    }

    .aso-sec-5 .custom-card-list-wrap-mobile {
        display: block;
    }

    .aso-sec-5 .aso-sec-5-content .title {
        font-weight: 600;
        font-size: 22px;
        line-height: 34px;
    }

    .aso-sec-5 .aso-sec-5-content .description {
        font-size: 14px;
        line-height: 22px;
    }

    .aso-sec-5 .aso-store-list {
        min-height: 585px;
        margin-bottom: 30px;
    }

    .aso-sec-5 .aso-store-list .store-menu-li {
        padding: 14px 20px;
    }

    .aso-sec-5 .aso-store-list::before {
        margin-top: 19px;
    }

    .aso-sec-6-img {
        margin: 0 10px;
    }

    .aso-sec-6 .tech-box {
        height: 40px;
    }

    .aso-sec-6 .aso-sec-6-content .title {
        font-size: 25px;
        line-height: 31px;
    }

    .dsktp-aso-sec-7 {
        display: none;
    }

    .mobile-aso-sec-7 {
        display: block;
    }

    .aso-sec-7 .aso-sec-7-list {
        height: 250px;
        width: 250px;
        padding: 22px;
    }

    .aso-sec-7 .aso-sec-7-contant .heading {
        font-weight: 600;
        font-size: 22px;
        line-height: 30px;
    }

    .aso-sec-7 .aso-sec-7-list .title {
        font-size: 24px;
    }

    .aso-sec-7 .aso-sec-7-list .description {
        padding-top: 17px;
        font-size: 14px;
    }

    .aso-sec-7 .aso-sec-7-list.border-ryt {
        border: none;
    }

    .aso-sec-7 .aso-sec-7-list.border-ryt-mob {
        border-right: 1px solid white;
        border-radius: 1px;
    }

    .aso-sec-7 .col-6 {
        margin-bottom: 20px;
    }

    .aso-sec-7 .aso-sec-7-contant .description {
        padding-bottom: 10px;

    }

    .desktop_html .case_port .owl-prev {
        left: -10px !important;
    }

    .desktop_html .case_port .owl-next {
        right: 10px !important;
    }

    .meeting-btn {
        width: 350px;
        padding: 10px;
    }

    .meeting-btn .btn-link {
        font-size: 14px;
    }

}

/* neha */
@media (max-width: 1300px) {


    .block_chain .sec-11-img {
        margin: 140px 0 -90px !important;
    }
}

@media (max-width: 1300px) {
    .tab-hire {
        margin-top: 1.5rem;
    }
}

@media (max-width: 767px) {
    .career-form .cr_frm {
        overflow: hidden !important;
        max-height: unset !important;
        padding: 0px;
    }

    .career-form .cr_frm .btn.btn-primary.white {
        margin-right: 15px;

    }

    .career-form .cr_frm .btn.btn-primary.white,
    .career-form .cr_frm .btn.btn-primary.yellow {
        padding: 12px 22px;
        font-size: 18px;
    }

    .block_chain #clin_ls .item {
        width: 130px !important;
        height: 150px;
    }

    .block_chain .sec-7-img img {
        height: 60px !important;
        width: 60px !important;
    }

    .block_chain .sec-3_mid_text {
        margin-left: 0;
    }

    .block_chain .sec-3-h3 {
        font-size: 20px;
        line-height: 30px;
    }

    .block_chain .sec-3_mid_text .sec-3-h2 {
        text-align: center;
        font-size: 30px;
    }

    .tab-hire button.active:before {
        display: none;
    }



    .hire-ismobile .btn.btn-primary {
        background: #fff;
        text-transform: uppercase;
        font-weight: 600;
        border: 0;
        border-radius: 50px;
        letter-spacing: 1.6px;
        padding: 13px 25px;
        color: #101214;
        overflow: hidden;
        margin-top: 20px;
    }

    .input-div {
        text-align: -webkit-center;
        /* margin-top: 20px; */
    }

    .hire-ismobile {
        display: block;
    }

    .hire-col-sec2 .col-block {
        padding: 12px;
    }

    .hire-col-sec2 .col-block .col-text {
        font-size: 16px;
        min-height: 40px;
    }

    .hire-col-sec2 .col-block p {
        font-size: 12px;
        margin-top: 15px;
        line-height: 20px;
    }

    .hire-side-img .tech-name {
        font-size: 8px;
    }

    .sec-2-but-row {
        justify-content: center;
    }

    .sec2-head {
        font-size: 20px !important;
        text-align: center;
        line-height: 36px;
    }

    .tab-hire .mb-service {
        padding: 29px;
        font-size: 15px;
    }

    .sideTab-hire-tab {
        padding-right: calc(var(--bs-gutter-x) * .5);
        display: unset;
    }

    .sideTab-hire .tabcontent {
        padding: 20px 0px;

    }

    .sub-service-services .tabcontent .tech-name {
        font-size: 9px !important;
    }

    .sec-3-hire {
        font-size: 20px;
    }

    .hire-col-sec2 {
        padding: 15px;
    }

    .sideTab-hire .tech-icon .hire-side-img img {
        width: 60px;
    }

    .cta-more-box.arrow.slide-right {
        left: 82%;
        top: 102%;
        z-index: 99;
    }

    .cta-more-box.arrow.slide-left {
        left: 15px;
        z-index: 99;
        top: 102%;
    }

    .section-container-8 .text-content.isactive p {
        min-height: 107px;
    }

    .intern-content h2 {
        max-width: 290px;
    }

    .desktop_html .case_hero .case_left_hero {
        padding: 20px 0 0;
    }

    .desktop_html .container {
        padding: 0 25px;
    }

    .desktop_html .case_about_sec p {
        line-height: 24px;
        font-size: 13px;
    }

    .desktop_html .case_tst_bx.htl_case p {
        min-height: 150px;
        font-size: 12px;
        line-height: 20px;
    }

    .desktop_html .case_tst_bx img {
        margin-bottom: 10px;
        max-width: 30px;
    }

    .desktop_html .case_tst_bx {
        padding: 10px 8px 10px;
        /* min-height: 342px; */
    }

    .desktop_html .case_tst_bx.case_tst_bx-savalogy {
        min-height: 260px;
        padding: 10px;
    }

    .case-talk-form-dsktp {
        display: none;
    }

    .case-talk-form-mob {
        display: block;
    }

    .desktop_html .case_scope .order-last,
    .desktop_html .case_scope .order-first {
        order: inherit !important;
    }

    .desktop_html .case_scope .case_splash {
        max-width: 180px;
        margin: 50px auto 0;
        display: block;
    }

    .desktop_html .case_scope p {
        line-height: 22px;
        font-size: 13px;
    }

    .desktop_html .case_goals_bx {
        padding: 20px;
        min-height: auto !important;
        margin-bottom: 20px;
    }

    .desktop_html .case_challenge p,
    .desktop_html .case_conclusion p,
    .desktop_html .case_app p,
    .desktop_html .case_sec_head p,
    .desktop_html .case_goals_bx p {
        line-height: 24px;
        font-size: 12px;
    }

    .desktop_html .case_goals_bx h4 {
        font-size: 17px;
        line-height: 31px;
        margin-bottom: 8px;
    }

    .desktop_html .case_research,
    .desktop_html .lets_talk,
    .desktop_html .case_conclusion,
    .desktop_html .case_app,
    .desktop_html .case_wire,
    .desktop_html .case_design,
    .desktop_html .case_goals {
        padding: 60px 0;
    }

    .desktop_html .case_challenge h3 {
        font-size: 15px;
    }

    .desktop_html .case_challenge #case_chase {
        padding: 25px 25px;
        min-height: 500px;
    }

    .desktop_html .case_challenge h5 {
        font-size: 12px;
    }

    .desktop_html .case_wire .row.otb_wire li {
        max-width: 50%;
        flex: 0 0 50%;
    }

    .desktop_html .cd_bx h3 {
        font-size: 20px;
        margin-bottom: 30px;
    }

    .desktop_html .cd_bx .d-block img:first-child {
        max-width: 100% !important;
    }

    .desktop_html .palette li {
        flex: 0 0 25%;
        max-width: 25%;
    }

    .desktop_html .palette li.myshift-logo-palette-1 {
        flex: 0 0 30%;
        max-width: 30%;
    }

    .desktop_html .palette li.myshift-logo-palette-2 {
        flex: 0 0 60%;
        max-width: 60%;
        margin-left: 20px;
    }

    .desktop_html .palette li.myshift-logo-palette-2 .color_bx {
        height: 100% !important;
    }

    .desktop_html .palette li.myshift-logo-palette-2 .color_bx {
        height: 100% !important;
    }

    .desktop_html .palette li.gynios-logo-palette-1 {
        flex: 0 0 50%;
        max-width: 50%;
        padding-right: 20px;
    }

    .desktop_html .palette li.gynios-logo-palette-2 {
        flex: 0 0 50%;
        max-width: 50%;
        padding-right: 20px;
    }

    .desktop_html .palette li.tg-logo-palette-1 {
        flex: 0 0 30%;
        max-width: 30%;
    }

    .desktop_html .palette li.tg-logo-palette-2 {
        flex: 0 0 30%;
        max-width: 30%;
    }

    .desktop_html .palette li.tg-logo-palette-3 {
        flex: 0 0 40%;
        max-width: 40%;
    }

    .desktop_html .palette li.tg-logo-palette-1 .color_bx {
        height: 100% !important;
    }

    .desktop_html .palette li.tg-logo-palette-2 .color_bx {
        height: 100% !important;
    }

    .desktop_html .palette li.tg-logo-palette-3 .color_bx {
        height: 100% !important;
    }

    .desktop_html .palette li.tg-logo-palette-3 img {
        height: 100% !important;
    }

    .desktop_html .palette li.oberit-logo-palette-1 {
        flex: 0 0 50%;
        max-width: 50%;
        padding-right: 20px;
    }

    .desktop_html .palette li.oberit-logo-palette-2 {
        flex: 0 0 50%;
        max-width: 50%;
        padding-right: 20px;
    }

    .desktop_html .palette li.savvology-logo-palette-1 {
        flex: 0 0 55%;
        max-width: 55%;
    }

    .desktop_html .palette li.savvology-logo-palette-2 {
        flex: 0 0 20%;
        max-width: 20%;
        margin-left: 30px;
    }

    .desktop_html .palette li.savvology-logo-palette-1 .color_bx {
        height: 100% !important;
    }

    .desktop_html .palette li.savvology-logo-palette-2 .color_bx {
        height: 100% !important;
    }

    .desktop_html .color_bx {
        height: 65px;
    }

    .desktop_html .color_bx+p {
        font-size: 13px;
    }

    .desktop_html .case_care p {
        line-height: 24px;
        font-size: 12px;
    }

    .desktop_html .case_care.case_care_htl {
        padding: 70px 0 !important;
    }

    .desktop_html .case_app img {
        max-width: 40px;
    }

    .desktop_html .cn_content {
        padding-top: 0;
    }

    .desktop_html .form-group input.form-control {
        border-radius: 5px;
        padding: 0 20px;
    }

    .desktop_html .lg_footer .text-right {
        text-align: left;
        margin-top: 30px;
    }

    .desktop_html .footer p a {
        margin-bottom: 0;
    }

    .desktop_html .footer li {
        list-style: none;
        margin-bottom: 7px;
    }

    .desktop_html .lg_footer+.row>div {
        margin-top: 30px;
    }

    .goa_cnt h3 {
        font-size: 20px;
        line-height: 35px;
    }

    .goa_cnt h5 {
        font-size: 14px;
        line-height: 24px;
        margin: 15px auto;
    }

    .goa_cnt .d-flex {
        display: block !important;
        text-align: center;
    }

    .goa_cnt .goCnt p {
        font-size: 14px;
        margin-bottom: 35px;
        margin-top: 40px;
    }

    .goa_cnt .btn.btn-primary {
        padding: 10px 20px;
        font-size: 14px;
        font-weight: bold;
    }


    /*Service detail*/
    .desktop_html .sb_sr_top h5 {
        line-height: 40px;
        margin-bottom: 40px;
    }

    .desktop_html .sb_sr_top .heading {
        font-size: 20px;
        line-height: 45px;
    }

    .desktop_html .sb_sr_top p {
        font-size: 13px;
        line-height: 25px;
        margin-bottom: 20px;
    }

    .desktop_html .btn.btn-primary {
        letter-spacing: 1.2px;
        padding: 13px 25px;
        font-size: 12px;
    }

    .desktop_html .sb_sr_top {
        position: relative;
        z-index: 5;
    }

    .desktop_html .srOurProcess h2 {
        font-size: 20px;
        line-height: 45px;
    }

    .desktop_html .srOurProcess h6 {
        font-size: 13px;
        line-height: 24px;
        margin-bottom: 40px;
    }

    .desktop_html .SuccessStories h2,
    .desktop_html .srv_testimonials h2 {
        font-size: 30px;
    }

    .desktop_html .heading p,
    .desktop_html .SuccessStories h6 {
        font-size: 14px;
    }

    .desktop_html .da_srv_bx {
        padding: 20px;
        margin: 0px auto 70px;
        width: 95%;
    }


}

/* neha */
@media (max-width: 1300px) {

    .block_chain .sec-11-img {
        margin: 140px 0 -90px !important;
    }
}

@media (max-width: 1300px) {
    .tab-hire {
        margin-top: 1.5rem;
    }
}

@media (max-width: 767px) {
    .career-form .cr_frm {
        overflow: hidden !important;
        max-height: unset !important;
        padding: 0px;
    }

    .career-form .cr_frm .btn.btn-primary.white {
        margin-right: 15px;

    }

    .career-form .cr_frm .btn.btn-primary.white,
    .career-form .cr_frm .btn.btn-primary.yellow {
        padding: 12px 22px;
        font-size: 18px;
    }

    .block_chain #clin_ls .item {
        width: 130px !important;
        height: 150px;
    }

    .block_chain .sec-7-img img {
        height: 60px !important;
        width: 60px !important;
    }

    .block_chain .sec-3_mid_text {
        margin-left: 0;
    }

    .block_chain .sec-3-h3 {
        font-size: 20px;
        line-height: 30px;
    }

    .block_chain .sec-3_mid_text .sec-3-h2 {
        text-align: center;
        font-size: 30px;
    }

    .tab-hire button.active:before {
        display: none;
    }



    .hire-ismobile .btn.btn-primary {
        background: #fff;
        text-transform: uppercase;
        font-weight: 600;
        border: 0;
        border-radius: 50px;
        letter-spacing: 1.6px;
        padding: 13px 25px;
        color: #101214;
        overflow: hidden;
        margin-top: 20px;
    }

    .input-div {
        text-align: -webkit-center;
        /* margin-top: 20px; */
    }

    .hire-dev-mob-banner {
        display: block;
        z-index: -1;
    }

    .hire-isdesktop .hire-sec-1-des {
        background-image: none;
        padding: 20px 0px;
    }

    .hire-isdesktop .hire-sec-1-des .sb_sr_top {
        text-align: center;
        margin-top: -80px;
    }

    .hire-isdesktop .hire-sec-1-des .heading {
        font-size: 35px;
        text-align: center;
    }

    .hire-col-sec2 .col-block {
        padding: 12px;
    }

    .hire-col-sec2 .col-block .col-text {
        font-size: 16px;
        min-height: 40px;
    }

    .hire-col-sec2 .col-block p {
        font-size: 12px;
        margin-top: 15px;
        line-height: 20px;
    }

    .hire-side-img .tech-name {
        font-size: 8px;
    }

    .sec-2-but-row {
        justify-content: center;
    }

    .sec2-head {
        font-size: 20px !important;
        text-align: center;
        line-height: 36px;
    }

    .tab-hire .mb-service {
        padding: 29px;
        font-size: 15px;
    }

    .sideTab-hire-tab {
        padding-right: calc(var(--bs-gutter-x) * .5);
        display: unset;
    }

    .sideTab-hire .tabcontent {
        padding: 20px 0px;

    }

    .sub-service-services .tabcontent .tech-name {
        font-size: 9px !important;
    }

    .sec-3-hire {
        font-size: 20px;
    }

    .hire-col-sec2 {
        padding: 15px;
    }

    .sideTab-hire .tech-icon .hire-side-img img {
        width: 60px;
    }

    .cta-more-box.arrow.slide-right {
        left: 82%;
        top: 102%;
        z-index: 99;
    }

    .cta-more-box.arrow.slide-left {
        left: 15px;
        z-index: 99;
        top: 102%;
    }

    .section-container-8 .text-content.isactive p {
        min-height: 107px;
    }

    .intern-content h2 {
        max-width: 290px;
    }

    .desktop_html .case_hero .case_left_hero {
        padding: 20px 0 0;
    }

    .desktop_html .container {
        padding: 0 25px;
    }

    .desktop_html .case_about_sec p {
        line-height: 24px;
        font-size: 13px;
    }

    .desktop_html .case_tst_bx.htl_case p {
        min-height: 150px;
        font-size: 12px;
        line-height: 20px;
    }

    .desktop_html .case_tst_bx img {
        margin-bottom: 10px;
        max-width: 30px;
    }

    .desktop_html .case_tst_bx {
        padding: 10px 8px 10px;
        /* min-height: 342px; */
    }

    .desktop_html .case_tst_bx.case_tst_bx-savalogy {
        min-height: 260px;
        padding: 10px;
    }

    .case-talk-form-dsktp {
        display: none;
    }

    .case-talk-form-mob {
        display: block;
    }

    .desktop_html .case_scope .order-last,
    .desktop_html .case_scope .order-first {
        order: inherit !important;
    }

    .desktop_html .case_scope .case_splash {
        max-width: 180px;
        margin: 50px auto 0;
        display: block;
    }

    .desktop_html .case_scope p {
        line-height: 22px;
        font-size: 13px;
    }

    .desktop_html .case_goals_bx {
        padding: 20px;
        min-height: auto !important;
        margin-bottom: 20px;
    }

    .desktop_html .case_challenge p,
    .desktop_html .case_conclusion p,
    .desktop_html .case_app p,
    .desktop_html .case_sec_head p,
    .desktop_html .case_goals_bx p {
        line-height: 24px;
        font-size: 12px;
    }

    .desktop_html .case_goals_bx h4 {
        font-size: 17px;
        line-height: 31px;
        margin-bottom: 8px;
    }

    .desktop_html .case_research,
    .desktop_html .lets_talk,
    .desktop_html .case_conclusion,
    .desktop_html .case_app,
    .desktop_html .case_wire,
    .desktop_html .case_design,
    .desktop_html .case_goals {
        padding: 60px 0;
    }

    .desktop_html .case_challenge h3 {
        font-size: 15px;
    }

    .desktop_html .case_challenge #case_chase {
        padding: 25px 25px;
        min-height: 500px;
    }

    .desktop_html .case_challenge h5 {
        font-size: 12px;
    }

    .desktop_html .case_wire .row.otb_wire li {
        max-width: 50%;
        flex: 0 0 50%;
    }

    .desktop_html .cd_bx h3 {
        font-size: 20px;
        margin-bottom: 30px;
    }

    .desktop_html .cd_bx .d-block img:first-child {
        max-width: 100% !important;
    }

    .desktop_html .palette li {
        flex: 0 0 25%;
        max-width: 25%;
    }

    .desktop_html .palette li.myshift-logo-palette-1 {
        flex: 0 0 30%;
        max-width: 30%;
    }

    .desktop_html .palette li.myshift-logo-palette-2 {
        flex: 0 0 60%;
        max-width: 60%;
        margin-left: 20px;
    }

    .desktop_html .palette li.myshift-logo-palette-2 .color_bx {
        height: 100% !important;
    }

    .desktop_html .palette li.myshift-logo-palette-2 .color_bx {
        height: 100% !important;
    }

    .desktop_html .palette li.gynios-logo-palette-1 {
        flex: 0 0 50%;
        max-width: 50%;
        padding-right: 20px;
    }

    .desktop_html .palette li.gynios-logo-palette-2 {
        flex: 0 0 50%;
        max-width: 50%;
        padding-right: 20px;
    }

    .desktop_html .palette li.tg-logo-palette-1 {
        flex: 0 0 30%;
        max-width: 30%;
    }

    .desktop_html .palette li.tg-logo-palette-2 {
        flex: 0 0 30%;
        max-width: 30%;
    }

    .desktop_html .palette li.tg-logo-palette-3 {
        flex: 0 0 40%;
        max-width: 40%;
    }

    .desktop_html .palette li.tg-logo-palette-1 .color_bx {
        height: 100% !important;
    }

    .desktop_html .palette li.tg-logo-palette-2 .color_bx {
        height: 100% !important;
    }

    .desktop_html .palette li.tg-logo-palette-3 .color_bx {
        height: 100% !important;
    }

    .desktop_html .palette li.tg-logo-palette-3 img {
        height: 100% !important;
    }

    .desktop_html .palette li.oberit-logo-palette-1 {
        flex: 0 0 50%;
        max-width: 50%;
        padding-right: 20px;
    }

    .desktop_html .palette li.oberit-logo-palette-2 {
        flex: 0 0 50%;
        max-width: 50%;
        padding-right: 20px;
    }

    .desktop_html .palette li.savvology-logo-palette-1 {
        flex: 0 0 55%;
        max-width: 55%;
    }

    .desktop_html .palette li.savvology-logo-palette-2 {
        flex: 0 0 20%;
        max-width: 20%;
        margin-left: 30px;
    }

    .desktop_html .palette li.savvology-logo-palette-1 .color_bx {
        height: 100% !important;
    }

    .desktop_html .palette li.savvology-logo-palette-2 .color_bx {
        height: 100% !important;
    }

    .desktop_html .color_bx {
        height: 65px;
    }

    .desktop_html .color_bx+p {
        font-size: 13px;
    }

    .desktop_html .case_care p {
        line-height: 24px;
        font-size: 12px;
    }

    .desktop_html .case_care.case_care_htl {
        padding: 70px 0 !important;
    }

    .desktop_html .case_app img {
        max-width: 40px;
    }

    .desktop_html .cn_content {
        padding-top: 0;
    }

    .desktop_html .form-group input.form-control {
        border-radius: 5px;
        padding: 0 20px;
    }

    .desktop_html .lg_footer .text-right {
        text-align: left;
        margin-top: 30px;
    }

    .desktop_html .footer p a {
        margin-bottom: 0;
    }

    .desktop_html .footer li {
        list-style: none;
        margin-bottom: 7px;
    }

    .desktop_html .lg_footer+.row>div {
        margin-top: 30px;
    }

    .goa_cnt h3 {
        font-size: 20px;
        line-height: 35px;
    }

    .goa_cnt h5 {
        font-size: 14px;
        line-height: 24px;
        margin: 15px auto;
    }

    .goa_cnt .d-flex {
        display: block !important;
        text-align: center;
    }

    .goa_cnt .goCnt p {
        font-size: 14px;
        margin-bottom: 35px;
        margin-top: 40px;
    }

    .goa_cnt .btn.btn-primary {
        padding: 10px 20px;
        font-size: 14px;
        font-weight: bold;
    }


    /*Service detail*/
    .desktop_html .sb_sr_top h5 {
        line-height: 40px;
        margin-bottom: 40px;
    }

    .desktop_html .sb_sr_top .heading {
        font-size: 20px;
        line-height: 45px;
    }

    .desktop_html .sb_sr_top p {
        font-size: 13px;
        line-height: 25px;
        margin-bottom: 20px;
    }

    .desktop_html .btn.btn-primary {
        letter-spacing: 1.2px;
        padding: 13px 25px;
        font-size: 12px;
    }

    .desktop_html .sb_sr_top {
        position: relative;
        z-index: 5;
    }

    .desktop_html .srOurProcess h2 {
        font-size: 20px;
        line-height: 45px;
    }

    .desktop_html .srOurProcess h6 {
        font-size: 13px;
        line-height: 24px;
        margin-bottom: 40px;
    }

    .desktop_html .SuccessStories h2,
    .desktop_html .srv_testimonials h2 {
        font-size: 30px;
    }

    .desktop_html .heading p,
    .desktop_html .SuccessStories h6 {
        font-size: 14px;
    }

    .desktop_html .da_srv_bx {
        padding: 20px;
        margin: 0px auto 70px;
        width: 95%;
    }


    .desktop_html .tst_rating {
        text-align: left;
        margin-top: 0;
    }

    .desktop_html .tst_msg {
        font-size: 13px;
        text-align: left;
        min-height: 80px;
        line-height: 24px;
        margin-top: 5px;
    }

    .desktop_html .tst_cnt h5 {
        font-size: 12px;
    }

    .desktop_html .tst_img {
        width: 65px;
        height: 65px;
        margin-right: 20px;
        margin-top: 7px;
    }

    .desktop_html .da_srv_bx .d-flex {
        display: block !important;
    }

    /* .desktop_html .da-cluch-bx ul {
        display: flex;
    } */
    .desktop_html .da-cluch-bx ul {
        min-height: 200px;
        max-height: 550px;
    }

    .desktop_html .da-cluch-bx li {
        flex: 0 0 45%;
        max-width: 32%;
        margin: 0 !important;
        padding: 18px;
    }

    .desktop_html .da-cluch-bx li img {
        max-height: 60px;
        margin: 0px;
    }

    .desktop_html .da-cluch-bx li p {
        margin-top: 15px;
        line-height: 19px;
        font-size: 10px;
    }

    .desktop_html .da-cluch-bx {
        margin-top: 0;
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .desktop_html .SuccessStories .text-right {
        text-align: left;
        margin-top: 17px;
    }

    .desktop_html .lets_talk_sus h3 a::before {
        display: none;
    }

    .desktop_html .lets_talk_sus {
        padding: 0px 0 50px;
    }

    .desktop_html .lets_talk_sus h3 a {
        font-size: 30px;
        border: 2px solid #e4a400;
        padding: 10px 30px;
        border-radius: 10px;
        color: #e4a400 !important;
    }

    .desktop_html .hrv_slide .hrs_detail {
        opacity: 1;
        top: auto;
        height: 50%;
        bottom: 0;
    }

    .desktop_html .hrs_bx h4 {
        font-size: 18px;
        margin-bottom: 0px;
    }

    .mobile_ani.w-100 {
        display: block;
    }

    .dev_ani {
        display: none;
    }

    .top-digital-head .ban_caption {
        width: 100%;
        top: 12%;
    }

    .top-digital-head .ban_caption p {
        padding: 60px 30px 0px;
        opacity: 0.9 !important;
    }

    .digital-sec-4 .digital_4_slack {
        background-color: transparent !important;
        box-shadow: none !important;
        height: auto !important;
    }

    .digital_4_slack .allSideBorder {
        width: 100%;
        height: 25.5em;
    }

    .digital-sec-2 .block-card-slider .slick-center .sec-3-right {
        border: none;
    }

    .digital_4_slack .insideImg {
        position: relative;
        width: 100%;
        height: 25em;
        top: 4%;
        left: 6%;
    }

    .digital_4_slack .downText {
        margin-top: 0.5em;
        font-weight: 500;
        font-size: 22px;
        line-height: 48px;
        text-align: center;
        letter-spacing: 8px;
    }

    .digital_4_slack .sideText {
        font-weight: 500;
        font-size: 22px;
        text-align: center;
        position: relative;
        width: fit-content;
        margin: auto;
    }

    .digital_4_slack .outsideText {
        width: fit-content;
        position: absolute;
        top: 3%;
        left: 3%;
    }
}




/* neha */
@media (max-width: 1300px) {
    .block_chain .sec-11-img {
        margin: 140px 0 -90px !important;
    }
}

@media (max-width: 1300px) {
    .tab-hire {
        margin-top: 1.5rem;
    }
}

@media (max-width: 767px) {

    /* case study mobile view */
    .desktop_html .new-case.case_hero {
        display: none !important;
    }

    .desktop_html .case_hero {
        display: block !important;
    }

    .desktop_html .case_mob_hero {
        margin-top: 93px;
        position: relative;
        padding: 80px 0;
        text-align: center;
        min-height: 634px;
        display: block !important;
    }

    .desktop_html .case_mob_hero_bx {
        position: relative;
        z-index: 5;
        display: block;
    }

    .desktop_html .case_mob_hero_bx ul {
        display: flex;
    }

    .desktop_html .case_mob_hero_bx li {
        list-style: none;
        display: inline-block;
        width: 33.33%;
        flex: 0 0 33.33%;
        color: #f9f9f9;
        font-size: 12px;
    }

    .desktop_html .case_mob_hero_bx li span {
        display: block;
        color: rgba(249, 249, 249, .41);
        margin-bottom: 10px;
    }

    .desktop_html .case_hero .dsktp-main-img {
        display: none;
    }

    .desktop_html .case_hero .mob-main-img {
        display: block;
        margin-top: 93px;
        position: relative;
        padding: 80px 0;
        text-align: center;
        background-size: 100% 100%;
        min-height: 634px;
    }

    .desktop_html .case_wire.dsktp-wirefrm {
        display: none;
    }

    .desktop_html .case_wire.mob-wirefrm {
        display: block;
    }

    .scop-dsktp-img {
        display: none;
    }

    .scop-mob-img {
        display: block;
    }

    .desktop_html .case_scope .description {
        line-height: 26px;
    }

    .desktop_html .case_challenge ul li {
        font-size: 12px;
    }

    .desktop_html .case_challenge .carousel-indicators {
        top: -33px;
    }

    .desktop_html .case_challenge .carousel-control-next,
    .desktop_html .case_challenge .carousel-control-prev {
        top: -28px;
    }

    .desktop_html .case_challenge h5 {
        line-height: 20px;
    }

    .case-talk-form-dsktp {
        display: block;
    }

    .case-talk-form-mob {
        display: none;
    }

    .desktop_html .case_scope .description ul li {
        font-size: 14px;
    }

    .desktop_html .case_tst_bx .title {
        font-weight: 700;
        font-size: 15px;
        line-height: 22px;
        text-align: center;
        color: #F9F9F9;
        min-height: 50px;
    }

    .desktop_html .case_tst_bx .description {
        color: #fff;
        font-size: 10px;
        line-height: 20px;
        min-height: 100px;
    }

    .block_chain #clin_ls .item {
        width: 130px !important;
        height: 150px;
    }

    .block_chain .sec-7-img img {
        height: 60px !important;
        width: 60px !important;
    }

    .block_chain .sec-3_mid_text {
        margin-left: 0;
    }

    .block_chain .sec-3-h3 {
        font-size: 20px;
        line-height: 30px;
    }

    .block_chain .sec-3_mid_text .sec-3-h2 {
        text-align: center;
        font-size: 30px;
    }

    .tab-hire button.active:before {
        display: none;
    }



    .hire-ismobile .btn.btn-primary {
        background: #fff;
        text-transform: uppercase;
        font-weight: 600;
        border: 0;
        border-radius: 50px;
        letter-spacing: 1.6px;
        padding: 13px 25px;
        color: #101214;
        overflow: hidden;
        margin-top: 20px;
    }

    .input-div {
        text-align: -webkit-center;

    }

    .hire-col-sec2 .col-block {
        padding: 12px;
    }

    .hire-col-sec2 .col-block .col-text {
        font-size: 16px;
    }

    .hire-col-sec2 .col-block p {
        font-size: 12px;
        margin-top: 15px;
        line-height: 20px;
    }

    .hire-side-img .tech-name {
        font-size: 8px;
    }

    .sec-2-but-row {
        justify-content: center;
    }

    .sec2-head {
        font-size: 20px !important;
        text-align: center;
        line-height: 34px !important;
    }

    .tab-hire .mb-service {
        padding: 29px;
        font-size: 15px;
    }

    .sideTab-hire-tab {
        padding-right: calc(var(--bs-gutter-x) * .5);
    }

    .sideTab-hire .tabcontent {
        padding-top: 20px;
        padding-bottom: 20px;
    }

    .sub-service-services .tabcontent .tech-name {
        font-size: 9px !important;
    }

    .sec-3-hire {
        font-size: 20px;
    }

    .hire-col-sec2 {
        padding: 15px;
    }

    .sideTab-hire .tech-icon .hire-side-img img {
        width: 60px;
    }

    .cta-more-box.arrow.slide-right {
        left: 75%;
        top: 102%;
        z-index: 99;
    }

    .cta-more-box.arrow.slide-left {
        left: 15px;
        z-index: 99;
        top: 102%;
    }

    .section-container-8 .text-content.isactive p {
        min-height: 107px;
    }

    .intern-content h2 {
        max-width: 290px;
    }

    .desktop_html .case_hero .case_left_hero {
        padding: 20px 0 0;
    }

    .desktop_html .container {
        padding: 0 25px;
    }

    .desktop_html .case_about_sec p {
        line-height: 24px;
        font-size: 13px;
    }

    .desktop_html .case_tst_bx.htl_case p {
        min-height: 150px;
        font-size: 12px;
        line-height: 20px;
    }

    .desktop_html .case_tst_bx img {
        margin-bottom: 10px;
        max-width: 30px;
    }

    .desktop_html .case_tst_bx {
        padding: 10px 8px 10px;
    }

    .desktop_html .case_scope .order-last,
    .desktop_html .case_scope .order-first {
        order: inherit !important;
    }

    .desktop_html .case_scope .case_splash {
        max-width: 180px;
        margin: 50px auto 0;
        display: block;
    }

    .desktop_html .case_scope p {
        line-height: 22px;
        font-size: 13px;
    }

    .desktop_html .case_goals_bx {
        padding: 20px;
        min-height: auto !important;
        margin-bottom: 20px;
    }

    .desktop_html .case_challenge p,
    .desktop_html .case_conclusion p,
    .desktop_html .case_app p,
    .desktop_html .case_sec_head p,
    .desktop_html .case_goals_bx p {
        line-height: 24px;
        font-size: 12px;
    }

    .desktop_html .case_goals_bx h4 {
        font-size: 17px;
        line-height: 31px;
        margin-bottom: 8px;
    }

    .desktop_html .case_research,
    .desktop_html .lets_talk,
    .desktop_html .case_conclusion,
    .desktop_html .case_app,
    .desktop_html .case_wire,
    .desktop_html .case_design,
    .desktop_html .case_goals {
        padding: 60px 0;
    }

    .desktop_html .case_challenge h3 {
        font-size: 15px;
    }

    .desktop_html .case_challenge #case_chase {
        padding: 25px 25px;
        min-height: 500px;
    }

    .desktop_html .case_challenge h5 {
        font-size: 12px;
    }

    .desktop_html .case_wire .row.otb_wire li {
        max-width: 50%;
        flex: 0 0 50%;
    }

    .desktop_html .cd_bx h3 {
        font-size: 20px;
        margin-bottom: 30px;
    }

    .desktop_html .cd_bx .d-block img:first-child {
        max-width: 100% !important;
    }

    .desktop_html .palette li {
        flex: 0 0 25%;
        max-width: 25%;
    }

    .desktop_html .color_bx {
        height: 65px;
    }

    .desktop_html .color_bx+p {
        font-size: 13px;
    }

    .desktop_html .case_care p {
        line-height: 24px;
        font-size: 12px;
    }

    .desktop_html .case_care.case_care_htl {
        padding: 70px 0 !important;
    }

    .desktop_html .case_app img {
        max-width: 40px;
    }

    .desktop_html .cn_content {
        padding-top: 0;
    }

    .desktop_html .form-group input.form-control {
        border-radius: 5px;
        padding: 0 20px;
    }

    .desktop_html .lg_footer .text-right {
        text-align: left;
        margin-top: 30px;
    }

    .desktop_html .footer p a {
        margin-bottom: 0;
    }

    .desktop_html .footer li {
        list-style: none;
        margin-bottom: 7px;
    }

    .desktop_html .lg_footer+.row>div {
        margin-top: 30px;
    }

    .goa_cnt h3 {
        font-size: 20px;
        line-height: 35px;
    }

    .goa_cnt h5 {
        font-size: 14px;
        line-height: 24px;
        margin: 15px auto;
    }

    .goa_cnt .d-flex {
        display: block !important;
        text-align: center;
    }

    .goa_cnt .goCnt p {
        font-size: 14px;
        margin-bottom: 35px;
        margin-top: 40px;
    }

    .goa_cnt .btn.btn-primary {
        padding: 10px 20px;
        font-size: 14px;
        font-weight: bold;
    }

    .desktop_html .sb_sr_top h5 {
        line-height: 40px;
        margin-bottom: 40px;
    }

    .desktop_html .sb_sr_top .heading {
        font-size: 20px;
        line-height: 45px;
    }

    .desktop_html .sb_sr_top p {
        font-size: 13px;
        line-height: 25px;
        margin-bottom: 20px;
    }

    .desktop_html .btn.btn-primary {
        letter-spacing: 1.2px;
        padding: 13px 25px;
        font-size: 12px;
    }

    .desktop_html .sb_sr_top {
        position: relative;
        z-index: 5;
    }

    .desktop_html .srOurProcess h2 {
        font-size: 20px;
        line-height: 45px;
    }

    .desktop_html .srOurProcess h6 {
        font-size: 13px;
        line-height: 24px;
        margin-bottom: 40px;
    }

    .desktop_html .SuccessStories h2,
    .desktop_html .srv_testimonials h2 {
        font-size: 30px;
    }

    .desktop_html .heading p,
    .desktop_html .SuccessStories h6 {
        font-size: 14px;
    }

    .desktop_html .da_srv_bx {
        padding: 20px;
        margin: 0px auto 70px;
        width: 95%;
    }


    .desktop_html .tst_rating {
        text-align: left;
        margin-top: 0;
    }

    .desktop_html .tst_msg {
        font-size: 13px;
        text-align: left;
        min-height: 80px;
        line-height: 24px;
        margin-top: 5px;
    }

    .desktop_html .tst_cnt h5 {
        font-size: 12px;
    }

    .desktop_html .tst_img {
        width: 65px;
        height: 65px;
        margin-right: 20px;
        margin-top: 7px;
    }

    .desktop_html .da_srv_bx .d-flex {
        display: block !important;
    }


    .desktop_html .da-cluch-bx ul {
        min-height: 200px;
        max-height: 550px;
    }

    .desktop_html .da-cluch-bx li {
        flex: 0 0 45%;
        max-width: 45%;
        margin: 0 !important;
        padding: 18px;
    }

    .desktop_html .da-cluch-bx li img {
        max-height: 60px;
        margin: 0px;
    }

    .desktop_html .da-cluch-bx li p {
        margin-top: 15px;
        line-height: 19px;
        font-size: 10px;
    }

    .desktop_html .da-cluch-bx {
        margin-top: 0;
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .desktop_html .SuccessStories .text-right {
        text-align: right;
        margin-top: 17px;
    }

    .desktop_html .lets_talk_sus h3 a::before {
        display: none;
    }

    .desktop_html .lets_talk_sus {
        padding: 0px 0 50px;
    }

    .desktop_html .lets_talk_sus h3 a {
        font-size: 30px;
        border: 2px solid #e4a400;
        padding: 10px 30px;
        border-radius: 10px;
        color: #e4a400 !important;
    }

    .desktop_html .hrv_slide .hrs_detail {
        opacity: 1;
        top: auto;
        height: 50%;
        bottom: 0;
    }

    .desktop_html .hrs_bx h4 {
        font-size: 18px;
        margin-bottom: 0px;
    }

    .mobile_ani.w-100 {
        display: block;
    }

    .mobile_ani {
        display: block;
    }

    .dev_ani {
        display: none;
    }

    .top-digital-head .ban_caption {
        width: 100%;
        top: 12%;
    }

    .top-digital-head .ban_caption p {
        padding: 60px 30px 0px;
        opacity: 0.9 !important;
    }

    .digital-sec-4 .digital_4_slack {
        background-color: transparent !important;
        box-shadow: none !important;
        height: auto !important;
    }

    .digital_4_slack .allSideBorder {
        width: 100%;
        height: 25.5em;
    }

    .digital-sec-2 .block-card-slider .slick-center .sec-3-right {
        border: none;
    }

    .digital_4_slack .insideImg {
        position: relative;
        width: 100%;
        height: 25em;
        top: 4%;
        left: 6%;
    }

    .digital_4_slack .downText {
        margin-top: 0.5em;
        font-weight: 500;
        font-size: 22px;
        line-height: 48px;
        text-align: center;
        letter-spacing: 8px;
    }

    .digital_4_slack .sideText {
        /* white-space: pre-wrap; */
        font-weight: 500;
        font-size: 22px;
        text-align: center;
        position: relative;
        width: fit-content;
        margin: auto;
    }

    .digital_4_slack .outsideText {
        width: fit-content;
        position: absolute;
        top: 3%;
        left: 3%;
    }
}

@media (max-width: 400px) {

    .block_chain .sec-3 .prev-block {
        left: 5% !important;
    }

    .block_chain .sec-3 .next-block {
        right: 5% !important;
    }

    #scroll-top-button {
        bottom: 10%;
    }

    .meeting-btn {
        width: 100%;
        padding: 10px;
    }

    .meeting-btn .btn-link {
        font-size: 14px;
    }
}

@media (max-width: 500px) {
    .desktop_html .case_port .owl-prev {
        left: -16px !important;
    }

    .desktop_html .case_port .owl-next {
        right: -20px !important;
    }

    .blockChain_slider .inner_item {
        padding: 10px;
        width: 80px;
        height: 80px;
        margin: auto;
    }

    .blockChain_slider .slick-list {
        margin-bottom: 0px !important;
    }

    .blockChain_slider .item {
        padding: 30px 0px;
    }

    .blockChain_slider .item-heading {
        font-size: 13px;
        padding-top: 50%;
        bottom: 37%;
    }

    .blockChain_slider .slick-slide img {
        max-width: 90px;
    }

    .block_chain .sec-2 .number {
        font-size: 36px;
        margin-top: 20px;
    }

    .block_chain .sec-2 .text {
        font-size: 14px;
    }

    .block_chain .sec-7-img img {
        height: 60px !important;
        width: 60px !important;
    }

    .wp_padding {
        padding: 40px 0;
    }

    .block_chain .isdesktop {
        display: none;
    }

    .block_chain .isMobile {
        display: block;
    }

    .block_chain .sec-11-Head {
        position: absolute;
        top: 13%;

    }

    .block_chain .sec-11-Head .sec-3-h2 {
        font-size: 20px !important;
        background-color: black;
        max-width: 270px;
        line-height: 30px;
    }

    .block_chain .sec-3-h4 {
        font-size: 20px !important;
        text-align: left;
        /* margin: 10px; */
        padding: 0 15px;
        line-height: 30px;
    }

    .block_chain .sec-3-para {
        font-size: 12px !important;
        margin: 2em;
    }

    .block_chain .des-para {
        width: 100%;
    }

    .block_chain .sec-des-para {
        text-align: left;
        font-size: 18px !important;
    }

    .block_chain .sec-6-hr {
        width: 10%;
    }

    .block_chain .sec-6-but {
        margin-top: -2.8em;
    }

    .block_chain .sec-9-imgBox {
        border: none;
    }

    .block_chain .sec-8-imgText {
        font-size: 12px;
    }

    .block_chain .sec-10-h4 {
        font-size: 18px;
    }

    .block_chain .sec-10-p {
        font-size: 12px !important;
    }

    .block_chain .sec-11-p {
        font-size: 12px;
        margin-top: 20px;
    }

    .block_chain .sec-12-p {
        font-size: 12px !important;
    }

    .block-sec-1-des {
        display: block;
        height: 35vh;
        margin-top: 70px !important;
    }

    .block-sec-1-mob {
        display: none;
        padding-top: 65px;
    }

    .blockchain-banner {
        background: none;
    }

    .blockchain-content-wrap {
        padding-top: 20%;
    }

    .block-sec-1-des .video-container video {
        height: 100%;
        width: 100%;
    }

    .block-sec-1-des .heading {
        font-size: 36px !important;
        line-height: 76px;
        margin-bottom: 20px;
        text-align: left;
    }

    .block-sec-1-mob video {
        height: auto;
        width: 100%;
    }

    .block_chain .sec-3-left {
        width: 60px;
        height: 202px;
    }

    .block_chain .sec-3-right {
        width: 250px;
        height: max-content;
        padding: 20px 0px;
    }

    .block_chain .sec-3-right h2 {
        font-size: 16px;
        color: #D8D8D8;
        margin: 15px;
    }

    .block_chain .sec-3-right p {
        font-weight: 400;
        font-size: 12px;
        color: #ABABAB;
        margin: 15px;
    }

    .square-blockChain img {
        width: 11em;
    }

    .mobile_html_slide {
        display: block;
    }

    .desktop_html_img {
        display: none;
    }

    .top-digital-head.hm_banner {
        height: unset;
    }

    .top-digital-head .ban_caption {
        margin: -40% 0 0 0;
        position: relative;
    }

}

@media (max-width: 400px) {
    .block_chain .sec-11-Head {
        position: absolute;
        top: 12%;
    }

    .block_chain .sec-11-Head .sec-3-h2 {
        font-size: 25px;
        line-height: 30px;
    }

    .square-blockChain img {
        width: 9em;
    }

    .block-sec-1-des {
        height: 50vh;
        margin-top: 70px !important;
    }
}

@media (max-width: 350px) {
    .block_chain .sec-11-Head {
        position: absolute;
        top: 9%;
    }

    .block_chain .sec-11-Head .sec-3-h2 {
        font-size: 25px;
        line-height: 40px;
    }

    .block_chain .sec-3-h3 {
        font-size: 22px !important;
    }

    .square-blockChain img {
        width: 7em;
    }

    .top-digital-head.hm_banner {
        height: calc(100vh + 100vh/4);

    }

    .top-digital-head.hm_banner p {
        font-size: 14px;
        line-height: 21px;
    }

    .digital_4_slack .outsideText {
        left: 1px;
    }

    .block-sec-1-des {
        height: 60vh;
    }
}

.home_portfolio {
    position: absolute;
    bottom: -60px;
    left: 50%;
    margin-left: -75px !important;
}

.desktop_html .wdp_11 .sb_sr_top {
    max-width: 580px;
}

.desktop_html .wdp_11 .sb_sr_top h2 {
    font-size: 40px;
}

.desktop_html .sb_sr_top p {
    color: #fff;
}

.wdp_11 .btn.btn-primary.hvr-sweep-to-right {
    margin-bottom: 50px;
    margin-top: 50px;
}

.nw_projects.gray_sec.wp_padding.ds_flex.flex_colum.flex_alcenter {
    padding: 80px 20px;
    text-align: center;
}


.sub-services-top.sub-services-min {
    padding: 110px 0;
}

.sub-services-top.sub-services-min h3 {
    margin-bottom: 50px;
    font-size: 26px;
    font-weight: 600;
    line-height: 26px;
}

.desktop_html .wdp_11 .sb_sr_top {
    max-width: 580px;
}

.desktop_html .wdp_11 .sb_sr_top h2 {
    font-size: 40px;
}

.desktop_html .wdp_11 .sb_sr_top p {
    color: #f3f1f1;
}

/*reetu */

.services-top {
    padding: 150px 0 100px;
    position: relative;
}

.services-top img {
    max-width: 550px;
}


.main_img {
    max-width: 100%;
}


.border-box {
    position: absolute;
    border: 2px solid #f1b308;
    width: 85%;
    height: 800px;
    z-index: 10;
    left: 102px;
}


.desktop_html .bnft::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 400px;
    z-index: 0;
}

.blog_bx .hd {
    font-family: AssistantRoman;
    font-size: 28px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.54;
    color: white;
}

.blog_bx_p {
    font-family: Lato;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.63;
}

.blog_bx .srvc_btn {
    padding: 5px 25px 5px 25px;
    border: 1px solid #f1b308;
    display: inline-flex;
    color: white;
    font-size: 1.125rem;
    margin: 20px 0 0;
    position: relative;
    border-radius: 50px;
}

.srvc_btn:before {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 1px;
    content: "";
}

.srvc_btn:hover {
    background: #f1b308;
}


.owl-theme .owl-dots .owl-dot span {
    background: #958b8b !important;
}

.owl-theme .owl-dots .owl-dot.active span,
.owl-theme .owl-dots .owl-dot:hover span {
    background: #fff !important;
}


/*hiring logo*/

.hiring {
    display: flex;
    align-items: end;
}

.hiring-hd {
    background-color: #f1b308;
    color: black;
    padding: 3px 10px;
    margin-left: 15px;
    border-radius: 50px;
    font-size: 15px;
}

.hiring .sanat-img {
    display: block;
    margin-left: -15px;
}

@media (max-width: 800px) {

    .hiring-hd {
        font-size: 13px;
        border-radius: 50px;
    }

    .hiring .sanat-img {
        display: none;
    }

    .block-sec-1-des .video-container video {
        width: 100%;
        height: 100%;
    }

    .blockchain-banner {
        background: none;
    }

    .block-sec-1-des {
        margin-top: 130px !important;
    }
}

@media (max-width: 700px) {
    .hiring-hd {
        font-size: 13px;
        border-radius: 50px;
        margin-left: 130px;
    }
}

@media (max-width: 425px) {
    .hiring-hd {
        font-size: 13px;
        border-radius: 50px;
        margin-left: 50px;
    }
}

.sub-service .btn.btn-primary.hvr-sweep-to-right {
    margin-bottom: 0px;
    margin-top: 50px;
    background-color: #f1b308;
}

.sub-service .btn.btn-primary:hover {
    color: black;
}

.sub-service .main_content .heading {
    font-size: 40px;
}

.sub-service .main_content .heading span {
    color: #ffb700;
}

.sub-service .main_content .description {
    color: #f3f1f1;
    font-size: 17px;
    line-height: 30px;
    margin: 10px 20px 10px 20px;
}

.sub-service .main_content .description span {
    color: #ffb700;
}

.sub-service .btn_line.b {
    font-size: 1.03rem;
    padding: 12px 42px 12px !important;
}


.grad-ylw {
    background: radial-gradient(circle at left center, #f1b308 -40%, #101214, #101214 90%);
    background-repeat: no-repeat;
    background-size: contain;
    height: 800px;
    width: 100%;
    position: absolute;
}

.grad-prpl {
    background: radial-gradient(circle at right center, rgb(182, 172, 255) -41%, transparent, transparent 80%) 0% 0% / contain no-repeat;
    height: 500px;
    right: 0;
    top: 20%;
    width: 100%;
    position: absolute;
    pointer-events: none;
}


/*key feature */

.key_feature {
    position: relative;
    z-index: 10;
}

.key_feature .key-border-heading {
    font-size: 36px;
    text-transform: uppercase;
    letter-spacing: 12px;
    padding-bottom: 30px;
    width: max-content;
}

.key_feature .key-border {
    width: 75px;
    height: 2px;
    background: #f1b308;
}

.key_feature .key_img {
    position: relative;
}

.key_feature .txt {
    min-height: 80px;
    max-height: 120px;
}

.key_feature .txt.aos-animate:before {
    position: absolute;
    content: "";
    width: 1px;
    height: 80px;
    background-color: #f1b308;
    top: -55%;
    left: 50%;
}

.key_feature .txt.aos-animate:after {
    position: absolute;
    content: "";
    height: 8px;
    width: 8px;
    background-color: #b6acff;
    top: 43%;
    left: 49%;
    border-radius: 100%;
}

.key_feature .hv-txt .sr_line_1.aos-animate:before {
    animation-timing-function: linear;
    animation-iteration-count: 1;
    -webkit-animation: expand;
    animation-duration: 1.6s;
}

.key_feature .hv-txt .sr_line_2.aos-animate:before {
    animation-timing-function: linear;
    animation-iteration-count: 1;
    -webkit-animation: expand;
    animation-duration: 2s;
}

.key_feature .hv-txt .sr_line_3.aos-animate:before {
    animation-timing-function: linear;
    animation-iteration-count: 1;
    -webkit-animation: expand;
    animation-duration: 3.4s;
}

.key_feature .hv-txt .sr_line_4.aos-animate:before {
    animation-timing-function: linear;
    animation-iteration-count: 1;
    -webkit-animation: expand;
    animation-duration: 3.8s;
}

.key_feature .hv-txt .sr_line_1.aos-animate:after {
    animation-timing-function: linear;
    animation-iteration-count: 1;
    -webkit-animation: expand-1;
    animation-duration: 0.8s;
    animation-delay: 1.4s;
    opacity: 0;
    animation-fill-mode: forwards;
}

.key_feature .hv-txt .sr_line_2.aos-animate:after {
    animation-timing-function: linear;
    animation-iteration-count: 1;
    -webkit-animation: expand-1;
    animation-duration: 0.8s;
    animation-delay: 1.8s;
    opacity: 0;
    animation-fill-mode: forwards;
}

.key_feature .hv-txt .sr_line_3.aos-animate:after {
    animation-timing-function: linear;
    animation-iteration-count: 1;
    -webkit-animation: expand-1;
    animation-duration: 0.8s;
    animation-delay: 2.2s;
    opacity: 0;
    animation-fill-mode: forwards;
}

.key_feature .hv-txt .sr_line_4.aos-animate:after {
    animation-timing-function: linear;
    animation-iteration-count: 1;
    -webkit-animation: expand-1;
    animation-duration: 0.8s;
    animation-delay: 2.6s;
    opacity: 0;
    animation-fill-mode: forwards;
}

.key_feature .hv-txt .sr_line_1.aos-animate .heading {
    animation-timing-function: linear;
    animation-iteration-count: 1;
    -webkit-animation: expand-2;
    animation-duration: 0.8s;
    animation-delay: 1.6s;
    opacity: 0;
    animation-fill-mode: forwards;
    font-size: 14px;
}

.key_feature .hv-txt .sr_line_2.aos-animate .heading {
    animation-timing-function: linear;
    animation-iteration-count: 1;
    -webkit-animation: expand-2;
    animation-duration: 0.8s;
    animation-delay: 2s;
    opacity: 0;
    animation-fill-mode: forwards;
    font-size: 14px;
}

.key_feature .hv-txt .sr_line_3.aos-animate .heading {
    animation-timing-function: linear;
    animation-iteration-count: 1;
    -webkit-animation: expand-2;
    animation-duration: 0.8s;
    animation-delay: 2.4s;
    opacity: 0;
    animation-fill-mode: forwards;
    font-size: 14px;
}

.key_feature .hv-txt .sr_line_4.aos-animate .heading {
    animation-timing-function: linear;
    animation-iteration-count: 1;
    -webkit-animation: expand-2;
    animation-duration: 0.8s;
    animation-delay: 2.8s;
    opacity: 0;
    animation-fill-mode: forwards;
    font-size: 14px;
}

@-webkit-keyframes expand {
    0% {
        height: 0px;
    }

    100% {
        height: 80px;
    }
}

@-webkit-keyframes expand-1 {
    0% {
        height: 0px;
        opacity: 0;
    }

    100% {
        height: 8px;
        opacity: 1;
    }
}

@-webkit-keyframes expand-2 {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.hv-txt .txt {
    opacity: 1;
    padding-top: 60px;
}


/* app heading */

.app-head-white h2,
.app-head-white h3 {
    color: white;
    font-weight: 600;
    font-size: 36px;
    line-height: 54px;
}

.app-head-white h2 span,
.app-head-white h3 span {
    color: #f1b308;
    font-weight: 600;
    font-size: 36px;
    line-height: 54px;
}

.app-head-ylw h2 {
    color: #f1b308;
    font-weight: 600;
    font-size: 36px;
    line-height: 54px;
}

.sub-sr-tab-icon {
    height: 600px !important;
}

.sub-sr-tab-icon .tab-icon {
    position: absolute;
    bottom: 30%;
    right: 0;
}

.sr-cta {
    background-color: black;

}

.sr-cta .hm-btn-main .btn.btn-primary.btn-white:hover {
    border: 1px solid transparent !important;
    background-color: #f1b308 !important;
    color: black;
}

.sr-cta .hm-btn-main .hvr-sweep-to-right:hover::before {
    transform: scaleX(0) !important;
}

.sr-cta .hm-btn-main .btn.btn-primary:visited {
    border: 1px solid white !important;
    border-radius: 50px;
}

.sr-cta .hm-btn-main .btn.btn-primary:focus {
    border: 1px solid white !important;
    border-radius: 50px;
}

.sr-cta .hm-btn-main .btn.btn-primary.hvr-sweep-to-right:focus {
    transform: unset;
    border: 1px solid #f1b308 !important;
}

/* sub services service */
/* .sr-cta .hm-btn-main .btn.btn-primary {
    text-transform: capitalize;
} */

.sub-service-services {
    position: static;
    z-index: 9999;
}

.sub-service-services p {
    font-size: 16px;
    line-height: 28px;
    color: #e9e9e9;
    font-weight: normal;
}

.sub-service-services p a {
    color: #ffb700 !important;
}

.sub-service-services .tabcontent {
    font-weight: normal;
    font-size: 18px;
    line-height: 27px;
    color: #f9f9f9;
}

.sub-service-services .tabcontent .tech-name {
    font-size: 14px;
    color: white;
}

.sub-service-services .tabcontent .tech-name:hover {
    color: #FFA500;
}

.sub-service-services .sr-box .sr-sub-head {
    background-color: #101214;
    display: flex;
    justify-content: center;
    align-items: center;
}

.sub-service-services .sr-box .sr-sub-head .heading {
    font-size: 18px;
    line-height: 36px;
    font-weight: 600;
    transition: 0.3s;
    margin-bottom: 0;
    color: white;
}

.sub-service-services .sr-box:hover .sr-sub-head .heading {
    color: rgba(241, 179, 8, 0.83);

}

.sub-service-services .sr-box .sr-sub-head {
    margin-top: 20px;
    height: 100px;
    transition: height 1s ease-in-out;
    background-color: #101214;
}

.sub-service-services .sr-box:hover {
    height: max-content;
    transition: all 1s ease-in-out;
}

.sub-service-services .sr-box {
    margin-top: 20px;
    margin-bottom: 20px;
    height: 80px;
    transition: height 1s ease-in-out;
    background-color: #101214;
}

.sub-service-services .sr-box .sr-description {
    margin: 15px;
    opacity: 0;
    transition: all 400ms ease-in-out;
    height: 0px;
}

.sub-service-services .sr-box .sr-description .description {
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #9C9C9C;
    padding-bottom: 15px;
    height: 0;
}

.sub-service-services .sr-box:hover .sr-description {
    opacity: 1;
    height: max-content;
}

.sub-service-services .sr-box:hover .sr-description .description {
    height: max-content;
}

/*our work */

.our-work .our-work-border-heading {
    font-size: 36px;
    text-transform: uppercase;
    letter-spacing: 12px;
    padding-bottom: 15px;
    width: max-content;
}

.our-work .our-work-border {
    width: 93px;
    height: 2px;
    background: #f1b308;
}

.our-work .Prtflo .heading {
    font-size: 80px;
    color: #f1b308;
    font-weight: bold;
}

.our-work .Prtflo .port-img {
    width: 450px;
    padding-left: 10%;
}

.our-work .Prtflo .port-img img {
    border-radius: 15px;
}

.our-work .Prtflo .port-img h6 {
    color: #d8d8d8;
    font-size: 14px;
}

.our-work .Prtflo .know-more a {
    color: #f1b308;
    font-size: 16px;
    float: right;
}

.our-work .Prtflo .know-more a span {
    color: #979797;
}

.sr-tech .nav-link {
    font-size: 16px;
    line-height: 32px;
    color: #414141;
    padding: 0;
    height: 50px;
    text-align: center;
    border: 0px !important;
}

.sr-tech .nav-link.active {
    background: transparent;
    border: 0;
    border-bottom: 2px solid #f9f9f9 !important;
    color: #f9f9f9;
}

.tech-br {
    border-radius: 20px;
    border: 1px solid #333333;
    min-height: 200px;
    max-height: 600px;
}

.tech-br .tech-name {
    padding-top: 10px;
}

.tech-br .tech-icon img {
    width: 55px;
}

.tech-pd {
    padding: 10px 50px;
}

.tech-pd ul {
    display: flex;
    justify-content: space-around;
}

.tech-pdng {
    padding: 10px 95px;
}

.tech-pdng .nav-link.active {
    background: transparent;
    border: 0;
    border-bottom: 2px solid #f1b308 !important;
    color: #f1b308;
}

.tech-pdng .nav-tabs {
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    width: max-content;
    min-width: 100%;
}

.tech-pdng .nav-tabs .tech-pd-backend {
    padding: 0 80px;
}

.tech-pdng .nav-link {
    font-size: 16px;
    line-height: 32px;
    color: white;
    padding: 10px 15px;
    height: 50px;
    text-align: center;
    border: 0px !important;
}

.sr-tech .tech-border-heading {
    font-size: 36px;
    margin: auto;
    width: max-content;
    text-align: center;
}

.sr-tech .tech-border {
    width: 120px;
    height: 2px;
    background: #f1b308;
    margin: auto;
}

.sr-cta .heading {
    font-weight: 300;
    font-size: 32px;
    line-height: 48px;
    color: #e9e9e9;
    text-align: center;
}

.sr-cta p {
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;
    color: #f1b308;
    text-align: center;
}

.cta-br {
    border-top: 1px solid rgba(233, 180, 63, 0.5) !important;
    border-bottom: 1px solid rgba(233, 180, 63, 0.5) !important;
}

.sr-cta-dot-img {
    opacity: 1;

}


/*tesimonial */

.sub-service .testimonial-grad {
    background: radial-gradient(circle at right center, #f1b308 -44%, transparent, transparent 80%) 0% 0% / contain no-repeat;
    height: 500px;
    right: 0;
    top: -17%;
    width: 100%;
    position: absolute;
    pointer-events: none;
}

.sub-service .noteworthy {
    padding-bottom: 60px;
}

.sub-service .noteworthy .heading {
    color: #f9f9f9;
    font-size: 50px;
    line-height: 60px;
    max-width: 150px;
}


.sub-service .da_srv_bx {
    background: #101214;
    box-shadow: -9px -9px 20px 0 rgba(27, 27, 27, 0.2), 9px 9px 20px 0 rgba(0, 0, 0, 0.5);
    max-width: 450px;
    padding: 50px;
    margin: 70px auto;
    border-radius: 15px;
    color: #f9f9f9;
    border: 1px solid #333;
    max-height: 250px;
    min-height: 250px;
}

.sub-service .tst_img {
    width: 80px;
    height: 80px;
    overflow: hidden;
    border-radius: 50%;
    border: 2px solid #fdcb04;
    margin-right: 20px;
    position: absolute;
    top: -110%;
}

.sub-service .tst_img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.sub-service .tst_cnt {
    flex: 0 0 calc(100% - 100px);
    margin-top: 10px;
}

.sub-service .tst_cnt h4 {
    font-size: 16px;
    font-weight: 500;

}

.sub-service .tst_cnt span {
    display: inline-block;
    width: 50px;
    height: 2px;
    background: #f1b308;
}

.sub-service .tst_cnt h5 {
    font-size: 16px;
    font-weight: 500;
    opacity: 0.7;
}

.sub-service .tst_rating {
    margin-top: -15px;
    text-align: right;
}

.sub-service .tst_rating .fa {
    color: #E4A400;
    margin: 0 0 0 3px;
}

.sub-service .tst_msg {
    margin-top: 40px;
    font-size: 14px;
    text-align: center;
    min-height: 64px;
}


/*why us */

.why-content {

    height: 390px;
    margin-top: 25px;
}

.why-content .why-icon-img {
    justify-content: center;
    display: flex;
}

.why-content .why-icon-img .icon-1 {
    display: block;
}

.why-content .why-icon-img .icon-2 {
    display: none;
}

.why-content:hover .why-icon-img .icon-1 {
    display: none;
}

.why-content:hover .why-icon-img .icon-2 {
    display: block;
}

.why-content .hv-line {
    min-height: 0px;
    position: relative;
    transition: min-height 1s ease-in-out;
}

.why-content:hover .hv-line {
    min-height: 50px;
}

.why-content .hv-line:after {
    position: absolute;
    height: 0px;
    content: "";
    width: 2px;
    background-color: #f1b308;
    top: 20%;
    left: 50%;
    transition: height 1s ease-in-out;
}

.why-content:hover .hv-line:after {
    height: 50px;
}

@keyframes lineout {
    0% {
        top: 100%;
        height: 0px;
    }

    100% {
        bottom: 100%;
        height: 0px;
    }
}

@keyframes line {
    0% {
        top: -50%;
        height: 3px;
    }

    100% {
        bottom: 100%;
        height: 50px;
    }
}

.why-icon-title {
    max-width: 260px;
    animation: p-height 4s ease-out;
}

.why-icon-title .why-icon-heading {
    font-size: 20px;
    line-height: 27px;
    padding-bottom: 10px;
    color: #f1b308;
}

.why-icon-title p {
    color: #9c9c9c;
    font-size: 16px;
    line-height: 27px;
    text-align: center;
    overflow: hidden;
    max-height: 200px;

}



@keyframes fade-out {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}

.sub_sr_process {
    text-align: center;
    color: #f9f9f9;
    padding: 50px 0;
}

.sub_sr_process .heading {
    text-align: left;
    font-size: 36px;
    letter-spacing: 1px;
    line-height: 58px;
    margin-bottom: 10px;
}

.sub_sr_process .heading span {
    color: #f1b308;
}

.sub_sr_process h6 {
    text-align: left;
    font-size: 16px;
    line-height: 28px;
    margin-bottom: 70px;
}

.sub_sr_process .mobile_sr_process {
    display: none;
}


/*accordion-faq */

.sr_faq .accordion-faq {
    position: relative;
    padding-bottom: 40px;
}

.sr_faq .accordion-faq-grad {
    background: radial-gradient(circle at right center, #f1b308 -41%, transparent, transparent 80%) 0% 0% / contain no-repeat;
    height: 500px;
    right: 0;
    width: 100%;
    position: absolute;
}

.sr_faq .accordion-faq .faq-border-heading {
    font-size: 36px;
    text-transform: uppercase;
    letter-spacing: 12px;
    padding-top: 20px;
    width: max-content;
    text-align: center;
    margin: auto;
}

.sr_faq .accordion-faq .faq-span {
    font-size: 25px;
    margin-left: -22px;
}

.sr_faq .accordion-faq .faq-border {
    width: 90px;
    height: 2px;
    background: #f1b308;
}

.sr_faq .faq-img {
    position: absolute;
    right: 5%;
    top: 5%;
}

.sr_faq .accordion-faq ul.accordion-list {
    position: relative;
    display: block;
    width: 100%;
    height: auto;
    padding: 20px;
    margin: 0;
    list-style: none;
}

.sr_faq .accordion-faq ul.accordion-list li i {
    margin-top: 6px;
    font-size: 20px;
}

.sr_faq .accordion-faq ul.accordion-list li {
    position: relative;
    display: block;
    width: 100%;
    height: auto;
    padding: 20px;
    margin: 0 auto 15px auto;
    border-bottom: 1px solid #404040;
    padding-left: 0;
    cursor: pointer;
}

.sr_faq .accordion-faq ul.accordion-list h4 {
    position: relative;
    display: block;
    width: 100%;
    height: auto;
    font-size: 18px;
    line-height: 27px;
    letter-spacing: 0.01em;
    max-width: 440px;
}

.sr_faq div.answer {
    position: relative;
    display: block;
    width: 100%;
    height: auto;
    margin: 0;
    padding: 0;
    cursor: pointer;
}

.sr_faq div.answer p {
    position: relative;
    display: block;
    font-weight: 300;
    padding: 20px 0 0 0;
    cursor: pointer;
    line-height: 20px;
    margin: 0 0 15px 0;
    font-size: 14px;
    text-align: left;
    color: #ffffff;
}

.sr_faq .accordion-faq .accordion-list .active .rotate-icon {
    transform: rotate(45deg);
    color: #f1b308;
}

.sr_faq .accordion-faq .accordion-list .rotate-icon {
    transition: transform 0.5s ease-in-out;
    color: #f1b308;
}

.sr_faq .faq-icon {
    display: flex;
    justify-content: space-between;
}

.sr_faq .inner-ul {
    display: block;
    width: 100%;
    padding-left: 25px;
}

.sr_faq .inner-ul li {
    border: none !important;
    padding: 0 !important;
    margin: 0 !important;
    list-style: none;
}

.sr_faq .inner-ul li::before {
    content: '-';
    text-indent: -1em;
    display: inline-block;
}

.sub_sr_process {
    text-align: center;
    color: #f9f9f9;
    padding: 50px 0;
}

.sub_sr_process h2 {
    text-align: left;
    font-size: 50px;
    letter-spacing: 1px;
    line-height: 76px;
    margin-bottom: 10px;
}

.sub_sr_process h6 {
    text-align: left;
    font-size: 16px;
    line-height: 28px;
    margin-bottom: 70px;
}

.sub_sr_process .mobile_sr_process {
    display: none;
}

@media(max-width: 950px) {
    .sub-service-services .sr-box:hover {
        height: unset;
        margin-bottom: 30px;
        transition: all 1s ease-in-out;
    }

    .sub-service-services .sr-box .sr-sub-head,
    .sub-service-services .sr-box {
        height: 100px;
    }

    .key_feature .txt.aos-animate:after {
        left: 47.5%;
    }

    .key_feature .txt.aos-animate:before {
        left: 49.5%;
    }


    .our-work .Prtflo .port-img {
        width: 300px;
    }

    .tech-br .tech-pdng {
        padding: 10px 15px;
    }

    .block-sec-1-des .video-container video {
        width: 100%;
        height: 100%;
    }

    .blockchain-banner {
        background: none;
    }

    .block-sec-1-des {
        margin-top: 142px;
    }

    .sr-tech .tech-border-heading {
        width: unset;
    }

}

@media (max-width: 767px) {

    .sub-service-services .sr-box,
    .sub-service-services .sr-box .sr-sub-head {
        margin-bottom: 30px;
        height: 100px;
    }

    .sub-service-services .sr-box {
        margin-bottom: 30px;
    }

    .sub-service-services .sr-box:hover {
        height: unset;
        margin-bottom: 30px;
        transition: all 1s ease-in-out;
    }

    .ios-sec-box #themes {
        display: block;
    }

    .ios-sec-box #themes .column {
        width: 100%;
        margin-left: 0px;
    }

    .ios-sec-box #themes .column.hover {
        width: 100%;
        margin-bottom: 20px;
    }

    .ios-sec-box #themes .column.not_hover {
        width: 100%;
    }

    .sub-service .main_content .heading {
        font-size: 30px;
    }

    .sub-service .main_content .description {
        font-size: 15px;
        line-height: 19px;
    }

    .sub-service .btn_line:hover svg {
        right: 9px;
        top: 7px;
    }

    .key_feature .key-border-heading {
        font-size: 20px;
        letter-spacing: 10px;
    }

    .key_feature .key-border {
        width: 50px;
        height: 2px;
        margin-top: 0;
    }

    .sub-service .btn.btn-primary.hvr-sweep-to-right {
        margin-bottom: 0px;
        margin-top: 50px;
    }

    .vector {
        position: absolute;
        top: 100px;
    }

    .vector_content {
        margin-top: 0 !important;
    }

    .vector_txt_content p {
        font-size: 20px;
        margin-bottom: 15px;
        line-height: 35px;
    }

    .vector img {
        max-width: 100px;
    }

    .our-work .our-work-border-heading {
        font-size: 20px;
        letter-spacing: 10px;
        padding-bottom: 15px;
    }

    .our-work .our-work-border {
        width: 65px;
        height: 2px;
        margin-top: 0;
    }

    .our-work .Prtflo .heading {
        font-size: 50px;
    }

    .our-work .Prtflo .prt-img-2 {
        margin-top: 80px;
    }

    .our-work .Prtflo .port-img-hd {
        margin-left: 15px;
    }

    .our-work .Prtflo .know-more a {
        right: 10px;
        margin-top: -30px;
    }

    .sr_faq .accordion-faq .faq-border {
        width: 90px;
        height: 2px;
        margin-top: 0;
        left: 29%;
    }

    .sr_faq .accordion-faq ul.accordion-list h4 {
        width: 100%;
        height: auto;
        font-size: 16px;
        letter-spacing: 0.01em;
    }

    .sr_faq div.answer p {
        position: relative;
        display: block;
        font-weight: 300;
        padding: 10px 0 0 0;
        cursor: pointer;
        line-height: 1.5;
        margin: 0 0 15px 0;
        font-size: 14px;
        text-align: left;
    }

    .sr_faq .inner-ul {
        list-style: none;
        margin-left: 0;
        padding-left: 1em;
    }

    .sr_faq .inner-ul li {
        border: none !important;
        padding: 0 !important;
        margin: 0 !important;
        font-size: 14px;
        font-weight: 300;
    }

    .key_feature .key_img img {
        width: 100%;
    }

    .key_feature .txt.aos-animate:before {
        position: absolute;
        content: "";
        height: 80px;
        width: 1px;
        transition-duration: 0.5s;
        background-color: #f1b308;
        top: -38%;
        left: 50%;
    }

    .key_feature .txt.aos-animate:after {
        position: absolute;
        content: "";
        height: 9px;
        width: 9px;
        background-color: #b6acff;
        top: 46%;
        left: 47%;
        border-radius: 100%;
    }

    .key_feature .hv-txt .txt {
        /* opacity: 0; */
        padding-top: 65px;
        min-height: 80px;
        max-height: 100px;
    }

    .key_feature .txt {
        opacity: 1;
    }

    .sub_sr_process .heading {
        font-size: 20px;
        line-height: 45px;
    }

    .sub_sr_process h6 {
        font-size: 13px;
        line-height: 24px;
        margin-bottom: 40px;
    }

    .sub_sr_process .mobile_sr_process {
        display: block;
    }

    .sub-service .noteworthy .quote-img .left-sec {
        margin-top: 140px;
        margin-left: 0;
    }


    .sub-service .noteworthy .quote-img h2 {
        max-width: 220px;
    }

    .sub-service .da_srv_bx {
        min-height: 300px;
        max-height: 300px;
    }

    .sub-sr-tab-icon .tab-icon {
        opacity: 0.3;
        bottom: 10%;
    }

    .sub-service-services .sr-box {
        margin: 10px;
    }

    .tech-pd ul {
        display: block;
    }


    .tech-tab-scroll {
        overflow-x: scroll;
        overflow-y: hidden;
    }

    .tech-pdng .nav-tabs {
        justify-content: space-evenly;
        width: max-content;
    }

    .tech-pdng .nav-tabs .tech-pd-backend {
        padding: 0 50px;
    }

    .sr-cta .ct-btn .ft_ls_sec {
        display: block;
    }

    .sr-cta .ct-btn {
        display: none;
    }

    .sub-service .noteworthy .heading {
        font-size: 30px;
        line-height: 40px;
        max-width: 150px;
    }

    .app-head-white h3 {
        font-weight: 600;
        font-size: 30px;
        line-height: 38px;
    }

    .app-head-white h3 span {
        font-weight: 600;
        font-size: 30px;
        line-height: 35px;
        color: #ffb700;
    }

    .app-head-ylw h2 {
        font-weight: 600;
        font-size: 25px;
        line-height: 35px;
    }

    .sr-cta .heading {
        font-size: 30px;
        line-height: 35px;
    }

    .block-sec-1-des {
        margin-top: 100px !important;
    }

    .block-sec-1-des .heading {
        font-size: 32px !important;
    }
}

@media (max-width: 650px) {
    .block-sec-1-des {
        margin-top: 70px !important;
    }

    .block-sec-1-des .heading {
        font-size: 32px;
    }


}

@media (max-width: 450px) {

    .sub-service-services .sr-box,
    .sub-service-services .sr-box .sr-sub-head {
        margin-bottom: 30px;
    }

    .sub-service-services .sr-box {
        margin-bottom: 30px;
    }

    .sub-service-services .sr-box:hover {
        height: unset;
        margin-bottom: 30px;
        transition: all 1s ease-in-out;
    }

    .key_feature .txt.aos-animate:before {
        height: 80px;
        width: 2px;
        top: -11%;
        left: 48%;
    }

    .key_feature .txt.aos-animate:after {
        top: 40%;
        left: 45%;
    }

    .key_feature .hv-txt .txt {
        /* opacity: 0; */
        padding-top: 65px;
        min-height: 80px;
        max-height: 150px;
    }

    .key_feature .txt {
        opacity: 1;
    }

    .key_feature .hv-txt .heading {
        min-height: 80px;
    }

    .key_feature .hv-txt .sr_line_1.aos-animate .heading {
        font-size: 11px;
        margin-top: 15px;
    }

    .key_feature .hv-txt .sr_line_2.aos-animate .heading {
        font-size: 11px;
        margin-top: 15px;
    }

    .key_feature .hv-txt .sr_line_3.aos-animate .heading {
        font-size: 11px;
        margin-top: 15px;
    }

    .key_feature .hv-txt .sr_line_4.aos-animate .heading {
        font-size: 11px;
        margin-top: 15px;
    }

    .sr-cta .ct-btn .ft_ls_sec {
        display: block;
    }

    .sr-cta .ct-btn {
        display: none;
    }

    .sr_faq .faq-img img {
        height: auto;
        width: 110px;
    }

    .sr-tech .tech-border-heading {
        font-size: 25px;
        width: unset;
    }

    .sr-tech .tech-border {
        left: 36%;
        margin-top: 10px;
    }

    .sub-sr-tab-icon {
        height: 450px !important;
        margin-top: 30px;
    }

    .sub-sr-tab-icon .tab-icon {
        opacity: 0.3;
    }

    .sub-service-services .sr-box {
        margin: 10px;
    }

    .our-work .Prtflo .port-img {
        width: 300px;
    }

    .tech-pdng {
        padding: 0;
    }

    .tech-pdng .nav-tabs .tech-pd-backend {
        padding: 0 25px;
    }


}

@media (max-width: 300px) {
    .key_feature .txt.aos-animate:before {
        height: 80px;
        width: 2px;
        top: -11%;
        left: 48%;
    }

    .key_feature .txt.aos-animate:after {
        top: 42%;
        left: 41%;
        border-radius: 100%;
    }

    .key_feature .hv-txt .txt {
        /* opacity: 0; */
        padding-top: 80px;
        font-size: 10px;
    }

    .key_feature .txt {
        opacity: 1;
    }

    .sub-service .noteworthy .ntw_slide {
        margin-top: 80px;
    }

    .tech-pdng .nav-tabs .tech-pd-backend {
        padding: 0 20px;
    }
}


@media (max-width : 768px) {
    .animated-disable {
        /*CSS transitions*/
        -o-transition-property: none !important;
        -moz-transition-property: none !important;
        -ms-transition-property: none !important;
        -webkit-transition-property: none !important;
        transition-property: none !important;
        /*CSS transforms*/
        -o-transform: none !important;
        -moz-transform: none !important;
        -ms-transform: none !important;
        -webkit-transform: none !important;
        transform: none !important;
        /*CSS animations*/
        -webkit-animation: none !important;
        -moz-animation: none !important;
        -o-animation: none !important;
        -ms-animation: none !important;
        animation: none !important;
    }
}